import { Loader } from 'Components/Loader/Loader';
import { getTokenDetail } from '../../helpers/helper';
import Breadcrumb from 'Components/Common/Breadcrumb';
import CustomDataTable from 'Components/Shared/CustomDataTable'
import { Role, formatDate } from 'Components/constants/PandaConstants';
import { IColumnModel } from 'Models/IColumnModel';
import { ITicketModal } from 'Models/ITicketsModel';
import CommonService from 'Services/CommonService';
import Tickets from 'pages/Tickets/Tickets';
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { forkJoin } from 'rxjs';
import { CustomDropdown } from 'Components/Shared/CustomDropdown';
import { useFormik } from 'formik';
import { CustomDatePicker } from 'Components/Shared/CustomDatePicker';
import moment from 'moment';

function DashBoardTicket() {
    const [statusEntityMaster, setStatusEntityMaster] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [delect, setDelect] = useState();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [loader, setLoader] = useState(true);
    const [rowPerPageValue, setRowPerPageValue] = useState(10);
    const [filterDetails, setFilterDetails] = useState({
        fromDate: '',
        toDate: "",
        statusId: ''
    });
    const [dateValues, setDateValues] = useState<{ fromDate: any, toDate: any }>({ fromDate: '', toDate: "" });

    document.title = "Tickets | Panda GoGoGo ";

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromDate: "",
            toDate: "",
            statusId: ''
        },
        onSubmit: (values: any) => {
            // setHidden(false);
            // getOrderHistoryBySearch(1, "", validation.values.fromDate, validation.values.toDate);
        }
    });

    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'User Ticket ID',
            accessor: 'userTicketId',
            width: "25%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'User Name',
            accessor: 'userName',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'University Name',
            accessor: 'universityName',
            width: "20%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Ticket Subject',
            accessor: 'subject',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Status',
            accessor: 'statusId',
            width: "10%",
            displayInList: true,
            Filter: true,
            sortable: true,
            datatype: "statusIdTicket"
        }, {
            Header: 'Created Date',
            accessor: 'transactionDate',
            datatype: 'date',
            width: "13%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            sortable: true,
            displayInList: false,
            width: "0%"
        },
        {
            Header: 'Active',
            accessor: 'active',
            datatype: "boolean",
            Filter: true,
            sortable: true,
            width: '10%'
        },
    ], []);

    const [userTicket, setuserTicket] = useState<Array<ITicketModal>>([]);
    const [modalDetails, setModalDetails] = useState<{ userId: any, ticketId: any }>({ userId: 0, ticketId: 0 });

    const toggleUserTicketModal = () => {
        setModalDetails({ userId: 0, ticketId: 0 });
        GetTicketBySearch(1, "");
    }

    const filterHandler = (type: "fromDate" | "toDate" | "userId" | "menuId" | "statusId", val: any, fromDate?: any, toDate?: any) => {
        if (type == "fromDate" || type == "toDate") {
            filterDetails.fromDate = fromDate;
            filterDetails.toDate = toDate;
            setFilterDetails(filterDetails);
        }
        else {
            filterDetails[`${type}`] = val;
            setFilterDetails(filterDetails);
        }
        GetTicketBySearch(1, "", null, null, rowPerPageValue,filterDetails.statusId,filterDetails.fromDate,filterDetails.toDate)
    }

    const clear = () => {
        filterDetails.fromDate = "";
        filterDetails.toDate = "";
        filterDetails.statusId = "";
        dateValues.fromDate = '';
        dateValues.toDate = "";
        setSearch("");
        setFilterDetails(filterDetails);
        GetTicketBySearch(1, "", null, null, rowPerPageValue,filterDetails.statusId,filterDetails.fromDate,filterDetails.toDate)
    }

    const GetTicketBySearch = (pageNumber: any, searchText: string, sortcolumn?: any, sortorder?: any, rowPerPageVal?: any, statusId?: any, startDate?: any, endDate?: any) => {
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        let restaurantId = localStorage.getItem("restaurentId");
        let searchModel = {
            offsetStart: pageNumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "Desc",
            sortOrderColumn: sortcolumn ? sortcolumn : "Modified",
            searchText: searchText ? searchText : "",
            restaurentId: roleIdValue == Role.Admin ? restaurantId : null,
            statusId: statusId ? statusId : null,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null
        }
        CommonService.post("UserTicket", "GetTicketBySearch", searchModel).then((res) => {
            if (res.status == 200) {
                userTicketHandler(res.data.responseData);
                setLoader(false);
            }
        });
    }

    const editHandler = (e) => {
        const selectedTicket = userTicket?.filter(x => x?.id == e?.id);
        ticketModalHandler(selectedTicket[0]?.userId, selectedTicket[0]?.id);
    }

    const deleteHandler = (val) => {
        setDelect(val.id);
        setShowDeleteConfirmation(true);
    }

    const confirmDelete = () => {
        setShowDeleteConfirmation(false);
        CommonService.deleteWithQueryParam("UserTicket", "RemoveUserTicket", "id", delect)
            .then(res => {
                if (res.data.responseMessage === "Deleted Successfully.") {
                    setShowDeleteSuccess(true);
                    setTimeout(() => {
                        setShowDeleteSuccess(false);
                        GetTicketBySearch(1, "");
                    }, 2000);
                }
                else {
                    console.error("Delete failed with an unexpected response:", res);
                }
            })
            .catch(e => console.log(e));
    }

    const cancelDelete = () => {
        setShowDeleteConfirmation(false);
    }

    const ticketModalHandler = (userId, ticketId) => {
        setModalDetails({ userId: userId, ticketId: ticketId });
    }

    const onColumnSortChange = (columnName, sortorder) => {
        setSelectedColumn(columnName);
        setSortOrder(sortorder);
        GetTicketBySearch(1, search, columnName, sortOrder);
    };

    const userTicketHandler = (data: Array<ITicketModal>) => {
        // let result: Array<ITicketModal> = [];
        // for (let parentResult of data) {
        //     if (parentResult.ticketCommunication.at(-1)?.statusId != 15) {
        //         result.push(parentResult);
        //     }
        // }
        setuserTicket(data);
    }

    const pagination = (pageNumber: any) => {
        GetTicketBySearch(pageNumber, search, selectedColumn, sortOrder);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        GetTicketBySearch(1, searchKeyword);
    }

    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        GetTicketBySearch(1, "", null, null, val);
    }

    useEffect(() => {
        GetTicketBySearch(1, "");
        let status = CommonService.getWithQueryParam("Status", "GetStatusByEntityName", "EntityName", "Userticket");
        forkJoin({ status }).subscribe((res: { status: any }) => {
            setStatusEntityMaster(res.status.data.responseData);
        })
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Tickets" breadcrumbItem="Ticket" breadcrumbTitle={"Dashboard"}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard-ticket"} />
                <Loader open={loader} />
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={4} className="mb-3">
                                <CustomDropdown
                                    label={"Status"}
                                    id={"statusId"}
                                    data={statusEntityMaster}
                                    value={filterDetails.statusId}
                                    onChange={(e) => filterHandler("statusId", e.target.value)}
                                    // onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    error={validation.errors.statusId}
                                    invalid={validation.touched.statusId && validation.errors.statusId ? true : false}
                                    touched={validation.touched.statusId}
                                    valueField={"id"}
                                    displayField={"externalStatus"} />
                            </Col>
                            <Col lg={4}>
                                <CustomDatePicker label={"From Date"} id={undefined} value={moment(dateValues.fromDate).format("YYYY-MM-DD")} onChange={(e) => { setDateValues({ ...dateValues, fromDate: e.target.value }); filterHandler("fromDate", e.target.value, e.target.value, dateValues.toDate) }} error={undefined} />
                            </Col>
                            <Col lg={4}>
                                <CustomDatePicker label={"To Date"} id={undefined} value={moment(dateValues.toDate).format("YYYY-MM-DD")} onChange={(e) => { setDateValues({ ...dateValues, toDate: e.target.value }); filterHandler("toDate", e.target.value, dateValues.fromDate, e.target.value) }} error={undefined} />
                            </Col>
                        </Row>
                        <Row >
                            <Col lg={12} style={{ marginTop: "-15px" }}>
                                <div className='d-flex justify-content-end' >
                                    <Button
                                        style={{ width: '75px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                        type={"button"} onClick={() => clear()} className="btn btn-secondary ms-2">Reset
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <CustomDataTable
                            rowPerPageValue={rowPerPageValue}
                            rowPerPageHandler={rowPerPageHandler}
                            columns={columns}
                            data={userTicket}
                            customPageSize={rowPerPageValue}
                            canShowButton={false} deleteHandler={deleteHandler}
                            editHandler={editHandler}
                            createHandler={undefined} path={""} canView={false} isPagination={true}
                            isGlobalFilter={true} canEdit={true} searchHandler={searchHandler}
                            selectedPage={pagination} perPageData={rowPerPageValue} onColumnSortChange={onColumnSortChange}
                            // showDeleteIcon='No'
                            isAction={false} isShowingPageLength={true} />
                    </CardBody>
                </Card>
            </div>
            <Modal isOpen={modalDetails.userId > 0} toggle={toggleUserTicketModal} size='xl' centered>
                <ModalHeader toggle={toggleUserTicketModal}>Tickets</ModalHeader>
                <ModalBody>
                    <Tickets id={modalDetails.userId} ticketId={modalDetails.ticketId} />
                </ModalBody>
            </Modal>
            <div className="container">
                <Modal isOpen={showDeleteConfirmation} toggle={cancelDelete}>
                    <ModalHeader toggle={cancelDelete}>Confirm Delete</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={cancelDelete}>No</Button>
                        <Button color="danger" onClick={confirmDelete}>Yes</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
            <div className="container">
                <Modal isOpen={showDeleteSuccess} toggle={() => setShowDeleteSuccess(false)}>
                    <ModalHeader toggle={() => setShowDeleteSuccess(false)}>Success</ModalHeader>
                    <ModalBody>
                        Deleted successfully.
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default DashBoardTicket