import { HelperConstant } from 'Components/constants/PandaConstants';
import React, { InputHTMLAttributes, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Toast,
    ToastHeader,
    ToastBody,
    Col,
} from "reactstrap";
interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {string} message message you want to toast*/
    message: string;
    /** @param {any} color is defines the color of the toast*/
    color: "text-success" | "text-danger" | "text-warning",
    /** @param {boolean} toast is defines showing toast*/
    toast: boolean,
    /** @param {any} setToast is defines Toast & Timeout*/
    setToast: any,
    /** @param {any} path is defines the path which we have to navigate*/
    path?: string
}

export const CustomToaster = React.forwardRef(({ message, color, toast, setToast, path }: inputProps, ref) => {

    const toggleToast = () => {
        setToast(!toast);
    };
    let navigate = useNavigate();

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(false);
                if (path) {
                    navigate(path);
                }
            }, HelperConstant.TOAST_TIMEOUT);
        }
    }, [toast]);

    return (
        <React.Fragment>
            <div className="top-0 end-0 p-3" style={{ left: "40%", position: "fixed", zIndex: "9999" }}>
                <Col sm={12}>
                    <Toast color="danger" isOpen={toast} className={`${color == "text-danger" ? "bg-danger" : "bg-success"} align-items-center border-0`} role='alert'>
                        <ToastHeader toggle={toggleToast}>
                            <ToastBody className={color}>
                                {message}
                            </ToastBody>
                        </ToastHeader>
                    </Toast>
                </Col>
            </div>
        </React.Fragment >
    );
})

