import React, { InputHTMLAttributes } from "react";
import { FormFeedback, Input, Label } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} name reference of the elements*/
    name?: any;
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} id defines the id of the DOM element */
    id: any,
    /** @param {any} className return the value of an element's class*/
    className?: any,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder?: any;
    /** @param {any} value is used to set or get the value for the input field*/
    value?: any;
    /** @param {any} data is to show the dropdown list */
    data: Array<any>,
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid: any;
    /** @param {any} touched it takes boolean values as input and it sets to true if a field is clicked*/
    touched: any,
    /** @param {any} valueField is defines the field value*/
    valueField: any,
    /** @param {any} displayField is defines the field display*/
    displayField: string,
    /** @param {any} disabled is defines the if it's editable or not*/
    disabled?: any;
    /** @param {any} defaultValue is defines the default value of the dropdown*/
    defaultValue?: any;
    /** @param {any} readonly is defines it is non editable*/
    readonly?: any;
    /** @param {any} isMandatory is defines the if it's mandatory or not*/
    isMandatory?: any,
}


export const CustomDropdown = React.forwardRef(({ name, value, data, onChange, onBlur, label, error, touched, valueField, displayField, invalid, disabled, className, readOnly, isMandatory, id, ...rest }: inputProps, ref) => {
    return (
        <>
            <Label className="mt-3 mb-2">{label}</Label>
            {isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
            <Input ref={ref} {...rest as any}
                type="select"
                id={id}
                disabled={disabled == true ? true : false}
                defaultValue={displayField}
                className={`${className} mb-1`}
                name={name}
                data={data}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                invalid={invalid}
                error={error}
                touched={touched}
                readOnly={readOnly}
            >
                <option defaultValue="" value="">Select {label}</option>
                {data?.map((val, i) => <option key={i} value={val[`${valueField}`]}>{val[`${displayField}`]}</option>)}
            </Input >
            <FormFeedback>{error}</FormFeedback>
        </>
    )
})