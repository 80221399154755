import React, { useEffect, useState } from 'react';
import Breadcrumb from "Components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { CustomDatePicker } from 'Components/Shared/CustomDatePicker';
import moment from 'moment';
import CommonService from 'Services/CommonService';
import { CustomSingleCheckbox } from 'Components/Shared/CustomsSingleSelect';
import { formatDate } from 'Components/constants/PandaConstants';
import { useFormik } from 'formik';
import CustomCard from 'Components/Shared/CustomCard';
import MiniCards from 'Components/Shared/CustomMiniCard';
import { Loader } from 'Components/Loader/Loader';

const AnalyticsDashboard = () => {
    const [filterDetails, setFilterDetails] = useState({
        fromDate: '',
        toDate: "",
        universityId: "",
        isVerified: ''
    });
    const [selectedMenuIds, setSelectedMenuIds] = useState<any>(1);
    const [rowsCount, setRowsCount] = useState("");
    const [registeredUserCount, setRegisteredUserCount] = useState("");
    const [nonRegisteredUserCount, setNonRegisteredUserCount] = useState("");
    const [userOrderCount, setUserOrderCount] = useState("");
    const [loginCount, setLoginCount] = useState("");
    const [dateValues, setDateValues] = useState<{ fromDate: any, toDate: any }>({ fromDate: '', toDate: "" });
    const [loader, setLoader] = useState(true);
    const checkBoxData = [{
        id: 1,
        name: "Today"
    },
    {
        id: 2,
        name: "This week"
    }, {
        id: 3,
        name: "This month"
    },
    ]

    const miniCards = [
        { title: "Verified Users", iconClass: "fas fa-users", text: registeredUserCount },
        { title: "Non Verified Users", iconClass: "fas fa-user-friends", text: nonRegisteredUserCount },
        { title: "Orders", iconClass: "bx bx-hourglass", text: userOrderCount },
        { title: "Logged Users", iconClass:  "fas fa-users", text: loginCount },

    ];

    document.title = "Analytics | Panda GoGoGo ";

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromDate: "",
            toDate: "",
            universityId: "",
            isVerified: ''
        },
        onSubmit: (values: any) => {
            getAnalysisCount(filterDetails.fromDate, filterDetails.toDate)
        }
    });


    const filterHandler = (type: "fromDate" | "toDate" | "universityId" | "isVerified", val: any, fromDate?: any, toDate?: any) => {
        if (type == "fromDate" || type == "toDate") {
            filterDetails.fromDate = fromDate;
            filterDetails.toDate = toDate;
            setFilterDetails(filterDetails);
        }
        else {
            filterDetails[`${type}`] = val;
            setFilterDetails(filterDetails);
        }
        getAnalysisCount(filterDetails.fromDate, filterDetails.toDate)
    }

    const handleFilter = (value) => {
        setSelectedMenuIds(value);
        let fromDates = "";
        let toDates = "";
        dateValues.fromDate = "";
        dateValues.toDate = "";
        const date = new Date();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        if (value == 1) {
            validation.setFieldValue("fromDate", formatDate(date));
            validation.setFieldValue("toDate", formatDate(tomorrow));
            fromDates = formatDate(date);
            toDates = formatDate(tomorrow);
        } else if (value == 2) {
            const dayOfWeek = date.getDay();
            const diffFromMonday = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
            const monday = new Date(date.setDate(diffFromMonday));
            const sunday = new Date(date.setDate(diffFromMonday + 6));
            validation.setFieldValue("fromDate", formatDate(monday));
            validation.setFieldValue("toDate", formatDate(sunday));
            fromDates = formatDate(monday);
            toDates = formatDate(sunday);
        } else if (value == 3) {
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            validation.setFieldValue("fromDate", formatDate(firstDayOfMonth));
            validation.setFieldValue("toDate", formatDate(lastDayOfMonth));
            fromDates = formatDate(firstDayOfMonth);
            toDates = formatDate(lastDayOfMonth);
        }
        filterHandler("fromDate", fromDates, fromDates, toDates);
    }

    const clear = () => {
        const date = new Date();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        filterDetails.fromDate = formatDate(date);
        filterDetails.toDate = formatDate(tomorrow);
        dateValues.fromDate = '';
        dateValues.toDate = "";
        filterDetails.isVerified = ""
        setSelectedMenuIds(1);
        setFilterDetails(filterDetails);
        getAnalysisCount(filterDetails.fromDate, filterDetails.toDate)
    }

    const getAnalysisCount = (startDate: any, endDate: any) => {
        setLoader(true);
        CommonService.getWithDoubleQueryParam("UserOrder", "GetAnalysisCount", "startDate", startDate, "endDate", endDate ? endDate : formatDate(new Date())).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                setUserOrderCount(data?.userorderCount);
                setLoginCount(data?.loginCount);
                setRegisteredUserCount(data?.verifiedUserCount);
                setNonRegisteredUserCount(data?.nonVerifiedUserCount);
                setLoader(false);
            }
        })
    }

    useEffect(() => {
        const date = new Date();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        filterDetails.fromDate = formatDate(date);
        filterDetails.toDate = formatDate(tomorrow);
        getAnalysisCount(filterDetails.fromDate, filterDetails.toDate)
    }, [])

    return (
        <React.Fragment>
            <div className='page-content'>
                <Breadcrumb title="Analysis" breadcrumbItem="Analysis" breadcrumbTitle={"Dashboard"}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/analysis"} />
                <Card>
                    <CardBody>
                        <Row className='mb-3'>
                            <Col lg={4}>
                                <CustomDatePicker label={"From Date"} id={undefined} value={moment(dateValues.fromDate).format("YYYY-MM-DD")} onChange={(e) => { setDateValues({ ...dateValues, fromDate: e.target.value }); filterHandler("fromDate", e.target.value, e.target.value, dateValues.toDate); setSelectedMenuIds(0) }} error={undefined} />
                            </Col>
                            <Col lg={4}>
                                <CustomDatePicker label={"To Date"} id={undefined} value={moment(dateValues.toDate).format("YYYY-MM-DD")} onChange={(e) => { setDateValues({ ...dateValues, toDate: e.target.value }); filterHandler("toDate", e.target.value, dateValues.fromDate, e.target.value); setSelectedMenuIds(0) }} error={undefined} />
                            </Col>
                            <Col lg={4} className="mt-4 mb-3 custom-border">
                                <div className="border-label">
                                    <label className="border-label-text"></label>
                                    <CustomSingleCheckbox
                                        label={""}
                                        className={"col-6 mb-2 registration-custom-checkbox"}
                                        id={"ids"}
                                        value={undefined}
                                        onChange={handleFilter}
                                        invalid={undefined}
                                        error={undefined}
                                        data={checkBoxData}
                                        isHorizontal={true}
                                        selectedValues={selectedMenuIds}
                                        valueField={"id"}
                                        displayField={"name"} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className='d-flex justify-content-end' >
                                    <Button
                                        style={{ width: '75px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                        type={"button"} onClick={() => clear()} className="btn btn-secondary ms-2">Reset
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Loader open={loader} />
                <Row>
                    {miniCards.map((card, i) => (
                        <MiniCards title={card.title} text={card.text} iconClass={card.iconClass} key={"_card_" + i} />
                    ))}
                </Row>
            </div>
        </React.Fragment>
    )
}

export default AnalyticsDashboard;