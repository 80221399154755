import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProfileMenu from "../../Components/CommonForBoth/TopBarDropDown/ProfileMenu";
import logo from "../../assets/images/logo.png";

import { withTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import CommonService from "Services/CommonService";
import { ITicketModal } from "Models/ITicketsModel";
import moment from "moment";
import Tickets from "pages/Tickets/Tickets";
import { IOrderCountModel } from "Models/IOrderCountModel";
import { getTokenDetail } from "../../helpers/helper";
import { OrderStatus, Role, formatDate } from "Components/constants/PandaConstants";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { orderDeliveredChange, saveDeliveryDateHandler, saveOrdersCountChange } from "slices/thunk";
import { ITokenModel } from "Models/ITokenModel";
import { jwtDecode } from "jwt-decode";
import { Loader } from "Components/Loader/Loader";
import { CustomToaster } from "Components/Shared/CustomToaster";

const Header = (props: any) => {
  const [statsCount, setStatsCount] = useState<IOrderCountModel>();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  const [isRoleId, setIsRoleId] = useState<any>();
  const [invalidQR, setInvalidQR] = useState("");
  const [disabled, setDisabled] = useState(false);
  const dispatch: any = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const selectProperties = createSelector(
    (state: any) => state.Login,
    (error) => error
  );
  const { isOrderCountChange } = useSelector(selectProperties);
  const { startDate, endDate } = useSelector(selectProperties);

  const date = new Date();
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

  const statsCards = [
    { title: "Total Orders", iconClass: "bx bxs-face", text: statsCount?.totalOrders, bulletColor: "#ff6600" },
    { title: "Pending", iconClass: "mdi mdi-order-bool-ascending-variant", text: statsCount?.pendingOrders, bulletColor: '#e60000' },
    // { title: "Packed", iconClass: "bx bx-map", text: statsCount?.readyForPickupOrders, bulletColor: "#0000cc" },
    { title: "Delivered", iconClass: "bx bx-food-tag", text: statsCount?.deliveredOrders, bulletColor: "#00b300" }
  ];

  const navigate = useNavigate();

  const toggleFullscreen = () => {
    let document: any = window.document;
    document.body.classList.add("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
    const exitHandler = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      )
        document.body.classList.remove("fullscreen-enable");
    };
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
  };

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const [dataScanned, setDataScanned] = useState<any>();
  const [startScan, setStartScan] = useState(false);

  const handleScan = async (scanData: any) => {
    if (scanData) {
      let data = JSON.parse(scanData);
      let getRestaurantId = localStorage.getItem("restaurentId");
      CommonService.getWithDoubleQueryParam("UserOrder", "GetUserOrderByOrderId", "id", data.id, "restaurentId", getRestaurantId).then(res => {
        if (res.status == 200) {
          if (res.data.responseData == null) {
            setInvalidQR("This QR Code is not belongs to our Restaurant");
          }
          else {
            data.statusName = res.data.responseData.statusName;
            data.statusId = res.data.responseData.statusId;
            setDataScanned(data);
            setInvalidQR("");
          }

          // setStartScan(false);
        }
      })
    }
  };

  const updateStatus = (id: any) => {
    setLoader(true);
    let token: any = getTokenDetail();
    let userId = parseInt(token.LoginId, 10);
    CommonService.postWithDoubleQueryParam("UserOrder", "UpdateOrderCompletedStatus", "id", id, "userid", userId).then(res => {
      if (res.status == 200) {
        setLoader(false);
        setShowToast(true);
        sendEmail(id);
        setStartScan(true);
        setDataScanned("");
        setInvalidQR("");
        getTodayOrdersCount(formatDate(date), formatDate(tomorrow));
        dispatch(saveDeliveryDateHandler({ startDate: "", endDate: "" }))
      }
    });
  }

  const sendEmail = (id:any) => {
    CommonService.postWithQueryParam("UserOrder", "SendOrderDeliveredEmail", "id", id).then(res => {
      if (res.status == 200) {
        // setShowToast(false);
      }
    })
  }
  const handleError = (err: any) => {
    console.error(err);
  };

  const toggle = () => {
    setDataScanned("");
    setInvalidQR("");
  }

  const [userTicket, setuserTicket] = useState<Array<ITicketModal>>([]);
  const [modalDetails, setModalDetails] = useState<{ userId: any, ticketId: any }>({ userId: 0, ticketId: 0 });

  const toggleUserTicketModal = () => {
    setModalDetails({ userId: 0, ticketId: 0 });
  }

  const getAllUsertickets = () => {
    CommonService.get("UserTicket", "GetAllUserTicket").then((res) => {
      if (res.status == 200) {
        userTicketHandler(res.data.responseData);
      }
    });
  }

  const GetTicketBySearch = (pageNumber: any, searchText: string, sortcolumn?: string, sortorder?: string) => {
    let token: any = getTokenDetail();
    let roleIdValue = parseInt(token.RoleId, 10);
    let restaurantId = localStorage.getItem("restaurentId");
    let searchModel = {
      offsetStart: pageNumber,
      rowsPerPage: 10,
      sortOrder: sortorder ? sortorder : "Desc",
      sortOrderColumn: sortcolumn ? sortcolumn : "Modified",
      searchText: searchText ? searchText : "",
      restaurentId: roleIdValue == Role.Admin ? restaurantId : null,
    }
    CommonService.post("UserTicket", "GetTicketBySearch", searchModel).then((res) => {
      if (res.status == 200) {
        userTicketHandler(res.data.responseData);
      }
    });
  }


  const ticketModalHandler = (userId, ticketId) => {
    setModalDetails({ userId: userId, ticketId: ticketId });
  }

  // const userTicketHandler = (data: Array<ITicketModal>) => {
  //   let result: Array<ITicketModal> = [];
  //   for (let parentResult of data) {
  //     if (parentResult.ticketCommunication.at(-1)?.statusId != 15) {
  //       result.push(parentResult);
  //     }
  //   }
  //   setuserTicket(result);
  // }

  const userTicketHandler = (data: Array<ITicketModal>) => {
    let result: Array<ITicketModal> = [];

    for (let parentResult of data) {
      if (parentResult.statusId != 15) {
        result.push(parentResult);
      }

      setuserTicket(result);
    }
  }

  const getTodayOrdersCount = (startDate: any, endDate: any) => {
    let token: any = getTokenDetail();
    let roleIdValue = parseInt(token.RoleId, 10);
    let restaurantId = localStorage.getItem("restaurentId");
    // let todayDate = moment.utc().format("YYYY-MM-DD HH:mm:ss");
    CommonService.getWithTripleParam("UserOrder", "GetTodayOrdersCount", "restaurentId", roleIdValue == Role.Admin ? restaurantId : "", "startDate", startDate, "endDate", endDate).then((res) => {
      if (res.status == 200) {
        setStatsCount(res.data.responseData);
        dispatch(saveOrdersCountChange({ isOrderCountChange: false }));
      }
    });
  }

  const toggleScan = () => {
    setStartScan(!startScan);
    dispatch(orderDeliveredChange({ isOrderDeliverChange: true }));
  };

  useEffect(() => {
    getTodayOrdersCount(formatDate(date), formatDate(tomorrow));
    // getAllUsertickets();
    GetTicketBySearch(1, "");
  }, []);

  useEffect(() => {
    if (isOrderCountChange) {
      getTodayOrdersCount(formatDate(date), formatDate(tomorrow));
    }
  }, [isOrderCountChange])

  useEffect(() => {
    if (startDate || endDate) {
      getTodayOrdersCount(startDate, endDate);
    }
  }, [startDate, endDate])

  useEffect(() => {
    let token: string = localStorage.getItem("authUser") as string;
    let tokendata: ITokenModel = jwtDecode(token);
    setIsRoleId(tokendata.RoleId);

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* <div className="navbar-brand-box d-lg-none d-md-block"> */}
            <Link to="/dashboard" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="22" />
              </span>
            </Link>

            {/* <Link to="/dashboard" className="logo logo-light">
                <span className="logo-md">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link> */}
            {/* </div> */}

            <button
              type="button"
              onClick={() => tToggle()}
              className="btn btn-sm px-3 font-size-16 header-item mobile-button "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {/* {isMobileView ? <><button
              type="button"
              id="PopoverClicked"
              className="btn header-item noti-icon"
              data-toggle="fullscreen">
              <i className="fas fa-list"></i>
            </button> :
              <UncontrolledPopover
                placement="bottom"
                target="PopoverClicked"
                trigger="focus"
                className="transparent-popover"
              >
                {statsCards.map((val, i) => (
                  <Card className="mt-2 ms-2 transparent-card"
                    style={{ height: '60px', border: `1px solid "#000"` }}
                  >
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1" style={{ overflowWrap: 'break-word' }}>
                          <p className={`mb-0`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontSize: '35px', lineHeight: '1', color: `${val.bulletColor}` }}>&bull;</span>
                            {val.title} -
                            <span className="order-count">{val.text}</span>
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </UncontrolledPopover>
              
              </>
              :
              <>
                {statsCards.map((val, i) => (
                  <Card className="mt-2 ms-2"
                    style={{ height: '60px', border: `1px solid "#000"` }}
                  >
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1" style={{ overflowWrap: 'break-word' }}>
                          <p className={`mb-0`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontSize: '35px', lineHeight: '1', color: `${val.bulletColor}` }}>&bull;</span>
                            {val.title} -
                            <span className="order-count">{val.text}</span>
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}</>} */}
            {!isMobileView && <>
              {statsCards.map((val, i) => (
                <Card className="mt-2 ms-2"
                  style={{ height: '60px', border: `1px solid "#000"` }}
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1" style={{ overflowWrap: 'break-word' }}>
                        <p className={`mb-0`} style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ fontSize: '35px', lineHeight: '1', color: `${val.bulletColor}` }}>&bull;</span>
                          {val.title} -
                          <span className="order-count">{val.text}</span>
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}</>}


            {/* {statsCards.map((val, i) => (
              <Card className="mt-2 ms-2"
                style={{ height: '60px', border: `1px solid "#000"` }}
              >
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1" style={{ overflowWrap: 'break-word' }}>
                      <p className={`mb-0`} style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: '35px', lineHeight: '1', color: `${val.bulletColor}` }}>&bull;</span>
                        {val.title} -
                        <span className="order-count">{val.text}</span>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))} */}

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative ms-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}
          </div>
          <Modal isOpen={modalDetails.userId > 0} toggle={toggleUserTicketModal} centered size="lg">
            <ModalHeader toggle={toggleUserTicketModal}>Tickets</ModalHeader>
            <ModalBody className="p-0">
              <Tickets id={modalDetails.userId} ticketId={modalDetails.ticketId} />
            </ModalBody>
          </Modal>

          <Modal isOpen={invalidQR !== ""} toggle={toggle} color="primary" centered>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <p className="text-left= fw-bold font-size-14 text-danger">{invalidQR}</p>
                </Col>
                <Col lg={6} className="text-end">
                  <Button color="secondary" onClick={toggle}>
                    Close
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Modal isOpen={dataScanned?.id > 0} toggle={toggle} color="primary" centered>
            <ModalHeader toggle={toggle}>Order Details</ModalHeader>
            <ModalBody>
              <Row>
                <Col className="text-left">Order Id</Col>
                <Col className="text-left fw-bold font-size-15">{dataScanned?.id}</Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-left">Box Number</Col>
                <Col className="text-left fw-bold font-size-15">{dataScanned?.id}</Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-left">Menu Name</Col>
                <Col className="text-left fw-bold font-size-15">{dataScanned?.menuName}</Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-left">Order Status</Col>
                <Col className="text-left fw-bold font-size-15">{dataScanned?.statusName}</Col>
              </Row>
            </ModalBody>
            {dataScanned?.statusId < OrderStatus.OrderCompleted ?
              <ModalFooter>
                <Button color="primary" onClick={() => updateStatus(dataScanned?.id)} disabled={disabled}>
                  Complete Order
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
              : null}
          </Modal>
          <Loader open={loader} />
          <div className="d-flex">
            <div className="dropdown d-lg-inline-block ms-1">
              {isMobileView && <><Button
                type="button"
                id="PopoverClicked"
                className="btn header-item  custom-header-button"
              >
                {/* <i className="fas fa-list"></i> */}
                <i className="bx bx-food-menu" style={{ fontSize: '24px' }}></i>
              </Button><UncontrolledPopover
                placement="bottom"
                target="PopoverClicked"
                trigger="legacy"
                className="transparent-popover"
              >
                  <PopoverBody>
                    {statsCards.map((val, i) => (
                      <Card className="mt-2 ms-2 transparent-card"
                        style={{ height: '60px', border: `1px solid "#000"` }}
                      >
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1" style={{ overflowWrap: 'break-word' }}>
                              <p className={`mb-0`} style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '35px', lineHeight: '1', color: `${val.bulletColor}` }}>&bull;</span>
                                {val.title} -
                                <span className="order-count">{val.text}</span>
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover></>}

              {(isRoleId == Role.Admin) ? <button
                type="button"
                onClick={() => {
                  setStartScan(!startScan);
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="fa fa-qrcode" />
              </button> : ""}

              <Button
                type="button"
                id="PopoverClick"
                className="btn header-item custom-header-button"
              >
                <i className="fa fa-bell" aria-hidden="true" style={{ fontSize: '24px' }}></i>
                <small className="bell-count">{userTicket.length}</small>
              </Button>
              <UncontrolledPopover
                placement="bottom"
                target="PopoverClick"
                trigger="legacy"
              >
                <PopoverBody>
                  <div className="notification-container">
                    <h3>Notifications </h3>
                    {userTicket.map((val, i) => (
                      <div key={i} onClick={() => ticketModalHandler(val.userId, val.id)}>
                        <input className="checkbox" type="checkbox" id="size_1" value="small" checked />
                        <label className="notification new" htmlFor="size_1"><em>{i + 1}</em><a href="javascript:void(0)">{val.comments.slice(0, 10)} ...</a> {moment(val.created).format("DD MMM YYYY")}</label>
                      </div>
                    ))}
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
              <Modal isOpen={startScan} centered size="sm" toggle={toggleScan}>
                <ModalHeader toggle={toggleScan}>Scan QR</ModalHeader>
                <QrReader
                  delay={1000}
                  onError={handleError}
                  onScan={handleScan}
                  // chooseDeviceId={()=>selected}
                  style={{ width: "100%" }}
                />
                <CustomToaster message={"Order delivered Successfully!!!"} color={"text-success"} toast={showToast} setToast={setShowToast} />
              </Modal>
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment >
  );
};


export default withTranslation()(Header);
