import React, { useEffect } from 'react'
import "./ContactUs.css"
import { Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { pageNameHandler } from 'slices/thunk';

function ContactUs() {
    const dispatch: any = useDispatch();

    document.title = "Contact Us  | Panda GoGoGo ";

    useEffect(() => {
        dispatch(pageNameHandler({ pageName: "Contact Us" }));
    }, []);

    return (
        <section className="about-section bg-white default-height">
            <div className="container">
                <div className="row">
                    <h2 className='text-center'>If you have any queries, Kindly Contact Us</h2>
                    <Row className='mt-4 text-center'>
                        <Col lg={4} md={3} sm={1} xs={1}></Col>
                        <Col lg={4} md={6} sm={10} xs={10}>
                            <p className='contact-email'>admin@pandagogogo.com</p>
                        </Col>
                        <Col lg={4} md={3} sm={1} xs={1}></Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default ContactUs