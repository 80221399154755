import { CustomButton } from "Components/Shared/CustomButton";
import { EncodeParam } from "../../helpers/helper";
import { IRestaurantSearchModel } from "Models/IRestaurantSearchModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import Breadcrumb from "Components/Common/Breadcrumb";
import CustomViewCard from "Components/Shared/CustomViewCard";
import { Loader } from "Components/Loader/Loader";

const RestaurantDashBoard = () => {
    const [restaurant, setRestaurant] = useState<Array<any>>([]);
    const [search, setSearch] = useState("");
    const [isToggle, setToggle] = useState<boolean>(true);
    const [isToggleModel, setIsToggleModel] = useState(false);
    const [isDecodedRestaurantId, setIsDecodedRestaurantId] = useState<any>();
    const [loader, setLoader] = useState(true);
    const [isCommonToggle, setIsCommonToggle] = useState<boolean>(true);
    document.title = "Restaurant | Panda GoGoGo ";

    let navigate = useNavigate();

    const onAddRestaurantClick = () => {
        navigate('/restaurant/create');
    }

    const getRestaurantBySearch = (pagenumber: any, searchText: string, sortorder?: any, isActive?: boolean) => {
        setLoader(true);
        let searchModel: IRestaurantSearchModel = {
            offsetStart: pagenumber,
            rowsPerPage: 10,
            sortOrder: "Desc",
            searchText: searchText ? searchText : "",
            isActive: isActive
        }
        CommonService.post("Restaurent", "GetRestaurentBySearch", searchModel).then(res => {
            if (res.status == 200) {
                let data: Array<IRestaurantSearchModel> = res.data.responseData;
                setRestaurant(data);
                setLoader(false);
            }
        })
    }

    const pagination = (pageNumber: any) => {
        getRestaurantBySearch(pageNumber, search, null, isCommonToggle);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getRestaurantBySearch(1, searchKeyword, null, isCommonToggle);
    }

    useEffect(() => {
        getRestaurantBySearch(1, "", null, isCommonToggle)
    }, []);

    const editHandler = (val: any) => {
        let encodedId = EncodeParam(val.id)
        navigate(`/restaurant/view/${encodedId}`);
    }

    const onToggleChange = (value: any) => {
        setIsDecodedRestaurantId(value?.id);
        setIsToggleModel(true);
    }


    const onClose = () => {
        setIsToggleModel(false);
    }

    const onNoClicked = () => {
        setIsToggleModel(false);
        setToggle(!isToggle);
    }

    const onYesClicked = () => {
        CommonService.putWithQueryParam("Restaurent", "ActiveRestaurent", "id", isDecodedRestaurantId).then(res => {
            if (res.data.responseData == true) {
                setIsToggleModel(false);
                getRestaurantBySearch(1, "", null, isCommonToggle);
            }
        })
    }

    const onCommonToggleChange = () => {
        setIsCommonToggle(!isCommonToggle);
        getRestaurantBySearch(1, "", null, !isCommonToggle);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Restaurants" breadcrumbItem="Dashboard" breadcrumbTitle={""}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard"} />

                <div className="d-grid" style={{marginTop: "-20px"}}>
                    <div className="form-check form-switch mt-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="toggle"
                            checked={isCommonToggle}
                            onChange={() => onCommonToggleChange()}
                        />
                        <label className="form-check-label">{isCommonToggle ? "Active Restaurants" : "InActive Restaurants"}</label>
                    </div>
                    <Col className="text-end" style={{ marginTop: "-20px", marginBottom: "10px" }}>

                        <CustomButton type="button" btnClassName="btn btn-success w-md" iconClasssName="fa fa-plus" name="Create" onClick={() => onAddRestaurantClick()} />
                    </Col>
                </div>

                <Loader open={loader} />

                <CustomViewCard
                    data={restaurant}
                    iconClassName={""}
                    canEdit={false}
                    canView={false}
                    canToggle={true}
                    perPageData={10}
                    toggleChange={onToggleChange}
                    // checked={}
                    selectedPage={pagination}
                    paginationDiv={undefined}
                    onIconClick={editHandler}
                    onSearch={searchHandler}>
                </CustomViewCard>
            </div>
            <div className="container">
                <Modal isOpen={isToggleModel} toggle={onClose}>
                    <ModalBody>
                        <p><b>"Are you sure you want to Activation the restaurant?"</b></p>
                        <p>"This Activation should restore all the dependencies of this restaurant such as <b>Menu, Admin Users, Pickup address..</b>"</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ width: '80px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} type="button" onClick={() => onNoClicked()}>No</Button>
                        <Button style={{ backgroundColor: '#E75605', width: '80px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} type={"submit"} onClick={() => onYesClicked()}>Yes</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default RestaurantDashBoard;