import { EncodeParam, getTokenDetail } from "../../helpers/helper";
import Breadcrumb from "Components/Common/Breadcrumb";
import CustomDataTable from "Components/Shared/CustomDataTable";
import { CustomDropdown } from "Components/Shared/CustomDropdown";
import { CustomSingleCheckbox } from "Components/Shared/CustomsSingleSelect";
import { IColumnModel } from "Models/IColumnModel";
import { IOrderHistorySearchModel } from "Models/IOrderHistorySearchModel";
import CommonService from "Services/CommonService";
import FileSaver from "file-saver";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { forkJoin } from "rxjs";
import { useFormik } from "formik";
import './OrderHistoryStyle.css'
import { Role } from "Components/constants/PandaConstants";
import { CustomDatePicker } from "Components/Shared/CustomDatePicker";
import moment from "moment";
import { Loader } from "Components/Loader/Loader";
import { CustomAutoComplete } from "Components/Shared/CustomAutoComplete";
import { saveDeliveryDateHandler } from "slices/thunk";
import { useDispatch } from "react-redux";

const OrderHistoryDashBoard = () => {
    const [orderhistory, setOrderhistory] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [totalRowsCount, setTotalRowsCount] = useState<any>();
    const [isPageNumber, setIsPageNumber] = useState<any>();
    const [selectedMenuIds, setSelectedMenuIds] = useState<any>(1);
    const [hidden, setHidden] = useState(false);
    const [loader, setLoader] = useState(true);
    const [menuMaster, setMenuMaster] = useState<any>([]);
    const [statusEntityMaster, setStatusEntityMaster] = useState<any>([]);
    const [userMaster, setUserMaster] = useState<any>([]);
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [roleId, setRoleId] = useState(0);
    const [selectedColumn, setSelectedColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [restaurentId, setRestaurentId] = useState<any>();
    const dispatch: any = useDispatch();
    const [filterDetails, setFilterDetails] = useState({
        fromDate:'',
        toDate: "",
        userId: "",
        menuId: "",
        statusId: '',
    });
    document.title = "Order history | Panda GoGoGo ";

    const [dateValues, setDateValues] = useState<{ fromDate: any, toDate: any }>({ fromDate: '', toDate: "" });
    const [rowPerPageValue, setRowPerPageValue] = useState(10);

    const filterHandler = (type: "fromDate" | "toDate" | "userId" | "menuId" | "statusId", val: any, fromDate?: any, toDate?: any) => {
        if (type == "fromDate" || type == "toDate") {
            filterDetails.fromDate = fromDate;
            filterDetails.toDate = toDate;
            setFilterDetails(filterDetails);
        }
        else {
            filterDetails[`${type}`] = val;
            setFilterDetails(filterDetails);
        }

        getOrderHistoryBySearch(1, "", validation.values.fromDate, validation.values.toDate, null, null, filterDetails.statusId, filterDetails.userId, filterDetails.menuId, filterDetails.fromDate, filterDetails.toDate);
    }

    const sampledata = [{
        id: 1,
        name: "Today"
    },
    {
        id: 4,
        name: "Tomorrow"
    },
    {
        id: 2,
        name: "This week"
    }, {
        id: 3,
        name: "This month"
    },
    ]

    let navigate = useNavigate();
    const AdminColumns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Order Id',
            accessor: 'formattedOrderId',
            width: "17%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'User Name',
            accessor: 'userName',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Menu',
            accessor: 'menuName',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Status',
            accessor: 'externalStatus',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Amount ($)',
            accessor: 'amount',
            width: "12%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Order Date',
            accessor: 'created',
            datatype: 'date',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Delivery Date',
            accessor: 'deliveryDate',
            datatype: 'date',
            width: "35%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            sortable: true,
            displayInList: false,
            width: "0%"
        },
    ], []);

    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Order Id',
            accessor: 'formattedOrderId',
            width: "17%",
            displayInList: true,
            Filter: true,
        }, {
            Header: 'User Name',
            accessor: 'userName',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Restaurant',
            accessor: 'restaurentName',
            width: "15%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Menu Name',
            accessor: 'menuName',
            width: "15%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Status',
            accessor: 'externalStatus',
            width: "10%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Amount ($)',
            accessor: 'amount',
            width: "10%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Ordered Date',
            accessor: 'created',
            datatype: 'date',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Delivery Date',
            accessor: 'deliveryDate',
            datatype: 'date',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "0%"
        },
    ], []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromDate: "",
            toDate: "",
            userId: "",
            menuId: "",
            statusId: '',
        },
        onSubmit: (values: any) => {
            setHidden(false);
            getOrderHistoryBySearch(1, "", validation.values.fromDate, validation.values.toDate);
        }
    });

    const pagination = (pageNumber: any) => {
        getOrderHistoryBySearch(pageNumber, search, validation.values.fromDate, validation.values.toDate, selectedColumn, sortOrder,filterDetails.statusId,filterDetails.userId,filterDetails.menuId,filterDetails.fromDate,filterDetails.toDate);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getOrderHistoryBySearch(1, searchKeyword, validation.values.fromDate, validation.values.toDate);
    }

    const onColumnSortChange = (columnName, sortorder) => {
        setSelectedColumn(columnName);
        setSortOrder(sortorder);
        getOrderHistoryBySearch(1, search, "", "", columnName, sortOrder);
    };

    const handleFilter = (value) => {
        dateClearHandler();
        setSelectedMenuIds(value);
        let fromDates = "";
        let toDates = "";
        const date = new Date();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        if (value == 1) {
            validation.setFieldValue("fromDate", formatDate(date));
            validation.setFieldValue("toDate", formatDate(tomorrow));
            fromDates = formatDate(date);
            toDates = formatDate(tomorrow);
        } else if (value == 2) {
            const dayOfWeek = date.getDay();
            const diffFromMonday = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
            const monday = new Date(date.setDate(diffFromMonday));
            const sunday = new Date(date.setDate(diffFromMonday + 6));
            validation.setFieldValue("fromDate", formatDate(monday));
            validation.setFieldValue("toDate", formatDate(sunday));
            fromDates = formatDate(monday);
            toDates = formatDate(sunday);
        } else if (value == 3) {
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            validation.setFieldValue("fromDate", formatDate(firstDayOfMonth));
            validation.setFieldValue("toDate", formatDate(lastDayOfMonth));
            fromDates = formatDate(firstDayOfMonth);
            toDates = formatDate(lastDayOfMonth);
        }
        else if (value == 4) {
            const tomorrowNextDay = new Date(new Date().setDate(new Date().getDate() + 2));
            validation.setFieldValue("fromDate", formatDate(tomorrow));
            validation.setFieldValue("toDate", formatDate(tomorrowNextDay));
            fromDates = formatDate(tomorrow);
            toDates = formatDate(tomorrowNextDay);
        }
        dispatch(saveDeliveryDateHandler({ startDate: fromDates, endDate: toDates }));
        filterHandler("fromDate", fromDates, fromDates, toDates);
    }

    const formatDate = (date) => {
        return moment.utc(date).format('YYYY-MM-DD');
        // const utcDate = new Date(date);
        // utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset());

        // const year = utcDate.getUTCFullYear();
        // const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
        // const day = String(utcDate.getUTCDate()).padStart(2, '0');

        // return `${year}-${month}-${day}`;
    };

    const editHandler = (val: any) => {
        let encodedId = EncodeParam(val.id)
        navigate(`/order/view/${encodedId}`);
    }

    const onFiterClick = () => {
        setHidden(!hidden)
    }

    const onFilter = () => {
        setHidden(false);
    }

    const dateClearHandler = () => {
        dateValues.fromDate = "";
        dateValues.toDate = "";
        filterDetails.fromDate = "";
        filterDetails.toDate = "";
    }

    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        getOrderHistoryBySearch(1, "", validation.values.fromDate, validation.values.toDate, null, null, filterDetails.statusId, filterDetails.userId, filterDetails.menuId, filterDetails.fromDate, filterDetails.toDate, val);
    }

    const clear = () => {
        const date = new Date();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        // validation.resetForm();
        // getOrderHistoryBySearch(1, "", validation.values.fromDate, validation.values.toDate);
        filterDetails.fromDate = formatDate(date);
        filterDetails.toDate = formatDate(tomorrow);
        filterDetails.menuId = "";
        filterDetails.statusId = "";
        filterDetails.userId = "";
        dateValues.fromDate ='';
        dateValues.toDate = "";
        setSearch("");
        validation.setFieldValue("universityId","")
        setSelectedMenuIds(1);
        setFilterDetails(filterDetails);
        getOrderHistoryBySearch(1, "", validation.values.fromDate, validation.values.toDate, null, null, filterDetails.statusId, filterDetails.userId, filterDetails.menuId, filterDetails.fromDate, filterDetails.toDate);

    }

    const DownloadCSV = () => {
        let data: IOrderHistorySearchModel = {
            offsetStart: 1,
            rowsPerPage: totalRowsCount,
            sortOrder: sortOrder ? sortOrder : "Desc",
            searchText: search ? search : "",
            sortColumn: selectedColumn ? selectedColumn : "deliveryDate",
            userId: filterDetails.userId as any > 0 ? filterDetails.userId : null,
            statusId: filterDetails.statusId as any > 0 ? filterDetails.statusId : null,
            menuId: filterDetails.menuId as any > 0 ? filterDetails.menuId : null,
            restaurentId: roleId == Role.Admin ? restaurentId : null,
            startDate: filterDetails.fromDate ? filterDetails.fromDate : null,
            endDate: filterDetails.toDate ? filterDetails.toDate : null
        }
        CommonService.post("UserOrder", "ExportOrderViewCsv", data).then(
            (res) => {
                if (res.status === 200) {
                    const csvContent = atob(res.data);
                    const blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
                    const file = new File([blob], `PandaGoGoGo_OrderHistory_Report.csv`, { type: "data:application/octet-stream;base64" });
                    FileSaver.saveAs(file);
                }
            },
            (error) => {
                console.error('Error occurred:', error);
            }
        );
    }

    const getOrderHistoryBySearch = (pagenumber: any, searchText: string, fromDate: string, toDate: string, sortcolumn?: any, sortorder?: any, statusId?: any, userId?: any, menuId?: any, startDate?: any, endDate?: any, rowPerPageVal?: any) => {
        setLoader(true);
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        let restaurantId = localStorage.getItem("restaurentId");
        setRestaurentId(restaurantId);
        let searchModel: IOrderHistorySearchModel = {
            offsetStart: pagenumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "DESC",
            searchText: searchText ? searchText : "",
            sortColumn: sortcolumn ? sortcolumn : "formattedorderid",
            userId: userId > 0 ? userId : null,
            statusId: statusId > 0 ? statusId : null,
            menuId: menuId > 0 ? menuId : null,
            restaurentId: roleIdValue == Role.Admin ? restaurantId : null,
            // startDate: validation.values.fromDate.length > 0 ? validation.values.fromDate : null,
            // endDate: validation.values.toDate.length > 0 ? validation.values.toDate : null
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null
        }

        CommonService.post("UserOrder", "GetOrderViews", searchModel).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                setOrderhistory(data);
                setIsPageNumber(pagenumber);
                setTotalRowsCount(res.data.responseData[0]?.totalRowsCount);
                setLoader(false);
            }
        });
    }

    useEffect(() => {
        const date = new Date();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        validation.setFieldValue("fromDate", formatDate(date));
        validation.setFieldValue("toDate", validation.initialValues.toDate);
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        filterDetails.fromDate = formatDate(date);
        filterDetails.toDate = formatDate(tomorrow);
        setRoleId(roleIdValue);
        if (roleIdValue == Role.Admin) {
            setIsRestaurant(true);
        }
        let status = CommonService.getWithQueryParam("Status", "GetStatusByEntityName", "EntityName", "Order");
        let restaurantId = localStorage.getItem("restaurentId");
        if (roleIdValue == Role.SuperAdmin) {
            var menu = CommonService.get("Menu", "GetAllMenu");
            var user = CommonService.getWithQueryParam("User", "GetAllUserByUserRoleId", "userRoleId", Role.User);
        } else if (roleIdValue == Role.Admin) {
            var menu = CommonService.getWithQueryParam("Menu", "GetMenuByRestaurentId", "restaurentId", restaurantId);
            var user = CommonService.getWithDoubleQueryParam("User", "GetAllUserByRestaurentId", "userRoleId", Role.User, "restaurentId", restaurantId);
        }
        forkJoin({ menu, status, user }).subscribe((res: { menu: any, status: any, user: any }) => {
            setMenuMaster(res.menu.data.responseData);
            const uniques = res.user?.data?.responseData?.filter((value, index, self) =>
                index == self.findIndex((t) => (
                    t.id == value.id
                )));
            setUserMaster(uniques);
            const filteredStatus = res.status.data.responseData?.filter(status => status.name == "Order Delivered" || status.name == "Pending");
            setStatusEntityMaster(filteredStatus)
        });
        dispatch(saveDeliveryDateHandler({ startDate: formatDate(date), endDate: formatDate(tomorrow) }));
        getOrderHistoryBySearch(1, "", validation.values.fromDate, validation.values.toDate, null, null, filterDetails.statusId, filterDetails.userId, filterDetails.menuId, formatDate(date), formatDate(tomorrow));
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Order History" breadcrumbItem="Dashboard" breadcrumbTitle={""}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard"} />
                <Card>
                    <CardBody>
                        <Row className="p-4">
                            <Col lg={4} className=" mb-3">
                                <CustomDropdown
                                    label="Menu" id="menuId"
                                    data={menuMaster}
                                    value = { filterDetails.menuId}
                                    onChange={(e) => { filterHandler("menuId", e.target.value); } }
                                    placeholder="Menu"
                                    name="Menu Name"
                                    isMandatory={false}
                                    error={validation.errors.menuId}
                                    touched={validation.touched.menuId}
                                    displayField="displayName"
                                    valueField="id" onBlur={undefined} invalid={undefined} />
                            </Col>
                            <Col lg={4} className="mb-3">
                                <CustomDropdown
                                    label={"Status"}
                                    id={"statusId"}
                                    data={statusEntityMaster}
                                    value={filterDetails.statusId}
                                    onChange={(e) => filterHandler("statusId", e.target.value)}
                                    // onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    error={validation.errors.statusId}
                                    invalid={validation.touched.statusId && validation.errors.statusId ? true : false}
                                    touched={validation.touched.statusId}
                                    valueField={"id"}
                                    displayField={"externalStatus"} />
                            </Col>
                            <Col lg={4}>
                                <CustomDropdown
                                    label="User Name" id="userId"
                                    data={userMaster.map(user => ({
                                        ...user,
                                        fullName: `${user.firstName} ${user.lastName}`
                                    }))}
                                    value={filterDetails.userId}
                                    onChange={(e) => { filterHandler("userId", e.target.value); } }
                                    placeholder="User Name"
                                    name="User Name"
                                    isMandatory={false}
                                    error={validation.errors.userId}
                                    touched={validation.touched.userId}
                                    displayField="fullName"
                                    valueField="id" onBlur={undefined} invalid={undefined} />
                            </Col>
                            <Col lg={4}>
                                <CustomDatePicker label={"Delivery From"} id={undefined} value={moment(dateValues.fromDate).format("YYYY-MM-DD")} onChange={(e) => { setSelectedMenuIds(0); setDateValues({ ...dateValues, fromDate: e.target.value }); filterHandler("fromDate", e.target.value, e.target.value, dateValues.toDate) }} error={undefined} />
                            </Col>
                            <Col lg={4}>
                                <CustomDatePicker label={"Delivery To"} id={undefined} value={moment(dateValues.toDate).format("YYYY-MM-DD")} onChange={(e) => { setSelectedMenuIds(0); setDateValues({ ...dateValues, toDate: e.target.value }); filterHandler("toDate", e.target.value, dateValues.fromDate, e.target.value) }} error={undefined} />
                            </Col>
                            <Col lg={4} className="mt-4 custom-border">
                                <div className="border-label">
                                    <label className="border-label-text">Order Delivery</label>
                                    <CustomSingleCheckbox
                                        label={""}
                                        id={"ids"}
                                        className={"col-6 mb-2 registration-custom-checkbox"}
                                        value={undefined}
                                        onChange={handleFilter}
                                        invalid={undefined}
                                        error={undefined}
                                        data={sampledata}
                                        isHorizontal={true}
                                        selectedValues={selectedMenuIds}
                                        valueField={"id"}
                                        displayField={"name"} />
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col lg={12} style={{ marginTop: "-15px" }}>
                                <div className='d-flex justify-content-end' >
                                    <Button
                                        style={{ width: '75px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                        type={"button"} onClick={() => clear()} className="btn btn-secondary ms-2">Reset
                                    </Button>
                                    {orderhistory.length > 0 && <div onClick={() => DownloadCSV()} style={{ cursor: "pointer" }}>
                                        <i className="fa fa-file-excel excel-icon ms-3"></i>
                                    </div>}
                                </div>
                            </Col>
                        </Row>
                        <Loader open={loader} />
                        <CustomDataTable
                            rowPerPageValue={rowPerPageValue}
                            rowPerPageHandler={rowPerPageHandler}
                            columns={roleId > 1 ? AdminColumns : columns}
                            data={orderhistory}
                            customPageSize={rowPerPageValue}
                            editHandler={editHandler} canShowButton={false} deleteHandler={undefined}
                            createHandler={undefined} path={""} canView={false} perPageData={rowPerPageValue} isPagination={true}
                            isGlobalFilter={true} canEdit={false}
                            selectedPage={pagination}
                            searchHandler={searchHandler} isAction={false} isShowingPageLength={true} onColumnSortChange={onColumnSortChange} />
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}
export default OrderHistoryDashBoard;