import moment from "moment";
export const HelperConstant = {
    // image_cdn_Url: 'https://cdnpandagogo.inventsoftlabs.in',  //dev cdn
    image_cdn_Url: 'https://cdnpandagogogo.inventsoftlabs.in', //production cdn
    emailPattern: { pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/ },
    phonenumberPattern: { pattern: /^[1-9]\d{9}$/ },
    amountPattern: { pattern: /(\d)(?=(\d{3})+(?!\d))/g },
    TOAST_TIMEOUT: 4000,
    deliveryCutOffSplitPattern: { pattern: /-(?=\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/ },
    SalesTax: 8.25,
    couponCodeLimit: 5,
    MenuCutOffTimeText: "Order cutoff time is closed, Visit again after 2pm for tomorrow's order! ",
    cutOffTimegreater2pmConditionText: 'Cutoff time closed and greater than 2pm',
    cutOffTimeClosedConditionText: "Cutoff time closed and less then 2pm",
    nextDayMenuConditionText: 'Next day menu not available',
    allMenuConditionText: 'All Menu'

}

export const TablePagePerView = {
    Count: 10
}

export const cardStatus = (status: any) => {
    if (status === "Completed") {
        return "bg-success";
    }
    if (status === "Pending") {
        return "bg-warning";
    }
    if (status === "Delay") {
        return "bg-danger";
    }
    return '';
}

export const cardStatusRestaurants = (status: any) => {
    if (status === "Open") {
        return "bg-success";
    }
    if (status === "Closed") {
        return "bg-warning";
    }
    return '';
}

export const formatTableCell = (value: any, datatype: any) => {
    if (datatype === null) {
        return value;
    }
    else if (datatype === 'boolean') {
        return value == true ? 'Active' : 'InActive';
    }
    else if (datatype === 'Amount') {
        return '₹' + ' ' + value;
    }
    else if (datatype === 'int') {
        return value;
    }
    else if (datatype === 'date') {
        if (value === null) {
            return "-";
        } else {
            return moment(value).format("YYYY-MM-DD");
        }
    }
    else if (datatype === 'datetime') {
        if (value === null) {
            return value;
        } else {
            return moment(value).format("DD-MM-YYYY HH:mm");
        }
    }
    else if (datatype === 'stringWith15char' && value && value.length > 15) {
        return `${value.slice(0, 15)}...`;
    }
    else if (datatype === 'stringWith40Char' && value && value.length > 40) {
        return `${value.slice(0, 40)}...`;
    } else if (datatype === 'utcdatetime') {
        return moment.utc(value).local().format("YYYY-MM-DD HH:mm");
    } else if (datatype === 'coupontype') {
        if (value == 1) {
            return 'New Users'
        } else {
            return 'Existing Users'
        }
    } else if (datatype === 'couponpercentage') {
        return value + '' + '%'
    } else if (datatype === 'utcdate') {
        return moment.utc(value).local().format("YYYY-MM-DD");
    }
    return value;
}

export const Toaster_ENUM = {
    create: {
        text: "User Created Successfully !!",
        toasterColor: "bg-success",
        textColor: "text-success"
    },
    edit: {
        text: "Edited Successfully !!",
        toasterColor: "bg-success",
        textColor: "text-success"
    },
    resetPassword: {
        text: "Reset link are sended to your Mail !!",
        toasterColor: "bg-success",
        textColor: "text-success"
    },
    editUser: {
        text: "Updated Successfully!! The changes will applied, Once you logout and again login into the site!!",
        toasterColor: "bg-success",
        textColor: "text-success"
    },
    restaurentcreate: {
        text: "Restaurant Created Successfully !!",
        toasterColor: "bg-success",
        textColor: "text-success"
    },
}


export const Role = {
    SuperAdmin: 1,
    Admin: 2,
    User: 3,
}

export const OrderStatus = {
    Pending: 1,
    Confirmation: 2,
    PaymentFailed: 3,
    RestaurentConfirmation: 4,
    RestaurentRejection: 5,
    KitchenStarted: 6,
    ReadyForPickup: 7,
    OrderCompleted: 8,
    OrderMismatch: 9
}

export const days = [
    {
        id: 1,
        day: "Monday"
    }, {
        id: 2,
        day: "Tuesday"
    }, {
        id: 3,
        day: "Wednesday"
    }, {
        id: 4,
        day: "Thursday"
    }, {
        id: 5,
        day: "Friday"
    }, {
        id: 6,
        day: "Saturday"
    }, {
        id: 7,
        day: "Sunday"
    },
]

export const ticketStatus = {
    Open: 13,
    Inprogress: 14,
    Completed: 15,
    Re_Open: 16
}

export const splitDate = (date: string) => {
    let splitedParts = date.split(HelperConstant.deliveryCutOffSplitPattern.pattern);
    const fromDateime = splitedParts[0]?.trim();
    const toDateTime = splitedParts[1]?.trim();
    let fromTime = moment.utc(fromDateime).local().format('hh:mm A');
    let toTime = moment.utc(toDateTime).local().format('hh:mm A');
    return fromTime + "-" + toTime;
}

export const splitDateTime = (fromDate: string, toDate: string) => {
    let fromTime = moment(fromDate, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    let toTime = moment(toDate, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
    return fromTime + "-" + toTime;
}

export const formatTimeString = (fromTime: string, toTime: string) => {
    let formattedFromTime = moment(fromTime, 'HH:mm:ss').format('hh:mm A');
    let formattedToTime = moment(toTime, 'HH:mm:ss').format('hh:mm A');
    return formattedFromTime + "-" + formattedToTime;
}

export const formatDate = (date) => {
    return moment.utc(date).format('YYYY-MM-DD');
};

export const dateByDayId = (dayId: any) => {
    let difference;
    let day = moment().format('dddd');
    let todayId = days.find(item => item.day == day)?.id;
    if (todayId !== undefined && dayId < todayId) {
        difference = (7 - todayId) + dayId;
    }
    else if (todayId !== undefined && dayId > todayId) {
        difference = dayId - todayId;
    }

    let cutOffDate;
    if (dayId != undefined) {
        cutOffDate = dayId === todayId ? moment(new Date().toISOString()).format('DD MMMM YYYY') : moment(new Date(new Date().setDate(new Date().getDate() + Math.abs(difference))).toISOString()).format('DD MMMM YYYY,');
    }
    if (dayId === todayId) {
        cutOffDate = moment().format('DD MMMM YYYY');
    }
    return cutOffDate;
}

export const formatDay = (dayId: any) => {
    return days.find(item => item.id == dayId)?.day;
}