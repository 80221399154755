import { jwtDecode } from 'jwt-decode';
import { ITokenModel } from 'Models/ITokenModel';

export const getTokenDetail = () => {
    try {
        let token: string = localStorage.getItem("authUser") as string;
        let tokendata: ITokenModel = jwtDecode(token);
        return tokendata;
    }
    catch (Error) {
        return null;
    }
}


export const routeHandlerThroughUser = () => {
    try {
        let token: string = localStorage.getItem("authUser") as string;
        let tokendata: ITokenModel = jwtDecode(token);
        if (tokendata.RoleId == "1" || tokendata.RoleId == "2") {
            window.location.href = "/dashboard";
        }
    }
    catch (Error) {
        return null;
    }
}

export const EncodeParam = (param: any): any => {
    let encodedId = btoa(param);
    // let encodedId = param;
    return encodedId;
}

export const DecodeParam = (param: any): any => {
    if (param) {
        let decodedId = atob(param);
        // let decodedId = param;
        return decodedId;
    }
    else {
        return param;
    }
}

export const appendFormData = (formData, data, parentKey = '') => {
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            const nestedKey = parentKey ? `${parentKey}.${key}` : key;
            if (Array.isArray(data[key])) {
                for (let i = 0; i < data[key].length; i++) {
                    appendFormData(formData, data[key][i], `${nestedKey}[${i}]`);
                }
            } else if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
                appendFormData(formData, data[key], nestedKey);
            } else {
                formData.append(nestedKey, data[key]);
            }
        }
    }
    return formData;
}

export const restrictInput = (e: any, maxLength: number) => {
    if (e.target.value.length > maxLength) {
        e.target.value = e.target.value.slice(0, maxLength);
    }
}