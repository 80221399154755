import React, { useEffect, useState } from "react";
import Breadcrumb from 'Components/Common/Breadcrumb';
import { Row, Col, Form, Container, CardBody, Card, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomTextBox } from "Components/Shared/CustomTextBox";
import CommonService from "Services/CommonService";
import { CustomDropdown } from "Components/Shared/CustomDropdown";
import { forkJoin } from "rxjs";
import { CustomDateTimePicker } from "Components/Shared/CustomDateTimePicker";
import { DecodeParam, EncodeParam, getTokenDetail } from "../../helpers/helper";
import { Role } from "Components/constants/PandaConstants";
import { CustomNumberOnlyBox } from "Components/Shared/CustomNumberOnlyBox";
import { CustomButton } from "Components/Shared/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { ICouponCodeModel } from "Models/ICouponCodeModel";
import moment from "moment";
import AddUserPopup from "./AddUserPopup";
import { CustomToaster } from "Components/Shared/CustomToaster";
import { CustomMultiCheck } from "Components/Shared/CustomMultiCheck";
import { CustomMultiSelect } from "Components/Shared/CustomMultiSelect";

const CouponCodeForm = () => {
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [restaurentMaster, setRestaurentMaster] = useState<any>([]);
    const [isNewUser, setIsNewUser] = useState(false);
    const [isExistingUser, setIsExistingUser] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isDecodedId, setIsDecodedId] = useState(0);
    const [encodedId, setEncodedId] = useState(0);
    const [mailCountErr, setMailCountErr] = useState(false);
    const [discountErr, setDiscountErr] = useState(false);
    const [restaurentErr, setRestaurentErr] = useState(false);
    const [selectUserErr, setSelectUserErr] = useState(false);
    const [isToggle, setToggle] = useState<boolean>(true);
    const [isToggleModel, setIsToggleModel] = useState(false);
    const [isSendEmail, setIsSendEmail] = useState(true);
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    const [isUserSelected, setIsUserSelected] = useState(false);
    const [userCount, setUserCount] = useState(0);
    const [userIds, setUserIds] = useState<any>([]);
    const [filterUserIds, setFilterUserIds] = useState<any>([]);
    const [toast, SetToast] = useState(false);
    const [selectedRestaurentIds, setSelectedRestaurentIds] = useState([]);
    const [holdUserIds, setHoldUserIds] = useState([]);
    const [isUserAvailable, setIsUserAvailable] = useState(false);
    const minSelectableDate = new Date().toISOString().split('T')[0];
    const [selectedRestaurent, setSelectedRestaurent] = useState<any>([]);
    const { id } = useParams();
    const isEdit = !!id;
    let navigate = useNavigate();

    document.title = isEdit ? "Edit Coupon Code | Panda GoGoGo " : "Create Coupon Code | Panda GoGoGo";

    const CouponCodeData = [{
        id: 1,
        name: "New Users"
    },
    {
        id: 2,
        name: 'Existing Users'
    }
    ]


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            type: '',
            fromDate: '',
            toDate: '',
            emailCount: 0,
            discount: 0,
            maxcount: 0,
            restaurentId: '',
            restaurentName:''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Coupon  Name").max(20, "Coupon name should not exceed 20 characters"),
            type: Yup.string().required("Please Select the Coupon Type"),
            // emailCount: Yup.number().min(1, "Email count should be greater than 0").required("Please Enter your offer count"),
            // discount: Yup.number().min(1, "Discount should be greater than 0").required("Please Enter your Discount Percentage"),
            // restaurentId: Yup.string().required("Please Select the Restaurent"),
            fromDate: Yup.string().required("Please select the from date of the coupon "),
            toDate: Yup.date().required('Please select the to date of the coupon')
                .when('fromDate', (fromDate, schema) => {
                    return fromDate ? schema.min(fromDate, 'To date must be greater than from date') : schema;
                }),
        }),
        onSubmit: (values: any) => {
            createCouponCode(values);
        }
    });

    const createCouponCode = (couponData: ICouponCodeModel) => {
        if (validation.values.type == 1 && couponData.emailCount == 0) {
            setMailCountErr(true);
            return;
        } else if (couponData.discount == 0) {
            setDiscountErr(true);
            return;
        } else if (couponData.restaurentId.length == 0) {
            setRestaurentErr(true);
            return;
        }
        couponData.userIds = userIds;
        if (validation.values.type == 2 && couponData.userIds?.length == 0) {
            setSelectUserErr(true);
            return;
        }
        couponData.isExpired = false;
        couponData.fromDate = moment(couponData.fromDate).utc().format();
        couponData.toDate = moment(couponData.toDate).utc().format();
        couponData.id = isEdit ? isDecodedId : 0;
        couponData.discount = couponData.discount / 100;
        if (couponData.type == 1) {
            couponData.maxCount = null;
            couponData.isExisting = false;
        } else {
            if (isEdit) {
                couponData.emailCount = holdUserIds?.length + userIds?.length
            } else {
                couponData.emailCount = userIds?.length
            }
            couponData.isExisting = true;
        }
        setDisabled(true);
        CommonService.post("CouponCode", isEdit ? "UpdateCouponCode" : "CreateCouponCode", couponData).then(res => {
            if (res.status == 200) {
                navigate("/coupon-code");
            }
        })
    }

    const handleTypeChange = async (event) => {
        validation.handleChange(event);
        if (event.target.value == 1) {
            setIsNewUser(true);
            setIsExistingUser(false);
        } else {
            setIsExistingUser(true);
            setIsNewUser(false);
        }
    };

    const handleEmailCountChange = (event) => {
        validation.handleChange(event);
        if (event.target.value > 0) {
            setMailCountErr(false);
        } else if (event.target.value == 0 || event.target.value == "") {
            setMailCountErr(true)
        }
    }

    const handleDiscountChange = (event) => {
        validation.handleChange(event);
        if (event.target.value > 0) {
            setDiscountErr(false);
        } else if (event.target.value == 0 || event.target.value == "") {
            setDiscountErr(true)
        }
    }

    const validateUserRole = () => {
        let token: any = getTokenDetail();
        if (token.RoleId == Role.Admin) {
            setIsRestaurant(true);
            let restaurentId = localStorage.getItem("restaurentId");
            validation.setFieldValue("restaurentId", restaurentId);
        }
    }

    const onToggleChange = () => {
        setIsToggleModel(true);
        setToggle(!isToggle);
    }

    const onClose = () => {
        setIsToggleModel(false);
    }

    const onNoClicked = () => {
        setIsToggleModel(false);
        setToggle(!isToggle);
    }

    const onYesClicked = () => {
        {
            !isToggle ?
                CommonService.deleteWithQueryParam("CouponCode", "DeleteCouponCode", "id", DecodeParam(id)).then(res => {
                    if (res.data.responseData == true) {
                        setIsToggleModel(false);
                        navigate('/coupon-code');
                    }
                }) : CommonService.putWithQueryParam("CouponCode", "ActiveCouponCode", "id", DecodeParam(id)).then(res => {
                    if (res.data.responseData == true) {
                        setIsToggleModel(false);
                        navigate('/coupon-code');
                    }
                })
        }
    }


    const handleAddPopup = () => {
        setIsAddPopupOpen(true);
    }

    const toggleAddPopup = () => {
        setIsAddPopupOpen(!isAddPopupOpen)
    }

    const handleUserSeleted = (selectedUserIds: any) => {
        setIsAddPopupOpen(false);
        setIsUserSelected(selectedUserIds?.length > 0);
        setUserCount(selectedUserIds?.length);
        setUserIds(selectedUserIds);
    }

    const handleUserAvailable = (isUserAvailable) => {
        setIsUserAvailable(isUserAvailable);
    }

    const handleSendEmail = () => {
        let couponCodeData = {
            userIds: userIds,
            couponCodeId: parseInt(DecodeParam(id), 10)
        }
        CommonService.post("CouponCode", "CouponCodeExistingUserMail", couponCodeData).then((res) => {
            if (res.status == 200) {
                SetToast(true);
            }
        })
    }

    const handleMultiCheckboxChange = (values) => {
        setSelectedRestaurentIds(values);
        validation.setFieldValue("restaurentId", values);
    };

    const handleMultiselect = (e) => {
        const selectedIds = e.map(option => option.value);
        setSelectedRestaurentIds(selectedIds);
        validation.setFieldValue("restaurentId", selectedIds);
    };


    const getCouponCodeById = () => {
        let decodedId = DecodeParam(id);
        setIsDecodedId(decodedId);
        CommonService.getWithQueryParam("CouponCode", "GetCouponCodeById", "id", decodedId).then(res => {
            if (res.status == 200) {
                validation.setValues(res.data.responseData);
                validation.setFieldValue("restaurentId", res.data.responseData.restaurentId);
                CommonService.getWithQueryParam("Restaurent", "GetRestaurentById", "id", res.data.responseData?.restaurentId[0]).then(res => {
                    if (res.status == 200) {
                        validation.setFieldValue("restaurentName",res.data.responseData.displayName)
                    }
                })
                if (res.data.responseData.fromDate) {
                    validation.setFieldValue("fromDate", moment.utc(res.data?.responseData?.fromDate).local().format("YYYY-MM-DD HH:mm"))
                }
                validation.setFieldValue("toDate", moment.utc(res.data?.responseData?.toDate).local().format("YYYY-MM-DD HH:mm"))
                validation.setFieldValue("discount", res.data.responseData.discount * 100)
                if (res.data.responseData.type == 1) {
                    setIsNewUser(true);
                    setIsExistingUser(false);
                }
                else if (res.data.responseData.type == 2) {
                    setIsExistingUser(true);
                    setIsNewUser(false);
                    setSelectedRestaurentIds(res.data.responseData.restaurentId);
                    setFilterUserIds(res.data.responseData.userIds);
                    setHoldUserIds(res.data.responseData.userIds);
                    // setUserCount(res.data.responseData.userIds.length);
                    // setIsUserSelected(res.data.responseData?.userIds.length > 0);
                    CommonService.getWithQueryParam("CouponCode", "GetCouponCodeTransactionByCouponCodeId", "couponCodeId", decodedId).then((res => {
                        if (res.status == 200) {
                            setIsSendEmail(res.data.responseData);
                        }
                    }))
                }
                if (res.data.responseData.active == false) {
                    setToggle(!isToggle);
                }
                else {
                    setToggle(isToggle)
                }
            }

        })

    }

    useEffect(() => {
        let restaurent = CommonService.get("Restaurent", "GetAllRestaurent");
        forkJoin({ restaurent }).subscribe((res: { restaurent: any }) => {
            setRestaurentMaster(res.restaurent.data.responseData);
        });
        validateUserRole();
    }, []);

    useEffect(() => {
        if (isEdit) {
            setEncodedId(EncodeParam(id));
            getCouponCodeById();
        }
    }, [isEdit]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title={isEdit ? "Coupon Edit" : "Coupon  Creation"}
                        breadcrumbItem={"create"}
                        breadcrumbTitle={"Coupon"}
                        breadcrumbTitlePath={"/coupon-code"}
                        breadcrumbItemPath={isEdit ? `/coupon-code/edit/${encodedId}` : "/coupon-code/create"} />
                    <Card>
                        <CardBody>
                            <Form className="form-horizontal" onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                                <Row>
                                    <Col lg={4}>
                                        <div>
                                            <CustomTextBox
                                                label={"Coupon Name"}
                                                id={"name"}
                                                isMandatory={true}
                                                disabled={isEdit ? true : false}
                                                placeholder={"Enter the Coupon Name"}
                                                value={validation.values?.name}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                invalid={validation.touched.name && validation.errors.name ? true : false}
                                                error={validation.errors.name} />
                                        </div>
                                    </Col>
                                    {isEdit ? <Col lg={3}>
                                        <div>
                                            <CustomDateTimePicker
                                                label={"From Date"}
                                                id={"fromDate"}
                                                name={"fromDate"}
                                                isMandatory={true}
                                                value={validation.values?.fromDate}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                disabled={isEdit ? true : false}
                                                disablePreviousDates={true}
                                                invalid={validation.touched.fromDate && validation.errors.fromDate ? true : false}
                                                error={validation.errors.fromDate} />
                                        </div>
                                    </Col> : <Col lg={4}>
                                        <div>
                                            <CustomDateTimePicker
                                                label={"From Date"}
                                                id={"fromDate"}
                                                name={"fromDate"}
                                                isMandatory={true}
                                                value={validation.values?.fromDate}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                disablePreviousDates={true}
                                                disabled={isEdit ? true : false}
                                                invalid={validation.touched.fromDate && validation.errors.fromDate ? true : false}
                                                error={validation.errors.fromDate} />
                                        </div>
                                    </Col>}
                                    {isEdit ? <Col lg={3}>
                                        <div>
                                            <CustomDateTimePicker
                                                label={"To Date"}
                                                id={"toDate"}
                                                name={"toDate"}
                                                isMandatory={true}
                                                disabled={isEdit ? true : false}
                                                value={validation.values?.toDate}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                invalid={validation.touched.toDate && validation.errors.toDate ? true : false}
                                                error={validation.errors.toDate} />
                                        </div>
                                    </Col> : <Col lg={4}>
                                        <div>
                                            <CustomDateTimePicker
                                                label={"To Date"}
                                                id={"toDate"}
                                                name={"toDate"}
                                                isMandatory={true}
                                                disabled={isEdit ? true : false}
                                                value={validation.values?.toDate}
                                                disablePreviousDates={true}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                invalid={validation.touched.toDate && validation.errors.toDate ? true : false}
                                                error={validation.errors.toDate} />
                                        </div>
                                    </Col>}

                                    {isEdit ?
                                        <Col xxl={2} lg={2} md={2}>
                                            <div className="form-check form-switch mt-5 ">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="toggle"
                                                    checked={isToggle}
                                                    onChange={() => onToggleChange()}
                                                />
                                                <label className="form-check-label">{isToggle ? "Active" : "InActive"}</label>
                                            </div>
                                        </Col> : null}
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        {isRestaurant ? <CustomDropdown
                                            label="Restaurant"
                                            id={"restaurentId"}
                                            value={validation.values?.restaurentId}
                                            data={restaurentMaster}
                                            error={validation.errors.restaurentId}
                                            valueField="id"
                                            isMandatory={true}
                                            disabled={true}
                                            displayField='displayName'
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={validation.touched.restaurentId && validation.errors.restaurentId ? true : false}
                                            touched={validation.touched.restaurentId} /> : isEdit ? <CustomTextBox
                                            label={"Restaurant"}
                                            id={"restaurentName"}
                                            isMandatory={true}
                                            disabled={isEdit ? true : false}
                                            placeholder={""}
                                            value={validation.values?.restaurentName}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={validation.touched.restaurentName && validation.errors.restaurentName ? true : false}
                                            error={validation.errors.restaurentName} /> :
                                            // <CustomDropdown
                                            //     label="Restaurant"
                                            //     id={"restaurentId"}
                                            //     value={validation.values?.restaurentId}
                                            //     data={restaurentMaster}
                                            //     error={validation.errors.restaurentId}
                                            //     valueField="id"
                                            //     isMandatory={true}
                                            //     displayField='displayName'
                                            //     onChange={validation.handleChange}
                                            //     onBlur={validation.handleBlur}
                                            //     invalid={validation.touched.restaurentId && validation.errors.restaurentId ? true : false}
                                            //     touched={validation.touched.restaurentId}/>
                                            // <CustomMultiCheck
                                            //     label={"Restaurent"}
                                            //     id={"restaurentId"}
                                            //     isHorizontal={true}
                                            //     value={validation.values.restaurentId}
                                            //     onChange={handleMultiCheckboxChange} invalid={false}
                                            //     error={restaurentErr ? "Please Select any restaurant" : ""}
                                            //     data={restaurentMaster}
                                            //     touched={validation.touched.restaurentId}
                                            //     selectedValues={selectedRestaurentIds}
                                            //     valueField={"id"}
                                            //     displayField={"displayName"}
                                            //     disabled={isEdit ? true : false} />\
                                            <CustomMultiSelect value={undefined}
                                                onChange={(e) => {
                                                    handleMultiselect(e);
                                                }}
                                                isMandatory={true}
                                                label={"Restaurant"}
                                                name="restaurentId"
                                                id={"restaurentId"}
                                                error={restaurentErr ? "Please Select any restaurant" : ""}
                                                options={restaurentMaster.map((option) => ({
                                                    value: option.id,
                                                    label: option.displayName
                                                }))}
                                                disabled={isEdit ? true : false}
                                                valueField="id" />
                                        }
                                    </Col>
                                    <Col lg={6}>
                                        <CustomDropdown
                                            label="Coupon Applicable To"
                                            id={"type"}
                                            data={CouponCodeData}
                                            onChange={handleTypeChange}
                                            onBlur={validation.handleBlur}
                                            disabled={isEdit ? true : false}
                                            value={validation.values?.type}
                                            error={validation.errors.type} isMandatory={true}
                                            invalid={validation.touched.type && validation.errors.type ? true : false}
                                            touched={validation.touched.type}
                                            valueField={"id"}
                                            displayField={"name"} />
                                    </Col>
                                </Row>
                                {isNewUser &&
                                    <Row>
                                        <Col lg={6}>
                                            <CustomNumberOnlyBox
                                                label={"Number of Count"}
                                                id={"emailCount"}
                                                placeholder={"Enter the Number of offer count"}
                                                value={validation.values?.emailCount}
                                                onChange={handleEmailCountChange}
                                                onBlur={validation.handleBlur}
                                                invalid={validation.touched.emailCount && validation.errors.emailCount ? true : false}
                                                touched={validation.touched.emailCount}
                                                error={mailCountErr ? "Please Enter the Number of Offer count" : ""} />
                                        </Col>
                                        <Col lg={6}>
                                            <CustomNumberOnlyBox
                                                label={"Discount (%)"}
                                                id={"discount"}
                                                placeholder={"Enter the Discount Percentage"}
                                                value={validation.values?.discount}
                                                onChange={handleDiscountChange}
                                                onBlur={validation.handleBlur}
                                                invalid={validation.touched.discount && validation.errors.discount ? true : false}
                                                touched={validation.touched.discount}
                                                disabled={isEdit ? true : false}
                                                error={discountErr ? "Please Enter the Discount Percentage" : ""} />
                                        </Col>
                                    </Row>}
                                {isExistingUser && <Col lg={6}>
                                    <CustomNumberOnlyBox
                                        label={"Discount (%)"}
                                        id={"discount"}
                                        placeholder={"Enter the Discount Percentage"}
                                        value={validation.values?.discount}
                                        onChange={handleDiscountChange}
                                        onBlur={validation.handleBlur}
                                        invalid={validation.touched.discount && validation.errors.discount ? true : false}
                                        touched={validation.touched.discount}
                                        disabled={isEdit ? true : false}
                                        error={discountErr ? "Please Enter the Discount Percentage" : ""} />
                                </Col>}
                                <Col lg={8}>
                                    {(isExistingUser && !isUserSelected) && <><div style={{ display: "inline-block", position: 'relative', whiteSpace: 'nowrap' }}><CustomButton
                                        type="button"
                                        btnClassName="btn-success ms-2"
                                        iconClasssName="dripicons-plus"
                                        name="Add users"
                                        onClick={handleAddPopup}
                                        disabled={false} />
                                        {selectUserErr ?
                                            <span className="text-danger fa-sm mt-1" style={{ position: 'absolute', top: '100%', left: '5%' }}>{"Please Add Users for this Coupon"}</span> : null}</div>
                                    </>} {isExistingUser && isUserSelected && <CustomButton
                                        type="button"
                                        btnClassName="btn-success ms-2"
                                        iconClasssName=""
                                        onClick={handleAddPopup}
                                        name={`${userCount} Users Selected`}
                                        disabled={false}
                                    />}</Col>
                                <Row>
                                    <div className="d-flex justify-content-end mt-3">
                                        <CustomButton
                                            type="submit"
                                            btnClassName="btn-secondary ms-2"
                                            iconClasssName="mdi mdi-close"
                                            name="Cancel"
                                            onClick={() => navigate('/coupon-code')}
                                            disabled={false}
                                        />
                                        {/* {isUserSelected && <CustomButton
                                            type="button"
                                            btnClassName="btn-success loginbtn ms-2"
                                            iconClasssName="bx bx-send"
                                            name="Send Email"
                                            onClick={handleSendEmail}
                                            disabled={disabled}
                                        />} */}
                                        {isEdit && isExistingUser ? (isUserAvailable ? <CustomButton
                                            type="submit"
                                            btnClassName="btn-success loginbtn ms-2"
                                            iconClasssName="dripicons-checkmark"
                                            name="Submit"
                                            disabled={disabled}
                                        /> : null) : <CustomButton
                                            type="submit"
                                            btnClassName="btn-success loginbtn ms-2"
                                            iconClasssName="dripicons-checkmark"
                                            name="Submit"
                                            disabled={disabled}
                                        />}
                                    </div>
                                </Row>
                            </Form>
                            <div className="container">
                                <Modal isOpen={isToggleModel} toggle={onClose}>
                                    <ModalBody>
                                        <p><b>{!isToggle ? "Are you sure you want to Deactivate the Coupon Code?" : "Are you sure you want to Activate the Coupon Code?"}</b></p>
                                        <p>{!isToggle ? <p>This confirmation should deactivate the coupon code. No more coupon codes will be sent to the Users</p> : "This Activation should activate the coupon code and the coupon code will be sent to the new users "}</p>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button style={{ width: '80px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} type="button" onClick={() => onNoClicked()}>No</Button>
                                        <Button style={{ backgroundColor: '#E75605', width: '80px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} type={"submit"} onClick={() => onYesClicked()}>Yes</Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                            <CustomToaster message={"Coupon Code Mail are sent successfully !!!"} color={"text-success"} toast={toast} setToast={SetToast} path="/coupon-code" />
                            <div className="container">
                                <AddUserPopup isOpen={isAddPopupOpen} toggle={toggleAddPopup} restaurentId={selectedRestaurentIds} callBack={handleUserSeleted} selectedUserIds={userIds} filterUserIds={filterUserIds} isUserAvailable={handleUserAvailable} />
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CouponCodeForm