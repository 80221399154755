import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../Components/Common/ChartDynamicColor";
import CommonService from "Services/CommonService";

const DailyOrderChart = ({ data }: { data: Array<any> }) => {
    const dataColors = '["--bs-success"]';
    const spineareaChartColors = getChartColorsArray(dataColors);
    const [chartData, setChartData] = useState<any>({
        series: [],
        labels: [],
        colors: []
    });

    const series = [
        {
            name: "Orders",
            data: chartData.series,
        },
    ]
    const options: any = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top",
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"],
            },
        },

        colors: spineareaChartColors,
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: chartData.labels,
            position: "bottom",
            labels: {
                offsetY: -5,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            title: {
                text: "Date",
                offsetY: -50,
                style: {
                    color: "#444",
                },
            },
            crosshairs: {
                fill: {
                    type: "gradient",
                    gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
            tooltip: {
                enabled: true,
                offsetY: -35,
            },
        },
        fill: {
            gradient: {
                shade: "light",
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100],
            },
        },
        yaxis: {
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                formatter: function (val: any) {
                    return val
                },
            },
            title: {
                text: "Count",
                offsetY: -10,
                style: {
                    color: "#444",
                },
            },
        },
        title: {
            text: "Daily Orders",
            floating: true,
            offsetY: 310,
            align: "center",
            style: {
                color: "#444",
            },
        },
    }

    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        const datesArray = Array.from({ length: daysInMonth }, (_, index) => {
            const date = new Date(currentYear, currentMonth, index + 1);
            return date.toISOString().split('T')[0];
        });
        const series = datesArray.map(date => {
            const matchingData = data.find(item => item.name === date);
            return matchingData ? matchingData.dataCount : 0;
        });
        setChartData({ series, labels: datesArray });
    }, [data])

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
            className="apex-charts"
        />
    )
}
export default DailyOrderChart
