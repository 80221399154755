import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { InputHTMLAttributes } from "react";
import { Label } from "reactstrap";


interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} data is to show the dropdown list */
    data: Array<any>,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder?: any;
    /** @param {any} name reference of the elements*/
    name: any;
    /** @param {any} id defines the id of the DOM element */
    id: any,
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur?: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid?: any;
    /** @param {any} touched it takes boolean values as input and it sets to true if a field is clicked*/
    touched: any,
    /** @param {any} valueField is defines the field value*/
    valueField: string,
    /** @param {any} displayField is defines the field display*/
    displayField: string,
    /** @param {any} defaultValue is defines the default Value*/
    defaultValue?: any;
    /** @param {any} isMandatory is defines the if it's mandatory or not*/
    isMandatory?: boolean;
    /** @param {any} isDisabled is defines the field is displayed or not */
    isDisabled?: boolean;
    isOptionEqualToValue?: any
}

export const CustomAutoComplete = React.forwardRef(({ label, name, placeholder, displayField, valueField, data, error, defaultValue, touched, invalid, onChange, isMandatory, isDisabled, isOptionEqualToValue, ...rest }: inputProps, ref) => {
    return (
        <>
            <Label className="mt-3 mb-2" htmlFor={`${displayField}`}>{label}</Label>
            {isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
            <Autocomplete
                className={(error && touched) ? "error mb-1" : "mb-1"}
                style={{ border: (error && touched) ? "1px solid #f57777" : "unset", borderRadius: (error && touched) ? "5px" : "" }}
                onChange={onChange}
                options={data}
                disabled={isDisabled}
                defaultValue={data?.find(x => x[`${valueField}`] == defaultValue)}
                disableClearable
                size="small"
                getOptionLabel={(option: any) => (option[`${displayField}`] ?? "")}
                isOptionEqualToValue={(option: any, value: any) => true}
                renderOption={(props, options) => {
                    return (
                        <li {...props} key={options[`${valueField}`]} style={{ fontSize: "14px" }}>
                            {options[`${displayField}`]}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField {...params} placeholder={`Select ${label}`}
                        InputProps={{
                            ...params.InputProps,
                            style: { fontSize: "14px", background: "#fff" },

                            endAdornment: (
                                <InputAdornment position="end">
                                    {(error && touched) ?
                                        <i style={{ color: "#f57777", fontSize: "large" }} className="mdi mdi-alert-circle-outline"></i>
                                        :
                                        null
                                    }
                                </InputAdornment>
                            )
                        }} />
                )}
            />
            {(error && touched) && <span className="text-danger fa-xs" >{error as any}</span>}
        </>
    );
});

