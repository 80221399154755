import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import "./Tickets.css"
import { ITicketModal } from 'Models/ITicketsModel';
import { ITokenModel } from 'Models/ITokenModel';
import { jwtDecode } from 'jwt-decode';
import CommonService from 'Services/CommonService';
import moment from 'moment';
import notickert from '../../assets/images/no-orders.png'
import { Loader } from 'Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { pageNameHandler } from 'slices/thunk';

type Param = {
    id?: any,
    ticketId?: any
}

const Tickets = ({ id, ticketId }: Param) => {
    document.title = "Tickets | Panda GoGoGo";

    const [isModalOpen, setisModalOpen] = useState(false);
    const [comments, setComments] = useState("");
    const [subjects, setSubjects] = useState("");

    const [ticktesDetails, setTicketsDetail] = useState<Array<ITicketModal>>([]);
    const [loginDetail, setLoginDetail] = useState<ITokenModel>();
    const [status, setStatus] = useState<Array<any>>([]);
    const [load, setLoad] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number>(id > 0 ? 0 : -1);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoad(load);
    }, [load]);

    const toggle = () => {
        setisModalOpen(!isModalOpen);
        setComments("");
        setSubjects("");
    }

    const createHandler = () => {
        let data: ITicketModal = {
            id: 0,
            active: true,
            userId: loginDetail?.UserId,
            comments: comments,
            ticketCommunication: null,
            userRoleId: loginDetail?.RoleId,
            universityId: loginDetail?.UniversityId,
            subject: subjects
        }
        submitHandler(data);
    }

    const editHandler = (id, index, statusId) => {
        let data: ITicketModal = {
            id: id,
            active: true,
            userId: loginDetail?.UserId,
            statusId: statusId,
            comments: ticktesDetails[index].userAnswer,
            ticketCommunication: null,
            userRoleId: loginDetail?.RoleId,
            universityId: id ? null : loginDetail?.UniversityId,
        }
        ticktesDetails[index].userAnswer = "";
        setTicketsDetail(ticktesDetails);
        setLoad(!load);
        submitHandler(data);
    }

    const submitHandler = (data: ITicketModal) => {
        CommonService.post("UserTicket", "CreateUserTicket", data).then((res => {
            if (res.status == 200) {
                setisModalOpen(false);
                setComments("");
                setSubjects("");
                getTicketsByUser(loginDetail?.UserId);
            }
        })).catch(e => console.log(e));
    }

    const getStatus = () => {
        CommonService.getWithQueryParam("Status", "GetStatusByEntityName", "EntityName", "Userticket").then((res) => {
            if (res.status == 200) {
                setStatus(res.data.responseData);
            }
        });
    }

    const getTicketsByUser = (userId) => {
        setLoader(true);
        CommonService.getWithQueryParam("UserTicket", "GetUserTicketByUserId", "userId", id ? id : userId).then((res) => {
            if (res.status == 200) {
                if (ticketId > 0) {
                    setLoader(false);
                    let data: Array<ITicketModal> = res.data.responseData;
                    let result = data.filter(x => x.id == ticketId);
                    setTicketsDetail(result);
                }
                else {
                    setLoader(false);
                    setTicketsDetail(res.data.responseData);
                    setSelectedId(res.data?.responseData?.length - 1);
                }
            }
        });
    }

    const getColorCode = (id): "primary" | "secondary" | "success" | "danger" => {
        let statusName = status?.find((x) => x?.id == id)?.name;
        if (statusName == "Open") {
            return "secondary";
        }
        if (statusName == "Inprogress") {
            return "primary"
        }
        if (statusName == "ReOpen") {
            return "danger"
        }
        return "success"
    }

    const onChangehandler = (value, index) => {
        ticktesDetails[index].userAnswer = value;
        setTicketsDetail(ticktesDetails);
    }

    const accortionHandler = (index) => {
        if (selectedId == index) {
            setSelectedId(-1);
        }
        else {
            setSelectedId(index);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            let token: string = localStorage.getItem("authUser") as string;
            let tokendata: ITokenModel = jwtDecode(token);
            setLoginDetail(tokendata);
            getTicketsByUser(tokendata.UserId);
        }
        getStatus();
    }, []);

    const dispatch: any = useDispatch();

    useEffect(() => {
        dispatch(pageNameHandler({ pageName: "Tickets" }));
    }, []);

    return (
        <Container fluid className='p-lg-5 p-md-5 p-sm-4 bg-white default-height'>
            <Row className="tickets-page">
                {id ? null :
                    <Col md={12} className='text-end mb-3'>
                        <Button color="success" onClick={toggle}>Create a Ticket</Button>
                    </Col>
                }
                {(ticktesDetails.length === 0 && !loader) &&
                    <Row className="justify-content-center">
                        <img src={notickert} style={{ width: "80%" }} /> </Row>}
                        <Loader open={loader} />
                <Col md={12} className='text-center'>
                    {ticktesDetails.map((val, i) => (
                        <React.Fragment key={i}>
                            {ticketId ?
                                <div className="row">
                                    <div className="col-12">
                                        <h5> {val.userName} ({val.universityName})</h5>
                                    </div>
                                </div>
                                : null}
                            <input type="radio" id={`radioYour Account-${i}`} name="accordion" checked={selectedId == i} />
                            <label className={`item ${selectedId != i ? 'custom-ticket-height' : null}`} htmlFor={`radioYour Account-${i}`}>
                                <div className="ticket-title" onClick={() => accortionHandler(i)}> {val.subject}  ({val.userTicketId})
                                    <div style={{ marginLeft: 'auto' }}>
                                        {val?.ticketCommunication?.at(-1)?.statusId ? (
                                            <span
                                                className="text-light cutoff-badge text-end"
                                                style={{
                                                    fontSize: "12px",
                                                    padding: "5px",
                                                    borderRadius: "5px",
                                                    fontWeight: 600
                                                }}
                                            >
                                                {status?.find((x) => x?.id == val?.ticketCommunication?.at(-1)?.statusId)?.name}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                {val.ticketCommunication.map((tVal, tI) => (
                                    <div key={tI} className={`ticket-content ${tVal.userRoleName == "User" ? "ticket-user-content" : "ticket-admin-content"}`}>
                                        <i className={tVal.userRoleName == "User" ? "fa fa-user" : "fa fa-store"} aria-hidden="true"></i>
                                        <div className="row w-100">
                                            <div className="col-12">
                                                {(loginDetail?.RoleId == "3") ? <>
                                                    {tVal.userRoleId != "3" ? <h5 className='user'>PandaGoGoGo</h5> : <h5 className='user'>Me</h5>}
                                                </> : null}
                                                {(loginDetail?.RoleId != "3") ? <>
                                                    {tVal.userRoleId == "3" ? <h5 className='user'>{val.userName}</h5> : <h5 className='user'>Admin</h5>}
                                                </> : null}
                                            </div>
                                            <div className="col-11">
                                                <p className='user'>{tVal.comments}</p>
                                            </div>
                                            <div className="col-1">
                                                <h6>{moment(tVal.created).format("YYYY-MMM-DD")}</h6>
                                            </div>
                                        </div>

                                        <Badge color={getColorCode(tVal.statusId)}>{status?.find((x) => x?.id == tVal?.statusId)?.name}</Badge>
                                    </div>
                                ))}
                                {/* {val.ticketCommunication.at(-1).userRoleName != "User" ? */}
                                <div className="ticket-content ticket-user-content">
                                    <textarea value={val.userAnswer} onChange={(e) => onChangehandler(e.target.value, i)} placeholder='Reply here...' rows={2}></textarea>
                                    {loginDetail?.RoleId == "3" ?
                                        <>
                                            {status?.find((x) => x?.id == val?.ticketCommunication?.at(-1)?.statusId)?.name == "Completed" ?
                                                <select defaultValue={ticktesDetails[i].ticketCommunication.at(-1).statusId} onChange={(e) => ticktesDetails[i].ticketCommunication.at(-1).statusId = e.target.value}>
                                                    {status.filter(x => x.name == "ReOpen" || x.name == "Completed").map((val, i) => (
                                                        <option key={i} value={val.id}>{val.name}</option>
                                                    ))}
                                                </select>
                                                : null
                                            }
                                        </>
                                        :
                                        <select defaultValue={ticktesDetails[i].ticketCommunication.at(-1).statusId} onChange={(e) => ticktesDetails[i].ticketCommunication.at(-1).statusId = e.target.value}>
                                            {status.map((val, i) => (
                                                <option key={i} value={val.id}>{val.name}</option>
                                            ))}
                                        </select>
                                    }
                                    <span className='plane-i' onClick={() => val.userAnswer ? editHandler(val.id, i, ticktesDetails[i].ticketCommunication.at(-1).statusId) : null}>Send</span>
                                </div>
                                {/* :
                                    null
                                } */}
                            </label>
                        </React.Fragment>
                    ))}
                </Col>
            </Row>
            <Modal isOpen={isModalOpen}>
                <ModalHeader toggle={toggle}>Raise a Ticket</ModalHeader>
                <ModalBody >
                    <Input type="text" value={subjects} onChange={(e) => setSubjects(e.target.value)} placeholder='Subject' style={{ width: "100%", marginBottom: "15px" }} />
                    <Input type="textarea" value={comments} onChange={(e) => setComments(e.target.value)} rows={5} placeholder='Write here...' style={{ width: "100%" }} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={comments == "" || subjects == ""} onClick={() => createHandler()}>
                        Submit
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
}

export default Tickets;