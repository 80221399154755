import { getTokenDetail } from "helpers/helper";
import withRouter from "Components/Common/withRouter";
import { CustomDropdown } from "Components/Shared/CustomDropdown";
import { CustomTextBox } from "Components/Shared/CustomTextBox";
import { OrderStatus, Role } from "Components/constants/PandaConstants";
import CommonService from "Services/CommonService";
import { useFormik } from "formik";
import React from "react";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { forkJoin } from "rxjs";
import * as Yup from "yup";
import { IOrderModel } from "Models/IOrderModel";

type Props = {
    orderId: any,
    canCancel: any,
    isModelOpen: any,
}

const OrderEditForm = ({ orderId, canCancel, isModelOpen }: Props) => {
    const [statusMaster, setStatusMaster] = useState<any>([]);
    const [orderDetails, setOrderDetails] = useState<IOrderModel>();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            kitchenUserId: "",
            deliveryUserId: "",
            statusId: "",
            menuName: "",
        },
        validationSchema: Yup.object({
            kitchenUserId: Yup.string().required("Please select a kitchen User"),
            deliveryUserId: Yup.string().required("Please Select a Delivery User"),
            statusId: Yup.string().required("Please select a status")
        }),
        onSubmit: (values: any) => {
            UpdateOrder(orderDetails);
        }
    });

    const UpdateOrder = (orderDetails: any) => {
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        if (roleIdValue == Role.Admin) {
            orderDetails.kitchenUserId = validation.values.kitchenUserId;
            orderDetails.deliveryUserId = validation.values.deliveryUserId;
            orderDetails.statusId = validation.values.statusId;
            CommonService.post("UserOrder", "UpdateUserOrderBoxNumber", orderDetails).then(res => {
                if (res.status == 200) {
                    canCancel();
                }
            }).catch(e => console.log(e));
        } else {
            CommonService.postWithDoubleQueryParam("UserOrder", "UpdateUserOrderStatusId", "orderId", orderDetails.id, "statusId", validation.values.statusId).then(res => {
                if (res.status == 200) {
                    canCancel();
                }
            }).catch(e => console.log(e));
        }

    }

    const isCancel = () => {
        canCancel();
    }

    const getUserOrderById = () => {
        let searchModel = {
            offsetStart: 1,
            rowsPerPage: 10,
            sortOrder: "Asc",
            searchText: ""
        }
        CommonService.postWithSingleParam("UserOrder", "GetUserOrderByOrderIdSearch", "orderId", orderId, searchModel).then(res => {
            if (res.status == 200) {
                setOrderDetails(res.data.responseData);
                validation.setFieldValue("menuName", res.data.responseData.menuName);
                validation.setFieldValue("kitchenUserId", res.data.responseData.kitchenUserId);
                validation.setFieldValue("deliveryUserId", res.data.responseData.deliveryUserId);
                validation.setFieldValue("statusId",res.data.responseData.statusId);
            }
        }).catch(e => console.log(e));
    }

    useEffect(() => {
        getUserOrderById();
    }, []);

    useEffect(() => {
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        let restaurentId = localStorage.getItem("restaurentId");
        let status = CommonService.getWithQueryParam("Status", "GetStatusByEntityName", "EntityName", "Order")
        forkJoin({ status }).subscribe((res: { status: any }) => {
            if (roleIdValue == Role.SuperAdmin) {
                setIsSuperAdmin(true);
                setStatusMaster(res.status.data.responseData);
            } else if (roleIdValue == Role.Admin) {
                setIsAdmin(true);
                const filteredOrderStatus = res.status.data.responseData.filter(item => item.id === OrderStatus.RestaurentConfirmation || item.id === OrderStatus.RestaurentRejection);
                setStatusMaster(filteredOrderStatus)
            }
        });
    }, []);


    return (
        <React.Fragment>
            <div className="page-content p-0">
                <div onClick={() => isCancel()} className="btn-close position-absolute end-0 top-0 m-3" ></div>
                <Form onSubmit={validation.handleSubmit}>
                    <Row>
                        <Col lg={6}>
                            <CustomTextBox id="menuName"
                                placeholder="Enter Menu Name" name="menuName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                disabled={true}
                                value={validation.values.menuName}
                                invalid={validation.touched.menuName && validation.errors.menuName ? true : false}
                                label="Menu" error={validation.errors.menuName} isMandatory={true} />
                        </Col>
                    </Row>
                  
                    {isSuperAdmin ? <Row>
                        <Col lg={6}>
                            <CustomDropdown label="Status" id="statusId" value={validation.values.statusId} data={statusMaster}
                                error={validation.errors.statusId} valueField="id" displayField='displayname' onChange={validation.handleChange}
                                onBlur={validation.handleBlur} invalid={validation.touched.statusId && validation.errors.statusId ? true : false}
                                touched={validation.touched.statusId} isMandatory={true} disabled={true} />
                        </Col>
                    </Row> : <Row>
                        <Col lg={6}>
                            <CustomDropdown label="Status" id="statusId" value={validation.values.statusId} data={statusMaster}
                                error={validation.errors.statusId} valueField="id" displayField='displayname' onChange={validation.handleChange}
                                onBlur={validation.handleBlur} invalid={validation.touched.statusId && validation.errors.statusId ? true : false}
                                touched={validation.touched.statusId} isMandatory={true} />
                        </Col>
                    </Row>}

                    <Row>
                        <div className="mt-4 d-grid">
                            <Col className="text-end">
                                <button className="cancel-button w-lg m-3" type="button" onClick={() => isCancel()}>
                                    Cancel
                                </button>
                                <button
                                    className="loginbtn w-md"
                                    type="submit"
                                >{"Update Order"}</button>
                            </Col>
                        </div>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default withRouter(OrderEditForm);