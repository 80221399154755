import React from 'react';
import { Breadcrumb, Col, Container, Row } from 'reactstrap';
import deliveryimage from "../../assets/images/Delivery_image.png";
import "../Customer/Customer.css";
import { Link } from 'react-router-dom';

const CustomerDashboard = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Dashboards" breadcrumbItem="Dashboards" />
                    <Row>
                        <Col className='header' sm={6}>
                            <h4>Hello Name !</h4>
                            <h3 className='mt-4'><b>We are <span style={{ color: '#004e73' }}>PandaGoGoGo.</span></b></h3>
                            <h2 className='leftalign mt-4'>If You <span style={{ color: '#004e73' }}>Lazy,</span></h2>
                            <h2 className='leftalign'>Call Us <span style={{ color: '#4CAF50' }}>Buddy...</span></h2>
                            <div className="leftalign mt-4">
                                <Link to="/restaurant-list">
                                    <button className="Ordernow" type="submit">Order Now</button>
                                </Link>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="right-section">
                                <img src={deliveryimage}
                                    alt="Delivery"
                                    style={{ maxWidth: '85%', height: 'auto', }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustomerDashboard; 