import { CustomButton } from "Components/Shared/CustomButton";
import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Modal } from "reactstrap";
import Breadcrumb from "Components/Common/Breadcrumb";
import CustomDataTable from "Components/Shared/CustomDataTable";
import PromotionalContentMaster from "./PromotionalContentForm";
import { Loader } from "Components/Loader/Loader";

const PromotionalContentDashboard = () => {
    const [promotionalContentModel, setPromotionalContentModel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditId, setIsEditId] = useState<number>(0);
    const [promotionalContent, setPromotionalContent] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    document.title = "Promotional Contents | Panda GoGoGo ";

    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "0%"
        }, {
            Header: 'Name',
            accessor: 'name',
            width: "45%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Type',
            accessor: 'displayArea',
            width: "20%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Description',
            accessor: 'description',
            width: "35%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Active',
            accessor: 'active',
            datatype: "boolean",
            isBadge: true,
            Filter: true,
            sortable: true,
            width: '20%',
        }
    ], []);

    const onAddMenuClick = () => {
        setPromotionalContentModel(true);
    }

    const getPromotionalContentBySearch = () => {
        setLoader(true);
        CommonService.get("PromotionalContents", "GetAllPromotionalContents").then(res => {
            if (res.status == 200) {
                setLoader(false);
                let data = res.data.responseData;
                setPromotionalContent(data)
            }
        })
    }

    const editHandler = (val: any) => {
        setIsEdit(true);
        setIsEditId(val.id);
        setPromotionalContentModel(true);
    }

    // const pagination = (pageNumber: any) => {
    //     getUniversityBySearch(pageNumber, search, selectedColumn, sortOrder);
    // }

    // const onColumnSortChange = (columnName, sortorder) => {
    //     setSelectedColumn(columnName);
    //     setSortOrder(sortorder);
    //     getUniversityBySearch(1, search, columnName, sortOrder);
    // };

    // const searchHandler = (searchKeyword: any) => {
    //     setSearch(searchKeyword);
    //     getUniversityBySearch(1, searchKeyword);
    // }

    const isCancel = () => {
        setIsEdit(false);
        setIsEditId(0);
        setPromotionalContentModel(false);
        getPromotionalContentBySearch();
    }

    useEffect(() => {
        getPromotionalContentBySearch()
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
            <Loader open={loader} />
                <Breadcrumb title="Promotional Content" breadcrumbItem="Dashboard" breadcrumbTitle={""}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard"} />
                <div className="mt-0 d-grid">
                    <Col className="text-end" style={{ marginTop: "-20px", marginBottom: "10px" }}>
                        <CustomButton type="button" btnClassName="btn btn-success w-md" iconClasssName="fa fa-plus" name="Create" onClick={() => onAddMenuClick()} />
                    </Col>
                </div>
                <CustomDataTable
                    columns={columns}
                    data={promotionalContent}
                    canEdit={true}
                    perPageData={9}
                    canDelete={false}
                    customPageSize={10}
                    editHandler={editHandler} canShowButton={false} deleteHandler={undefined}
                    createHandler={undefined} path={""} canView={false} isPagination={false}
                    isGlobalFilter={false}
                    selectedPage={1}
                    searchHandler={undefined}
                    isAction={false} isShowingPageLength={false} onColumnSortChange={undefined} />
            </div>
            <div className="container">
                <Modal isOpen={promotionalContentModel} size="md" centered>
                    <div role='document'>
                        <div className='modal-content'>
                            <div className='modal-body m-3'>
                                <PromotionalContentMaster
                                    isModelOpen={true}
                                    canCancel={isCancel}
                                    isEditId={isEditId}
                                    isEdit={isEdit}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default PromotionalContentDashboard;