import React, { useState, useEffect } from 'react';
import { Container, Row, Col, CardBody, Card, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Popover, Badge } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import '../OrderCreation/OrderCreation.css';
import CommonService from 'Services/CommonService';
import "../OrderCreation/OrderCreation.css";
import { DecodeParam, EncodeParam, getTokenDetail } from 'helpers/helper';
import { HelperConstant, days, splitDateTime } from 'Components/constants/PandaConstants';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePayment from '../../Payment/StripePayment';
import { ITokenModel } from 'Models/ITokenModel';
import { jwtDecode } from 'jwt-decode';

import paypalimg from "../../../assets/images/payment/paypal.png";
import stripeimg from "../../../assets/images/payment/stripe.png";
import totalpay from "../../../assets/images/payment/totalpay.png";
import PaypalPayment from 'pages/Payment/PaypalPayment';
import { useDispatch } from 'react-redux';
import { pageNameHandler } from 'slices/thunk';
import moment from 'moment';
import { Loader } from 'Components/Loader/Loader';
import momentTz from 'moment-timezone';
import { TextField } from '@mui/material';
import { CustomToaster } from 'Components/Shared/CustomToaster';
// import ApplePayPayment from 'pages/Payment/ApplePay';

const CourseMenu = () => {
  const { restaurentid, selectedAddressId, menuid, dayId } = useParams();
  let navigate = useNavigate();

  const [menuDetails, setMenuDetails] = useState<Array<{ amount: number; menuName: string; menuitems: Array<string>, menuUploadFilePath: string, id: any, description: string, countryName: string, cityName: string, stateName: string, zipCodeName: string, deliveryTime: string, quantity: number, remainingCount: number, weeklyMenuCount: number }>>([]);
  const [disabled, setDisabled] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [selectPayment, setSelectPayment] = useState(false);
  const [paypalOpen, setPaypalOpen] = useState(false);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState('');
  const [clientId, setClientId] = useState<any>("");
  const [count, setCount] = useState<number>(1);
  const [selectedMethod, setSelectedMethod] = useState<number>(1);
  const [isPaymentStart, setPaymentStart] = useState<{ paymentProcess: boolean, orderCreation: boolean }>({ paymentProcess: false, orderCreation: false });
  const [isDeliveryDate, setIsDeliveryDate] = useState("");
  const [loader, setLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isOrderCountWarning, setIsOrderCountWarning] = useState(false);
  const [difference, setDifference] = useState(0);
  // const [paymentKey,setPaymentKey] = useState("");
  const [couponCode, setCouponCode] = useState<any>("");
  const [couponCodeMessage, setCouponCodeMessage] = useState<string>("");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [availbleCoupon, setAvailbaleCoupon] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState<any>();
  const [userId, setUserId] = useState<any>();
  const [vaLidateOfferCode, setValidateOfferCode] = useState<any>();
  const [isApplied, setIsApplied] = useState(false);
  const [discountCalc, setDiscountCalc] = useState<any>();
  // const [validMessage, setValidMessage] = useState("");
  const [paymentMethodData, setPaymentMethodData] = useState<any>([]);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState<boolean>(false);

  let token: string = localStorage.getItem("authUser") as string;
  let tokendata: ITokenModel = jwtDecode(token);


  // const validMessage = "Your Coupon Code applied Successfully !";
  const inValidMessage = "Invalid Promo Code";

  document.title = "Order | Panda GoGoGo ";

  const getStripekey = () => {
    let decodedId = DecodeParam(restaurentid);

    let data = {
      RestaurantId: decodedId,
      PaymentMethodId: null,
      LoginId: tokendata?.LoginId,
      Amount: discountCalc != null ? (((menuDetails[0]?.amount * count) - (menuDetails[0]?.amount * count) * (discountCalc)) +
        ((8.25 / 100) * ((menuDetails[0]?.amount * count) - ((menuDetails[0]?.amount * count) * (discountCalc))))).toFixed(2) : (((8.25 / 100) * menuDetails[0]?.amount * count) + (menuDetails[0]?.amount * count)).toFixed(2),
    }

    CommonService.post("Payment", "CreatePaymentDetails", data).then((res) => {
      if (res.status == 200) {
        if (res.data) {
          let key = res.data;
          setClientSecret(key);
          loadkeys(decodedId);
        }
      }
    });
  }

  const loadkeys = (decodedId) => {
    CommonService.getWithQueryParam("Payment", "GetPaymentKey", "restaurantId", decodedId).then(async (res) => {
      if (res.status == 200) {
        let data = await loadStripe(res.data.paymentKey ?? "");
        // setPaymentKey(res.data.paymentKey);
        setStripePromise(data);
        setIsPaymentOpen(true);
        togglePaymentType();
        setLoader(false);
      }
    });
  }

  const getPaypalkey = () => {
    CommonService.getWithQueryParam("Restaurent", "GetRestaurentPaymentConfigByUniversityId", "universityId", tokendata.UniversityId).then(async (res) => {
      if (res.status == 200) {
        setClientId(res.data.responseData);
        setPaypalOpen(true);
        togglePaymentType();
      }
    });
  }

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(pageNameHandler({ pageName: "Order Summary" }));
  }, []);

  const paymentTypeToggle = () => {
    setLoader(true);
    setSubmitDisabled(true);
    getPaypalkey();
    getStripekey();
    // togglePaymentType();
  }

  const onCancelClick = () => {
    navigate(`/pickup-address/${restaurentid}/${menuid}/${dayId}`);
  }

  const GetPickUpAddress = () => {
    setLoader(true);
    let decodedId = DecodeParam(menuid);
    let decodedDayId = DecodeParam(dayId);
    let decodedPickUpAddressId = DecodeParam(selectedAddressId);
    CommonService.postWithTribalParam("Menu", "GetMenuDetailsByMenuId", "menuId", "dayId", "pickupAddressId", decodedId, decodedDayId, decodedPickUpAddressId)
      .then(res => {
        if (res.status === 200) {
          setLoader(false);
          // setCount(res.data.responseData[0].quantity)
          res.data.responseData.map((menu, index) => {
            menu.deliveryTime = splitDateTime(menu.deliveryCutOffStartTime, menu.deliveryCutOffEndTime);
          })
          setIsOrderCountWarning(res.data.responseData[0].remainingCount == count ? true : false)
          setMenuDetails(res.data.responseData);
        }
      })
      .catch(e => console.log(e));
  };

  const getPaymentMethods = () => {
    CommonService.get("Masters", "GetPaymentMethodMaster").then((res) => {
      if (res.status == 200) {
        setPaymentMethodData(res.data.responseData.paymentMethodList);
      }
    })
  }

  useEffect(() => {
    let userid = tokendata?.UserId;
    setUserId(userid);
    GetPickUpAddress();
    getPaymentMethods();
    let DecodedDayId = DecodeParam(dayId);
    let day = moment().format('dddd');
    let todayId = days.find(item => item.day == day)?.id;
    let difference;
    if (todayId !== undefined && parseInt(DecodedDayId) < todayId) {
      difference = (7 - todayId) + parseInt(DecodedDayId);
    }
    else if (todayId !== undefined && parseInt(DecodedDayId) > todayId) {
      difference = parseInt(DecodedDayId) - todayId;
    }

    setDifference(difference);
    const momentDate = moment().add(difference, 'day').utc().format('YYYY-MM-DD');
    if (parseInt(DecodedDayId) === todayId) {
      setIsDeliveryDate(moment(new Date().toISOString()).format('YYYY-MM-DD'));
    }
    else {
      setIsDeliveryDate(moment(new Date(new Date().setDate(new Date().getDate() + difference)).toISOString()).format('YYYY-MM-DD'))
    }
  }, [menuid]);


  const processOrder = (Paymentmethodid: string) => {
    setDisabled(true);
    let token: any = getTokenDetail();
    const subtotal = menuDetails ? menuDetails[0].amount : 0;
    let decodedDayId = DecodeParam(dayId);
    let day = moment().format('dddd');
    let todayId = days.find(item => item.day == day)?.id;
    let decodedRestaurantId = DecodeParam(restaurentid);
    let decodedPickUpAddressId = DecodeParam(selectedAddressId);
    const userTimezone = momentTz().tz(momentTz.tz.guess()).format('Z');
    const requestBody = {
      userId: parseInt(token.LoginId),
      // orderDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      restaurentId: decodedRestaurantId,
      paymentMethodId: selectedMethod,
      transactionId: Paymentmethodid.toString(),
      amount: discountCalc != null ? (((menuDetails[0]?.amount * count) - (menuDetails[0]?.amount * count) * (discountCalc)) +
        ((8.25 / 100) * ((menuDetails[0]?.amount * count) - ((menuDetails[0]?.amount * count) * (discountCalc))))).toFixed(2) : (((8.25 / 100) * menuDetails[0]?.amount * count) + (menuDetails[0]?.amount * count)).toFixed(2),
      count: count,
      pickUpAddressId: decodedPickUpAddressId,
      // deliveryDate: decodedDayId == todayId ? new Date().toISOString() : new Date(new Date().setDate(new Date().getDate() + difference)).toISOString(),
      // deliveryDate: decodedDayId == todayId ? moment().utc().format("YYYY-MM-DD HH:mm:ss") : moment().add(difference, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
      orderMenu: {
        menuId: menuDetails ? menuDetails[0].id : 0,
        quantity: 1,
        orderMenuItem: [],
      },
      dayId: decodedDayId,
      userTimezone: userTimezone,
      Discount: discountCalc != null ? discountCalc : null,
      SalesTax: HelperConstant.SalesTax / 100,
      CouponCodeTransactionId: appliedCoupon?.id,
    };

    CommonService.post("UserOrder", "CreateUserOrder", requestBody).then(res => {
      if (res.status == 200) {
        var routingPath = res.data.responseData as string;
        var ids = routingPath.split(',');
        var encryptedIds = ids.map(id => EncodeParam(id));
        var encryptedRoutingPath = encryptedIds.join('/');
        navigate(`/order-summary?${encryptedRoutingPath}`);
      }
    });
  }

  const togglePaymentType = () => {
    setSelectPayment(!selectPayment);
  }

  const toggleImagePopup = () => {
    setIsImagePopupOpen(!isImagePopupOpen);
  }

  const handleImagePopup = () => {
    setIsImagePopupOpen(true);
  }

  const handleImageClose = () => {
    setIsImagePopupOpen(false);
  }

  const onAddIconClick = () => {
    if (menuDetails[0]?.remainingCount >= menuDetails[0].quantity) {
      if (count < menuDetails[0].quantity) {
        setCount(count + 1);
      }
    } else {
      if (count < menuDetails[0]?.remainingCount && count < menuDetails[0].quantity) {
        setCount(count + 1);
      }
    }
  }

  const checkValidReferralCode = () => {
    CommonService.getWithTripleParam("CouponCode", "GetCouponCodeTransactionByOfferCode", "userId", userId, "offerCode", couponCode, "getDate", moment.utc().format("YYYY-MM-DD HH:mm:ss")).then(res => {
      if (res.status === 200) {
        if (res.data.responseData != "") {
          setIsApplied(true);
          if (res.data.responseData[0].message == null) {
            setCouponCodeMessage("Your Coupon Code applied Successfully !");
            setDiscountCalc(res.data.responseData[0].discount);
          }
          else {
            setCouponCodeMessage(res.data.responseData[0]?.message)
          }
          setAppliedCoupon(res.data.responseData[0]);
        }
        else {
          setCouponCodeMessage("Invalid Coupon Code");
        }
      }
    })
  }

  const togglePopover = () => {
    setCouponCode("");
    setCouponCodeMessage("");
    setDiscountCalc(null);
    automaticUpdateCouponExpiry();
    getAvailableCouponCode();
    setPopoverOpen(!popoverOpen);
  };

  const unsecuredCopyToClipboard = (text: any) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      setIsOpen(true);
      setTimeout(() => { setIsOpen(false) }, 1000);
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }


  const copyHandler = (index: number) => {
    let content = availbleCoupon[index]?.offerCode;
    setValidateOfferCode(availbleCoupon[index]?.offerCode);
    // setDiscountCalc(availbleCoupon[index]?.discount / 100);
    if (window.isSecureContext && navigator.clipboard) {
      navigator?.clipboard?.writeText(content);
      setIsOpen(true);
      setTimeout(() => { setIsOpen(false) }, 2000);
    } else {
      unsecuredCopyToClipboard(content);
    }
  };

  const deleteCouponCode = () => {
    setCouponCode("");
    setCouponCodeMessage("");
    setDiscountCalc(null);
  }

  const automaticUpdateCouponExpiry = () => {
    CommonService.putWithQueryParam("CouponCode", "UpdateCouponCodeExpired", "toDate", moment.utc().format("YYYY-MM-DD HH:mm:ss")).then(res => {
      if (res.status === 200) {
      }
    })
  }

  const getAvailableCouponCode = () => {
    const availableData = {
      userId: userId,
      getDate: moment.utc().format("YYYY-MM-DD HH:mm:ss")
    }
    CommonService.post("CouponCode", "GetAvailableCouponCodeByUser", availableData).then(res => {
      if (res.status === 200) {
        setAvailbaleCoupon(res.data.responseData);
      }
    }).catch(e => console.log(e));
  }

  return (
    <Container>
      {/* <Modal isOpen={selectPayment} toggle={togglePaymentType} centered size='md'>
        <ModalHeader toggle={togglePaymentType}>Select Mode of Payment</ModalHeader>
        <ModalBody>
          <div className="row payment-type">
            <div className="col-6 text-end">
              <img src={totalpay} alt="" onClick={() => getStripekey()} />
            </div>
            <div className="col-6 text-start">
              <img src={paypalimg} alt="" style={{ padding: "30px" }} onClick={() => getPaypalkey()} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={togglePaymentType}>Close</Button>
        </ModalFooter>
      </Modal> */}

      {/* stripe modal */}
      {/* <Modal className='payment-modal' isOpen={isPaymentOpen && stripePromise && clientSecret} toggle={() => { setIsPaymentOpen(false); setStripePromise(null); setClientId("") }} centered size='md'>
        <ModalHeader className='text-end' toggle={() => { setIsPaymentOpen(false); setStripePromise(null); setClientId("") }}>
          Bill Total : ${(menuDetails[0]?.amount * count).toFixed(2)}
        </ModalHeader>
        <ModalBody>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripePayment setIsPaymentOpen={setIsPaymentOpen} setStripePromise={setStripePromise} isPaymentStart={isPaymentStart} setPaymentStart={setPaymentStart} restaurantId={restaurentid} callBack={processOrder} amount={menuDetails[0]?.amount * count} loginId={tokendata?.LoginId} />
          </Elements>
        </ModalBody>
      </Modal> */}

      {/* paypal modal */}
      {/* <Modal className='payment-modal' isOpen={paypalOpen && clientId} toggle={() => { setPaypalOpen(!paypalOpen); setClientId("") }} centered size='md'>
        <ModalHeader className='text-end' toggle={() => { setPaypalOpen(!paypalOpen); setClientId("") }}>
          Bill Total : ${(menuDetails[0]?.amount * count).toFixed(2)}
        </ModalHeader>
        <ModalBody>
          <PaypalPayment clientId={clientId} callBack={processOrder} amount={menuDetails[0]?.amount * count} />
        </ModalBody>
      </Modal> */}
      <Modal className='payment-modal' isOpen={paypalOpen && clientId && isPaymentOpen && stripePromise && clientSecret} toggle={() => { setPaypalOpen(!paypalOpen); setClientId(""); setIsPaymentOpen(false); setStripePromise(null); }} centered size='lg'>
        <ModalHeader className='text-end' toggle={() => { setSubmitDisabled(false); setPaypalOpen(!paypalOpen); setClientId(""); setIsPaymentOpen(false); setStripePromise(null) }}>
          Bill Total : ${discountCalc != null ? (((menuDetails[0]?.amount * count) - (menuDetails[0]?.amount * count) * (discountCalc)) +
            ((8.25 / 100) * ((menuDetails[0]?.amount * count) - ((menuDetails[0]?.amount * count) * (discountCalc))))).toFixed(2) : (((8.25 / 100) * menuDetails[0]?.amount * count) + (menuDetails[0]?.amount * count)).toFixed(2)}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <aside className="sidebar-payment">
                <nav className="nav-payment">
                  <ul>
                    <li className={selectedMethod == paymentMethodData[0]?.id ? "active" : ""} onClick={() => setSelectedMethod(paymentMethodData[0]?.id)}><i className='fa fa-thumbs-up'></i> Credit or Debit Card</li>
                    <div className="col-12 text-center">
                      <span className='line-or text-muted'>Other</span>
                      <span className='line-or-line'></span>
                    </div>
                    <div className='mb-1'></div>
                    <li className={selectedMethod == paymentMethodData[1]?.id ? "active" : ""} onClick={() => setSelectedMethod(paymentMethodData[1]?.id)}><i className='bx bxl-paypal'></i> Paypal</li>
                    {/* <div className='mb-1'></div>
                    <li className={selectedMethod == 3 ? "active" : ""} onClick={() => setSelectedMethod(3)}><i className='fa-brands fa-google-pay' style={{ fontSize: '24px', marginTop: '1px' }}></i>Google Pay or Apple Pay</li> */}
                  </ul>
                </nav>
              </aside>
            </div>
            <div className="col-lg-9 col-sm-12 text-center" style={{ minHeight: "150px" }}>
              {selectedMethod == paymentMethodData[0]?.id &&
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <StripePayment menuName={menuDetails[0]?.menuName} setIsPaymentOpen={setIsPaymentOpen} setStripePromise={setStripePromise} isPaymentStart={isPaymentStart} setPaymentStart={setPaymentStart} restaurantId={restaurentid} callBack={processOrder} amount={discountCalc != null ? (((menuDetails[0]?.amount * count) - (menuDetails[0]?.amount * count) * (discountCalc)) +
                    ((8.25 / 100) * ((menuDetails[0]?.amount * count) - ((menuDetails[0]?.amount * count) * (discountCalc))))).toFixed(2) : (((8.25 / 100) * menuDetails[0]?.amount * count) + (menuDetails[0]?.amount * count)).toFixed(2)} loginId={tokendata?.LoginId} />
                </Elements>
              }
              {selectedMethod == paymentMethodData[1]?.id &&
                <PaypalPayment clientId={clientId} callBack={processOrder} amount={discountCalc != null ? (((menuDetails[0]?.amount * count) - (menuDetails[0]?.amount * count) * (discountCalc)) +
                  ((8.25 / 100) * ((menuDetails[0]?.amount * count) - ((menuDetails[0]?.amount * count) * (discountCalc))))).toFixed(2) : (((8.25 / 100) * menuDetails[0]?.amount * count) + (menuDetails[0]?.amount * count)).toFixed(2)} />
              }
              {/* {selectedMethod == 3 &&
                  <ApplePayPayment stripeKey={paymentKey} menuName={menuDetails[0]?.menuName} amount={menuDetails[0]?.amount * count} />
              } */}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Loader open={(isPaymentStart?.paymentProcess || isPaymentStart?.orderCreation) && isPaymentOpen} />
      <Row className='p-lg-5' style={{ opacity: (isPaymentStart?.paymentProcess || isPaymentStart?.orderCreation) ? "0.7" : "1" }}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card style={{ borderRadius: "10px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
            <CardBody>
              <p style={{ fontSize: '20px', fontWeight: 'bolder', textAlign: 'center' }}>Order Summary</p>
              <Row>
                {/* <Col md={8}> */}
                {menuDetails.map((val, i) => (
                  <div className="single_menu">
                    <Row>
                      <Col lg={3} md={12} sm={12} xs={12}>
                        <img src={`${HelperConstant.image_cdn_Url}/${val.menuUploadFilePath}`} alt="order" className='order-img' onDoubleClick={handleImagePopup} />
                      </Col>
                      <Col lg={5} md={12} sm={12} xs={12} className="menu_content">
                        <h4><span>${(val.amount).toFixed(2)}</span>{val.menuName} <br />
                          <p style={{ fontSize: "11px" }}>{val.description}</p></h4>
                        <Row>
                          <Col lg={8} md={8}>
                            <h3 style={{ color: '#393a3bcc', fontSize: '15px' }}>{val.cityName}, {val.stateName}</h3>
                            <h3 style={{ color: '#393a3bcc', fontSize: '15px' }}>{val.countryName} - {val.zipCodeName}</h3>
                          </Col>
                          <Col lg={4} md={4}>
                            <h3 style={{ color: '#393A3B', fontSize: '13px', whiteSpace: "nowrap" }}>Pickup Date & Time</h3>
                            <h3 className="primary-color" style={{ fontSize: '12px', whiteSpace: "nowrap" }}>{isDeliveryDate}</h3>
                            <h3 className="primary-color" style={{ fontSize: '12px', whiteSpace: "nowrap" }}>{val?.deliveryTime}</h3>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={4} md={8}>
                        <Card style={{ backgroundColor: '#F1F1F1', borderRadius: "5px" }}>
                          <CardBody>
                            <Row style={{ textAlign: "left" }} className='mb-1'>
                              <Col md={8} sm={8} xs={8}>
                                <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>Quantity</h3>
                              </Col>
                              <Col md={4} sm={4} xs={4}>
                                <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>{count} * {menuDetails[0]?.amount}</h3>
                              </Col>
                            </Row>

                            <Row className='mb-2'>
                              <Col md={8} sm={8} xs={8}>
                                <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>Subtotal</h3>
                              </Col>
                              <Col md={4} sm={4} xs={4}>
                                <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>${(menuDetails[0]?.amount * count).toFixed(2)}</h3>
                              </Col>
                            </Row>

                            {discountCalc != null &&
                              <Row className='mb-2'>
                                <Col md={8} sm={8} xs={8}>
                                  <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>Discount (-)</h3>
                                </Col>
                                <Col md={4} sm={4} xs={4}>
                                  <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>${((menuDetails[0]?.amount * count) * (discountCalc)).toFixed(2)}</h3>
                                </Col>
                              </Row>}

                            {discountCalc != null ?
                              <Row className='mb-2'>
                                <Col md={8} sm={8} xs={8}>
                                  <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>Total</h3>
                                </Col>
                                <Col md={4} sm={4} xs={4}>
                                  <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>${((menuDetails[0]?.amount * count) - ((menuDetails[0]?.amount * count) * (discountCalc))).toFixed(2)}</h3>
                                </Col>
                              </Row>
                              : ""}

                            <Row style={{ borderBottom: "1px solid #e2e2e2", textAlign: "left" }} className='mb-3'>
                              <Col md={8} sm={8} xs={8}>
                                <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>Sales Tax</h3>
                              </Col>
                              <Col md={4} sm={4} xs={4}>
                                {discountCalc != null ?
                                  <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>${((8.25 / 100) * ((menuDetails[0]?.amount * count) - ((menuDetails[0]?.amount * count) * (discountCalc)))).toFixed(4)}</h3>
                                  :
                                  <h3 style={{ color: '#8A8C8E', fontSize: '15px' }}>${((8.25 / 100) * (menuDetails[0]?.amount * count)).toFixed(4)}</h3>
                                }
                              </Col>
                            </Row>
                            <Row style={{ textAlign: "left" }}>
                              <Col md={8} sm={8} xs={8}>
                                <h3 style={{ color: '#393A3B', fontSize: '17px' }}>Grand Total</h3>
                              </Col>
                              <Col md={4} sm={4} xs={4}>
                                {discountCalc != null ?
                                  <h3 style={{ color: '#393A3B', fontSize: '17px' }}>
                                    ${(((menuDetails[0]?.amount * count) - (menuDetails[0]?.amount * count) * (discountCalc)) +
                                      ((8.25 / 100) * ((menuDetails[0]?.amount * count) - ((menuDetails[0]?.amount * count) * (discountCalc))))).toFixed(2)}</h3> :
                                  <h3 style={{ color: '#393A3B', fontSize: '17px' }}>
                                    ${(((8.25 / 100) * menuDetails[0]?.amount * count) + (menuDetails[0]?.amount * count)).toFixed(2)}</h3>
                                }
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      {/* {(appliedCoupon === validMessage) &&
                        <><label className="dd_selling-price">Offer </label>
                          <label className="price"> - ({appliedCoupon?.offerCode})</label> &nbsp;&nbsp;
                          <span style={{ position: "absolute" }} />
                          <i className='fa fa-trash' onClick={() => { deleteCouponCode() }}></i>
                        </>

                      } */}
                      <Col lg={8} md={8} sm={12} xs={12} className='coupon-col'>
                        <TextField type="number" size='small' label="Coupon Code" variant="outlined" value={couponCode}
                          inputProps={{
                            maxLength: HelperConstant.couponCodeLimit,
                          }}
                          sx={{
                            marginRight: "20px"
                          }}
                          onChange={(e) => {
                            automaticUpdateCouponExpiry();
                            setIsApplied(false);
                            const inputValue = e.target.value;
                            if (inputValue.length <= HelperConstant.couponCodeLimit) {
                              setCouponCode(inputValue);
                            }
                          }} />
                        {(couponCode !== "" && couponCodeMessage) ?
                          <span className={`valid-msg ${couponCodeMessage == "Your Coupon Code applied Successfully !" ? "text-success" : "text-danger"} `}>{couponCodeMessage}</span> : ""}

                        {(couponCode !== "" && (couponCodeMessage != null))
                          ?
                          <><>{isApplied ?
                            <i className='fa fa-trash dlt-icon' onClick={() => { deleteCouponCode() }}></i> :
                            <Button color="success" className="text-center coupon-btn" outline onClick={() => checkValidReferralCode()} size="small">Apply</Button>}</></>
                          :
                          null
                        }
                        <span
                          id="PopoverLegacy"
                          onClick={togglePopover}
                          className='avl-coupon'

                        >
                          Available Coupon Code
                        </span>
                        <Popover
                          placement="bottom-start"
                          target="PopoverLegacy"
                          trigger="legacy"
                          isOpen={popoverOpen}
                          toggle={togglePopover}
                        >
                          {availbleCoupon.length > 0 ? <>
                            {availbleCoupon.map((item, index) => (
                              <p className='cc-tip'> {item?.offerCode} - {item?.discount}% OFF<i className='bx bxs-copy-alt font-size-18 cursor-pointer mx-2' onClick={() => copyHandler(index)}></i></p>
                            ))}
                          </> : <p className='cc-tip'>No Coupons available!</p>}
                        </Popover>
                        {isOpen ?
                          <span className="coupon-badge"> Copied!</span> : null}

                      </Col>
                      <Col lg={4} md={4} sm={12} xs={12}>
                        <div className="count d-flex"><i className={`fa fa-minus ${count == 1 && 'disabled-i'} `} onClick={() => count == 1 ? null : setCount(count - 1)}></i>{count}
                          <i className={`fa fa-plus ${count === menuDetails[0]?.remainingCount || count === menuDetails[0].quantity && 'disabled-i'} `}
                            onClick={() => count === menuDetails[0]?.remainingCount ? setIsOrderCountWarning(true) : count === menuDetails[0].quantity ? null : onAddIconClick()}></i><h3 className="primary-color" style={{ fontSize: '15px', whiteSpace: "nowrap" }}>Quantity</h3>
                        </div>
                      </Col>
                      <Modal isOpen={isImagePopupOpen} toggle={toggleImagePopup} centered size='md'>
                        {/* <ModalHeader toggle={togglePaymentType}>Select Mode of Payment</ModalHeader> */}
                        <ModalBody >
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <img src={`${HelperConstant.image_cdn_Url}/${val.menuUploadFilePath}`} alt="order" className='menuimg' onClick={handleImageClose} />
                          </Col>
                        </ModalBody>
                      </Modal>
                    </Row>
                    {isOrderCountWarning && <p>{count === menuDetails[0]?.remainingCount && (
                      <div className='quantity-error'>
                        <i className="fas fa-info-circle"></i>{" "}Order Count of this menu reached for Today!
                      </div>
                    )}</p>}

                  </div>
                ))}
              </Row>
              <Row>
                <div className="modal-body">
                  <form>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "-60px" }}>
                      <Button
                        onClick={onCancelClick} disabled={disabled} className='btn btn-secondary ms-2 cm-cancel-button'
                        type="button"
                      >Back
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          paymentTypeToggle();
                        }} disabled={submitDisabled} className='ms-2 cm-submit-button'
                        type="submit"
                      >Submit Order
                      </Button>
                    </div>
                    <Loader open={loader}></Loader>
                  </form>
                </div>

                {/* </Col> */}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row >
    </Container >
  );
};

export default CourseMenu;
