import React, { InputHTMLAttributes, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { days } from 'Components/constants/PandaConstants';

interface CardView {
    weekDay?: number;
    menuName?: string;
    isHoliday?: boolean;
}

interface ViewCardProps extends InputHTMLAttributes<HTMLInputElement> {
    data: CardView[];
    iconClassName: string;
    perPageData: number,
    PaginationData?: any,
    paginationDiv: any,
    selectedPage?: any,
    canEdit?: boolean;
    canView?: boolean;
    isUser?: boolean;
    showName?: boolean;
    canToggle?: boolean;
    onIconClick?: (id: string) => void;
    onSearch: (value: string) => void;
    toggleChange?: (id: string, isHoliday: boolean) => void;
}

const CustomMenuSelectionCard = React.forwardRef(({ data, onIconClick, selectedPage, canToggle, perPageData, PaginationData, paginationDiv, canEdit = true, canView = false, isUser, showName = true, toggleChange, onSearch, ...rest }: ViewCardProps, ref) => {

    const editHandler = (id: any) => {
        if (onIconClick) {
            onIconClick(id);
        }
    }

    const toggle = (data: any) => {
        if (toggleChange) {
            const invertedIsHoliday = !data.isHoliday;
            toggleChange(data.weekDay, invertedIsHoliday);
        }
    }

    const filteredDays = days.filter(day => day.id !== 6 && day.id !== 7);

    return (
        <React.Fragment>
            <Row>
                {filteredDays.map((day) => {
                    const matchedData = data.find((value) => value.weekDay === day.id);
                    const menus = matchedData && matchedData.menuName ? matchedData.menuName.split(',') : [];
                    return (
                        <Col xl={4} lg={6} md={6} sm={6} xs={12} key={day.id} >
                            <Card style={{ borderRadius: "10px", cursor: "pointer", boxShadow: '0 4px 5px lightgray', borderLeft: `4px solid #E75605` }}>
                                <CardBody>
                                    <Row style={{ justifyContent: "end" }}>
                                        <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                                            {canToggle && (matchedData?.isHoliday == true || matchedData?.isHoliday == false ) ?
                                                <>
                                                    <div className="form-check form-switch" style={{ textAlign: "right" }}>
                                                        <input
                                                            className="form-check-input bold-border"
                                                            type="checkbox"
                                                            id={`toggle-${day.id}`}
                                                            checked={!matchedData?.isHoliday}
                                                            onChange={() => toggle(matchedData)}
                                                        />
                                                        <label className="form-check-label">{matchedData?.isHoliday ? "Day Off" : "Day On"}</label>
                                                    </div>
                                                </>
                                                :
                                                null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10} lg={10} md={10} sm={10} xs={10} className='mt-2'>
                                            <h5 className='header-text-grid fw-bold'>{day.day}</h5>
                                            {/* {menus.map((menu, index) => (
                                                <p style={{ marginBottom: '0px' }} className='mt-2' key={index}>{menu}</p>
                                            ))} */}
                                        </Col>
                                        <Col xl={2} lg={2} md={2} sm={2} xs={2} className='mt-2 mr-1'>
                                            {canEdit ?
                                                <i className="fa fa-edit cursor-pointer" style={{ fontSize: "16px", color: '#474848', justifyContent: "flex-end", display: "flex" }} onClick={() => editHandler(day)}></i>
                                                : null
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                            {menus.map((menu, index) => (
                                                <p style={{ marginBottom: '0px' }} className='mt-2' key={index}>{menu}</p>
                                            ))}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </React.Fragment>
    )
});

export default CustomMenuSelectionCard;
