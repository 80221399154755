//Author : Kavitha Sri

import React, { InputHTMLAttributes } from "react";
import { Button } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /**@param {any} type defines which type of input control to display */
    type: any;
    /** @param {any} btnClassName return the value of the button's class*/
    btnClassName: any,
    /** @param {any} iconClasssName return the value of the icon's class*/
    iconClasssName: any,
    /** @param {any} name reference of the elements*/
    name: any,
    /** @param {any} onClick is refers the clicking the function*/
    onClick?: any,
    /** @param {any} disabled is defines the field is displayed or not */
    disabled?: boolean
}

export const CustomButton = React.forwardRef(({ type, name, btnClassName, iconClasssName, onClick, disabled, ...rest }: inputProps, ref) => {

    return (
        <>
            <Button ref={ref} {...rest as any} type={type} className={`btn ${btnClassName} mt-3`} onClick={onClick} disabled={disabled}><i className={`${iconClasssName} align-middle me-2`}></i>{name}</Button>
        </>
    )
})