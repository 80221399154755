import Dashboard from "../pages/Dashboard";

// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register"
import AppQRCode from "pages/QrCode/Index";
import AdminRestaurantCreation from "pages/Restaurant/AdminRestaurantCreation";
import RestaurantDashBoard from "pages/Restaurant/Index";
import MenuCreate from "pages/Menu/MenuCreateEdit";
import RestaurantView from "pages/Restaurant/RestaurantView";
import CustomerDashboard from "pages/Customer/Index";
// import RestaurantList from "pages/Customer/Restaurants/Restaurant";
// import OrderCreation from "pages/Customer/OrderCreation/Index";
import MenuDashBoard from "pages/Menu";
import CourseMenu from "pages/Customer/OrderCreation/CourseMenu";
import UserRestaurantForm from "pages/UserRestaurant/UserRestaurantForm";
import OrderDashBoard from "pages/Order/Index";
import Home from "pages/Home/Home";
import { ITokenModel } from "Models/ITokenModel";
import { jwtDecode } from "jwt-decode";
import AboutUs from "pages/About-Us/AboutUs";
// import OrderNow from "pages/Order-Now/OrderNow";
import Tickets from "pages/Tickets/Tickets";
import MyOrders from "pages/Customer/OrderCreation/MyOrders";
import OrderSummary from "pages/Order-Summary/OrderSummary";
import UniversityDashBoard from "pages/Masters/University/Index";
import KitchenOrderDashBoard from "pages/KitchenOrder/Index";
import OrderHistoryDashBoard from "pages/Order-History/Index";
// import PackedOrderDashBoard from "pages/Packed Order";
import MenuSelectionDashBoard from "pages/MenuSelection/Index";
import DashBoardTicket from "pages/Dashboard-Ticket/DashBoardTicket";
import ProfileForm from "Components/ProfileForm/ProfileForm";
import ProfileView from "Components/ProfileForm/ProfileView";
import PickUpAddressPopUp from "pages/Customer/Restaurants/PickUpAddress";
import UserVerification from "pages/Authentication/User-Verification";
import PromotionalContentDashboard from "pages/Promotional-Content/Index";
import ResetPassword from "pages/Authentication/Reset-Password";
import RegistereduserDashboard from "pages/Users/Index";
import ContactUs from "pages/Contact-Us/ContactUs";
import TermsAndConditionPopUp from "pages/Authentication/Terms-Condition";
import PrivacyPolicy from "pages/Authentication/Privacy-Policy";
import CouponCodeDashoard from "pages/Coupon-Code/Index";
import CouponCodeForm from "pages/Coupon-Code/CouponCodeForm";
import CouponCodeHistoryDashboard from "pages/Coupon-Code-History/Index";
import AnalyticsDashboard from "pages/Analytics/Index";
import { access } from "fs";

const protectedRoutes = [
  { path: "/dashboard", component: <Dashboard />, access: [1, 2] },
  { path: "/profile", component: <UserProfile />, access: [1, 2, 3] },
  { path: "/QRCodeScanner", component: <AppQRCode />, access: [1, 2, 3] },
  { path: "/restaurant/create", component: <AdminRestaurantCreation />, access: [1] },
  { path: "/restaurant", component: <RestaurantDashBoard />, access: [1] },
  { path: "/restaurant/view/:id", component: <RestaurantView />, access: [1, 2] },
  { path: "/customerDashboard", component: <CustomerDashboard />, access: [3] },
  { path: "/restaurant/edit/:id", component: <AdminRestaurantCreation />, access: [1, 2] },
  { path: "/menu/create", component: <MenuCreate />, access: [2] },
  { path: "/menu", component: <MenuDashBoard />, access: [2] },
  { path: "/weekly-menu", component: <MenuSelectionDashBoard />, access: [2] },
  { path: "/menu/edit/:id", component: <MenuCreate />, access: [1, 2] },
  { path: "/ordercreation/:restaurentid/:selectedAddressId/:menuid", component: <CourseMenu />, access: [3] },
  { path: "/menu", component: <MenuCreate />, access: [1, 2] },
  { path: "/restaurantUser/create", component: <UserRestaurantForm />, access: [2] },
  { path: "/restaurantUser/edit/:id", component: <UserRestaurantForm />, access: [2] },
  { path: "/order", component: <OrderDashBoard />, access: [1, 2] },
  // { path: "/packed-order", component: <PackedOrderDashBoard />, access: [2] },
  { path: "/university", component: <UniversityDashBoard />, access: [1] },
  { path: "/kitchenorder", component: <KitchenOrderDashBoard />, access: [2] },
  { path: "/orderhistory", component: <OrderHistoryDashBoard />, access: [1, 2] },
  { path: "/dashboard-ticket", component: <DashBoardTicket />, access: [1, 2] },
  { path: "/promotional-content", component: <PromotionalContentDashboard />, access: [1] },
  { path: "/user", component: <RegistereduserDashboard />, access: [1] },
  { path: "/coupon-code", component: <CouponCodeDashoard />, access: [1, 2] },
  { path: '/coupon-code/create', component: <CouponCodeForm />, access: [1, 2] },
  { path: "/coupon-code/edit/:id", component: <CouponCodeForm />, access: [1, 2] },
  { path: "/coupon-history", component: <CouponCodeHistoryDashboard />, access: [1] },
  { path: "/analysis", component: <AnalyticsDashboard />, access: [1] }
];

const publicRoutes = [
  { path: "/", exact: true, component: <Home /> },
  { path: "/User-Verification/:id", component: <UserVerification />, access: [3] },
  { path: "/Reset-Password/:id", component: <ResetPassword />, access: [3] },
  { path: "/order-summary/:id", component: <OrderSummary />, access: [3] },
  { path: "/order-summary", component: <OrderSummary />, access: [3] },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/contact-us", component: <ContactUs />, access: [1] },
  // { path: "/order-now", component: <OrderNow /> },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> },
  { path: "/ordercreation/:restaurentid/:selectedAddressId/:menuid/:dayId", component: <CourseMenu /> },
  // { path: "/menuView/:restaurentid/:selectedAddressId", component: <OrderCreation /> },
  // { path: "/restaurant-list", component: <RestaurantList /> },
  { path: "/my-orders", component: <MyOrders />, access: [3] },
  { path: "/tickets", component: <Tickets />, access: [3] },
  // { path: "/profile", component: <ProfileForm />, access: [3] },
  { path: "/profile/edit/:id", component: <ProfileForm />, access: [3] },
  { path: "/profileview", component: <ProfileView />, access: [3] },
  { path: "/pickup-address/:restaurentId/:menuId/:dayId", component: <PickUpAddressPopUp />, access: [3] },
  { path: "/terms", component: <TermsAndConditionPopUp />, access: [3] },
  { path: "/privacy-policy", component: <PrivacyPolicy />, access: [3] }
]

let token: string = localStorage.getItem("authUser") ?? "" as string;
let authProtectedRoutes: any = [];
if (token == "") {
  authProtectedRoutes = [];
} else {
  let tokendata: ITokenModel = jwtDecode(token);
  let roleId = tokendata.RoleId;
  authProtectedRoutes = protectedRoutes.filter(x => x.access.includes(parseInt(roleId)));

}


export { authProtectedRoutes, publicRoutes };
