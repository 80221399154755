import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../Components/Common/ChartDynamicColor";

const MonthlyOrderChart = ({ data }: { data: Array<any> }) => {
    // const dataColors = '["#E75605"]';
    const dataColors = '["--bs-primary"]';
    const spineareaChartColors = getChartColorsArray(dataColors);
    const [chartData, setChartData] = useState<any>({
        series: [],
        labels: [],
        colors: []
    });

    const series = [
        {
            name: "Order",
            data: chartData.series,
        },
    ]

    const options: any = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top",
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val + ""
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"],
            },
        },

        colors: spineareaChartColors,
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: chartData.labels,
            position: "bottom",
            labels: {
                show: "true",
                offsetY: -5,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            crosshairs: {
                fill: {
                    type: "gradient",
                    gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
            title: {
                text: "Month",
                offsetY: -20,
                style: {
                    color: "#444",
                },
            },
            tooltip: {
                enabled: true,
                offsetY: -35,
            },
        },
        fill: {
            gradient: {
                shade: "light",
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100],
            },
        },
        yaxis: {
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
            labels: {
                show: true,
                formatter: function (val: any) {
                    return val + ""
                },
            },
            title: {
                text: "Count",
                offsetY: -10,
                style: {
                    color: "#444",
                },
            },
        },
        title: {
            text: "Monthly Orders",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
                color: "black",
            },
        },
    }

    const generateMonthLabels = () => {
        const months = [] as any;
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        for (let month = 0; month < 12; month++) {
            const date = new Date(currentYear, month, 1);
            const monthName = date.toLocaleString('default', { month: 'long' });
            months.push(monthName);
        }
        return months;
    };

    useEffect(() => {
        const months = generateMonthLabels();
        const seriesData = Array(12).fill(0);
        data.forEach(item => {
            const monthIndex = parseInt(item.name.split('-')[1]) - 1;
            seriesData[monthIndex] = item.dataCount;
        });
        setChartData({
            series: seriesData,
            labels: months,
            colors: dataColors
        });
    }, [data])

    return (
        <ReactApexChart
            options={options}
            series={[{ name: "Orders", data: chartData.series }]}
            type="bar"
            height={350}
            className="apex-charts"
        />
    )
}
export default MonthlyOrderChart
