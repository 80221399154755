import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

const MenuChart = ({ data }: { data: Array<any> }) => {
    // const dataColors = '["--bs-primary","--bs-success", "--bs-danger","--bs-info", "--bs-warning"]'
    // const dataColors = dashboardChartColors;
    // const doughnutEChartColors = getChartColorsArray(colors);
    const colors = [] as any;

    for (let i = 0; i < data.length; i++) {
        const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        colors.push(randomColor);
    }

    const [options, setOptions] = useState({
        toolbox: {
            show: false,
        },
        tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
            orient: "horizontal",
            x: "center",
            y: "bottom", 
            type: 'scroll',
            width: '80%',
            pageButtonItemGap: 5,
            pageButtonGap: 5,
            pageButtonPosition: 'end',
            pageFormatter: '{current}/{total}',
            pageIconColor: '#8791af',
            pageIconInactiveColor: '#666',
            pageIconSize: 10,
            pageTextStyle: {
                color: '#8791af',
            },
            data: [] as any,
            textStyle: {
                color: ["#8791af"],
            },
        },
        color: colors,
        series: [
            {
                name: "",
                type: "pie",
                radius: ["50%", "70%"],
                avoidLabelOverlap: true,
                label: {
                    normal: {
                        show: false,
                        position: "center",
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            color: ["#8791af"],
                            fontSize: "16",
                            fontWeight: "bold",
                        },
                    },
                },
                labelLine: {
                    normal: {
                        show: true,
                    },
                },
                data: [] as any,
            },
        ],
    });


    const getMenuChart = () => {
        const labels = data.map((item: any) => `${item.name} - ${item.dataCount}`);
        const newData = data.map(item => ({ value: item.dataCount, name: `${item.name} - ${item.dataCount}` }));
        setOptions(prevOptions => ({
            ...prevOptions,
            legend: {
                ...prevOptions.legend,
                data: labels,
            },
            series: [{
                ...prevOptions.series[0],
                data: newData,
            }],
        }));
    }

    useEffect(() => {
        getMenuChart();
    }, [data])

    return (
        <React.Fragment>
            <div data-aos="fade-up">
                <ReactEcharts style={{ height: "350px" }} option={options} />
            </div>
        </React.Fragment>
    );
};

export default MenuChart;
