import React from 'react'
import { PayPalButton } from "react-paypal-button-v2";

type Props = {
    callBack: any,
    amount: any,
    clientId: any
}

function PaypalPayment({ callBack, amount, clientId }: Props) {

    const payment = (status, paymentID) => {
        if (status == "COMPLETED") {
            callBack(paymentID);
        }
    }

    return (
        <div className="container-fluid">
            <div>
                <PayPalButton
                    amount={amount}
                    shippingPreference='NO_SHIPPING'
                    onSuccess={(details, data) => {
                        payment(details.status, data.paymentID)
                    }}
                    options={{
                        clientId: clientId,
                    }}
                />
            </div>
        </div>
    )
}

export default PaypalPayment