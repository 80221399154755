import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../Components/Common/ChartDynamicColor";

const Universitychart = ({ data }: { data: Array<any> }) => {
    const dataColors = '["#E75605"]'
    const spineareaChartColors = getChartColorsArray(dataColors);
    const [chartData, setChartData] = useState<any>({
        series: [],
        labels: [],
        colors: []
    });

    const series = [
        {
            name: "Order",
            data: chartData.series,
        },
    ]
    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: false,
        },

        colors: spineareaChartColors,
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: chartData.labels,
            title: {
                text: "Order",
                offsetY: -10,
                style: {
                    color: "#444",
                },
            },
        },
        yaxis: {
            title: {
                text: "University",
                offsetY: -10,
                style: {
                    color: "#444",
                },
            },
        }
    }

    useEffect(() => {
        const labels = data.map((item: any) => item.name);
        const series = data.map((item: any) => item.dataCount);
        setChartData({ series, labels, colors: dataColors });
    }, [])

    return (
        <div data-aos="fade-up">
            <ReactApexChart options={options} series={series} type="bar" height="350" />
        </div>
    )
}

export default Universitychart
