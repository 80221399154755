import React, { ChangeEvent, useEffect, useState } from "react";
import Breadcrumb from 'Components/Common/Breadcrumb';
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import { CustomTextBox } from "Components/Shared/CustomTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomAmount } from "Components/Shared/CustomAmount";
import { CustomImageUpload } from "Components/Shared/CustomImageUpload";
import "./Menustyle.css";
import { CustomDropdown } from "Components/Shared/CustomDropdown";
import { CustomButton } from "Components/Shared/CustomButton";
import { IMenuModel } from "Models/IMenuModel";
import CommonService from "Services/CommonService";
import { forkJoin } from "rxjs";
import debounce from 'lodash/debounce';
import { useNavigate, useParams } from "react-router-dom";
import { DecodeParam, getTokenDetail } from "../../helpers/helper";
import { Role } from "Components/constants/PandaConstants";
import { CustomTextArea } from "Components/Shared/CustomTextArea";

type MenuProps = {
    isEditId?: any,
}

const MenuCreate = ({ isEditId }: MenuProps) => {
    const [fileSelected, setFileSelected] = useState<any>([]);
    const [uploadedDocument, setUploadedDocument] = useState(null);
    const [isErr, setIsErr] = useState("");
    const [courseMaster, setCourseMaster] = useState<any>([]);
    const [restaurentMaster, setRestaurentMaster] = useState<any>([]);
    const { id } = useParams();
    const [isDecodedId, setIsDecodedId] = useState(1);
    const [RoleId, setRoleId] = useState<any>();
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [colStates, setColStates] = useState({});
    const [profileImage, setProfileImage] = useState<any>();
    const [updateImage, setUpdateImage] = useState<any>();
    const [imageId, setImageId] = useState(0);
    const isEdit = !!id;

    const [errCourse, setErrCourse] = useState({ 10: "", 20: "", 30: "", 31: "" })

    let navigate = useNavigate();
    document.title = "Create Menu | Panda GoGoGo ";

    // const toggleTab = (tab) => {
    //     if (activeTab !== tab) {
    //         setActiveTab(tab);
    //     }
    // };

    const toggleCollapse = (key) => {
        setColStates((prevColStates) => ({
            ...prevColStates,
            [key]: !prevColStates[key],
        }));
    };
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: "",
            displayName: "",
            description: "",
            enumName: "",
            amount: 0,
            restaurentId: "",
            courseCount: 3,
            menuCourse: [{
                minimumSelection: null,
                maximumSelection: null,
                displayName: "",
                menuId: 0,
                courseId: 1,
                menuCourseItem: [{
                    menuId: 0,
                    courseId: 1,
                    name: "",
                    displayName: "",
                }]
            }, {
                minimumSelection: null,
                maximumSelection: null,
                displayName: "",
                menuId: 0,
                courseId: 2,
                menuCourseItem: [{
                    menuId: 0,
                    courseId: 2,
                    name: "",
                    displayName: "",
                }]
            }, {
                minimumSelection: null,
                maximumSelection: null,
                displayName: "",
                menuId: 0,
                courseId: 3,
                menuCourseItem: [{
                    menuId: 0,
                    courseId: 3,
                    name: "",
                    displayName: "",
                }, {
                    menuId: 0,
                    courseId: 3,
                    name: "",
                    displayName: ""
                }]
            }],
            menuImage: {
                id: 0,
                menuId: 0,
                uploadedImage: "",
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Please Enter the menu name").max(30, "Menu Name must be at most 30 characters"),
            // pickUpAddressId: Yup.string().required("Please Select the Pickup Address"),
            restaurentId: Yup.number().required("Please Select the Restaurent"),
            amount: Yup.number()
                .required("Please Enter the amount")
                .positive("Amount must be a positive number")
                .min(0, "Amount must be greater than or equal to 1")
                .max(1000000, "Amount must be less than or equal to 1,000,000"),
            description: Yup.string().notRequired().nullable().max(200, "The description should not exceed 200 characters"),
            // courseCount: Yup.number().required("Please Enter the Courses Count").positive("Amount must be a positive number"),
            // menuCourse: Yup.array().of(
            //     Yup.object().shape({
            //         menuCourseItem: Yup.array().of(
            //             Yup.object().shape({
            //                 name: Yup.string()
            //             })
            //         )
            //     })
            // )
        }),
        onSubmit: (values: any) => {
            if (isEdit ? false : !uploadedDocument) {
                setIsErr("Please upload Menu Image");
                return;
            }
            for (let i = 0; i < values.menuCourse.length; i++) {
                let menuCourse = values.menuCourse[i];
                for (let j = 0; j < menuCourse.menuCourseItem.length; j++) {
                    if (menuCourse.menuCourseItem[j].name === "") {
                        menuCourse.menuCourseItem.splice(j, 1);
                        j--;
                    }
                }
                if (menuCourse.menuCourseItem.length === 0) {
                    values.menuCourse.splice(i, 1);
                    i--;
                }
            }
            // if (values.menuCourse[0].menuCourseItem[0].name == "") {
            //     setErrCourse({ ...errCourse, 10: "Please Enter the menu course item name" });
            //     return;
            // } if (values.menuCourse[1].menuCourseItem[0].name == "") {
            //     setErrCourse({ ...errCourse, 20: "Please Enter the menu course item name" });
            //     return;
            // } if (values.menuCourse[2].menuCourseItem[0].name == "") {
            //     setErrCourse({ ...errCourse, 30: "Please Enter the menu course item name" });
            //     return;
            // } if (values.menuCourse[2].menuCourseItem[1].name == "") {
            //     setErrCourse({ ...errCourse, 31: "Please Enter the menu course item name" });
            //     return;
            // }
            createMenu(values);
            // setDisabled(true);
        }
    });

    const createMenu = (menuData: IMenuModel) => {
        let token: any = getTokenDetail();
        if (token.RoleId == Role.Admin) {
            let restaurentId = localStorage.getItem("restaurentId");
            menuData.displayName = menuData.enumName = menuData.name;
            menuData.restaurentId = restaurentId;
            menuData.id = isEdit ? isDecodedId : 0;
            menuData.menuImage.menuId = isEdit ? isDecodedId : 0;
            menuData.menuImage.uploadedImage = isEdit ? updateImage : uploadedDocument as any;
            menuData.menuImage.id = imageId;
            if (menuData.menuCourse.length == 0) {
                menuData.menuCourse = [{
                    minimumSelection: 0,
                    maximumSelection: 0,
                    displayName: "",
                    menuId: 0,
                    courseId: 1,
                    menuCourseItem: [{
                        menuId: 0,
                        courseId: 1,
                        name: "",
                        displayName: "",
                        courseItemImage: {
                            menuId: 0,
                            menuCourseItemId: 0,
                            uploadedImage: "",
                        }
                    }]
                }]
                menuData.menuCourse[0].menuCourseItem[0].name = menuData.menuCourse[0].menuCourseItem[0].displayName = menuData.name
            }
            menuData.menuCourse = menuData.menuCourse.map(course => ({
                ...course,
                menuId: isEdit ? isDecodedId : 0,
                menuCourseItem: course.menuCourseItem.map(menuCourseItem => ({
                    ...menuCourseItem,
                    menuId: isEdit ? isDecodedId : 0,
                    displayName: menuCourseItem.name
                }))
            }));
        } else {
            menuData.displayName = menuData.enumName = menuData.name;
            menuData.id = isEdit ? isDecodedId : 0;
            menuData.menuImage.menuId = isEdit ? isDecodedId : 0;
            menuData.menuCourse = menuData.menuCourse.map(course => ({
                ...course,
                menuId: isEdit ? isDecodedId : 0,
                menuCourseItem: course.menuCourseItem.map(menuCourseItem => ({
                    ...menuCourseItem,
                    menuId: isEdit ? isDecodedId : 0,
                    displayName: menuCourseItem.name
                }))
            }));
        }

        // if (menuData.menuCourse[0].menuCourseItem[0].name == "") {
        //     setErrCourse({ ...errCourse, 10: "Please Enter the menu Name" })
        // } else if (menuData.menuCourse[1].menuCourseItem[0].name == "") {
        //     setErrCourse({ ...errCourse, 20: "Please Enter the menu Name" })
        // } else if (menuData.menuCourse[2].menuCourseItem[0].name == "") {
        //     setErrCourse({ ...errCourse, 30: "Please Enter the menu Name" })
        // } else if (menuData.menuCourse[2].menuCourseItem[1].name == "") {
        //     setErrCourse({ ...errCourse, 31: "Please Enter the menu Name" })
        // }
        let formData = new FormData();
        formData = appendFormData(formData, menuData);
        setDisabled(true);
        CommonService.postWithFormData("Menu", isEdit ? "UpdateMenu" : "CreateMenu", formData).then(res => {
            if (res.status === 200) {
                navigate("/menu")
            }
        }).catch((e: string) => {
            console.log(e)
        });
    }

    function appendFormData(formData, data, parentKey = '') {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                const nestedKey = parentKey ? `${parentKey}.${key}` : key;
                if (Array.isArray(data[key])) {
                    for (let i = 0; i < data[key].length; i++) {
                        appendFormData(formData, data[key][i], `${nestedKey}[${i}]`);
                    }
                } else if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
                    appendFormData(formData, data[key], nestedKey);
                } else {
                    formData.append(nestedKey, data[key]);
                }
            }
        }
        return formData;
    }

    const debouncedUpdate = debounce((index, menuCourseItemIndex, selectedValue) => {
        const updatedMenuCourseItemList = validation.values.menuCourse[index].menuCourseItem.map((item, i) => {
            if (i === menuCourseItemIndex) {
                return { ...item, name: selectedValue, courseId: validation.values.menuCourse[index].courseId };
            } else {
                return item;
            }
        });

        validation.setFieldValue(`menuCourse[${index}].menuCourseItem`, updatedMenuCourseItemList);
    }, 50);

    // const handleCourseCountChange = (event) => {
    //     validation.setFieldValue("courseCount", event.target.value);
    //     const courseCount = event.target.value;
    //     const updatedMenuCourse = [] as any;
    //     let menuCourseValue = {
    //         minimumSelection: 0,
    //         maximumSelection: 0,
    //         displayName: "",
    //         menuId: isEdit ? isDecodedId : 0,
    //         courseId: 0,
    //         menuCourseItem: [{
    //             menuId: isEdit ? isDecodedId : 0,
    //             courseId: 0,
    //             name: "",
    //             displayName: "",
    //             menuImage: {
    //                 uploadFilename: "",
    //             }
    //         }]
    //     }
    //     for (let i = 0; i < courseCount; i++) {
    //         updatedMenuCourse.push(menuCourseValue);
    //     }
    //     validation.setFieldValue("menuCourse", updatedMenuCourse);
    // }

    const handleRestaurentChange = async (event) => {
        validation.handleChange(event);
        // getPickUpAddress(event.target.value);
    };

    // const getPickUpAddress = (id) => {
    //     let pickUpAddress = CommonService.getWithQueryParam("PickupAddress", "GetPickupAddressByRestaurentId", "restaurentId", id);
    //     forkJoin({ pickUpAddress }).subscribe((res: { pickUpAddress: any }) => {
    //         // let pickUpAddressValue: any = [];
    //         // pickUpAddressValue.push(res.pickUpAddress.data.responseData);
    //         setPickUpAddressMaster(res.pickUpAddress.data.responseData)
    //     });
    // }

    // const AddMenuCourseItem = (menuCourseIndex) => {
    //     validation.setFieldValue(`menuCourse[${menuCourseIndex}].menuCourseItem`, [
    //         ...validation.values.menuCourse[menuCourseIndex].menuCourseItem,
    //         {
    //             menuId: isEdit ? isDecodedId : 0,
    //             courseId: 0,
    //             name: "",
    //             displayName: "",
    //             menuImage: {
    //                 uploadFilename: "",
    //             }
    //         },
    //     ]);
    // }

    // const handleMenuCourseItemFileUpload = (index, menuCourseItemIndex, fileName, fileSize, selectedFile) => {
    //     validation.setFieldValue(`menuCourse[${index}.menuCourseItem[${menuCourseItemIndex}.courseItemImage]]`, {
    //         uploadedImage: selectedFile,
    //     })
    // }

    // const handleNextTab = (currentTab: string) => {
    //     const nextTabNumber = parseInt(currentTab, 10) + 1;
    //     setActiveTab(nextTabNumber.toString());
    // };

    // const renderAccordions = () => {
    //     return validation.values.menuCourse.map((menuCourse, index) => {
    //         const tabNumber = (index + 1).toString();
    //         const menuCourseItemLength = validation.values.menuCourse[index].menuCourseItem.length;
    //         return (
    //             validation.values.menuCourse[index].menuCourseItem.map((menuCourseItem, menuCourseItemIndex) => (
    //                 <Col lg={6} key={menuCourseItemIndex}>
    //                     <CustomTextBox
    //                         label={tabNumber == 1 ? "Entree" : tabNumber == 2 ? "Main" : `Side ${menuCourseItemIndex + 1}`}
    //                         id={`name_${menuCourseItemIndex}`}
    //                         placeholder={"Enter the Course Item Name"}
    //                         value={menuCourseItem.name}
    //                         name={`menuCourseItem[${menuCourseItemIndex}].name`}
    //                         onChange={(event: ChangeEvent<{ value: unknown; }>) => {
    //                             const selectedValue = event.target.value as string;
    //                             if (selectedValue) {
    //                                 errCourse[`${tabNumber}${menuCourseItemIndex}`] = "";
    //                                 setErrCourse(errCourse);
    //                             }
    //                             else {
    //                                 errCourse[`${tabNumber}${menuCourseItemIndex}`] = "Please Enter the menu course item name";
    //                                 setErrCourse(errCourse);
    //                             }                                // console.log(validation.errors.menuCourse ?validation.errors.menuCourse[index]["menuCourseItem"][menuCourseItemIndex].name :null)
    //                             // const updatedMenuCourseItemList = validation.values.menuCourse[index].menuCourseItem.map((item, i) => i === menuCourseItemIndex ? { ...item, name: selectedValue } : item
    //                             // );
    //                             // validation.setFieldValue(`menuCourse[${index}].menuCourseItem[${menuCourseItemIndex}]`, updatedMenuCourseItemList);
    //                             debouncedUpdate(index, menuCourseItemIndex, selectedValue);
    //                         }}
    //                         onBlur={validation.handleBlur}
    //                         invalid={undefined} error={errCourse[`${tabNumber}${menuCourseItemIndex}`]} />
    //                     <span className="text-danger">{errCourse[`${tabNumber}${menuCourseItemIndex}`]}</span>
    //                 </Col>
    //             ))
    //         )
    //     })
    // }

    // const renderTabs = () => {
    //     const tabs: React.ReactNode[] = [];
    //     for (let i = 1; i <= validation.values.courseCount; i++) {
    //         const tabNumber = i.toString();
    //         tabs.push(
    //             <NavItem key={i}>
    //                 <NavLink
    //                     style={{ cursor: "pointer" }}
    //                     className={classnames({
    //                         "mb-2": true,
    //                         active: activeTab === tabNumber,
    //                         "custom-active-tab": activeTab === tabNumber,
    //                     })}
    //                     onClick={() => toggleTab(tabNumber)}
    //                 >
    //                     Course {i}
    //                 </NavLink>
    //             </NavItem>
    //         );
    //     }
    //     return tabs;
    // };


    // const renderTabContents = () => {
    //     return validation.values.menuCourse.map((menuCourse, index) => {
    //         const tabNumber = (index + 1).toString();
    //         const isLastTab = validation.values.courseCount;

    //         return (
    //             <TabPane key={index + 1} tabId={tabNumber}>
    //                 <div>
    //                     {/* <Row>
    //                         <Col lg={4}>
    //                             <CustomDropdown
    //                                 label="Courses"
    //                                 id={`courseId_${index}`}
    //                                 value={menuCourse.courseId}
    //                                 data={courseMaster}
    //                                 error={validation.errors.course}
    //                                 valueField="id"
    //                                 isMandatory={true}
    //                                 displayField='displayName'
    //                                 disabled={true}
    //                                 onChange={(event: ChangeEvent<{ value: unknown }>) => {
    //                                     const selectedValue = event.target.value;
    //                                     const updatedMenuCourseList = validation.values.menuCourse.map((item, i) =>
    //                                         i === index ? { ...item, courseId: selectedValue } : item
    //                                     );
    //                                     validation.setFieldValue('menuCourse', updatedMenuCourseList);
    //                                 }}
    //                                 onBlur={validation.handleBlur}
    //                                 invalid={validation.touched.course && validation.errors.course ? true : false}
    //                                 touched={validation.touched.course} />
    //                         </Col>
    //                         <Col lg={4}>
    //                             <CustomNumberOnlyBox
    //                                 label={"Minimum Count"}
    //                                 id={`minimumselection_${index}`}
    //                                 placeholder={"Enter the Minimum Count"}
    //                                 value={menuCourse.minimumSelection}
    //                                 isMandatory={true}
    //                                 onChange={(event: ChangeEvent<{ value: unknown }>) => {
    //                                     const selectedValue = event.target.value;
    //                                     const updatedMenuCourseList = validation.values.menuCourse.map((item, i) =>
    //                                         i === index ? { ...item, minimumSelection: selectedValue } : item
    //                                     );
    //                                     validation.setFieldValue('menuCourse', updatedMenuCourseList);
    //                                 }}
    //                                 onBlur={validation.handleBlur}
    //                                 error={undefined} />
    //                         </Col>
    //                         <Col lg={4}>
    //                             <CustomNumberOnlyBox
    //                                 label={"Maximum Count"}
    //                                 id={`maximumSelection_${index}`}
    //                                 placeholder={"Enter the Maximum Count"}
    //                                 value={menuCourse.maximumSelection}
    //                                 isMandatory={true}
    //                                 onChange={(event: ChangeEvent<{ value: unknown }>) => {
    //                                     const selectedValue = event.target.value;
    //                                     const updatedMenuCourseList = validation.values.menuCourse.map((item, i) =>
    //                                         i === index ? { ...item, maximumSelection: selectedValue } : item
    //                                     );
    //                                     validation.setFieldValue('menuCourse', updatedMenuCourseList);
    //                                 }}
    //                                 onBlur={validation.handleBlur}
    //                                 error={undefined} />
    //                         </Col>
    //                     </Row> */}

    //                     {validation.values.menuCourse[index].menuCourseItem.map((menuCourseItem, menuCourseItemIndex) => (
    //                         <Row key={menuCourseItemIndex}>
    //                             <Col lg={6}>
    //                                 <CustomTextBox
    //                                     label={" Course Item Name"}
    //                                     id={`name_${menuCourseItemIndex}`}
    //                                     placeholder={"Enter the Course Item Name"}
    //                                     value={menuCourseItem.name}
    //                                     onChange={(event: ChangeEvent<{ value: unknown; }>) => {
    //                                         const selectedValue = event.target.value as string;
    //                                         // const updatedMenuCourseItemList = validation.values.menuCourse[index].menuCourseItem.map((item, i) => i === menuCourseItemIndex ? { ...item, name: selectedValue } : item
    //                                         // );
    //                                         // validation.setFieldValue(`menuCourse[${index}].menuCourseItem[${menuCourseItemIndex}]`, updatedMenuCourseItemList);
    //                                         debouncedUpdate(index, menuCourseItemIndex, selectedValue);
    //                                     }}
    //                                     onBlur={validation.handleBlur}
    //                                     isMandatory={true}
    //                                     invalid={undefined} error={undefined} />
    //                             </Col>
    //                             {/* <Col lg={2} className="custom-image-upload">
    //                                 <CustomImageUpload
    //                                     placeholder={"Drop your image here"}
    //                                     label={"Image Upload"}
    //                                     id={"imageUpload"}
    //                                     value={undefined}
    //                                     error={undefined}
    //                                     isMandatory={true}
    //                                     onFileSelected={(fileName, fileSize, selectedFile) => handleMenuCourseItemFileUpload(index, menuCourseItemIndex, fileName, fileSize, selectedFile)}
    //                                 />
    //                             </Col> */}
    //                             {/* <Col lg={3} className="mb-3">
    //                                 {isEdit ? null : <button type="button" onClick={() => AddMenuCourseItem(index)} className="additembtn">
    //                                     <p className="header-text-grid fw-bold">+ Add Item</p>
    //                                 </button>}
    //                             </Col> */}
    //                         </Row>
    //                     ))}
    //                 </div>
    //                 <Row>
    //                     <div className=" d-flex justify-content-end">
    //                         {isLastTab == activeTab ? null : (
    //                             <CustomButton
    //                                 type="button"
    //                                 btnClassName="btn-success loginbtn"
    //                                 iconClasssName="fas fa-arrow-circle-right"
    //                                 name="Next"
    //                                 onClick={() => handleNextTab(tabNumber)}
    //                             />
    //                         )}
    //                     </div>
    //                 </Row>
    //             </TabPane>
    //         );
    //     });
    // };

    const handleFileSelected = (fileName, fileSize, selectedFile) => {
        setIsErr("");
        setUploadedDocument(selectedFile);
        setUpdateImage(selectedFile);
        setFileSelected((prevFileSelected) => {
            const updatedFileSelected = (fileName !== "");
            return updatedFileSelected;
        });
        // validation.setFieldValue("menuImage", {
        //     uploadedImage: selectedFile,
        //     uploadedFilePath: "menu",
        //     uploadFilename: fileName,
        //     displayName: fileName
        // })
    };

    const base64toFile = (base64String, fileName, mimeType) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });
        return file;
    };

    const getMenuById = () => {
        let decodedId = DecodeParam(id);
        setIsDecodedId(decodedId);
        CommonService.getWithQueryParam("Menu", "GetMenuById", "id", decodedId).then(res => {
            if (res.status == 200) {
                let responseValue = res.data.responseData as IMenuModel;
                let courseCountValue = res.data.responseData.menuCourse.length;
                const newValue = Math.floor(res.data.responseData.amount).toString();
                const amountValue = parseFloat(newValue);
                validation.setFieldValue("amount", amountValue);
                validation.setValues({
                    ...responseValue,
                    courseCount: courseCountValue
                });
                setImageId(responseValue.menuImage.id);
                validation.setFieldValue("menuImage.id", responseValue.menuImage.id);
                CommonService.GetBase64ProfileImage("Menu", "GetBase64MenuImage", "id", responseValue.menuImage.id).then(response => {
                    if (response.status === 200) {
                        let base64ImageString = `data:image/png;base64,${response.data}`;
                        setProfileImage(base64ImageString);
                        const base64ImageStringforFile = response.data;
                        const convertedfile = base64toFile(base64ImageStringforFile, responseValue.menuImage.uploadFileName, "image/png");
                        setUpdateImage(convertedfile);
                        validation.setFieldValue("menuImage.uploadedImage", convertedfile);
                    }
                });
            }
        }).catch(e => console.log(e));
    }

    const validateUserRole = () => {
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        setRoleId(roleIdValue);
        if (token.RoleId == Role.Admin) {
            setIsRestaurant(true);
            let restaurentId = localStorage.getItem("restaurentId");
            validation.setFieldValue("restaurentId", restaurentId);
            // let pickUpAddress = CommonService.getWithQueryParam("PickupAddress", "GetPickupAddressByRestaurentId", "restaurentId", restaurentId);
            // forkJoin({ pickUpAddress }).subscribe((res: { pickUpAddress: any }) => {
            //     let pickUpAddressValue: any = [];
            //     pickUpAddressValue.push(res.pickUpAddress.data.responseData);
            //     setPickUpAddressMaster(res.pickUpAddress.data.responseData)
            // });
        }
    }
    useEffect(() => {
        let course = CommonService.get("Masters", "GetCourseMaster");
        let restaurent = CommonService.get("Restaurent", "GetAllRestaurent");
        forkJoin({ course, restaurent }).subscribe((res: { course: any, restaurent: any }) => {
            setCourseMaster(res.course.data.responseData.courseList);
            setRestaurentMaster(res.restaurent.data.responseData);
        });
        validateUserRole();
        // if (isRestaurant) {
        //     let restaurentId = localStorage.getItem("restaurentId");
        //     let pickUpAddress = CommonService.getWithQueryParam("PickupAddress", "GetPickupAddressByRestaurentId", "restaurentId", restaurentId);
        //     forkJoin({ pickUpAddress }).subscribe((res: { pickUpAddress: any }) => {
        //         setPickUpAddressMaster(res.pickUpAddress.data.responseData)
        //     });
        // }
    }, []);

    useEffect(() => {
        if (isEdit) {
            getMenuById();
        }
    }, [isEdit, isEditId]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title="Menu"
                        breadcrumbItem={"createMenu"}
                        breadcrumbTitle={"Menu"}
                        breadcrumbTitlePath={"/menu"}
                        breadcrumbItemPath={"/menu/create"} />
                    <Card>
                        <CardBody>
                            <Form className="form-horizontal" onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                                <Row>
                                    <Col lg={4} >
                                        <div>
                                            <CustomTextBox
                                                label={"Menu Name"}
                                                id={"name"}
                                                isMandatory={true}
                                                placeholder={"Enter the menu Name"}
                                                value={validation.values.name || ""}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                invalid={validation.touched.name && validation.errors.name ? true : false}
                                                error={validation.errors.name} />
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div>
                                            <CustomAmount
                                                label={"Cost"}
                                                id={"amount"}
                                                value={validation.values.amount || ""}
                                                name={"amount"}
                                                placeholder={"Enter the cost of menu"}
                                                error={validation.errors.amount}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                touched={validation.touched.amount}
                                                isMandatory={true}
                                                invalid={validation.touched.amount && validation.errors.amount ? true : false} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="custom-image-upload">
                                        <CustomImageUpload
                                            placeholder={"Drop your image here"}
                                            label={"Image Upload"}
                                            id={"imageUpload"}
                                            isMandatory={true}
                                            value={undefined}
                                            className={isErr ? "custom-err" : ""}
                                            error={isErr}
                                            onFileSelected={handleFileSelected}
                                            isEditForm={isEdit} isEditPageImage={profileImage} />
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Col lg={4} className="mb-3">
                                        <Label>Course Count</Label>
                                        <input
                                            className="form-control mt-3 mb-1"
                                            type="number"
                                            id="example-number-input"
                                            placeholder="Enter the Courses Count"
                                            disabled={true}
                                            defaultValue={validation.values.courseCount}
                                            onChange={(e) => { handleCourseCountChange(e) }}
                                        />
                                    </Col> */}
                                    <Col lg={4}>
                                        {isRestaurant ? <CustomDropdown
                                            label="Restaurant"
                                            id={"restaurentId"}
                                            value={validation.values.restaurentId}
                                            data={restaurentMaster}
                                            error={validation.errors.restaurentId}
                                            valueField="id"
                                            isMandatory={true}
                                            disabled={true}
                                            displayField='displayName'
                                            onChange={handleRestaurentChange}
                                            onBlur={validation.handleBlur}
                                            invalid={validation.touched.restaurentId && validation.errors.restaurentId ? true : false}
                                            touched={validation.touched.restaurentId} /> : <CustomDropdown
                                            label="Restaurant"
                                            id={"restaurentId"}
                                            value={validation.values.restaurentId}
                                            data={restaurentMaster}
                                            error={validation.errors.restaurentId}
                                            valueField="id"
                                            isMandatory={true}
                                            displayField='displayName'
                                            onChange={handleRestaurentChange}
                                            onBlur={validation.handleBlur}
                                            invalid={validation.touched.restaurentId && validation.errors.restaurentId ? true : false}
                                            touched={validation.touched.restaurentId} />}
                                    </Col>
                                    {/* {pickUpAddressMaster.length > 0 ? <Col lg={4}>
                                        <CustomRadioGroup
                                            data={pickUpAddressMaster}
                                            value={validation.values.pickUpAddressId}
                                            displayName={'address'}
                                            direction={'vertical'}
                                            id="pickUpAddressId" defaultValue={""}
                                            onChange={validation.handleChange}
                                            error={validation.errors.pickUpAddressId}
                                            valueField="id"
                                            label="PickUp Address" />
                                    </Col> : null} */}
                                </Row>
                                {validation.values.courseCount > 0 && (
                                    <Col>
                                        <Card className="mt-3 custom-border">
                                            <CardBody>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        {/* {renderAccordions()} */}
                                                        <Col>
                                                            <CustomTextArea
                                                                label={"Description"}
                                                                id={"description"}
                                                                isMandatory={false}
                                                                value={validation.values.description}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                invalid={validation.touched.description && validation.errors.description ? true : false}
                                                                error={validation.errors.description} />

                                                        </Col>
                                                    </div>
                                                    {/* <Row>
                                                <Nav pills className="navtab-bg nav-justified">
                                                    {renderTabs()}
                                                </Nav>
                                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                                    {renderTabContents()}
                                                </TabContent>
                                            </Row> */}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>)}
                                <div>
                                    <div className="d-flex justify-content-end">
                                        <CustomButton
                                            type="submit"
                                            btnClassName="btn-secondary ms-2"
                                            iconClasssName="mdi mdi-close"
                                            name="Cancel"
                                            onClick={() => navigate('/menu')}
                                            disabled={disabled}
                                        />
                                        <CustomButton
                                            type="submit"
                                            btnClassName="btn-success loginbtn ms-2"
                                            iconClasssName="dripicons-checkmark"
                                            name="Submit"
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>

                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    )
}

export default MenuCreate;