import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, CardBody, Card, Container, Form, Alert } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import foodprofile from "../../assets/images/login/foodprofile.png"
import { resetLoginMsgFlag } from "slices/auth/login/thunk";
import withRouter from "Components/Common/withRouter";
import { createSelector } from 'reselect';
import { CustomEmailBox } from "Components/Shared/CustomEmailBox";
import { CustomPasswordBox } from "Components/Shared/CustomPasswordBox";
import { CustomCheckBox } from "Components/Shared/CustomCheckBox";
import { HelperConstant, Role } from "Components/constants/PandaConstants";
import CommonService from "Services/CommonService";
import { IAuthenticateModel } from "Models/IAuthenticateModel";
import { EncodeParam, getTokenDetail } from "helpers/helper";
import { Loader } from "Components/Loader/Loader";
import moment from "moment";

const Login = (props: any) => {

  const [rememberMe, setRememberMe] = useState(false);
  const [authErr, setAuthErr] = useState("");
  const [loginId, setLoginId] = useState<any>();
  const [loader, setLoader] = useState(false);

  const dispatch: any = useDispatch();
  let navigate = useNavigate();

  document.title = "Login | Panda GoGoGo ";


  const selectProperties = createSelector(
    (state: any) => state.Login,
    (error) => error
  );
  const { error, userName, password } = useSelector(selectProperties);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email").matches(HelperConstant.emailPattern.pattern, 'Please Enter a Valid Email'),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: IAuthenticateModel) => {
      setLoader(true);
      CommonService.postWithDoubleParam("Auth", "authenticate", values.email,EncodeParam(values.password)).then(res => {
        if (res.status === 200) {
          setLoader(false);
          if (res.data == "Not Valid User") {
            return setAuthErr("Invalid User Credentials");
          }
          if (res.data == "Invalid Credentials") {
            return setAuthErr(res.data);
          }
          if (res.data == "Activate Your Account Kindly Check Your Email") {
            setAuthErr("Kindly Check Your Email for Activate Your Account");
          }
          else {
            rememberMeHandler(values.email, values.password);
            setAuthErr("");
            localStorage.setItem("authUser", res.data.token);
            let token: any = getTokenDetail();
            setLoginId(token.LoginId);
            if (token.RoleId == Role.SuperAdmin) {
              window.location.href = '/dashboard'
            } else if (token.RoleId == Role.Admin) {
              getRestaurantIdByLoginId();
            } else if (token.RoleId = Role.User) {
              window.location.href = "/";
            }
          }
        }
      }).catch((err) => {
        console.log(err)
      });
    }
  });

  const getRestaurantIdByLoginId = () => {
    let token: any = getTokenDetail();
    let userId = token.LoginId;
    CommonService.getWithQueryParam("UserRestaurent", "GetUserRestaurentByLoginIdAsync", "loginId", userId).then(res => {
      if (res.status == 200) {
        localStorage.setItem("loginId", res.data.responseData.loginId);
        localStorage.setItem("restaurentId", res.data.responseData.restaurentId);
        if (token.RoleId == Role.Admin) {
          let encodedId = EncodeParam(res.data.responseData.restaurentId)
          window.location.href = `/restaurant/view/${encodedId}`
        }
      }
    }).catch(e => console.log(e));
  }

  const automaticUpdateCouponExpiry = () => {
    CommonService.putWithQueryParam("CouponCode", "UpdateCouponCodeExpired", "toDate", moment.utc().format("YYYY-MM-DD HH:mm:ss")).then(res => {
      if (res.status === 200) {
      }
    })
  }

  const retainRememberMe = () => {
    let rememberMe = localStorage.getItem("rememberme");
    if (userName && password) {
      validation.setFieldValue("email", userName);
      validation.setFieldValue("password", password);
    }
    else {
      if (rememberMe) {
        setRememberMe(true);
        let { userName, password } = JSON.parse(rememberMe);
        validation.setFieldValue("email", userName);
        validation.setFieldValue("password", password);
      }
    }
  }

  const rememberMeHandler = (userName, password) => {
    if (rememberMe) {
      localStorage.setItem("rememberme", JSON.stringify({ userName: userName, password: password }));
    }
    else {
      localStorage.removeItem("rememberme");
    }
  }

  useEffect(() => {
    retainRememberMe();
    automaticUpdateCouponExpiry();
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  return (
    <React.Fragment>
      <div className="account-pages pt-5 login-app default-height">
        <Loader open={loader} />
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                <div className="background-grid">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h4 className="header-text-grid" style={{whiteSpace :"nowrap" }}>Welcome Back !</h4>
                        <p className="header-text-grid">Sign in to Panda GoGoGo.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={foodprofile} alt="" className="profile-login" style={{ height: "120px" }} />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mx-1 mt-2">
                  {authErr &&
                    <div className="row">
                      <div className="col-12 text-center">
                        <Alert
                          color="warning"
                          className="fade show"
                          role="alert"
                        >
                          <i className="mdi mdi-alert-outline me-2"></i>
                          {authErr}
                        </Alert>
                      </div>
                    </div>
                  }
                  <div className="p-1">
                    <Form className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      <Col lg={12}>
                        <CustomEmailBox label="Email" name="email" id="email" placeholder="Enter Email" value={validation.values.email}
                          onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.errors.email && validation.touched.email ? true : false}
                          error={validation.errors.email} isMandatory={true} />
                      </Col>
                      <Col lg={12}>
                        <CustomPasswordBox
                          label="Password"
                          placeholder="Enter Password"
                          id="password"
                          value={validation.values.password}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          error={validation.errors.password}
                          touched={validation.touched.password}
                          invalid={validation.errors.password && validation.touched.password ? true : false}
                          isMandatory={true}
                        />
                      </Col>
                      <div className="flex">
                        <div className="mt-2 form-check">
                          <CustomCheckBox
                            checked={rememberMe}
                            id="checkbox"
                            value={rememberMe}
                            error={""}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            defaultChecked={false}
                            touched={""}
                            label="Remember Me"
                          />
                          <Link to="/forgot-password" className="text-muted forgot_pass_style"><i className="mdi mdi-lock me-1" /> Forgot your password?</Link>
                        </div>
                      </div>
                      <div className="mt-lg-3 mt-4 d-grid">
                        <button className="loginbtn" type="submit">Sign In</button>
                      </div>
                      <div className="mt-4 mb-0 text-center">
                        <div className="mt-4 text-center">
                          <p> Don&apos;t have an account ?{" "}
                            <Link to="/register" className="fw-medium text-primary"> {" "} Register here{" "}</Link>{" "}
                          </p>
                        </div>
                        <p> Pandagogogo © {new Date().getFullYear()}. All Rights Reserved..
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
