import { CustomButton } from "Components/Shared/CustomButton";
import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Breadcrumb from "Components/Common/Breadcrumb";
import { getTokenDetail } from "helpers/helper";
import UniversityMaster from "./UniversityCreateEdit";
import CustomDataTable from "Components/Shared/CustomDataTable";
import { Loader } from "Components/Loader/Loader";

const UniversityDashBoard = () => {
    const [universityModel, setUniversityModel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditId, setIsEditId] = useState<number>(0);
    const [delect, setDelect] = useState();
    const [university, setUniversity] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [loader, setLoader] = useState(true);
    const [rowPerPageValue, setRowPerPageValue] = useState(10);

    let navigate = useNavigate();
    document.title = "University | Panda GoGoGo ";


    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "0%"
        }, {
            Header: 'University Name',
            accessor: 'name',
            width: "25%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'County Name',
            accessor: 'countryName',
            width: "20%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'State Name',
            accessor: 'stateName',
            width: "20%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'City Name',
            accessor: 'cityName',
            width: "20%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Zipcode',
            accessor: 'zipName',
            width: "20%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Active',
            accessor: 'active',
            datatype: "boolean",
            Filter: true,
            sortable: true,
            width: '20%',
        },
    ], []);

    const onAddMenuClick = () => {
        setUniversityModel(true);
    }

    const getUniversityBySearch = (pageNumber: any, searchText: string, sortcolumn?: any, sortorder?: any, rowPerPageVal?: any) => {
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        let restaurentId = localStorage.getItem("restaurentId");

        let searchModel = {
            offsetStart: pageNumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "",
            searchText: searchText ? searchText : "",
            sortOrderColumn: sortcolumn ? sortcolumn : "",
        }
        CommonService.post("University", "GetUniversitySearch", searchModel).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                setUniversity(data);
                setLoader(false);
            }
        })
    }

    const editHandler = (val: any) => {
        setIsEdit(true);
        setIsEditId(val.id);
        setUniversityModel(true);
    }

    const deleteHandler = (val) => {
        setDelect(val.id);
        setShowDeleteConfirmation(true);
    }

    const confirmDelete = () => {
        setShowDeleteConfirmation(false);
        CommonService.deleteWithQueryParam("University", "RemoveUniversity", "id", delect)
            .then(res => {
                if (res.data.responseMessage === "Deleted Successfully.") {
                    setShowDeleteSuccess(true);
                    setTimeout(() => {
                        setShowDeleteSuccess(false);
                        getUniversityBySearch(1, "")
                    }, 2000);
                }
                else {
                    console.error("Delete failed with an unexpected response:", res);
                }
            })
            .catch(e => console.log(e));
    }

    const cancelDelete = () => {
        setShowDeleteConfirmation(false);
    }

    const pagination = (pageNumber: any) => {
        getUniversityBySearch(pageNumber, search, selectedColumn, sortOrder);
    }

    const onColumnSortChange = (columnName, sortorder) => {
        setSelectedColumn(columnName);
        setSortOrder(sortorder);
        getUniversityBySearch(1, search, columnName, sortOrder);
    };

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getUniversityBySearch(1, searchKeyword);
    }

    const isCancel = () => {
        setIsEdit(false);
        setIsEditId(0);
        setUniversityModel(false);
        getUniversityBySearch(1, "");
    }

    useEffect(() => {
        getUniversityBySearch(1, "")
    }, []);


    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        getUniversityBySearch(1, "", null, null, val);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="University" breadcrumbItem="University" breadcrumbTitle={"Dashboard"}
                    breadcrumbTitlePath={"/university"} breadcrumbItemPath={"/dashboard"} />
                <Card>
                    <CardBody>
                        <div className="d-grid">
                            <Col className="text-end" style={{ marginTop: "-20px", marginBottom: "5px" }}>
                                <CustomButton type="button" btnClassName="btn btn-success w-md" iconClasssName="fa fa-plus" name="Create" onClick={() => onAddMenuClick()} />
                            </Col>
                        </div>
                        <Loader open={loader} />

                        <CustomDataTable
                            rowPerPageValue={rowPerPageValue}
                            rowPerPageHandler={rowPerPageHandler}
                            columns={columns}
                            data={university}
                            canEdit={false}
                            perPageData={rowPerPageValue}
                            customPageSize={rowPerPageValue}
                            editHandler={editHandler} canShowButton={false} deleteHandler={deleteHandler}
                            createHandler={undefined} path={""} canView={false} isPagination={true}
                            isGlobalFilter={true}
                            selectedPage={pagination}
                            searchHandler={searchHandler}
                            isDeleteAction={true}
                            isAction={false} isShowingPageLength={true} onColumnSortChange={onColumnSortChange} />
                    </CardBody></Card>
                {/* <CustomViewCard
                    data={university}
                    customPageSize={10}
                    editHandler={editHandler} canShowButton={false} deleteHandler={deleteHandler}
                    createHandler={undefined} path={""} canView={false} perPageData={10} isPagination={true}
                    isGlobalFilter={true} canEdit={false}
                    selectedPage={pagination}
                    searchHandler={searchHandler} isAction={true} isShowingPageLength={true} /> */}
            </div>
            <div className="container">
                <Modal isOpen={universityModel} size="md" centered>
                    <div role='document'>
                        <div className='modal-content'>
                            <div className='modal-body m-3'>
                                <UniversityMaster
                                    isModelOpen={true}
                                    canCancel={isCancel}
                                    isEditId={isEditId}
                                    isEdit={isEdit}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="container">
                <Modal isOpen={showDeleteConfirmation} toggle={cancelDelete}>
                    <ModalHeader toggle={cancelDelete}>Confirm Delete</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={cancelDelete}>No</Button>
                        <Button color="danger" onClick={confirmDelete}>Yes</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
            <div className="container">
                <Modal isOpen={showDeleteSuccess} toggle={() => setShowDeleteSuccess(false)}>
                    <ModalHeader toggle={() => setShowDeleteSuccess(false)}>Success</ModalHeader>
                    <ModalBody>
                        Deleted successfully.
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default UniversityDashBoard;