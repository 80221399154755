import React, { useEffect, useState } from "react";
import { Row, Form } from "reactstrap";
import { useFormik } from "formik";
import CommonService from "Services/CommonService";
import { forkJoin } from "rxjs";
import { CustomMultiCheckbox } from "Components/Shared/CustomMultiCheckBox";
import { IMenuSelectionModel } from "Models/IMenuSelectionModel";
import { CustomButton } from "Components/Shared/CustomButton";

type Props = {
    canCancel: any,
    isModelOpen: any,
    isEditId: any,
    dayId: any,
    isEdit: boolean
}

const MenuSelection = ({ isEditId, isModelOpen, canCancel, dayId, isEdit }: Props) => {
    const [disabled, setDisabled] = useState(false);
    const [selectedMenuIds, setSelectedMenuIds] = useState([]);
    const [menuMaster, setMenuMaster] = useState<any>([]);
    const [isCount, setIsCount] = useState<any>([]);
    const [quantity, setQuantity] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [restrictedCount, setRestrictedCount] = useState(0);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            menuId: "",
            count: ""
        },

        onSubmit: (values: any) => {
            let isQuantityEmpty = quantity.some(value => value < 1);
            // setIsChecked(selectedMenuIds.length > 0 ? true : false)
            // setDisabled( selectedMenuIds.length == quantity.length && !isQuantityEmpty ? false : true)
            if (selectedMenuIds.length == quantity.length && !isQuantityEmpty) {
                submitMenuSelection(values);
            } else {
                setIsChecked(true);
                return;
            }
        }
    });

    const submitMenuSelection = (values: IMenuSelectionModel) => {
        setDisabled(true);
        let restaurentId = localStorage.getItem("restaurentId");
        values.weekDay = dayId;
        values.restaurentId = restaurentId;
        values.menuId = selectedMenuIds;
        values.isLastAdded = true;
        // const removeNullableCount = isCount.map(count => isNaN(count) ? 0 : count);
        values.count = validation.values.count;
        CommonService.post("WeeklyMenu", "CreateWeeklyMenu", values).then(res => {
            if (res.status == 200) {
                canCancel();
            }
        })
    }

    const isCancel = () => {
        canCancel();
    }

    const handleMultiCheckboxChange = (values) => {
        setSelectedMenuIds(values);
        validation.setFieldValue("menuId", values);
    };

    useEffect(() => {
        let restaurentId = localStorage.getItem("restaurentId");
        let menu = CommonService.getWithQueryParam("Menu", "GetMenuByRestaurentId", "restaurentId", restaurentId);
        forkJoin({ menu }).subscribe((res: { menu: any }) => {
            setMenuMaster(res.menu.data.responseData);
        })
    }, []);

    const getDayMenuById = () => {
        let restaurentId = localStorage.getItem("restaurentId");
        CommonService.postWithDoubleQueryParam("Menu", "GetDayMenu", "dayId", dayId, "restaurentId", restaurentId).then((res) => {
            if (res.status == 200) {
                let array = res.data.responseData;
                setRestrictedCount(array[0].restrictedCount);
                if (array[0].id > 0) {
                    let menuIdsArray = array.map(item => item.id);
                    setSelectedMenuIds(menuIdsArray);
                    let countArray = array.map(item => item.count);
                    setIsCount(countArray);
                    setQuantity(countArray)
                    validation.setFieldValue('count', countArray);
                }
            }
        })
    }

    const handleQuantityChange = (quantity: any) => {
        const updatedQuantity = quantity.map((item, index) => {
            if (item === '') {
                return 0;
            } else {
                return item;
            }
        });
        setIsCount(quantity);
        setQuantity(updatedQuantity);
        setIsChecked(false);
        validation.setFieldValue("count", updatedQuantity);
    };

    useEffect(() => {
        if (isEdit) {
            getDayMenuById();
        }
    }, [isEdit]);

    // useEffect(() => {
    //     let isQuantityEmpty = quantity.some(value => value < 1);
    //     setIsChecked( selectedMenuIds.length == quantity.length && !isQuantityEmpty ? false : true)
    // }, [quantity])

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <p className="header-text-grid fw-bold fa-lg">Menu Selection {menuMaster?.length > restrictedCount && <span style={{ fontSize: "13px" }} className="primary-color">(Maximum Selection {restrictedCount})</span>}</p>
                <div onClick={() => isCancel()} className="btn-close position-absolute end-0 top-0 m-3" ></div>
                <Form onSubmit={validation.handleSubmit}>
                    <Row>
                        <CustomMultiCheckbox
                            data={menuMaster}
                            invalid={false}
                            label=""
                            id="menuId"
                            isHorizontal={false}
                            selectedValues={selectedMenuIds}
                            onChange={handleMultiCheckboxChange}
                            value={validation.values.menuId}
                            touched={validation.values.menuId}
                            error={validation.errors.menuId}
                            minValues={0}
                            restrictedLength={restrictedCount}
                            valueField={"id"}
                            displayField={"displayName"} disabled={false}
                            isWeeklyCount={true} isCount={isCount} setIsCount={setIsCount} inputValue={isCount} handleChange={handleQuantityChange}
                        />
                    </Row>
                    {(isChecked) && <p style={{ fontSize: "13px" }} className="text-danger">The count of a menu must be greater than zero!!!</p>}
                    <div className="d-grid">
                        <CustomButton className="loginbtn" type="submit" disabled={disabled} btnClassName={"loginbtn"} iconClasssName={undefined} name={"Submit"} />
                    </div>
                </Form>
            </div>
            {/* <CustomToaster message={(!isCreateMode && isEdit) ? Toaster_ENUM.edit.text : Toaster_ENUM.create.text} color={"text-success"}
                toast={(!isCreateMode && isEdit) ? toastUpdate : toastCreate}
                setToast={(!isCreateMode && isEdit) ? setToastUpdate : setToastCreate} path={""} /> */}
        </React.Fragment >
    )
};

export default MenuSelection;
