import React, { InputHTMLAttributes, useState } from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    perPageData: any,
    data: Array<any>,
    CurrentPageHandler?: any,
    isShowingPageLength: boolean,
    paginationDiv: any,
    paginationClass: any,
    currentPage?: any,
    rowPerPageValue?: any,
    rowPerPageHandler?: any
}

export const CustomPagination = React.forwardRef(({ perPageData, data, CurrentPageHandler, rowPerPageValue, rowPerPageHandler, isShowingPageLength, paginationDiv, paginationClass, ...rest }: inputProps, ref) => {

    const [currentPage, setCurrentPage] = useState(1);
    const pageNumbersToShow = 5;

    const handleprevPage = () => {
        if (currentPage > 1) {
            let prevPage = currentPage - 1;
            setCurrentPage(prevPage);
            CurrentPageHandler(prevPage);
        }
    };

    const handlemostFirstPage = () => {
        setCurrentPage(1);
        CurrentPageHandler(1);
    };

    const pageNumbers: Array<any> = [];
    let totalRecord = data[0]?.totalRowsCount ?? data?.length;
    for (let i = 1; i <= Math.ceil(totalRecord / perPageData); i++) {
        pageNumbers.push(i);
    };

    const handleClick = (e: any) => {
        const selectedPage = Number(e.target.id);
        setCurrentPage(selectedPage);
        CurrentPageHandler(selectedPage);
    };

    const handlenextPage = () => {
        if (currentPage < pageNumbers[pageNumbers.length - 1]) {
            let nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            CurrentPageHandler(nextPage);
        }
    };

    const handleMostLastPage = () => {
        const lastPage = pageNumbers[pageNumbers.length - 1];
        setCurrentPage(lastPage);
        CurrentPageHandler(lastPage);
    };

    const calculatePageNumbersToShow = () => {
        const totalPageCount = pageNumbers.length;
        if (totalPageCount <= pageNumbersToShow) {
            return pageNumbers;
        } else {
            const halfPageNumbersToShow = Math.floor(pageNumbersToShow / 2);
            if (currentPage <= halfPageNumbersToShow) {
                return pageNumbers.slice(0, pageNumbersToShow);
            } else if (currentPage >= totalPageCount - halfPageNumbersToShow) {
                return pageNumbers.slice(totalPageCount - pageNumbersToShow, totalPageCount);
            } else {
                return pageNumbers.slice(currentPage - halfPageNumbersToShow - 1, currentPage + halfPageNumbersToShow);
            }
        }
    };

    const visiblePageNumbers = calculatePageNumbersToShow();

    return (
        <React.Fragment>
            <Row className="justify-content-between align-items-center" ref={ref} {...rest as any}>
                <div className={paginationDiv}>
                    {isShowingPageLength && <div className="start-0 col-sm d-flex">
                        <div className="text-muted">Showing <span className="fw-semibold">{data?.length}</span> of <span className="fw-semibold">{data[0]?.totalRowsCount}</span> entries</div>
                        {rowPerPageValue > 0 &&
                            <div className="text-muted" style={{ marginLeft: "10px" }}>
                                <select name="" id="" value={rowPerPageValue} onChange={(e) => { rowPerPageHandler(e.target.value) }} style={{ marginLeft: "5px" }}>
                                    <option value="10">10</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="70">70</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        }
                    </div>}
                    <ul className={paginationClass}>
                        <li className="page-item" title="Go to FirstPage">
                            <Link className={`page-link ${currentPage > 1 ? "" : "disabled"}`} to="#" onClick={() => handlemostFirstPage()}>
                                <i className="fas fa-angle-double-left"></i>
                            </Link>
                        </li>
                        <li className={`page-item ${currentPage <= 1 ? "disabled" : ''}`}>
                            <Link className={`${currentPage <= 1 ? "page-link disabled" : "page-link"} `} to="#" onClick={() => handleprevPage()}>
                                <i className="mdi mdi-chevron-left"></i>
                            </Link>
                        </li>
                        {visiblePageNumbers.map((item: number, index: number) => (
                            <li className={currentPage === item ? "page-item active" : "page-item"} key={index}>
                                <Link className="page-link" to="#" id={item.toString()} onClick={handleClick}>
                                    {item}
                                </Link>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === pageNumbers[pageNumbers.length - 1] ? "disabled" : ""}`}>
                            <Link className={`page-link ${currentPage === pageNumbers[pageNumbers.length - 1] ? "disabled" : ""}`} to="#" onClick={handlenextPage}>
                                <i className="mdi mdi-chevron-right"></i>
                            </Link>
                        </li>
                        <li className="page-item" title="Go to LastPage">
                            <Link className={`page-link ${currentPage < pageNumbers[pageNumbers.length - 1] ? "" : "disabled"}`} to="#" onClick={() => handleMostLastPage()}>
                                <i className="fas fa-angle-double-right"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </Row>
        </React.Fragment>
    )
})