import httpCommon from "./http-common";

type ControllerName = "Auth" | "User" | "Masters" | "Restaurent" | "UserRestaurent" | "Menu" | "MenuSelection" | "PickupAddress" | "UserOrder";
type ModelName = "authenticate" | "CreateUser" | "ForgetPassword" | "GetCountryMaster" | "GetStateMaster" | "GetAllRestaurent" |
    "CreateRestaurent" | "UpdateRestaurent" | "GetCityMaster" | "GetZipCodeMaster" | "CreateUserRestaurent" | "GetCourseMaster" |
    "CreateMenu" | "GetAllMenu" | "CreateMenuSelection" | "GetRestaurentBySearch" | "GetMenuBySearch" | "GetBase64RestaurentFile" | "GetAllUserRestaurent"
    | "GetUserRestaurentByLoginandRestaurentSearch" | "GetUserRoleMaster" | "GetPickupAddressView" | "DecryptPassword" | "UpdateMenu" | "GetUserRestaurentByLoginIdAsync" |
    "GetMenuSelectionByRestaurentIdSearch" | "UpdateMenuSelection" | "GetUserRestaurentByLoginandRestaurentSearch" | "GetPickupAddressByRestaurentId"
    | "GetPickupAddressView" | "GetAllRestaurentView" | "GetMenuCourseItemView" | "GetMenuView" | "CreateUserOrder" | "GetAllRestaurentView" | "GetMenuCourseItemView" | "GetMenuView"
    | "GetUserOrderByUser" | "UpdateUserOrderBoxNumber" | "UpdateUserOrderStatusId" | "GetBase64UserFile" | "GetStateByCountryId" | "GetCityByStateId"
    | "GetDashboardStats" | "GetUniversityMaster" | "GetBase64MenuImage" | "GetKitchenOrder" | "UpdateMenuCourseItemById" | "GetPackedOrderView"
    | "GetUserLoginbyUserAddressDetailsView" | "ResetPasswordByGuId";

type Param = "id" | "restaurentId" | "loginId" | "date" | "EntityName" | "orderId" | "countryId" | "stateId" | "cityId" | "userRoleId" | "dayId" | "universityId" | "guId" | "menuId" | "userId" | "startDate";
type Param1 = "pickupAddressId" | "userRoleId" | "statusId" | "restaurentId" | "dayId" | "password" | "offerCode" | "startDate" | "endDate" | "userid";
type Param2 = "menuId" | "pickupAddressId" | "localDateTime" | "deliveredTime" | "getDate"| "endDate";
type Param3 = "userLocalTime";

class CommonService {
    get(ControllerName: string, ModelName: string) {
        return httpCommon.get(`/${ControllerName}/${ModelName}`).catch((err: Error) => {
            throw err?.message
        });
    }

    getWithQueryParam(ControllerName: string, ModelName: string, Param: any, value: any) {
        return httpCommon.get(`/${ControllerName}/${ModelName}?${Param}=${value}`).catch((err: Error) => {
            throw err?.message
        });
    }


    getWithSingleParam(ControllerName: string, ModelName: string, value: any) {
        return httpCommon.get(`/${ControllerName}/${ModelName}/${value}`).catch((err: Error) => {
            throw err?.message
        });
    }

    getWithDoubleQueryParam(ControllerName: string, ModelName: string, Param: Param, value: any, Param1: Param1, value1: any) {
        return httpCommon.get(`/${ControllerName}/${ModelName}?${Param}=${value}&${Param1}=${value1}`).catch((err: Error) => {
            throw err?.message
        });
    }

    getWithTripleParam(ControllerName: string, ModelName: string, Param: Param, value: any, Param1: Param1, value1: any, Param2: Param2, value2: any) {
        return httpCommon.get(`/${ControllerName}/${ModelName}?${Param}=${value}&${Param1}=${value1}&${Param2}=${value2}`).catch((err: Error) => {
            throw err?.message
        });
    }

    post(ControllerName: string, ModelName: string, data: any) {
        return httpCommon.post(`/${ControllerName}/${ModelName}`, data).catch((err: Error) => {
            throw err?.message
        });
    }

    postWithQueryParam(ControllerName: string, ModelName: string, Param: string, value: any) {
        return httpCommon.post(`/${ControllerName}/${ModelName}?${Param}=${value}`).catch((err: Error) => {
            throw err?.message
        });
    }

    postWithSingleParam(ControllerName: string, ModelName: string, Param: Param, value1: any, data) {
        return httpCommon.post(`/${ControllerName}/${ModelName}?${Param}=${value1}`, data).catch((err: Error) => {
            throw err?.message
        });
    }

    postWithDoubleQueryParam(ControllerName: string, ModelName: string, Param: Param, value: any, Param1: Param1, value1: any) {
        return httpCommon.post(`/${ControllerName}/${ModelName}?${Param}=${value}&${Param1}=${value1}`).catch((err: Error) => {
            throw err?.message
        });
    }

    postWithTribalParam(ControllerName: string, ModelName: string, Param: Param, Param1: Param1, Param2: Param2, value: any, value1: any, value2: any) {
        return httpCommon.post(`/${ControllerName}/${ModelName}?${Param}=${value}&${Param1}=${value1}&${Param2}=${value2}`).catch((err: Error) => {
            throw err?.message
        });
    }


    postWithQudraParam(ControllerName: string, ModelName: string, Param: Param, Param1: Param1, Param2: Param2, Param3: Param3, value: any, value1: any, value2: any, value3: any) {
        return httpCommon.post(`/${ControllerName}/${ModelName}?${Param}=${value}&${Param1}=${value1}&${Param2}=${value2}&${Param3}=${value3}`).catch((err: Error) => {
            throw err?.message
        });
    }

    postWithFormData(ControllerName: string, ModelName: string, data: any) {
        return httpCommon.post(`/${ControllerName}/${ModelName}`, data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }).catch((err: Error) => {
            throw err?.message
        });
    }

    postWithDoubleParam(ControllerName: ControllerName, ModelName: ModelName, value1: any, value2: any) {
        return httpCommon.post(`/${ControllerName}/${ModelName}/${value1}/${value2}`).catch((err: Error) => {
            throw err?.message
        });
    }

    deleteWithQueryParam(ControllerName: string, ModelName: string, Param: Param, value: any) {
        return httpCommon.delete(`/${ControllerName}/${ModelName}?${Param}=${value}`).catch((err: Error) => {
            throw err?.message
        });
    }

    GetBase64ProfileImage(ControllerName: ControllerName, ModelName: ModelName, Param: Param, value: any) {
        return httpCommon.get(`/${ControllerName}/${ModelName}?${Param}=${value}`).catch((err: Error) => {
            throw err?.message;
        })
    }

    putWithQueryParam(ControllerName: string, ModelName: string, Param: any, value: any) {
        return httpCommon.put(`/${ControllerName}/${ModelName}?${Param}=${value}`).catch((err: Error) => {
            throw err?.message
        });
    }

    putWithDoubleQueryParam(ControllerName: string, ModelName: string, Param: any, value: any,Param1 : any,value1:any) {
        return httpCommon.put(`/${ControllerName}/${ModelName}?${Param}=${value}&${Param1}=${value1}`).catch((err: Error) => {
            throw err?.message
        });
    }
    putWithTribleQueryParam(ControllerName: string, ModelName: string, Param: any, value: any,Param1 : any,value1:any,Param2:any,value2:any) {
        return httpCommon.put(`/${ControllerName}/${ModelName}?${Param}=${value}&${Param1}=${value1}&${Param2}=${value2}`).catch((err: Error) => {
            throw err?.message
        });
    }

}

export default new CommonService();