//Author : Kavitha Sri

import React, { InputHTMLAttributes } from "react";
import { Col, Input, Label } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} name reference of the elements*/
    name?: any;
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} id defines the id of the DOM element */
    id: any,
    /** @param {any} className return the value of an element's class*/
    className?: any,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder?: any;
    /** @param {any} value is used to set or get the value for the input field*/
    value: any;
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange?: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur?: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid?: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} touched it takes boolean values as input and it sets to true if a field is clicked*/
    touched?: any,
    /** @param {any} checked it depends upon the box is selected or not*/
    checked?: boolean,
    /** @param {any} defaultChecked it checks as default*/
    defaultChecked?: boolean
}

export const CustomCheckBox = React.forwardRef(({ name, value, onChange, onBlur, invalid, error, label, id, touched, defaultChecked, checked }: inputProps, ref) => {

    return (
        <>
            <Col style={{ display: "flex" }}>
                <Input
                    style={{ border: "1px solid #000" }}
                    type="checkbox"
                    className="form-check registration-custom-checkbox"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    invalid={invalid}
                    error={error}
                    checked={checked}
                    defaultChecked={defaultChecked}
                />
                <Label className="form-check-Label" htmlFor="formrow-customCheck" style={{ marginLeft: "10px", marginTop: "4px" }}>{label}</Label>
            </Col>
            {
                error || touched ? (
                    <span className="text-danger">{error}</span>
                ) : null
            }
        </>
    )
})