import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import CustomDataTable from "Components/Shared/CustomDataTable";
import Breadcrumb from "Components/Common/Breadcrumb";
import { EncodeParam, getTokenDetail } from "../../helpers/helper";
import { Role } from "Components/constants/PandaConstants";
import { IOrderSearchModel } from "Models/IOrderSearchModel";
import { ITokenModel } from "Models/ITokenModel";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { CustomButton } from "Components/Shared/CustomButton";
import { Loader } from "Components/Loader/Loader";

const CouponCodeDashoard = () => {
    const [promoCode, setPromoCode] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [userModel, setUserModel] = useState(false);
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [roleId, setRoleId] = useState(0);
    const [tokenData, setTokenData] = useState<ITokenModel>();
    const [rowPerPageValue, setRowPerPageValue] = useState(10);
    const [loader, setLoader] = useState(true);
    const [isCommonToggle, setIsCommonToggle] = useState<boolean>(true);

    let navigate = useNavigate();

    document.title = "Coupon Code | Panda GoGoGo ";

    const toggleModal = (isText) => {
        setModalIsOpen(!modalIsOpen);
        removeBodyCss();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: ' Coupon Name',
            accessor: (data) => {
                // Use a span with inline style to apply red color to the appliedCount part
                return (
                    <span>
                        {data.name} <span style={{ color: 'green', fontWeight: "bold" }}>({data.appliedCount})</span>
                    </span>
                );
            },
            width: "15%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: ' Restaurant Name',
            accessor: 'restaurentName',
            width: "18%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Type',
            accessor: 'type',
            width: "13%",
            displayInList: true,
            Filter: true,
            datatype: 'coupontype'
        },
        {
            Header: 'Valid From',
            accessor: 'fromDate',
            width: "18%",
            displayInList: true,
            Filter: true,
            datatype: 'datetime'
        },
        {
            Header: ' Valid To',
            accessor: 'toDate',
            width: "18%",
            displayInList: true,
            Filter: true,
            datatype: 'datetime'
        },
        {
            Header: 'Discount',
            accessor: 'discount',
            width: "10%",
            displayInList: true,
            Filter: true,
            datatype: 'couponpercentage'
        },
        {
            Header: 'Count',
            accessor: (data) => {
                // Use a span with inline style to apply red color to the appliedCount part
                return (
                    <span>
                        {data.emailCount} <span style={{ color: '#E75605', fontWeight: "bold" }}>({data.sentEmailCount})</span>
                    </span>
                );
            },
            width: "15%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "0%"
        },
    ], []);

    const AdminColumns: IColumnModel[] = useMemo(() => [
        {
            Header: ' Coupon Name',
            accessor: (data) => {
                return (
                    <span>
                        {data.name} <span style={{ color: 'green', fontWeight: "bold" }}>({data.appliedCount})</span>
                    </span>
                );
            },
            width: "20%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Type',
            accessor: 'type',
            width: "13%",
            displayInList: true,
            Filter: true,
            datatype: 'coupontype'
        },
        {
            Header: 'Valid From',
            accessor: 'fromDate',
            width: "25%",
            displayInList: true,
            Filter: true,
            datatype: 'datetime'
        },
        {
            Header: ' Valid To',
            accessor: 'toDate',
            width: "20%",
            displayInList: true,
            Filter: true,
            datatype: 'datetime'
        },
        {
            Header: 'Discount (%)',
            accessor: 'discount',
            width: "13%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Count',
            accessor: (data) => {
                return (
                    <span>
                        {data.emailCount} <span style={{ color: '#E75605', fontWeight: "bold" }}>({data.sentEmailCount})</span>
                    </span>
                );
            },
            width: "25%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "0%"
        },
    ], []);

    const getCouponCodeBySearch = (pageNumber: any, searchText: string, commonToggle: any, sortorder?: any, rowPerPageVal?: any) => {
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        let restaurantId = localStorage.getItem("restaurentId");
        let searchModel = {
            offsetStart: pageNumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "Desc",
            searchText: searchText ? searchText : "",
            restaurentId: roleIdValue == Role.Admin ? restaurantId : null,
            isActive: commonToggle,
        }
        CommonService.post("CouponCode", "GetCouponCodeBySearch", searchModel).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                setPromoCode(data);
                setLoader(false);
            }
        })
    }

    const editHandler = (val: any) => {
        let encodedId = EncodeParam(val.id);
        navigate(`/coupon-code/edit/${encodedId}`)
    }

    const createHandler = () => {
        navigate('/coupon-code/create');
    }

    const pagination = (pageNumber: any) => {
        let getPageNumber = ((pageNumber) * 10);
        getCouponCodeBySearch(pageNumber, search,isCommonToggle);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getCouponCodeBySearch(1, searchKeyword,isCommonToggle);
    }


    const validateUserRole = () => {
        let token: any = getTokenDetail();
        setTokenData(token);
        let roleIdValue = parseInt(token.RoleId, 10);
        setRoleId(roleIdValue);
        if (roleIdValue == Role.Admin) {
            setIsRestaurant(true);
        }
        getCouponCodeBySearch(1, "",isCommonToggle)
    }

    const onCommonToggleChange = () => {
        setIsCommonToggle(!isCommonToggle);
        getCouponCodeBySearch(1, "",!isCommonToggle);
    }

    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        getCouponCodeBySearch(1, "", isCommonToggle, "Desc",val);
    }

    useEffect(() => {
        validateUserRole();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Coupon Code" breadcrumbItem="Coupon Code" breadcrumbTitle={"Dashboard"}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/coupon-code"} />
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-end">
                            <Row className="align-items-center">
                                <Col className="text-end" style={{ marginTop: "-20px", marginBottom: "5px" }}>
                                    <div className="form-switch mt-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="toggle"
                                            checked={isCommonToggle}
                                            onChange={() => onCommonToggleChange()}
                                        />
                                        <label className="form-check-label">{isCommonToggle ? "Active Coupons" : "Inactive Coupons"}</label>
                                    </div>
                                </Col>
                                <Col style={{ marginTop: "-20px", marginBottom: "5px" }}>
                                    <CustomButton type="button" btnClassName="btn btn-success w-md" iconClasssName="fa fa-plus" name="Create" onClick={() => createHandler()} />
                                </Col>
                            </Row>
                            {/* <Col className="text-end" style={{ marginTop: "-20px", marginBottom: "5px" }}>
                                <div className="form-switch mt-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="toggle"
                                        checked={isCommonToggle}
                                        onChange={() => onCommonToggleChange()}
                                    />
                                    <label className="form-check-label">{isCommonToggle ? "Active Coupon Codes" : "InActive Coupon Codes"}</label>
                                </div>
                                <CustomButton type="button" btnClassName="btn btn-success w-md" iconClasssName="fa fa-plus" name="Create" onClick={() => createHandler()} />
                            </Col> */}
                        </div>
                        <Loader open={loader} />

                        <CustomDataTable
                            rowPerPageValue={rowPerPageValue}
                            rowPerPageHandler={rowPerPageHandler}
                            columns={isRestaurant ? AdminColumns : columns}
                            data={promoCode}
                            canEdit={true}
                            perPageData={rowPerPageValue}
                            customPageSize={rowPerPageValue}
                            editHandler={editHandler} canShowButton={false} deleteHandler={undefined}
                            createHandler={undefined} path={""} canView={false} isPagination={true}
                            isGlobalFilter={true}
                            selectedPage={pagination}
                            searchHandler={searchHandler}
                            isDeleteAction={false}
                            isAction={false} isShowingPageLength={true} onColumnSortChange={undefined} />
                        <Row>
                            <Col lg={12} style={{ display: "flex" }}>
                                <div style={{ marginRight: "10px" }}>
                                    <div style={{ width: "10px", height: "10px", background: "#E75605", borderRadius: "50%", marginRight: "5px", display: "inline-block", marginBottom: "0px" }}></div> <span style={{ fontSize: "12px", fontWeight: "600", color: "#E75605" }}>-  Coupon Sent Count</span>
                                </div>
                                <div>
                                    <div style={{ width: "10px", height: "10px", background: "green", borderRadius: "50%", marginRight: "5px", display: "inline-block", marginBottom: "0px" }}></div> <span style={{ fontSize: "12px", fontWeight: "600", color: "green" }}>-  Coupon Used Count</span>
                                </div>
                            </Col>
                        </Row>
                    </CardBody></Card>
            </div>
        </React.Fragment>
    );
}

export default CouponCodeDashoard;