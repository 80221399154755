import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataTable from "Components/Shared/CustomDataTable";
import Breadcrumb from "Components/Common/Breadcrumb";
import { IKitchenOrderModel } from "Models/IKitchenOrderModel";
import { forkJoin } from "rxjs";
import * as FileSaver from 'file-saver';

import { Card, CardBody, Col, Row } from "reactstrap";
import { getTokenDetail } from "../../helpers/helper";
import { Role } from "Components/constants/PandaConstants";
import { saveOrdersCountChange,orderDeliveredChange } from "slices/auth/login/thunk";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "Components/Loader/Loader";
import { createSelector } from "reselect";

const KitchenOrderDashBoard = () => {
    const [search, setSearch] = useState("");
    const [kitchenOrder, setKitchenOrder] = useState<any>([]);
    const [statusDropdownid, setStatusDropdownid] = useState<any>();
    const [statusEntityMaster, setStatusEntityMaster] = useState<any>([]);
    const [isAllowToCook, setIsAllowToCook] = useState<boolean>(false);
    const [totalRowsCount, setTotalRowsCount] = useState<any>();
    const [isPageNumber, setIsPageNumber] = useState<any>();
    const [selectedColumn, setSelectedColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const dispatch: any = useDispatch();
    const [loader, setLoader] = useState(true);
    const [roleId, setRoleId] = useState(0);
    const [restaurentId, setRestaurentId] = useState<any>();
    const [rowPerPageValue, setRowPerPageValue] = useState(10);

    let navigate = useNavigate();
    document.title = "Kitchen Order | Panda GoGoGo ";

    const selectProperties = createSelector(
        (state: any) => state.Login,
        (error) => error
    );
    const { isOrderDeliverChange } = useSelector(selectProperties);
    
    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Dish Name',
            accessor: 'name',
            width: "30%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Count',
            accessor: 'count',
            width: "20%",
            displayInList: true,
            Filter: true,
        }, {
            Header: 'Id',
            accessor: 'orderMenuItemIds',
            Filter: true,
            displayInList: false,
            width: "0%"
        },
        {
            Header: 'Status',
            accessor: 'statusName',
            width: "30%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "0%"
        },
    ], []);

    const getKicthenOrderBySearch = (pageNumber: any, searchText: string, sortcolumn?: any, sortorder?: any, rowPerPageVal?: any) => {
        setLoader(true);
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        let restaurantId = localStorage.getItem("restaurentId");
        setRoleId(roleIdValue);
        setRestaurentId(restaurantId);
        let searchModel: IKitchenOrderModel = {
            offsetStart: pageNumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "",
            searchText: searchText ? searchText : "",
            sortOrderColumn: sortcolumn ? sortcolumn : "",
            restaurentId: roleIdValue == Role.Admin ? restaurantId : null,
        }
        CommonService.post("UserOrder", "GetKitchenOrder", searchModel).then(res => {
            if (res.status == 200) {
                let data: Array<IKitchenOrderModel> = res.data;
                setLoader(false);
                setIsPageNumber(pageNumber);
                setTotalRowsCount(res.data[0]?.totalRowsCount);
                setKitchenOrder(data);
                setStatusDropdownid(data[0]?.statusId);
                allowCookHandeler(data[0]?.cutofftime);
            }
        });
    }

    const allowCookHandeler = (cutofftime: any) => {
        // let cuttTime = moment(cutofftime).format("HH:MM");
    }

    const statusHandler = (statusid: any, val: any) => {
        setStatusDropdownid(statusid);
        // Replace this with your actual array of numbers
        const menuIdsArray = [val.orderMenuItemIds];
        let arrOfIds: Array<number> = [];
        menuIdsArray.toString().split(",").map((val, i) => (
            arrOfIds.push(Number(val))
        ));
        UpdateStatus(arrOfIds);
    }

    const UpdateStatus = (menuids: any) => {
        let restaurentId = localStorage.getItem("restaurentId");
        CommonService.postWithSingleParam("UserOrder", "UpdateOrderMenuItemById", "restaurentId", restaurentId, menuids).then(res => {
            if (res.status === 200) {
                // window.location.reload();
                getKicthenOrderBySearch(1, "");
                dispatch(saveOrdersCountChange({ isOrderCountChange: true }));
            }
        });
    }

    const pagination = (pageNumber: any) => {
        setIsPageNumber(pageNumber);
        getKicthenOrderBySearch(pageNumber, search, selectedColumn, sortOrder);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getKicthenOrderBySearch(1, searchKeyword);
    }

    const onColumnSortChange = (columnName, sortorder) => {
        setSelectedColumn(columnName);
        setSortOrder(sortorder);
        getKicthenOrderBySearch(1, search, columnName, sortOrder);
    };

    useEffect(() => {
        getKicthenOrderBySearch(1, "");
        let status = CommonService.getWithQueryParam("Status", "GetStatusByEntityName", "EntityName", "Order");
        forkJoin({ status }).subscribe((res: { status: any }) => {
            const filteredStatus = res.status.data.responseData?.filter(status => status.externalStatus == "Order Prepared" || status.externalStatus == "Preparing Order");
            setStatusEntityMaster(filteredStatus)
        })
    }, []);

    const DownloadCSV = () => {
        let data: IKitchenOrderModel = {
            offsetStart: 1,
            rowsPerPage: totalRowsCount,
            sortOrder: sortOrder ? sortOrder : "",
            searchText: search ? search : "",
            sortOrderColumn: selectedColumn ? selectedColumn : "",
            restaurentId: roleId == Role.Admin ? restaurentId : null,
        }
        CommonService.post("UserOrder", "ExportKitchenOrderCsv", data).then(
            (res) => {
                if (res.status === 200) {
                    const csvContent = atob(res.data);
                    const blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
                    const file = new File([blob], `PandaGoGoGo_KitchenOrder_Report.csv`, { type: "data:application/octet-stream;base64" });
                    FileSaver.saveAs(file);
                }
            },
            (error) => {
                console.error('Error occurred:', error);
            }
        );
    }


    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        getKicthenOrderBySearch(1, "", null, null, val);
    }

    useEffect(() => {
        if (isOrderDeliverChange) {
            getKicthenOrderBySearch(1, "");
        }
    }, [isOrderDeliverChange])

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Kitchen Order" breadcrumbItem="Dashboard" breadcrumbTitle={""}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard"} />
                <Card >
                    <CardBody>
                        {/* <Row>
                            <Col lg={9}></Col>
                            {kitchenOrder.length > 0 && <Col lg={3} className="text-end excel-col">
                                <div onClick={() => DownloadCSV()} style={{ cursor: "pointer" }}>
                                    <i className="fa fa-file-excel excel-icon"></i>
                                </div>
                            </Col>}
                        </Row> */}
                        <Loader open={loader} />
                        {/* {!loader && */}
                        <CustomDataTable rowPerPageValue={rowPerPageValue}
                            rowPerPageHandler={rowPerPageHandler} columns={columns} data={kitchenOrder} customPageSize={rowPerPageValue}
                            isAction={false} canShowButton={false} deleteHandler={undefined}
                            createHandler={undefined} path={""} canEdit={false} perPageData={rowPerPageValue} isPagination={true}
                            isGlobalFilter={true}
                            selectedPage={pagination} loader={loader}
                            onColumnSortChange={onColumnSortChange}
                            searchHandler={searchHandler}
                            //  isStatusDropdownData={statusEntityMaster} 
                            //  isStatusValueField={true}
                            //   isStatusDropdown={true} 
                            //   statusDropdownId={statusDropdownid} 
                            //   statusHandler={statusHandler}
                            isShowingPageLength={true} />
                        {/* } */}
                    </CardBody>
                </Card>

            </div>

        </React.Fragment>
    );
}

export default KitchenOrderDashBoard;