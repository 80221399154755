import React, { ChangeEvent, useEffect, useState } from "react";
import withRouter from "Components/Common/withRouter";
import { Button, Card, CardBody, Col, Container, Form, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import * as  Yup from "yup";
import { useFormik } from "formik";
import { CustomTextBox } from "Components/Shared/CustomTextBox";
import { forkJoin } from "rxjs";
import CommonService from "Services/CommonService";
import { useNavigate, useParams } from "react-router-dom";
import { CustomImageUpload } from "Components/Shared/CustomImageUpload";
import { IRestaurantCreationModel } from "Models/IRestaurantCreationModel";
import { DecodeParam, EncodeParam } from "../../helpers/helper";
import { CustomToaster } from "Components/Shared/CustomToaster";
import { HelperConstant, Toaster_ENUM } from "Components/constants/PandaConstants";
import { CustomButton } from "Components/Shared/CustomButton";
import { CustomTimePicker } from "Components/Shared/CustomTimePicker";
import moment from "moment";
import { CustomAutoComplete } from "Components/Shared/CustomAutoComplete";
import Breadcrumb from "Components/Common/Breadcrumb";
import { ITokenModel } from "Models/ITokenModel";
import { jwtDecode } from "jwt-decode";

const AdminRestaurantCreation = () => {
    const [stateMaster, setStateMaster] = useState<any>([]);
    const [cityMaster, setCityMaster] = useState<any>([]);
    const [zipCodeMaster, setZipCodeMaster] = useState<any>([]);
    const [universityMaster, setUniversityMaster] = useState<any>();
    const [isStoredData, setIsStoredData] = useState<any>();
    const [editUniversityMaster, setEditUniversityMaster] = useState<any>([]);
    const [roleId, setRoleId] = useState<any>();

    const [uploadedDocument, setUploadedDocument] = useState<any>();
    const [isErr, setIsErr] = useState("");
    const [isPickupErr, setIsPickupErr] = useState("");

    const [isPickUpId, setIsPickUpId] = useState<any>();
    const [isDecodedRestaurantId, setIsDecodedRestaurantId] = useState<any>();
    const [toastCreate, setToastCreate] = useState(false);
    const [toastUpdate, setToastUpdate] = useState(false);
    const [profileImage, setProfileImage] = useState<any>();
    const [updateImage, setUpdateImage] = useState<any>();
    const [disabled, setDisabled] = useState(false);
    const [pickupUploadDocument, setPickupUploadDocument] = useState<Array<any>>([]);
    const [pickupUpdateImage, setPickupUpdateImage] = useState<Array<any>>([]);
    const [isFileName, setIsFileName] = useState<Array<any>>([]);
    const [isFilePath, setIsFilePath] = useState<Array<any>>([]);
    const [isToggle, setToggle] = useState<boolean>(true);
    const [isToggleModel, setIsToggleModel] = useState(false);
    const [stateTimeZone, setStateTimeZone] = useState<any>("");
    const [isDayLightSavings, setIsDayLightSavings] = useState(true);

    let navigate = useNavigate();
    const { id } = useParams();
    const isEdit = !!id;
    document.title = "Create Restaurant | Panda GoGoGo ";

    const formik = useFormik({
        initialValues: {
            name: "",
            orderCutOff: "",
            // deliveryCutOff: "",
            pickupAddress: [
                {
                    name: '',
                    universityId: '',
                    address: '',
                    countryId: '1',
                    stateId: '',
                    cityId: '',
                    zipId: '',
                    fromTime: '',
                    toTime: '',
                },
            ],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Please Enter Restaurant Name"),
            orderCutOff: Yup.string().required("Please Select Order CutOff Time").test('is-greater', 'Catering CutOff Time must be before 2 PM', function (value) {
                const cutoffTime = moment(value, 'HH:mm');
                const cutoffLimit = moment('14:00', 'HH:mm');
                return cutoffTime.isBefore(cutoffLimit);
            }),
            pickupAddress: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required("Please Select Location Name").max(50, "Location Name must be at most 50 characters"),
                    universityId: Yup.string().required("Please Select University"),
                    address: Yup.string().required("Please Enter the Address").max(250, "Address must be at most 250 characters"),
                    countryId: Yup.string(),
                    // countryId: Yup.string().required("Please Select Country"),
                    stateId: Yup.string().required("Please Enter the State"),
                    cityId: Yup.string().required("Please Enter the City"),
                    zipId: Yup.string().required("Please Enter the Zipcode"),
                    fromTime: Yup.string().required("Please Enter Delivery From Time").test('is-greater', 'Delivery from time must be greater than Order Cutoff Time', function (value) {
                        const orderCutOff = formik.values.orderCutOff;
                        const orderCutoffTiming = moment(orderCutOff, 'HH:mm');
                        const fromTiming = moment(value, 'HH:mm');
                        return fromTiming.isAfter(orderCutoffTiming);
                    }),
                    toTime: Yup.string().required("Please Select Delivery To Time").test('is-greater', 'Delivery To time must be greater than Delivery From time', function (value) {
                        const { fromTime } = this.parent;
                        const fromTiming = moment(fromTime, 'HH:mm');
                        const toTiming = moment(value, 'HH:mm');
                        return toTiming.isAfter(fromTiming);
                    }),
                })
            ),
        }),
        onSubmit: (values: any) => {
            if (isEdit ? false : !uploadedDocument) {
                setIsErr("Please upload your restaurant Profile");
                return;
            }
            else if (isEdit ? false : pickupUploadDocument.some(item => !item)) {
                setIsPickupErr("Please upload your PickupAddress Image");
                return;
            }
            createRestaurant(values);
            setDisabled(true);
        },
    });

    const AddNewPickUpAddress = () => {
        formik.setFieldValue("pickupAddress", [
            ...formik.values.pickupAddress,
            {
                name: '',
                universityId: '',
                address: '',
                countryId: '',
                stateId: '',
                cityId: '',
                zipId: '',
                fromTime: '',
                toTime: ''
            },
        ]);
    };

    const handleRemoveAddress = (index) => {
        let data = [...formik.values.pickupAddress];
        const addressToRemove = data[index];

        if (!addressToRemove || !addressToRemove.id) {
            data.splice(index, 1);
        } else {
            data[index] = { ...addressToRemove, active: false };
        }

        formik.setFieldValue('pickupAddress', data);
    };


    const base64toFile = (base64String, fileName, mimeType) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeType });

        const file = new File([blob], fileName, { type: mimeType });
        return file;
    };

    const handleFileSelected = (fileName, fileSize, selectedFile) => {
        setIsErr("");
        setUploadedDocument(selectedFile);
        setUpdateImage(selectedFile);
    };

    // const handleFileSelectedPickup = (fileName, fileSize, selectedFile) => {
    //     setIsErr("");
    //     setPickupUploadDocument(selectedFile);
    //     setPickupUpdateImage(selectedFile);
    // };

    const handleFileSelectedPickup = (fileName, fileSize, selectedFile, index) => {
        setIsPickupErr("");
        const updatedPickupUploadDocuments = [...pickupUploadDocument];
        updatedPickupUploadDocuments[index] = selectedFile;
        setPickupUploadDocument(updatedPickupUploadDocuments);

        const updatedPickupUpdateImages = [...pickupUpdateImage];
        updatedPickupUpdateImages[index] = selectedFile;
        setPickupUpdateImage(updatedPickupUpdateImages);
    };

    const createRestaurant = async (data: IRestaurantCreationModel) => {
        let timezone;
        let isDayLightSavings;
        const response = await CommonService.getWithQueryParam("Masters", "GetStateById", "id", formik.values.pickupAddress[0].stateId);
        if (response.status === 200) {
            isDayLightSavings = response.data.responseData?.isDaylightsavings;
            // responseValue = bindCuttOffIntoTime(res.data.responseData, timezone);
            timezone = response.data.responseData?.isDaylightsavings ? response.data.responseData?.daylightsavingtimezone : response.data.responseData?.timezone
            setStateTimeZone(response.data.responseData?.isDaylightsavings ? response.data.responseData?.daylightsavingtimezone : response.data.responseData?.timezone);
        }

        const formData = new FormData();
        formData.append("active", isToggle as any);
        formData.append("id", isEdit ? isDecodedRestaurantId : 0);
        formData.append("name", data.name);
        formData.append("description", data.name);
        formData.append("displayName", data.name);
        formData.append("enumName", data.name);
        formData.append("entityName", data.name);
        formData.append("uploadedFile", isEdit ? updateImage : uploadedDocument as any);
        formData.append("uploadFilePath", null as any);
        formData.append("uploadFileName", null as any);
        formData.append("orderCutOff", moment(data.orderCutOff, 'HH:mm').utcOffset(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') as any);
        // formData.append("deliveryCutOff", moment(data.deliveryCutOff, 'HH:mm').format('hh:mm A') as any);
        formData.append("displayInList", true as any);
        formData.append("isNextDay", true as any);
        formData.append("isdaylightsavings", isDayLightSavings as any);

        formik.values.pickupAddress.forEach((addressItem, index) => {
            formData.append(`pickupAddress[${index}].active`, addressItem.active ?? true);
            formData.append(`pickupAddress[${index}].id`, isEdit ? (addressItem.id ? addressItem.id : 0) : 0);
            formData.append(`pickupAddress[${index}].restaurentId`, isEdit ? isDecodedRestaurantId : 0);
            formData.append(`pickupAddress[${index}].universityId`, addressItem.universityId);
            formData.append(`pickupAddress[${index}].name`, addressItem?.name);
            formData.append(`pickupAddress[${index}].address`, addressItem.address);
            formData.append(`pickupAddress[${index}].countryId`, "1");
            formData.append(`pickupAddress[${index}].stateId`, addressItem.stateId);
            formData.append(`pickupAddress[${index}].cityId`, addressItem.cityId);
            formData.append(`pickupAddress[${index}].zipId`, addressItem.zipId);
            formData.append(`pickupAddress[${index}].UploadedFile`, isEdit ? pickupUpdateImage[index] : pickupUploadDocument[index]);
            formData.append(`pickupAddress[${index}].uploadFilePath`, isEdit ? isFilePath[index] : null as any);
            formData.append(`pickupAddress[${index}].uploadFileName`, isEdit ? isFileName[index] : null as any);
            if (isEdit && addressItem.id > 0) {
                const deliveryCutOffParts = addressItem?.deliveryCutOff?.split('-');
                const fromTime = deliveryCutOffParts[0]?.trim();
                const toTime = deliveryCutOffParts[1]?.trim();
                formData.append(`pickupAddress[${index}].deliveryCutOff`, moment(formik.values[`fromTime${index}`], 'HH:mm').utcOffset(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') + '-' + moment(formik.values[`toTime${index}`], 'HH:mm').utcOffset(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') as any);
                formData.append(`pickupAddress[${index}].deliveryCutOffStartTime`, moment(formik.values[`fromTime${index}`], 'HH:mm').utcOffset(timezone, true).utc().format('HH:mm:ss'));
                formData.append(`pickupAddress[${index}].deliveryCutOffEndTime`, moment(formik.values[`toTime${index}`], 'HH:mm').utcOffset(timezone, true).utc().format('HH:mm:ss'));
            }
            else if (isEdit && addressItem?.id == 0) {
                formData.append(`pickupAddress[${index}].deliveryCutOff`, moment(addressItem?.fromTime, 'HH:mm').utcOffset(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') + '-' + moment(addressItem?.toTime, 'HH:mm').utcOffset(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') as any);
                formData.append(`pickupAddress[${index}].deliveryCutOffStartTime`, moment(addressItem?.fromTime, 'HH:mm').utcOffset(timezone, true).utc().format('HH:mm:ss'));
                formData.append(`pickupAddress[${index}].deliveryCutOffEndTime`, moment(addressItem?.toTime, 'HH:mm').utcOffset(timezone, true).utc().format('HH:mm:ss'));
            }
            else {
                formData.append(`pickupAddress[${index}].deliveryCutOff`, moment(addressItem?.fromTime, 'HH:mm').utcOffset(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') + '-' + moment(addressItem?.toTime, 'HH:mm').utcOffset(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') as any);
                formData.append(`pickupAddress[${index}].deliveryCutOffStartTime`, moment(addressItem?.fromTime, 'HH:mm').utcOffset(timezone, true).utc().format('HH:mm:ss'));
                formData.append(`pickupAddress[${index}].deliveryCutOffEndTime`, moment(addressItem?.toTime, 'HH:mm').utcOffset(timezone, true).utc().format('HH:mm:ss'));
            }
            formData.append(`pickupAddress[${index}].addressType`, index === 0 ? 1 : 2 as any);
        });

        CommonService.postWithFormData("Restaurent", isEdit ? "UpdateRestaurent" : "CreateRestaurent", formData).then(res => {
            if (res.status === 200) {
                if (isEdit) {
                    setToastUpdate(true)
                }
                else {
                    setToastCreate(true)
                }
            }
        });
    };

    const onCancelButtonClick = () => {
        if (isEdit) {
            navigate(`/restaurant/view/${id}`);
        }
        else {
            navigate('/restaurant');
        }
    }

    const getStateByCountryId = (countryid: any) => {
        CommonService.getWithQueryParam("Masters", "GetStateByCountryId", "countryId", countryid).then(res => {
            if (res.status === 200) {
                setStateMaster(res.data.responseData.stateList);
            }
        });
    }

    const getCityByStateId = (stateid: any) => {
        CommonService.getWithQueryParam("Masters", "GetCityByStateId", "stateId", stateid).then(res => {
            if (res.status === 200) {
                setCityMaster(res.data.responseData.cityList);
            }
        });
    }

    const getZipCodeByCityId = (cityId: any) => {
        CommonService.getWithQueryParam("Masters", "GetZipCodeByCityId", "cityId", cityId).then(res => {
            if (res.status === 200) {
                setZipCodeMaster(res.data.responseData.zipCodeList);
            }
        });
    }

    const getUniversityMasterEdit = (id: Array<any>) => {
        CommonService.post("University", "GetUniversityEdit", id).then(res => {
            if (res.status === 200) {
                setEditUniversityMaster(res.data.responseData);
            }
        });
    }

    const bindCuttOffIntoTime = (responseValue: any, timeZone: any) => {
        let arr: Array<any> = [];
        responseValue.pickupAddress.forEach((y, j) => {
            const deliveryCutOffParts = y?.deliveryCutOff?.split(HelperConstant.deliveryCutOffSplitPattern.pattern);
            const fromTime = deliveryCutOffParts[0]?.trim();
            const toTime = deliveryCutOffParts[1]?.trim();
            if (fromTime && toTime) {
                y.fromTime = moment.utc(fromTime).utcOffset(timeZone).format('HH:mm');
                y.toTime = moment.utc(toTime).utcOffset(timeZone).format('HH:mm');
            }
            arr.push(y);
        });

        responseValue.pickupAddress = arr;

        return responseValue;
    }

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            let token: string = localStorage.getItem("authUser") as string;
            let tokendata: ITokenModel = jwtDecode(token);
            let id = tokendata?.RoleId;
            setRoleId(id);
        }
        let decodedRestaurantId = DecodeParam(id);
        setIsDecodedRestaurantId(decodedRestaurantId);
        getStateByCountryId(1);

        // let country = CommonService.get("Masters", "GetCountryMaster");
        let university = CommonService.get("Masters", "GetUniversityMaster");

        forkJoin({ university }).subscribe((res: { university: any }) => {
            // setCountryMaster(res.country.data.responseData.countryList);
            setUniversityMaster(res.university.data.responseData.universityList);
        });

        if (isEdit) {
            let decodedId = DecodeParam(id);

            CommonService.getWithQueryParam("Restaurent", "GetRestaurentById", "id", decodedId).then(async res => {
                if (res.status === 200) {
                    setIsDayLightSavings(res.data.responseData.isdaylightsavings);
                    let timezone;
                    let responseValue;
                    const response = await CommonService.getWithQueryParam("Masters", "GetStateById", "id", res.data.responseData?.pickupAddress[0].stateId);
                    if (response.status === 200) {
                        timezone = res.data.responseData.isdaylightsavings ? response.data.responseData?.daylightsavingtimezone : response.data.responseData?.timezone;
                        responseValue = bindCuttOffIntoTime(res.data.responseData, timezone);
                        setStateTimeZone(timezone);
                    }
                    setIsStoredData(res.data.responseData);
                    setIsPickUpId(res.data.responseData.pickupAddress.id);
                    CommonService.GetBase64ProfileImage("Restaurent", "GetBase64RestaurentFile", "id", decodedId).then(response => {
                        if (response.status === 200) {
                            let base64ImageString = `data:image/png;base64,${response.data}`;
                            setProfileImage(base64ImageString);
                            const base64ImageStringforFile = response.data;
                            const convertedfile = base64toFile(base64ImageStringforFile, responseValue.uploadFileName, "image/png");
                            setUpdateImage(convertedfile);
                        }
                    });
                    const pickupAddressArray = res.data.responseData?.pickupAddress || [];

                    if (pickupAddressArray.length > 0) {
                        const defaultValues = {} as any;
                        pickupAddressArray.forEach((addressItem, index) => {
                            defaultValues[`universityId${index}`] = addressItem?.universityId;
                            defaultValues[`countryId${index}`] = addressItem?.countryId;
                            defaultValues[`stateId${index}`] = addressItem?.stateId;
                            defaultValues[`cityId${index}`] = addressItem?.cityId;
                            defaultValues[`zipId${index}`] = addressItem?.zipId;
                            defaultValues[`uploadFilePath${index}`] = addressItem?.uploadFilePath;

                            getStateByCountryId("1");
                            getCityByStateId(addressItem?.stateId);
                            getZipCodeByCityId(addressItem?.cityId);

                            const deliveryCutOffParts = addressItem?.deliveryCutOff?.split(HelperConstant.deliveryCutOffSplitPattern.pattern);
                            const fromTime = deliveryCutOffParts[0]?.trim();
                            const toTime = deliveryCutOffParts[1]?.trim();

                            defaultValues[`fromTime${index}`] = fromTime ? moment.utc(fromTime).utcOffset(timezone).format('HH:mm') : "";
                            defaultValues[`toTime${index}`] = toTime ? moment.utc(toTime).utcOffset(timezone).format('HH:mm') : "";

                            const universityIds = pickupAddressArray.map(addressItem => addressItem?.universityId);
                            getUniversityMasterEdit(universityIds);

                            setIsFileName(prevState => ({ ...prevState, [index]: addressItem?.uploadFileName }));
                            setIsFilePath(prevState => ({ ...prevState, [index]: addressItem?.uploadFilePath }));

                            handleFileSelectedPickup(addressItem?.uploadFileName, null, addressItem?.uploadFilePath, index);
                        });

                        formik.setValues({
                            ...formik.values,
                            ...defaultValues,
                            ...responseValue,
                            orderCutOff: moment.utc(responseValue.orderCutOff).utcOffset(timezone).format('HH:mm'),
                            // orderCutOff: moment(responseValue.orderCutOff, 'hh:mm A').format('HH:mm'),
                            // deliveryCutOff: moment(responseValue.deliveryCutOff, 'hh:mm A').format('HH:mm'),
                            name: responseValue.name,
                            // universityId: responseValue.pickupAddress.universityId,
                            // address: responseValue.pickupAddress.address,
                            // stateId: responseValue.pickupAddress.stateId,
                            // cityId: responseValue.pickupAddress.cityId,
                            // zipId: responseValue.pickupAddress.zipId,
                        });
                    }
                }
            })
        }
    }, []);

    const onToggleChange = () => {
        setIsToggleModel(true);
        setToggle(!isToggle);
    }

    const onClose = () => {
        setIsToggleModel(false);
    }

    const onNoClicked = () => {
        setIsToggleModel(false);
        setToggle(!isToggle);
    }

    const onYesClicked = () => {
        CommonService.deleteWithQueryParam("Restaurent", "DeleteRestaurent", "id", isDecodedRestaurantId).then(res => {
            if (res.data.responseData == true) {
                window.location.href = "/restaurant"
            }
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumb
                    title="Restaurant creation"
                    breadcrumbItem={"Restaurant"}
                    breadcrumbTitle={"Dashboard"}
                    breadcrumbTitlePath={"/dashboard"}
                    breadcrumbItemPath={`${roleId == 1 ? "/restaurant" : `/restaurant/view/${EncodeParam(isDecodedRestaurantId)}`}`} />
                <Container fluid>
                    <Row>
                        <Card className="overflow-hidden">
                            <CardBody className="pt-0 mx-2 mt-1">
                                <div className="p-1">
                                    <Form className="form-horizontal" onSubmit={formik.handleSubmit} >
                                        <Row className="mb-5">
                                            <Col lg={4} md={4}>
                                                <CustomTextBox id="name"
                                                    placeholder="Enter Restaurant Name" name="name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                    invalid={formik.touched.name && formik.errors.name ? true : false}
                                                    label="Restaurant Name" error={formik.errors.name} isMandatory={true} />
                                            </Col>
                                            <Col lg={3} md={3}><CustomTimePicker label={"Catering CutOff Time"}
                                                name={"orderCutOff"}
                                                id={"orderCutOff"}
                                                placeholder={"Enter CutOff Time"}
                                                value={formik.values.orderCutOff}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isMandatory={true}
                                                invalid={formik.touched.orderCutOff && formik.errors.orderCutOff ? true : false}
                                                error={formik.errors.orderCutOff} />
                                            </Col>
                                            <Col lg={3} md={3}>
                                                <CustomImageUpload
                                                    customClass={isErr ? "custom-err" : ""}
                                                    placeholder={"Drop your image here"}
                                                    label={"Restaurant Image"}
                                                    id={"profileimage"}
                                                    value={undefined}
                                                    isMandatory={true}
                                                    error={isErr}
                                                    onFileSelected={handleFileSelected} isEditForm={isEdit} isEditPageImage={profileImage} isPicupaddressImage={undefined} />
                                            </Col>
                                            {/* <Col lg={4} md={4} className="time-picker-adjustment">
                                                <CustomTimePicker label={"Catering Delivery Time"}
                                                    name={"deliveryCutOff"}
                                                    id={"deliveryCutOff"}
                                                    placeholder={"Enter Delivery Time"}
                                                    value={formik.values.deliveryCutOff}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isMandatory={true}
                                                    invalid={formik.touched.deliveryCutOff && formik.errors.deliveryCutOff ? true : false}
                                                    error={formik.errors.deliveryCutOff} />

                                            </Col> */}{isEdit ?
                                                <Col xxl={2} lg={2} md={2}>
                                                    <div className="form-check form-switch ">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="toggle"
                                                            checked={isToggle}
                                                            onChange={() => onToggleChange()}
                                                        />
                                                        <label className="form-check-label">{isToggle ? "Active" : "InActive"}</label>
                                                    </div>
                                                </Col> : null}
                                        </Row>
                                        <Row>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="header-text-grid fw-bold address-header"><p>Pickup Address</p></Col>
                                            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="text-end address-btn">
                                                <button type="button" onClick={() => AddNewPickUpAddress()} className="addaddressbtn">
                                                    <p className="fw-bold" style={{ margin: 0 }}>+ Add Pickup Address</p>
                                                </button>
                                            </Col>
                                        </Row>
                                        {formik.values.pickupAddress.filter(entity => entity.active !== undefined ? entity.active : true).map((entity, index) => (
                                            <Row key={index} className="mb-3 address-card">
                                                {index !== 0 && (
                                                    <div>
                                                        <Col className="text-end">
                                                            <i className="bx bx-minus-circle less-btn" onClick={() => handleRemoveAddress(index)}></i>
                                                        </Col>
                                                    </div>
                                                )}
                                                <Col lg={4} md={4}>
                                                    <CustomTextBox
                                                        name={`pickupAddress[${index}].name`}
                                                        placeholder="Enter Location Name"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        id={`name_${index}`}
                                                        value={entity.name}
                                                        // error={formik.touched.pickupAddress && formik.touched.pickupAddress[index] && formik.errors.pickupAddress && formik.errors.pickupAddress[index] ? (formik.errors.pickupAddress[index] as any).name : undefined}
                                                        label="Location Name"
                                                        isMandatory={true}
                                                        invalid={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.name && formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.name ? true : false}
                                                        error={formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.name}
                                                    />
                                                </Col>
                                                <Col lg={4} md={4}>
                                                    {(isEdit ? editUniversityMaster?.length > 0 : universityMaster?.length > 0) &&
                                                        <CustomAutoComplete
                                                            label="University"
                                                            id={`universityId_${index}`}
                                                            data={isEdit ? editUniversityMaster : universityMaster}
                                                            onChange={(event: ChangeEvent<{ value: unknown }>, value) => {
                                                                const selectedValue = value.id as string;
                                                                const updatedAddressList = formik.values.pickupAddress.map((item, i) =>
                                                                    i === index ? { ...item, universityId: selectedValue } : item
                                                                );
                                                                formik.setFieldValue('pickupAddress', updatedAddressList);
                                                                formik.setFieldValue(`pickupAddress[${index}].universityId`, value?.id)
                                                            }}
                                                            placeholder="Universtiy"
                                                            name={`pickupAddress[${index}].universityId`}
                                                            isMandatory={true}
                                                            defaultValue={entity.universityId}
                                                            onBlur={formik.handleBlur}
                                                            invalid={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.universityId && formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.universityId ? true : false}
                                                            error={formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.universityId}
                                                            valueField="id" displayField='displayName' touched={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.universityId}
                                                        />
                                                    }
                                                </Col>

                                                <Col lg={4} md={4}>
                                                    <CustomTextBox
                                                        name={`pickupAddress[${index}].address`}
                                                        placeholder="Enter Street Name"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        id={`address_${index}`}
                                                        value={entity.address}
                                                        invalid={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.address && formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.address ? true : false}
                                                        error={formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.address}
                                                        label="Street Name"
                                                        isMandatory={true}
                                                    />
                                                </Col>
                                                <Col lg={4} md={4}>
                                                    {(stateMaster?.length > 0) &&
                                                        <CustomAutoComplete
                                                            label="State"
                                                            id={`stateId_${index}`}
                                                            data={stateMaster}
                                                            onChange={(event: ChangeEvent<{ value: unknown; }>, value: any) => {
                                                                const selectedValue = value.id as string;
                                                                const updatedAddressList = formik.values.pickupAddress.map((item, i) => i === index ? { ...item, stateId: selectedValue } : item
                                                                );
                                                                formik.setFieldValue('pickupAddress', updatedAddressList);
                                                                formik.setFieldValue(`pickupAddress[${index}].stateId`, value?.id)
                                                                getCityByStateId(selectedValue);
                                                            }}
                                                            isMandatory={true}
                                                            invalid={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.stateId && formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.stateId ? true : false}
                                                            error={formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.stateId}
                                                            displayField="displayName"
                                                            valueField="id"
                                                            defaultValue={entity.stateId} touched={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.stateId} name={`pickupAddress[${index}].stateId`} />}
                                                </Col>
                                                <Col lg={4} md={4}>
                                                    {(isEdit ? (stateMaster?.length > 0 && cityMaster?.length > 0) : (stateMaster?.length > 0 || cityMaster?.length > 0)) &&
                                                        <CustomAutoComplete
                                                            label="City"
                                                            id={`cityId_${index}`}
                                                            data={cityMaster}
                                                            onChange={(event: ChangeEvent<{ value: unknown; }>, value: any) => {
                                                                const selectedValue = value.id as string;
                                                                const updatedAddressList = formik.values.pickupAddress.map((item, i) => i === index ? { ...item, cityId: selectedValue } : item
                                                                );
                                                                formik.setFieldValue('pickupAddress', updatedAddressList);
                                                                formik.setFieldValue(`pickupAddress[${index}].cityId`, value?.id);
                                                                getZipCodeByCityId(selectedValue);
                                                            }}
                                                            isMandatory={true}
                                                            invalid={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.cityId && formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.cityId ? true : false}
                                                            error={formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.cityId}
                                                            displayField="displayName"
                                                            valueField="id"
                                                            defaultValue={entity.cityId} touched={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.cityId} name={`pickupAddress[${index}].cityId`} />}
                                                </Col>

                                                <Col lg={4} md={4}>
                                                    {(isEdit ? (cityMaster?.length > 0 && zipCodeMaster?.length > 0) : (stateMaster?.length > 0 || zipCodeMaster?.length > 0)) &&
                                                        <CustomAutoComplete
                                                            label="Zip Code"
                                                            id={`zipId_${index}`}
                                                            data={zipCodeMaster}
                                                            onChange={(event: ChangeEvent<{ value: unknown; }>, value: any) => {
                                                                const selectedValue = value.id as string;
                                                                const updatedAddressList = formik.values.pickupAddress.map((item, i) =>
                                                                    i === index ? { ...item, zipId: selectedValue } : item
                                                                );
                                                                formik.setFieldValue('pickupAddress', updatedAddressList);
                                                                formik.setFieldValue(`pickupAddress[${index}].zipId`, value?.id)
                                                            }}
                                                            isMandatory={true}
                                                            invalid={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.zipId && formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.zipId ? true : false}
                                                            error={formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.zipId}
                                                            displayField="displayName"
                                                            valueField="id"
                                                            defaultValue={entity.zipId} touched={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.zipId} name={`pickupAddress[${index}].zipId`} />}
                                                </Col>
                                                <Col lg={4}>
                                                    <CustomTimePicker label={"Delivery From"}
                                                        name={`fromTime${index}`}
                                                        id={`fromTime${index}`}
                                                        value={formik.values[`fromTime${index}`]}
                                                        placeholder={"Enter Delivery From"}
                                                        onChange={(event: ChangeEvent<{ value: unknown }>) => {
                                                            const selectedValue = event.target.value as string;
                                                            const updatedAddressList = formik.values.pickupAddress.map((item, i) =>
                                                                i === index ? { ...item, fromTime: selectedValue } : item
                                                            );
                                                            formik.setFieldValue(`fromTime${index}`, selectedValue);
                                                            formik.setFieldValue('pickupAddress', updatedAddressList);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        isMandatory={true}
                                                        invalid={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.fromTime && formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.fromTime ? true : false}
                                                        error={formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.fromTime} />
                                                </Col>
                                                <Col lg={4}>
                                                    <CustomTimePicker label={"Delivery To"}
                                                        name={`toTime${index}`}
                                                        id={`toTime${index}`}
                                                        value={formik.values[`toTime${index}`]}
                                                        // defaultValue={entity.toTime}
                                                        placeholder={"Enter Delivery To"}
                                                        onChange={(event: ChangeEvent<{ value: unknown }>) => {
                                                            const selectedValue = event.target.value as string;
                                                            const updatedAddressList = formik.values.pickupAddress.map((item, i) =>
                                                                i === index ? { ...item, toTime: selectedValue } : item
                                                            );
                                                            formik.setFieldValue(`toTime${index}`, selectedValue);
                                                            formik.setFieldValue('pickupAddress', updatedAddressList);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        isMandatory={true}
                                                        invalid={formik.touched.pickupAddress && formik.touched.pickupAddress[index]?.toTime && formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.toTime ? true : false}
                                                        error={formik.errors.pickupAddress && formik.errors.pickupAddress[index]?.toTime} />
                                                </Col>

                                                <Col lg={4} md={4}>
                                                    {/* <CustomImageUpload
                                                        customClass={isErr ? "custom-err" : ""}
                                                        placeholder={"Drop your image here"}
                                                        label={"Pickup Address Image"}
                                                        id={"updateImage"}
                                                        value={undefined}
                                                        isMandatory={true}
                                                        error={isErr}
                                                        isPicupaddressImage={`${HelperConstant.image_cdn_Url}/${entity.uploadFilePath}`}
                                                        onFileSelected={handleFileSelectedPickup} isEditForm={isEdit} isEditPageImage={undefined} /> */}
                                                    <CustomImageUpload
                                                        customClass={isPickupErr ? "custom-err" : ""}
                                                        placeholder={"Drop your image here"}
                                                        label={"Pickup Location Image"}
                                                        id={`uploadFile_${index}`}
                                                        value={undefined}
                                                        isMandatory={true}
                                                        error={isPickupErr}
                                                        index={index}
                                                        onFileSelected={(fileName, fileSize, selectedFile, index) => handleFileSelectedPickup(fileName, fileSize, selectedFile, index)}
                                                        isEditForm={isEdit && entity.uploadFilePath} isPicupaddressImage={`${HelperConstant.image_cdn_Url}/${entity.uploadFilePath}`} />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Row className="mt-4 d-grid d-flex">
                                            <Col className="text-end">
                                                <CustomButton
                                                    type="button"
                                                    name="Cancel"
                                                    onClick={() => onCancelButtonClick()}
                                                    btnClassName={"cancel-button w-lg m-3"}
                                                    iconClasssName={undefined} disabled={disabled} />
                                                {/* <CustomButton
                                                    className="loginbtn w-lg"
                                                    type="submit"
                                                    name={isEdit ? "Update Restaurant" : "Create Restaurant"}
                                                    btnClassName={"ms-2 loginbtn w-lg m-3"}
                                                    disabled={disabled}
                                                    iconClasssName={undefined} /> */}
                                                <Button
                                                    type="submit" disabled={disabled} style={{ backgroundColor: '#E75605', width: '170px', height: '35px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}>
                                                    {isEdit ? "Update Restaurant" : "Create Restaurant"}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
            <div className="container">
                <Modal isOpen={isToggleModel} toggle={onClose}>
                    <ModalBody>
                        <p><b>{!isToggle ? "Are you sure you want to delete the restaurant?" : "Are you sure you want to Activation the restaurant?"}</b></p>
                        <p>{!isToggle ? <p>This confirmation should delete all dependencies of this restaurant such as <b>Menu, Admin Users, Pickup address..</b></p> : "This Activation should restore all the dependencies of this restaurant"}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ width: '80px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} type="button" onClick={() => onNoClicked()}>No</Button>
                        <Button style={{ backgroundColor: '#E75605', width: '80px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} type={"submit"} onClick={() => onYesClicked()}>Yes</Button>
                    </ModalFooter>
                </Modal>
            </div>
            <CustomToaster message={isEdit ? Toaster_ENUM.edit.text : Toaster_ENUM.restaurentcreate.text} color={"text-success"} toast={isEdit ? toastUpdate : toastCreate} setToast={isEdit ? setToastUpdate : setToastCreate} path={`${isEdit && isToggle ? `/restaurant/view/${id}` : '/restaurant'}`} />
        </React.Fragment>
    );
};
export default withRouter(AdminRestaurantCreation);
