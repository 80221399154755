import React, { } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

type Props = {
    isOpen?: any;
    toggle?: any;
    checked?: any;
    setTermsChecked?: any;
    setCheckError?: any
}

const TermsAndConditionPopUp = ({ isOpen, toggle, checked, setTermsChecked, setCheckError }: Props) => {
    return (
        <div className={`bg-white ${isOpen ? "p-0" : "page-content p-4"}`}>
            <Card style={{ boxShadow: `${isOpen ? "" : "#64646f4a 0px 7px 29px 0px"}`}} className={`${isOpen ? "p-0":"p-3"}`}>
                <CardHeader className="bg-white" style={{ borderRadius: "30px" }}>
                    <div className="modal-title h5" style={{ marginTop: `${isOpen ? "0px" : "20px"}` }}>
                        {isOpen &&
                            <p onClick={() => {
                                toggle()
                            }} style={{ textAlign: "right" }}><i className="fas fa-times" style={{ fontSize: "20px" }}></i></p>}
                        Terms and Conditions Agreement <p className="m-2">Effective: April 3, 2019</p>
                    </div>
                </CardHeader>
                <CardBody style={{ textAlign: "justify" }}>
                    <p>
                        PLEASE READ THE TERMS AND CONDITIONS CAREFULLY. THE TERMS AND CONDITIONS (“AGREEMENT”) CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND PANDA GOGOGO LLC (“COMPANY”).
                    </p>
                    <p>
                        By accessing or using the website located at <Link to={"/"}>https://www.pandagogogo.com/</Link> (“Website”) in any way, downloading, installing or using the Company’s mobile application (“Application”) or any other software supplied by the Company (collectively, with the Application, the “Software”), accessing or using any information, services, features or resources available or enabled via the Website or Software (each, a “Service” and collectively, the “Services”)), clicking on a button or taking similar action to signify your affirmative acceptance of this Agreement, or completing the Panda GoGoGo account registration process, you hereby represent that: <p><b>(1)</b> you have read, understand, and agree to be bound by this Agreement and any future amendments and additions to this Agreement as published from time to time at <Link to={"/terms"}>https://www.pandagogogo.com/terms/</Link> or through the Services;</p> <p><b>(2)</b> you are of legal age in the jurisdiction in which you reside to form a binding contract with Company; and </p><b>(3)</b> you have the authority to enter into the Agreement personally and, if applicable, on behalf of any company, organization or other legal entity you have named as the user during the Panda GoGoGo account registration process and to bind that company, organization or entity to the Agreement. The terms “you,” “user” and “users” refer to all individuals and other persons who access or use the Website, Software, and/or Services, including, without limitation, any companies, organizations or other legal entities that register accounts or otherwise access or use the Services through their respective employees, agents or representatives. Except as otherwise provided herein, if you do not agree to be bound by the Agreement, you may not access or use the Website, the Services, or the Software.
                    </p>
                    <p>
                        Subject to Section 12(h) of this Agreement, the Company reserves the right to modify the terms and conditions of this Agreement or its policies relating to the Website, Software or Services at any time, effective upon posting of an updated version of this Agreement on the Website or Software. You should regularly review this Agreement, as your continued use of the Services after any such changes constitutes your agreement to such changes.
                    </p>
                    <p>
                        The Company uses a network of independent third-party contractors (Deliverers) who provide delivery services to our users and food service providers. It is up to each Deliverer to provide such delivery services. The Food Service Providers available through our Services (“Food Service Providers”) also operate independently of the Company. The Company will not assess the suitability, legality or ability of any Deliverer or Food Service Provider. The Company is not responsible for the Food Service Providers’ food preparation or safety and does not verify their compliance with applicable laws or regulations. The Company has no responsibility or liability for acts by any third-party Food Service Provider or Deliverer, other than as stated herein.
                    </p>
                    <p><b>1. If you cannot pick up the food on the posted time frame</b></p>

                    <p><b>(a)</b> Have someone reliable to pick up the food with the QR code you received.</p>

                    <p><b>(b)</b> The food will be left at the posted location.</p>

                    <p><b>(c)</b> There will be no refund or credit for the future orders</p>
                    <p>
                        <b>2. User Representations, Warranties and Covenants</b>
                    </p>
                    <p> By using the Services, you expressly represent and warrant that you are legally entitled to enter this Agreement. Your participation in using the Services is for your sole, personal or internal business use. When using the Services, you agree to comply with all applicable laws from your home nation, and the country, state and city in which you are present while using the Services.</p>
                    <p>You may only access the Services using authorized means. It is your responsibility to check to ensure you download the correct Software for your device. The Company is not liable if you do not have a compatible device or if you have downloaded the wrong version of the Software for your device. The Company reserves the right to terminate your use of the Software and/or Services should you be using the Software or Services with an incompatible or unauthorized device.</p>
                    <p>By using the Services, you agree that:</p>
                    <p><b>(a)</b> You will only use the Services for lawful purposes; you will not use the Services for sending or storing any unlawful material or for deceptive or fraudulent purposes.</p>
                    <p><b>(b)</b> You will not use the Services to cause nuisance, annoyance or inconvenience.</p>
                    <p><b>(c)</b> You will not use the Services, or any content accessible through the Services, for any commercial purpose, including but not limited to contacting, advertising to, soliciting or selling to, any Food Service Provider, user or Deliverer, unless the Company has given you permission to do so in writing.</p>
                    <p><b>(d)</b> You will not copy or distribute the Software or any content displayed through the Services without prior written permission from the Company.</p>
                    <p><b>(e)</b> You will not create or compile, directly or indirectly, any collection, compilation, or other directory from any content displayed through the Services except for your personal, noncommercial use.</p>
                    <p><b>(f)</b> The information you provide to us when you register an account or otherwise communicate with us is accurate, you will promptly notify us of any changes to such information, and you will provide us with whatever proof of identity we may reasonably request.</p>
                    <p><b>(g)</b> You are aware that when requesting Services by SMS text messages, standard messaging charges will apply.</p>
                    <p><b>(h)</b> You will keep secure and confidential your account password or any identification credentials we provide you which allows access to the Service</p>
                    <p><b>(i)</b> You will only use the Services for your own use and will not resell either the Software or Services to a third party.</p>
                    <p><b>(j)</b> You will not use the Website or Software in any way that could damage, disable, overburden or impair any Company server, or the networks connected to any Company server.</p>
                    <p><b>(k)</b> You will not attempt to gain unauthorized access to any part of the Website and/or to any service, account, resource, computer system and/or network connected to any Company server</p>
                    <p><b>(l)</b> You will not deep-link to the Website or access the Website manually or with any robot, spider, web crawler, extraction software, automated process and/or device to scrape, copy or monitor any portion
                        of the Website or any content on the Website, unless the Company has given you permission to do so in writing.</p>
                    <p><b>(m)</b> You will not copy any content displayed through the Services, including but not limited to Food Service Providers’ menu content and reviews, for republication in any format or media.</p>
                    <p><b>(n)</b> You will not conduct any systematic retrieval of data or other content from the Website, Software or Services.</p>
                    <p><b>(o)</b> You will not try to harm other Users or the Company, the Website, Software or Services in any way whatsoever.</p>
                    <p><b>(p)</b> You will report any errors, bugs, unauthorized access methodologies or any breach of our intellectual property rights that you uncover in your use of the Website, Software or Services.</p>
                    <p><b>(q)</b> You will not abuse our promotional or credit code system by redeeming multiple coupons at once.</p>
                    <p><b>3. User Account</b></p>
                    <p>You are the sole authorized User of any account you create through the Services. You are solely and fully responsible for all activities that occur under your password or account. You agree that you shall monitor your account to prevent use by minors, and you will accept full responsibility for any unauthorized use of your password or your account by minors. You may not authorize others to use your User status, and you may not assign or otherwise transfer your User account to any other person or entity. Should you suspect that any unauthorized party may be using your password or account, you will notify the Company immediately. If you provide any information that is untrue, inaccurate, not current, or incomplete, or the Company has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, the Company has the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof). You agree not to create an account or use the Services if you have been previously removed by the Company, or if you have been previously banned from use of the Services.</p>
                    <p><b>4. User Content</b></p>
                    <p><b>(a)</b> User Content. The Company may provide you with interactive opportunities through the Services, including, by way of example, the ability to post User ratings and reviews (collectively, “User Content”). You represent and warrant that you are the owner of, or otherwise have the right to provide, all User Content that you submit, post and/or otherwise transmit (“Make Available”) through the Services. You hereby grant the Company a perpetual, irrevocable, transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicenseable right and license to use, copy, display, publish, modify, remove, publicly perform, translate, create derivative works, distribute and/or otherwise use the User Content in connection with the Company’s business and in all forms now known or hereafter invented (“Uses”), without notification to and/or approval by you. You further grant the Company a license to use your username and/or other User profile information, including without limitation your ratings history, to attribute User Content to you in connection with such Uses, without notification or approval by you.</p>
                    <p><b>(b)</b> Feedback. You agree that any submission of any ideas, suggestions, and/or proposals to the Company through its suggestion, feedback, wiki, forum or similar pages (“Feedback”) is at your own risk and that the Company has no obligations (including without limitation, obligations of confidentiality)
                        with respect to such Feedback. You represent and warrant that you have all rights necessary to submit the Feedback and you hereby grant to Company a perpetual, irrevocable, transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicenseable right and license to use, copy, display, publish, modify, remove, publicly perform, translate, create derivative works, distribute and/or otherwise use such Feedback.</p>
                    <p><b>(c)</b> Ratings and Reviews. To the extent that you are asked to rate and post reviews of Food Service Providers or other businesses (“Ratings” and “Reviews”), such Ratings and Reviews are considered User Content and are governed by this Agreement. Ratings and Reviews are not endorsed by the Company and do not represent the views of the Company or its affiliates. The Company does not assume liability for Ratings and Reviews or for any claims for economic loss resulting from such Ratings and Reviews. Because we strive to maintain a high level of integrity with respect to Ratings and Reviews posted or otherwise made available through the Services, you agree that: (i) you will base any Rating or Review on first-hand experience with the Food Service Provider or business; (ii) you will not provide a Rating or Review for any Food Service Provider or business for which you have an ownership interest, employment relationship or other affiliation or for any of that company’s competitors; (iii) you will not submit a Rating or Review in exchange for payment, free food items, or other benefits from a Food Service Provider or business and (iv) your review will comply with the terms of this Agreement. If we determine, in our sole discretion, that any Rating or Review could diminish the integrity of the Ratings and Reviews, we may exclude such User Content without notice.</p>
                    <p><b>5. Intellectual Property Ownership</b></p>
                    <p>The Company alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the Website, the Software and the Services. This Agreement is not a sale and does not convey to you any rights of ownership in or related to the Website, the Software or the Services, or any intellectual property rights owned by the Company. The Company name, the Company logo, and the product names associated with the Website, the Software and Services are trademarks of the Company or third parties, and no right or license is granted to use them. You agree that you will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Website, the Software or the Services.</p>
                    <p><b>6. Payment Terms</b></p>
                    <p><b>(a)</b> Prices. You understand that: (a) the prices for menu items displayed through the Services may differ from the prices offered or published by Food Service Providers for the same menu items and/or from prices available at other third-party websites and that such prices may not be the lowest prices at which the menu items are sold; (b) the Company has no obligation to itemize its costs, profits or margins when publishing such prices; and (c) the Company reserves the right to change such prices at any time, at its discretion. You are liable for all transaction taxes on the Services provided under this Agreement (other than taxes based on the Company’s income). Payment will be processed by the Company, using the preferred payment method designated in your account.</p>
                    <p><b>(b)</b> No Refunds. Charges paid by you for completed and delivered orders are final and non-refundable. The Company has no obligation to provide refunds or credits, but may grant them, in each case in Company’s sole discretion.</p>
                    <p><b>(c)</b> Promotional Offers. The Company, at its sole discretion, may make promotional offers with different features and different rates to any of our Users. These promotional offers, unless made to you, shall have no bearing whatsoever on your offer or contract. We encourage you to check back at our Website periodically if you are interested in learning more about how we charge for the Software or Services.</p>
                    <p><b>(d)</b> Fees for Services and Software. The Company may change the fees for our Services as we deem necessary or appropriate for our business.</p>
                    <p><b>(e)</b> Referral Program. The Company’s Referral Program Terms and Conditions are available at https://www.pandagogogo.com/referral-terms/ (“Program”). Under the Program, the Company offers its registered customers in good standing the opportunity to earn Panda GoGoGo credits as promotional rewards by inviting their eligible friends (each, a “Referred Friend”) to register as new Panda GoGoGo customers and place their initial order through the Service by using a unique referral ID link (“Personal Link”). For each Qualified Referral (as defined in the Program) generated through a User’s Personal Link, the User may receive a credit as specified on the Company’s Referral Program page. You agree we may change the terms and conditions of the Program or terminate the Program at any time.</p>

                    <p><b>7. Third-Party Interactions</b></p>
                    <p><b>(a)</b> Third-Party Websites, Applications and Advertisements. The Services may contain links to third-party websites (“Third-Party Websites”) and applications (“Third-Party Applications”) and advertisements (“Third-Party Advertisements”) (collectively, “Third-Party Websites & Advertisements”). When you click on a link to a Third-Party Website, Third-Party Application or Third-Party Advertisement, the Company will not warn you that you have left the Company’s Website or Services and will not warn you that you are subject to the terms and conditions (including privacy policies) of another website or destination. Such Third-Party Websites & Advertisements are not under the control of the Company. The Company is not responsible for any Third-Party Websites, Third-Party Applications or any Third-Party Advertisements. The Company provides these Third-Party Websites & Advertisements only as a convenience and does not review, approve, monitor, endorse, warrant, or make any representations with respect to such Third-Party Websites & Advertisements, or their products or services. You use all links in Third-Party Websites & Advertisements at your own risk. You should review applicable terms and policies, including privacy and data gathering practices of any Third-Party Websites or Third-Party Applications, and make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any third party.</p>
                    <p><b>(b)</b> App Stores. You acknowledge and agree that the availability of the Application is dependent on the third party from which you received the Application license, e.g., the Apple iPhone or Android app stores (“App Store”). You acknowledge that this Agreement is between you and the Company and not with the App Store. The Company, not the App Store, is solely responsible for the Software and the Services, including the Application and the Services, the content thereof, maintenance, support services and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance or intellectual property infringement). In order to use the Application, you must have access to a wireless network, and you agree to pay all fees associated with such access. You also agree to pay all fees (if any) charged by the App Store in connection with the Application or the Services. You agree to comply with, and your license to use the Application is conditioned upon your compliance with, all applicable third-party terms of agreement (e.g., the App Store’s terms and policies) when using the Application. You
                        acknowledge that the App Store (and its subsidiaries) are intended third-party beneficiaries of the Agreement and have the right to enforce them.</p>
                    <p><b>8. Transactions Involving Alcohol</b></p>
                    <p>You may have the option to request delivery of alcohol products in some locations and from certain Food Service Providers. If you receive your delivery in the United States, you agree that you will only order alcohol products if you are 21 years of age or older. If you receive your delivery in another country, you agree that you will only order alcohol products if you are of legal age to purchase alcohol products in the relevant jurisdiction. You also agree that, upon delivery of alcohol products, you will provide valid government-issued identification proving your age to the Deliverer delivering the alcohol products and that the recipient will not be intoxicated when receiving delivery of such products. If you order alcohol products, you understand and acknowledge that neither the Company nor the Deliverer can accept your order of alcohol products, and the order will only be delivered if the Food Service Provider accepts your order. The Deliverer reserves the right to refuse delivery if you are not 21 years of older, if you cannot provide a valid government issued ID, if the name on your ID does not match the name on your order, or you are visibly intoxicated. If the Deliverer is unable to complete the delivery of alcohol products for one or more of these reasons, you are subject to a non-refundable $20 re-stocking fee.</p>
                    <p><b>9. Indemnification</b></p>
                    <p>You agree to indemnify and hold harmless the Company and its officers, directors, employees, agents and affiliates (each, an “Indemnified Party”), from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including without limitation attorneys’ fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or resulting from (a) your User Content; (b) your misuse of the Website, Software or Services; (c) your violation of this Agreement; or (d) your violation of any applicable laws, rules or regulations through or related to the use of the Website, Software or Services. In the event of any claim, allegation, suit or proceeding alleging any matter potentially covered by the agreements in this section, you agree to pay for the defense of the Indemnified Party, including reasonable costs and attorneys’ fees incurred by the Indemnified Party. The Company reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with the Company in asserting any available defenses. This provision does not require you to indemnify any Indemnified Party for any unconscionable commercial practice by such party, or for such party’s negligence, fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the Website, Software or Services. You agree that the provisions in this section will survive any termination of your account, this Agreement, or your access to the Website, Software and/or Services.</p>
                    <p><b>10. Disclaimer of Warranties</b></p>
                    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT OF LAW, YOUR USE OF THE WEBSITE, SOFTWARE AND SERVICES IS ENTIRELY AT YOUR OWN RISK. CHANGES ARE PERIODICALLY MADE TO THE WEBSITE, SOFTWARE AND SERVICES AND MAY BE MADE AT ANY TIME WITHOUT NOTICE TO YOU. THE WEBSITE, SOFTWARE AND SERVICES ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
                    <p>THE COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS OR TIMELINESS OF THE CONTENT MADE AVAILABLE THROUGH THE WEBSITE, SOFTWARE OR SERVICES, OR THE SERVICES, SOFTWARE, TEXT, GRAPHICS OR LINKS.</p>
                    <p>THE COMPANY DOES NOT WARRANT THAT THE WEBSITE, SOFTWARE OR SERVICES WILL OPERATE ERROR-FREE OR THAT THE WEBSITE, SOFTWARE OR SERVICES ARE FREE OF COMPUTER VIRUSES AND OTHER HARMFUL MALWARE. IF YOUR USE OF THE WEBSITE, SOFTWARE OR SERVICES RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, THE COMPANY SHALL NOT BE RESPONSIBLE FOR THOSE ECONOMIC COSTS.</p>
                    <p><b>11. Internet Delays</b></p>
                    <p>The Company’s Website, Software and Services may be subject to limitations, delays, and other problems inherent in the use of the Internet and electronic communications. Except as set forth in the Company’s privacy policy or as otherwise required by applicable law, the Company is not responsible for any delays, delivery failures, or other economic damage resulting from such problems.</p>
                    <p><b>12. Limitation of Liability</b></p>
                    <p><b>(a)</b> Cap on Liability. TO THE FULLEST EXTENT OF LAW THE COMPANY’S AGGREGATE LIABILITY SHALL NOT EXCEED THE GREATER OF (a) AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM YOU TO THE COMPANY IN THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM, AND (b) THE REMEDY OR PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM ARISES. THE FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY OF THE COMPANY FOR (a) DEATH OR PERSONAL INJURY CAUSED BY THE COMPANY’S NEGLIGENCE OR WILLFUL MISCONDUCT, OR FOR (b) ANY INJURY CAUSED BY THE COMPANY’S FRAUD OR FRAUDULENT MISREPRESENTATION.</p>
                    <p><b>(b)</b> Disclaimer of Certain Damages. TO THE FULLEST EXTENT OF LAW THE COMPANY SHALL NOT BE LIABLE TO ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY, LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE). THE COMPANY SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE OR INJURY WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO LOSS, DAMAGE OR INJURY ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE WEBSITE, SOFTWARE, OR SERVICES INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE WEBSITE, SOFTWARE, OR SERVICES, ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY FOOD SERVICE PROVIDER, DELIVERER, ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE WEBSITE OR IS REFERRED BY THE SOFTWARE OR SERVICES, EVEN IF THE COMPANY AND/OR ITS LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING DISCLAIMER OF PUNITIVE AND EXEMPLARY DAMAGES, AND THE ENTIRE DISCLAIMER OF DAMAGES FOR PERSONAL INJURY OR PROPERTY DAMAGE, OR FOR ANY INJURY CAUSED BY THE COMPANY’S FRAUD OR FRAUDULENT MISREPRESENTATION, SHALL NOT APPLY TO USERS WHO RESIDE IN THE STATE OF NEW JERSEY.</p>
                    <p><b>13. Dispute Resolution</b></p>
                    <p>PLEASE READ THE FOLLOWING SECTION CAREFULLY. IT REQUIRES YOU TO ARBITRATE DISPUTES WITH THE COMPANY AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF. THIS SECTION 12 OF THIS AGREEMENT SHALL BE REFERRED TO AS THE “ARBITRATION AGREEMENT”.</p>
                    <p><b>(a)</b> Scope of Arbitration Agreement. You agree that any dispute or claim relating in any way to your access or use of the Services as a consumer of our Services, to any advertising or marketing communications regarding the Company or the Services, to any products or services sold or distributed through the Services that you received as a consumer of our Services, or to any aspect of your relationship or transactions with Company as a consumer of our Services will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify, so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis; and (2) you or the Company may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents). This Arbitration Agreement shall apply, without limitation, to all claims that arose or were asserted before the Effective Date of this Agreement.</p>
                    <p>CASES HAVE BEEN FILED AGAINST THE COMPANY—AND OTHERS MAY BE FILED IN THE FUTURE—THAT ATTEMPT TO ASSERT CLASS ACTION CLAIMS, AND BY ACCEPTING THIS ARBITRATION AGREEMENT YOU ELECT NOT TO PARTICIPATE IN SUCH CASES.</p>
                    <p>IF YOU AGREE TO ARBITRATION WITH THE COMPANY, YOU ARE AGREEING IN ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER MONETARY OR OTHER RELIEF IN ANY SUCH CLASS, COLLECTIVE, AND/OR REPRESENTATIVE LAWSUIT. INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING YOUR CLAIMS AGAINST THE COMPANY IN AN INDIVIDUAL ARBITRATION PROCEEDING. IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE AWARDED MONEY OR OTHER RELIEF BY AN ARBITRATOR.</p>
                    <p><b>(b)</b> Arbitration Rules and Forum. This Arbitration Agreement is governed by the Federal Arbitration Act in all respects. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to our registered agent, General Counsel, at 901 Market Street, 6th Floor, San Francisco, CA, 94103. The arbitration will be conducted by JAMS under its rules and pursuant to the terms of this Agreement. Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s rules are also available at www.jamsadr.com (under the Rules/Clauses tab) or by calling JAMS at 800-352-5267. Payment of all filing, administration, and arbitration fees will be governed by JAMS’s rules. If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver of fees from JAMS, the Company will pay them for you. In addition, the Company will reimburse all such JAMS’s filing, administrative, hearing and/or other fees for claims with an amount in controversy totaling less than $10,000. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. You may choose to have the arbitration conducted by telephone, video conference, based on written submissions, or in person in the country where you live or at another mutually agreed location.</p>
                    <p><b>(c)</b> Arbitrator Powers. The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to the interpretation, applicability, enforceability or
                        formation of this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and the Company. The arbitration proceeding will not be consolidated with any other matters or joined with any other proceedings or parties. The arbitrator will have the authority to grant motions dispositive of all or part of any claim or dispute. The arbitrator will have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and this Agreement (including this Arbitration Agreement). The arbitrator will issue a written statement of decision describing the essential findings and conclusions on which any award (or decision not to render an award) is based, including the calculation of any damages awarded. The arbitrator shall follow the applicable law. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The arbitrator’s decision is final and binding on you and the Company.</p>
                    <p><b>(d)</b> Waiver of Jury Trial. YOU AND THE COMPANY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND RECEIVE A JUDGE OR JURY TRIAL. You and the Company are instead electing to have claims and disputes resolved by arbitration, except as specified in section 12(a) above. There is no judge or jury in arbitration, and court review of an arbitration award is limited.</p>
                    <p><b>(e)</b> Waiver of Class or Consolidated Actions. YOU AND THE COMPANY AGREE TO WAIVE ANY RIGHT TO RESOLVE CLAIMS WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT ON A CLASS, COLLECTIVE, OR REPRESENTATIVE BASIS. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable with respect to a particular claim or dispute, neither you nor the Company is entitled to arbitration of such claim or dispute. Instead, all such claims and disputes will then be resolved in a court as set forth in Section 13.</p>
                    <p><b>(f)</b> Opt Out. You may opt out of this Arbitration Agreement. If you do so, neither you nor the Company can force the other to arbitrate as a result of this Agreement. To opt out, you must notify the Company in writing no later than 30 days after first becoming subject to this Arbitration Agreement. Your notice must include your name and address, your Panda GoGoGo username (if any), the email address you used to set up your Panda GoGoGo account (if you have one), and a CLEAR statement that you want to opt out of this Arbitration Agreement. You must send your opt-out notice to: opt-out@pandagogogo.com. If you opt out of this Arbitration Agreement, all other parts of this Agreement will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may have entered into with us or may enter into in the future with us. NOTWITHSTANDING ANYTHING TO THE CONTARY HEREIN, NOTHING IN THIS AGREEMENT SHALL SUPERSEDE, AMEND, OR MODIFY THE TERMS OF ANY SEPARATE AGREEMENT(S) BETWEEN YOU AND THE COMPANY RELATING TO YOUR WORK AS AN EMPLOYEE OR INDEPENDENT CONTRACTOR, INCLUDING WITHOUT LIMITATION, ANY INDEPENDENT CONTRACTOR AGREEMENT GOVERNING YOUR SERVICES AS A DELIVERER. FOR THE AVOIDANCE OF DOUBT, IF YOU ARE A DELIVERER, OPTING-OUT OF THE ARBITIRATION AGREEMENT SET FORTH IN THIS SECTION 12 HAS NO AFFECT ON YOUR AGREEMENT TO ARBITRATE DISPUTES COVERED BY YOUR INDEPEDENT CONTRACTOR AGREEMENT WITH THE COMPANY.</p>
                    <p><b>(g)</b> Survival. This Arbitration Agreement will survive any termination of your relationship with the Company.</p>
                    <p><b>(h)</b> Modification. Notwithstanding any provision in the Agreement to the contrary, we agree that if the Company makes any future material change to this Arbitration Agreement, it will not apply to any individual claim(s) that you had already provided notice of to the Company</p>
                    <p><b>14. Exclusive Venue</b></p>
                    <p>To the extent the parties are permitted under this Agreement to initiate litigation in a court, both you and the Company agree that all claims and disputes arising out of or relating to the Agreement will be litigated exclusively in the state or federal courts located in Travis County if you are a Texas citizen or resident, and in the United States District Court for the District in which you reside if you are not a Texas citizen or resident.</p>
                    <p><b>15. Termination</b></p>
                    <p>At its sole discretion, the Company may modify or discontinue the Software or Service, or may modify, suspend or terminate your access to the Software or the Services, for any reason, with or without notice to you and without liability to you or any third party. In addition to suspending or terminating your access to the Software or the Service, the Company reserves the right to take appropriate legal action, including without limitation pursuing civil, criminal or injunctive redress. Even after your right to use the Software or the Services is terminated, this Agreement will remain enforceable against you. All provisions which by their nature should survive to give effect to those provisions shall survive the termination of this Agreement.</p>
                    <p><b>16. Procedure for Making Claims of Copyright Infringement.</b></p>
                    <p>It is the Company’s policy to terminate membership privileges of any User who repeatedly infringes copyright upon prompt notification to the Company by the copyright owner or the copyright owner’s legal agent. Without limiting the foregoing, if you believe that your work has been copied and posted on the Website or the Services in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information: (a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; (b) a description of the copyrighted work that you claim has been infringed; (c) a description of the location on the Website or in the Services of the material that you claim is infringing; (d) your address, telephone number and e-mail address; (e) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; and (f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. Contact information for the Company’s Copyright</p>
                    <p><b>17. General</b></p>
                    <p><b>(a)</b> No Joint Venture or Partnership. No joint venture, partnership, employment, or agency relationship exists between you, the Company or any third party provider as a result of this Agreement or use of the Software or Services.</p>
                    <p><b>(b)</b> Choice of Law. This Agreement is governed by the laws of the State of Delaware consistent with the Federal Arbitration Act, without giving effect to any principles that provide for the application of the law of any other jurisdiction.</p>
                    <p><b>(c)</b> Severability. Except as otherwise provided herein, if any provision of this Agreement is found to be invalid, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect.</p>
                    <p><b>(d)</b> Consumer Complaints. <Link target="_blank" to={"https://www.texasattorneygeneral.gov/consumer-protection/file-consumer-complaint"}>https://www.texasattorneygeneral.gov/consumer-protection/file-consumer-complaint</Link></p>
                    <p><b>(e)</b> Accessing and Downloading the Application from iTunes. The following applies to any Application accessed through or downloaded from the Apple App Store (an “App Store Sourced Application”):</p>
                    <p><b>(1)</b> You acknowledge and agree that (i) the Agreement is concluded between you and the Company only, and not Apple, and (ii) the Company, not Apple, is solely responsible for the App Store Sourced Application and content thereof. Your use of the App Store Sourced Application must comply with the App Store Terms of Service.</p>
                    <p><b>(2)</b> You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App Store Sourced Application.</p>
                    <p><b>(3)</b> In the event of any failure of the App Store Sourced Application to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price, if any, for the App Store Sourced Application to you and to the fullest extent permitted by law, Apple will have no other warranty obligation whatsoever with respect to the App Store Sourced Application. As between the Company and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of the Company.</p>
                    <p><b>(4)</b> You and the Company acknowledge that, as between the Company and Apple, Apple is not responsible for addressing any claims you have or any claims of any third party relating to the App Store Sourced Application or your possession and use of the App Store Sourced Application, including, but not limited to: (i) product liability claims; (ii) any claim that the App Store Sourced Application fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</p>
                    <p><b>(5)</b> You and the Company acknowledge that, in the event of any third-party claim that the App Store Sourced Application or your possession and use of that App Store Sourced Application infringes that third party’s intellectual property rights, as between the Company and Apple, the Company, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by the Terms.</p>
                    <p><b>(6)</b> You and the Company acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of the Terms as related to your license of the App Store Sourced Application, and that, upon your acceptance of the terms and conditions of the Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms as related to your license of the App Store Sourced Application against you as a third-party beneficiary thereof.</p>
                    <p><b>(7)</b> Without limiting any other terms of the Terms, you must comply with all applicable third-party terms of agreement when using the App Store Sourced Application.</p>
                    <p><b>(f)</b> Notice. Where the Company requires that you provide an e-mail address, you are responsible for providing the Company with your most current e-mail address. In the event that the last e-mail address you provided to the Company is not valid, or for any reason is not capable of delivering to you any notices required or permitted by this Agreement, the Company’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice. You may give notice to the Company through the following web form: www.pandagogogo.com/help/. Such notice shall be deemed given on the next business day after such e-mail is actually received by the Company.</p>
                    <p><b>(g)</b> Electronic Communications. For contractual purposes, you (1) consent to receive communications from the Company in an electronic form; and (2) agree that all terms and conditions, agreements, notices, disclosures, and other communications that the Company provides to you electronically satisfy any legal requirement that such communications would satisfy if they were in writing. This subparagraph does not affect your statutory rights</p>
                    <p><b>(h)</b> Entire Agreement. This Agreement is the final, complete and exclusive agreement of the parties with respect to the subject matter hereof and supersedes and merges all prior discussions between the parties with respect to such subject matter. However, nothing in this Agreement shall supersede, amend, or modify the terms of any separate agreement(s) between you and the Company relating to your work as an employee or independent contractor, including, without limitation, any Independent Contractor Agreement governing your efforts as a Deliverer.</p>
                    <p><b>18. Contact Information</b></p>
                    <p>Panda GoGoGo welcomes your questions or comments regarding the Terms:</p>
                    <p><b>Panda GoGoGo LLC</b></p>
                    <p>Help Form: <Link to={"/contact-us"}>https://www.pandagogogo.com/help/</Link></p>
                    {isOpen &&
                        <Row className="d-grid d-flex">
                            <Col className="text-end">
                                <Button color="secondary" className="m-2" onClick={() => {
                                    toggle();
                                }} >Cancel</Button>
                                <Button style={{ backgroundColor: '#E75605', width: '85px', height: '36px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} onClick={() => {
                                    toggle();
                                    setTermsChecked(!checked);
                                    if (checked) {
                                        setCheckError("Please check the box to agree to the terms and conditions.");
                                    }
                                    else {
                                        setCheckError("");
                                    }
                                }} >I Agree</Button>
                            </Col>
                        </Row>}
                </CardBody>
                <div className="modal-body" >

                </div>
            </Card>
        </div>
    )

}

export default TermsAndConditionPopUp;