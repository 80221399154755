import Breadcrumb from "Components/Common/Breadcrumb";
import CustomDataTable from "Components/Shared/CustomDataTable";
import { CustomDropdown } from "Components/Shared/CustomDropdown";
import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { forkJoin } from "rxjs";
import { useFormik } from "formik";
import { Role } from "Components/constants/PandaConstants";
import { Loader } from "Components/Loader/Loader";
import { CustomAutoComplete } from "Components/Shared/CustomAutoComplete";

const CouponCodeHistoryDashboard = () => {
    const [orderhistory, setOrderhistory] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(true);
    const [universityMaster, setUniversityMaster] = useState<any>([]);
    const [couponCodeMaster, setCouponCodeMaster] = useState<any>([]);
    const [userMaster, setUserMaster] = useState<any>([]);
    const [selectedColumn, setSelectedColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [totalRowsCount, setTotalRowsCount] = useState<any>();
    const [isPageNumber, setIsPageNumber] = useState<any>();
    const [filterDetails, setFilterDetails] = useState({
        userId: "",
        universityId: "",
        couponCodeId: '',
    });
    document.title = "Order history | Panda GoGoGo ";

    const [rowPerPageValue, setRowPerPageValue] = useState(10);

    const filterHandler = (type: "fromDate" | "toDate" | "userId" | "universityId" | "couponCodeId", val: any) => {
            filterDetails[`${type}`] = val;
            setFilterDetails(filterDetails);

        getCouponCodeHistorySearch(1, "", null, null, filterDetails.couponCodeId, filterDetails.userId, filterDetails.universityId);
    }

    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'User Name',
            accessor: 'userName',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'University',
            accessor: 'universityName',
            width: "15%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Coupon Name',
            accessor: 'couponName',
            width: "20%",
            displayInList: true,
            Filter: true,
        }, {
            Header: 'Order Count',
            accessor: 'couponCount',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Email',
            accessor: 'email',
            width: "25%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Mobile Number',
            accessor: 'mobileNumber',
            width: "20%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
    ], []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: "",
            universityId: "",
            couponCodeId: '',
        },
        onSubmit: () => {
            getCouponCodeHistorySearch(1, "", );
        }
    });

    const pagination = (pageNumber: any) => {
        getCouponCodeHistorySearch(pageNumber, search, selectedColumn, sortOrder);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getCouponCodeHistorySearch(1, searchKeyword, );
    }

    const onColumnSortChange = (columnName, sortorder) => {
        setSelectedColumn(columnName);
        setSortOrder(sortorder);
        getCouponCodeHistorySearch(1, search, "", "", columnName, sortOrder);
    };

    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        getCouponCodeHistorySearch(1, "", null, null, filterDetails.couponCodeId, filterDetails.userId, filterDetails.universityId, val);
    }

    const clear = () => {
        filterDetails.universityId = "";
        filterDetails.couponCodeId = "";
        filterDetails.userId = "";
        setFilterDetails(filterDetails);
        getCouponCodeHistorySearch(1, "", null, null, filterDetails.couponCodeId, filterDetails.userId, filterDetails.universityId);

    }

    const getCouponCodeHistorySearch = (pagenumber: any, searchText: string, sortcolumn?: any, sortorder?: any, couponCodeId?: any, userId?: any, universityId?: any, rowPerPageVal?: any) => {
        setLoader(true);
        let searchModel = {
            offsetStart: pagenumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "Desc",
            searchText: searchText ? searchText : "",
            sortColumn: sortcolumn ? sortcolumn : "deliveryDate",
            userId: userId > 0 ? userId : null,
            couponCodeId: couponCodeId > 0 ? couponCodeId : null,
            universityId: universityId > 0 ? universityId : null
        }

        CommonService.post("CouponCode", "GetCouponCodeHistorySearch", searchModel).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                setOrderhistory(data);
                setIsPageNumber(pagenumber);
                setTotalRowsCount(res.data.responseData[0]?.totalRowsCount);
                setLoader(false);
            }
        });
    }

    useEffect(() => {
        let couponCode = CommonService.get("CouponCode", "GetAllCouponCode");
        let university = CommonService.get("University", "GetAllUniversityMaster");
        var user = CommonService.getWithQueryParam("User", "GetAllUserByUserRoleId", "userRoleId", Role.User);
        forkJoin({ university, couponCode, user }).subscribe((res: { university: any, couponCode: any, user: any }) => {
            setUniversityMaster(res.university.data.responseData);
            const uniques = res.user?.data?.responseData?.filter((value, index, self) =>
                index == self.findIndex((t) => (
                    t.id == value.id
                )));
            setUserMaster(uniques);
            setCouponCodeMaster( res.couponCode.data.responseData)
        });
        getCouponCodeHistorySearch(1, "", null, null, filterDetails.couponCodeId, filterDetails.userId, filterDetails.universityId);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Coupon History" breadcrumbItem="Coupon History" breadcrumbTitle={"Dashboard"}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/coupon-history"} />
                <Card>
                    <CardBody>
                        <Row className="p-4">
                            <Col lg={4} className=" mb-3">
                                <CustomDropdown
                                    label="University" id="universityId"
                                    value={filterDetails.universityId}
                                    data={universityMaster}
                                    onChange={(e) => { filterHandler("universityId", e.target.value); } }
                                    placeholder="University"
                                    name="University"
                                    isMandatory={false}
                                    error={validation.errors.universityId}
                                    touched={validation.touched.universityId}
                                    displayField="displayName"
                                    valueField="id" onBlur={undefined} invalid={undefined} />
                            </Col>
                            <Col lg={4} className="mb-3">
                            <CustomDropdown
                                    label="Coupon Name" id="couponCodeId"
                                    data={couponCodeMaster} value={filterDetails.couponCodeId}
                                    onChange={(e) => { filterHandler("couponCodeId", e.target.value); } }
                                    placeholder="Coupon Name"
                                    name="Coupon Name"
                                    isMandatory={false}
                                    error={validation.errors.couponCodeId}
                                    touched={validation.touched.couponCodeId}
                                    displayField="name"
                                    valueField="id" onBlur={undefined} invalid={undefined} />
                            </Col>
                            <Col lg={4}>
                                <CustomDropdown
                                    label="User Name" id="userId" value={filterDetails.userId}
                                    data={userMaster.map(user => ({
                                        ...user,
                                        fullName: `${user.firstName} ${user.lastName}`
                                    }))}
                                    onChange={(e) => { filterHandler("userId", e.target.value); } }
                                    placeholder="User Name"
                                    name="User Name"
                                    isMandatory={false}
                                    error={validation.errors.userId}
                                    touched={validation.touched.userId}
                                    displayField="fullName"
                                    valueField="id" onBlur={undefined} invalid={undefined} />
                            </Col>
                        </Row>
                        <Row >
                            <Col lg={12} style={{ marginTop: "-15px" }}>
                                <div className='d-flex justify-content-end' >
                                    <Button
                                        style={{ width: '75px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                        type={"button"} onClick={() => clear()} className="btn btn-secondary ms-2">Reset
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Loader open={loader} />
                        <CustomDataTable
                            rowPerPageValue={rowPerPageValue}
                            rowPerPageHandler={rowPerPageHandler}
                            columns={columns}
                            data={orderhistory}
                            customPageSize={rowPerPageValue}
                            editHandler={undefined} canShowButton={false} deleteHandler={undefined}
                            createHandler={undefined} path={""} canView={false} perPageData={rowPerPageValue} isPagination={true}
                            isGlobalFilter={true} canEdit={false}
                            selectedPage={pagination}
                            searchHandler={searchHandler} isAction={false} isShowingPageLength={true} onColumnSortChange={onColumnSortChange} />
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}
export default CouponCodeHistoryDashboard;