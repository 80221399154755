import React, { Fragment, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col } from "reactstrap";
import { DefaultColumnFilter } from "../Common/filters";
import JobListGlobalFilter from "../Common/GlobalSearchFilter";
import { CustomPagination } from "./CustomPagination";
import { IColumnModel } from "Models/IColumnModel";
import { OrderStatus, formatTableCell, ticketStatus } from "Components/constants/PandaConstants";
import { CustomButton } from "./CustomButton";
import { CustomCheckBox } from "./CustomCheckBox";
import { CustomSwitch } from "./CustomSwitch";
import { MenuItem, Select } from "@mui/material";
import debounce from 'lodash/debounce';

interface filter {
  preGlobalFilteredRows: any;
  globalFilter?: any;
  setGlobalFilter: any;
  isJobListGlobalFilter: any;
  searchHandler: any;
  toggleAllRowsSelected: any;
}

interface CustomDataTableProps {
  columns: Array<IColumnModel>;
  data: any;
  isGlobalFilter?: any;
  customPageSize: any;
  isJobListGlobalFilter?: any;
  isPagination?: any,
  selectedPage?: any,
  perPageData?: number,
  searchHandler?: any
  isAction: boolean,
  showDeleteIcon?: "Yes" | "No",
  editHandler?: any,
  viewHandler?: any,
  canShowButton: boolean,
  canShowToggle?: boolean,
  deleteHandler: any,
  createHandler: any,
  FilterHandler?: any,
  MailHandle?: any,
  isEmailCheck?: boolean,
  toggleHandler?: any,
  path: string,
  /** @param {any} isSelect will give access to select the data */
  isSelect?: any,
  /** @param {any} onCheckboxChange is a callback function to send the selected id value */
  onCheckboxChange?: any,
  /** @param {any} canView is defines it's view only or not*/
  canView?: any,
  /** @param {any} isEdit defines it has only edit in actions */
  canEdit?: any,
  onColumnSortChange?: any,
  isFilter?: any,
  isDelete?: any,
  /** @param {any} canAdd is defines it is create or not*/
  canAdd?: any,
  /** @param {any} isActive is defines if it is active or inActive*/
  isActive?: any,
  /** @param {any} canDelete is defines you want delete action?*/
  canDelete?: any,
  /** @param {any} isForm is defines you want form action?*/
  isForm?: any,
  isCampainTarget?: any,
  toggleAllRowsSelected?: any,
  checkedState?: any,
  isStatusDropdown?: any,
  isStatusDropdownData?: any,
  isStatusValueField?: any,
  handleStatusDropdown?: any,
  statusDropdownId?: any,
  statusHandler?: any,
  isShowingPageLength: any,
  isDeleteAction?: any,
  onAllCheckboxChange?: any,
  rowPerPageValue?: any,
  rowPerPageHandler?: any,
  loader?: any,
  isVerifiedaction?: any,
  selectedValues?: any
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
  searchHandler
}: filter) {

  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value: any) => {
    debouncedSearchHandler(value);
  }, 500);

  const debouncedSearchHandler = debounce((value) => {
    searchHandler(value);
  }, 500);

  return (
    <React.Fragment>
      <Col xxl={3} lg={6}>
        <input type="search" className="form-control" id="search-bar-0" value={value || ""} placeholder={"Search"} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} />
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}
    </React.Fragment>
  );
}
const CustomDataTable = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isPagination,
  canShowButton,
  canShowToggle,
  selectedPage,
  perPageData,
  searchHandler,
  isAction,
  deleteHandler,
  createHandler,
  toggleHandler,
  FilterHandler,
  MailHandle,
  isEmailCheck,
  editHandler,
  viewHandler,
  canView,
  canEdit,
  isDelete,
  isForm,
  onCheckboxChange,
  onAllCheckboxChange,
  isFilter,
  onColumnSortChange,
  canAdd, canDelete, isCampainTarget, checkedState,
  isStatusValueField,
  isStatusDropdownData, handleStatusDropdown, statusDropdownId, statusHandler,
  isShowingPageLength,
  showDeleteIcon,
  isDeleteAction,
  rowPerPageValue,
  rowPerPageHandler,
  loader,
  isVerifiedaction,
  selectedValues
}: CustomDataTableProps) => {
  // const selectedId: number[] = [];
  // const [checkedIds, setCheckedIds] = useState<number[]>([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 50
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const handleColumnSort = (column: any) => {
    const sortOrder = column.sortDescFirst == false ? "asc" : "desc";
    column.sortDescFirst = !column.sortDescFirst;
    // onColumnSortChange(column.id, sortOrder);
  };

  const renderSortingIcon = (column: any) => {
    if (column.isSorted) {
      return column.isSortedDesc ? (
        <i className="fa fa-caret-down" />
      ) : (
        <i className="fa fa-caret-up" />
      );
    }
    return null;
  };

  const [clickCounts, setClickCounts] = useState({});
  const [selectedIds, setSelectedIds] = useState([]) as any;
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  let checkIds: number[] = []
  let unCheckIds: number[] = []

  const handleCheckboxChange = (event, row) => {
    let Id = row.original.id;
    const isChecked = event.target.checked;
    const currentClickCount = clickCounts[row] || 0;
    const newClickCount = currentClickCount === 0 ? 1 : 0;
    setClickCounts({
      ...clickCounts,
      [Id]: newClickCount,
    });

    if (isChecked) {
      setSelectedIds([...selectedIds, Id]);
      checkIds.push(Id);
    } else {
      unCheckIds.push(Id)
      checkIds = checkIds.filter((checkId) => checkId !== Id);
      setSelectedIds(selectedIds.filter((selectedIds) => selectedIds !== Id));
      setAllRowsSelected(false);
    }
    onCheckboxChange(checkIds, unCheckIds);
  };

  const checkIfChecked = (row) => {
    if (isEmailCheck) {
      return selectedIds.find(x => x.id == row.original.id);
    }
    else {
      return selectedIds.includes(row.original.id);
    }
    // const clickCount = clickCounts[id] || 0;
    // return clickCount === 0;
  };

  const toggleAllRowsSelected = (checked) => {
    const selectedIds = checked ? data.map((row) => row.id) : [];
    setSelectedIds(selectedIds);
    setAllRowsSelected(checked);
    onAllCheckboxChange(selectedIds, checked);
  };

  const handleToggleAllRows = (event) => {
    toggleAllRowsSelected(event.target.checked);
  };

  useEffect(() => {
    setSelectedIds(selectedValues?.length > 0 ? selectedValues : []);
    setAllRowsSelected(selectedValues?.length > 0 ? ((selectedValues?.length === data?.length) ? true : false) : false);
    setAllRowsSelected(selectedValues?.length > 0 ? ((selectedValues?.length === data[0]?.totalRowsCount) ? true : false) : false)
    // checkIds.length = 0;
  }, []);

  useEffect(() => {
    if (allRowsSelected) {
      toggleAllRowsSelected(true);
    }
  }, [data])

  return (
    <Fragment>
      <Row className="mb-3">
        <Col xl={8} lg={8} md={6}>
          {isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              isJobListGlobalFilter={isJobListGlobalFilter} searchHandler={searchHandler} toggleAllRowsSelected={toggleAllRowsSelected}
            />
          )}
        </Col>
        <Col xl={4} lg={4} md={6}>

          {canShowToggle && (
            <div
              style={{ float: "left", marginLeft: "70%", position: "absolute", top: "-30%" }}>
              <CustomSwitch
                style={{}}
                onChange={() => toggleHandler()}
                checkedState={checkedState}
                positiveText={"Yes"}
                negativeText={"No"}
                label={undefined}
              >
              </CustomSwitch>
            </div>
          )}
          {isFilter &&
            <div className="filter-button" >
              <CustomButton
                type="Button"
                btnClassName="ms-1 btn-success"
                iconClasssName={undefined}
                name="filter"
                onClick={() => FilterHandler()}
              >
              </CustomButton></div>
          }
          {canShowButton &&
            <div className="filter-button" >
              <CustomButton
                type="Button"
                btnClassName="ms-1 btn-success"
                iconClasssName={undefined}
                name="Create"
                onClick={() => createHandler()}
              >
              </CustomButton>
            </div>
          }
        </Col>
      </Row>
      <div className="table-responsive">
        {(data.length > 0 && !loader) ?
          <Table  {...getTableProps()} >
            {headerGroups.map((headerGroup: any) => (
              <>
                <tbody  {...getTableBodyProps()} className="table-light" >
                  <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} style={{ border: "1px solid #ced4da" }}>
                    {headerGroup.headers.map((column: any) => (
                      <th key={column.id} className={`${column.isSortable ? (column.isSorted ? "sorting" : "") : ""}`} style={{ width: column.width }} onClick={() => handleColumnSort(column)} {...column.getHeaderProps()}>
                        {column.datatype === 'checkBox' && column.displayInList === true ? (
                          <CustomCheckBox
                            label={""}
                            id={"headerCheckBox"}
                            checked={allRowsSelected}
                            onChange={handleToggleAllRows} value={undefined} error={undefined} />
                        ) : (
                          <div {...column.getSortByToggleProps()}>
                            {column.displayInList === false || column.datatype === 'checkBox'
                              ? null
                              : column.render('Header')}
                            {renderSortingIcon(column)}
                          </div>
                        )}
                      </th>
                    ))}
                    {isAction && <th>Actions</th>}
                    {canView && <th>Actions</th>}
                    {canEdit && <th>Actions</th>}
                    {canAdd && <th>Actions</th>}
                    {isForm && <th>Actions</th>}
                    {isDelete && <th>Actions</th>}
                    {isDeleteAction && <th>Actions</th>}
                    {isVerifiedaction && <th>Actions</th>}
                    {(canDelete || isCampainTarget) && <th>Actions</th>}
                  </tr>
                </tbody>
                <tbody {...getTableBodyProps()} style={{ border: "1px solid #ced4da" }}>
                  {page.map((row: any) => {
                    prepareRow(row);
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <tr>
                          {row.cells.map((cell: any) => {
                            return (
                              <>
                                <td key={cell.id} {...cell.getCellProps()} style={{ width: cell.column.width, textAlign: "left" }}
                                  title={(cell.column.datatype === 'stringWith15char' && cell.value.length > 15) || (cell.column.datatype === 'stringWith40Char' && cell.value.length > 40) ? cell.value : undefined}>
                                  {cell.column.displayInList === false ? null : cell.column.datatype === 'statusIdTicket' ?
                                    Object.keys(ticketStatus).find(key => ticketStatus[key] === cell.value) || "" : cell.column.isBadge ?
                                      // <span
                                      //   className="text-dark active-badge">
                                      //   {formatTableCell(cell.value, cell.column.datatype)}
                                      // </span> 
                                      <span
                                        className={formatTableCell(cell.value, cell.column.datatype) === 'Active' ? 'active-badge' : 'inactive-span'}>
                                        {formatTableCell(cell.value, cell.column.datatype)}
                                      </span>
                                      : formatTableCell(cell.value, cell.column.datatype)}
                                  {cell.column.datatype === "checkBox" && cell.column.displayInList === true ?
                                    <CustomCheckBox
                                      label={""}
                                      id={"checkBox_" + row.original.id}
                                      checked={checkIfChecked(row) ? true : false}
                                      error={undefined}
                                      onChange={(event) => handleCheckboxChange(event, row)} value={undefined} />
                                    // <CustomCheckBox
                                    //   label={""}
                                    //   id={"checkBox_" + row.original.id}
                                    //   checked={selectedIds.includes(row.original.id)}
                                    //   onChange={(event) => handleCheckboxChange(event, row.original.id)} value={undefined} error={undefined} />
                                    : null}
                                  {(cell.column.datatype == "statusDropdown" && row.original.statusId > 0) ? <><Select variant="standard" style={{ marginTop: "-50px", fontSize: "13px", width: "65%" }}
                                    onChange={(e) => { statusHandler(e.target.value, row.values); }} defaultValue={row.original.statusId}
                                  >
                                    <MenuItem value={row.original.statusId} style={{ fontSize: "13px" }}>
                                      {isStatusDropdownData.find(item => item.id === row.original.statusId)?.externalStatus}
                                    </MenuItem>
                                    {isStatusDropdownData
                                      .filter(item => item.id >= row.original.statusId)
                                      .slice(1, 2)
                                      // .filter(item => item.id !== statusDropdownId)
                                      .map((item, i) => (
                                        <MenuItem value={item.id} key={i} style={{ fontSize: "13px" }}>
                                          {isStatusValueField ? item.externalStatus : item.displayname}
                                        </MenuItem>
                                      ))}
                                  </Select></> : null}
                                  {cell.column.datatype == "isverifiedstatus" && (
                                    <td>
                                      {row.original.isVerified == true ? (
                                        <span className="active-badge"> verified</span>
                                      ) : (
                                        <span className="inactive-span">Pending</span>
                                      )}
                                    </td>
                                  )}
                                </td ></>
                            );
                          })}

                          {isAction ?
                            <td className="action">
                              <i className="fa fa-edit cursor-pointer" onClick={() => editHandler(row.values)}></i>&nbsp;&nbsp;&nbsp;
                              {showDeleteIcon == "No" ? null : <i className="fa fa-trash cursor-pointer" onClick={() => deleteHandler(row.values)}></i>}
                            </td>
                            :
                            null
                          }
                          {isForm ?
                            <td className="action-width">
                              <i className="fa fa-edit cursor-pointer" onClick={() => editHandler(row.values)}></i>&nbsp;&nbsp;&nbsp;
                              <i className="fa fa-trash cursor-pointer" onClick={() => deleteHandler(row.values)}></i>&nbsp;&nbsp;&nbsp;
                              <i className="fa fa-envelope cursor-pointer font-size-15" onClick={() => MailHandle(row.values)}></i>
                            </td>
                            :
                            null
                          }
                          {canEdit ?
                            <td className="action">
                              <i className="fa fa-edit cursor-pointer" onClick={() => editHandler(row.values)}></i>&nbsp;&nbsp;&nbsp;
                            </td>
                            :
                            null
                          }
                          {isDelete ?
                            <td className="action">
                              <i className="fa fa-trash cursor-pointer" onClick={() => deleteHandler(row.values)}></i>
                            </td>
                            :
                            null
                          }
                          {canView ?
                            <td className="action">
                              <i className="mdi mdi-eye-check cursor-pointer font-size-18" onClick={() => editHandler(row.values)}></i>&nbsp;&nbsp;&nbsp;
                            </td> : null}

                          {canAdd ?
                            <td className="action">
                              <i className="mdi mdi-plus cursor-pointer font-size-20 " onClick={() => editHandler(row.values)}></i>&nbsp;&nbsp;&nbsp;
                              <i className="mdi mdi-eye-check cursor-pointer font-size-18" onClick={() => viewHandler(row.values)}></i>
                            </td> : null}

                          {isDeleteAction ?
                            (row.values.active === true) &&
                            <td className="action">
                              <i className="fa fa-edit cursor-pointer" onClick={() => editHandler(row.values)}></i>&nbsp;&nbsp;&nbsp;
                              <i className="fa fa-trash cursor-pointer" onClick={() => deleteHandler(row.values)}></i></td>
                            :
                            null
                          }

                          {isDeleteAction ? (row.values.active === false &&
                            <td className="action"><i className="fa fa-edit cursor-pointer" onClick={() => editHandler(row.values)}></i></td>)
                            :
                            null}

                          {isVerifiedaction ?
                            (row.values.isVerifiedUser === 1) &&
                            <td className="action">
                              <i className="fa fa-edit cursor-pointer" onClick={() => editHandler(row.values)}></i>&nbsp;&nbsp;&nbsp;
                              <i className="fa fa-trash cursor-pointer" onClick={() => deleteHandler(row.values)}></i></td>
                            :
                            null
                          }

                          {isVerifiedaction ? (row.values.isVerifiedUser === 0 &&
                            <td className="action"><i className="fa fa-edit cursor-pointer" onClick={() => editHandler(row.values)}></i></td>)
                            :
                            null}

                          {(canDelete && !isCampainTarget) ? <td className="action"><i className="fa fa-trash cursor-pointer" onClick={() => deleteHandler(row.values)}></i></td> : null}

                          {isCampainTarget && canDelete ? <>
                            {row.original.statusName === "Planned" ? <td className="action"><i className="fa fa-trash cursor-pointer" onClick={() => deleteHandler(row.values)}></i></td>
                              : row.original.statusName === "Responded" ? <td className="action"><i className="mdi mdi-check-all fa-lg status-name" ></i></td>
                                : row.original.statusName === "Sent" ? <td className="action"><i className="mdi mdi-check fa-lg status-name" ></i></td> : null}</> : null}

                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody >
              </>
            ))}
          </Table>
          : <p className='fw-bold text-center fa-md' >No Record Found!</p>}
      </div>
      {
        isPagination && data.length > 0 && (
          <CustomPagination rowPerPageValue={rowPerPageValue} rowPerPageHandler={rowPerPageHandler} perPageData={perPageData} data={data} CurrentPageHandler={selectedPage} isShowingPageLength={isShowingPageLength} paginationDiv={undefined} paginationClass={"justify-content-center pagination pagination-rounded"} />
        )
      }
    </Fragment >
  );
};
export default CustomDataTable;
