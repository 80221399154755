import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import Breadcrumb from "Components/Common/Breadcrumb";
import CustomMenuSelectionCard from "Components/Shared/CustomMenuSelectionCard";
import MenuSelection from "./MenuSelection";
import { Loader } from "Components/Loader/Loader";
import { getTokenDetail } from "@helpers/helper";

const MenuSelectionDashBoard = () => {
    const [menuSelection, setMenuSelection] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [menuSelectionModel, setMenuSelectionModel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditId, setIsEditId] = useState<number>(0);
    const [dayId, setDayId] = useState<number>(0);
    const [loader, setLoader] = useState(true);

    let navigate = useNavigate();
    document.title = "Weekly Menu | Panda GoGoGo ";


    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            displayInList: false,
        }, {
            Header: 'Day',
            accessor: 'weekday'
        },
        {
            Header: 'Name',
            accessor: 'menuName',
            displayInList: true,
        }
    ], []);

    const getMenuSelectionBySearch = (pageNumber: any, searchText: string, sortorder?: string,) => {
        setLoader(true);
        let restaurentId = localStorage.getItem("restaurentId");
        let searchModel = {
            offsetStart: pageNumber,
            rowsPerPage: 9,
            sortOrder: sortorder ? sortorder : "Desc",
            searchText: searchText ? searchText : "",
        }
        CommonService.postWithSingleParam("WeeklyMenu", "GetWeeklyMenuByRestaurentIdSearch", "restaurentId", restaurentId, searchModel).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                setMenuSelection(data);
                setLoader(false);
            }
        })
    }

    const editHandler = (val: any) => {
        setDayId(val.id);
        setIsEdit(true);
        setIsEditId(val.id);
        setMenuSelectionModel(true);
    }

    const isCancel = () => {
        setIsEdit(false);
        setIsEditId(0);
        setDayId(0);
        setMenuSelectionModel(false);
        getMenuSelectionBySearch(1, "");
    }

    const pagination = (pageNumber: any) => {
        getMenuSelectionBySearch(pageNumber, search);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getMenuSelectionBySearch(1, searchKeyword);
    }

    const toggleChangeHandler = (weekDay, isHoliday) => {
        let restaurantId = localStorage.getItem("restaurentId");
        CommonService.putWithTribleQueryParam("WeeklyMenu", "UpdateWeeklyMenuIsHoliday", "weekDay", weekDay, "isHoliday", isHoliday,"restaurentId",restaurantId).then((res) => {
            if (res.status == 200) {
                getMenuSelectionBySearch(1, "");
            }
        })
    }


    useEffect(() => {
        getMenuSelectionBySearch(1, "")
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Weekly Menu" breadcrumbItem="Dashboard" breadcrumbTitle={""}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard"} />
                <Loader open={loader} />
                <CustomMenuSelectionCard
                    data={menuSelection}
                    canEdit={true}
                    iconClassName={""}
                    perPageData={9}
                    canToggle={true}
                    selectedPage={pagination}
                    onIconClick={editHandler}
                    paginationDiv={undefined}
                    toggleChange={toggleChangeHandler}
                    onSearch={searchHandler} />
            </div>
            <div className="container">
                <Modal isOpen={menuSelectionModel} size="lg" centered>
                    <div role='document'>
                        <div className='modal-content'>
                            <div className='modal-body m-3'>
                                <MenuSelection
                                    dayId={dayId}
                                    isModelOpen={true}
                                    canCancel={isCancel}
                                    isEditId={isEditId}
                                    isEdit={isEdit}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default MenuSelectionDashBoard;