//Author : Kavitha Sri

import React, { InputHTMLAttributes } from "react";
import { FormFeedback, Input, Label } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} name reference of the elements*/
    name?: any;
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} id defines the id of the DOM element */
    id: any,
    /** @param {any} className return the value of an element's class*/
    className?: any,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder?: any;
    /** @param {any} value is used to set or get the value for the input field*/
    value: any;
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} isMandatory is defines the if it's mandatory or not*/
    isMandatory?: any
}

export const CustomTextArea = React.forwardRef(({ name, placeholder, value, onChange, onBlur, invalid, error, label, id, disabled, isMandatory, ...rest }: inputProps, ref) => {

    return (
        <>
            <Label className="mt-3 mb-1">{label}</Label>
            {isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
            <Input ref={ref} {...rest as any}
                type="textarea"
                rows={2}
                disabled={disabled}
                className="form-control mb-1"
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                invalid={invalid}
                error={error} />
            <FormFeedback>{error}</FormFeedback>
        </>
    )
})