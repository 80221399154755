//Author : Kavitha Sri

import React, { InputHTMLAttributes } from "react";
import { Input, InputGroup, Label } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} error interferes with the proper operation of the program*/
    value: any;
    /** @param {any} isMandatory is defines the if it's mandatory or not*/
    isMandatory: boolean;
    /** @param {any} disabled is defines the field is displayed or not */
    disabled?: any;
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid?: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    touched: any
}

export const CustomAmount = React.forwardRef(({ error, label, value, isMandatory, disabled, onChange, onBlur, invalid, touched, ...rest }: inputProps, ref) => {

    return (
        <>
            <Label className="mt-3 mb-1">{label}</Label>
            {isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
            <InputGroup>
                <div className="input-group-text">$</div>
                <Input ref={ref} {...rest as any} type="number" value={value} disabled={disabled} onChange={onChange}
                    onBlur={onBlur}
                    invalid={invalid}
                    error={error}
                    touched={touched}
                />
            </InputGroup>
            {
                error ? (
                    <span className="text-danger fa-xs" >{error}</span>
                ) : null
            }
            {/* <FormFeedback>{error}double</FormFeedback> */}
        </>
    )
})