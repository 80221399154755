import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

const LocationChart = ({ data }: { data: Array<any> }) => {
    // const dataColors = dashboardChartColors;
    // const PieApexChartColors = getChartColorsArray(dataColors);
    const colors = [] as any;

    for (let i = 0; i < data.length; i++) {
        const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        colors.push(randomColor);
    }

    const [chartData, setChartData] = useState<any>({
        series: [],
        labels: [],
        colors: []
    });

    const options: any = {
        chart: {
            height: 320,
            type: 'pie',
        },
        labels: chartData.labels,
        colors: colors,
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 280
                },
                legend: {
                    show: true
                },
            }
        }]
    }

    const getLocationDetails = () => {
        const labels = data.map((item: any) => item.name);
        const series = data.map((item: any) => item.dataCount);
        setChartData({ series, labels, colors: colors });
    }

    useEffect(() => {
        getLocationDetails();
    }, [data])

    return (
        <div data-aos="fade-up">
            <ReactApexChart options={options} series={chartData.series} type="pie" height="320" />
        </div>
    )
}

export default LocationChart
