import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Form, Container, CardBody, Card, Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import CommonService from "Services/CommonService";
import CustomDataTable from "Components/Shared/CustomDataTable";
import { TablePagePerView } from "Components/constants/PandaConstants";
import { CustomButton } from "Components/Shared/CustomButton";

interface AddUserProps {
    isOpen: any;
    toggle: any;
    restaurentId: any;
    callBack: any;
    selectedUserIds?: any;
    filterUserIds?: any;
    isUserAvailable?: any
}

const AddUserPopup = ({ isOpen, toggle, restaurentId, callBack, selectedUserIds, filterUserIds, isUserAvailable }: AddUserProps) => {
    const [user, setUser] = useState<any>([]);
    const [search, setSearch] = useState("");
    let selectedIds: any = [];
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [rowPerPageValue, setRowPerPageValue] = useState(10);
    const [totalRowsCount, setTotalRowsCount] = useState<any>();
    const [isPageNumber, setIsPageNumber] = useState<any>();
    const [allSelected, setAllSelected] = useState(false);
    const columns = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            sortable: true,
            displayInList: false,
        },
        {
            Header: 'Id',
            datatype: "checkBox",
            displayInList: true,
        },
        {
            Header: 'Student Name',
            accessor: 'userName',
            Filter: true,
            sortable: true,
            width: '25%'
        },
        {
            Header: 'Contact',
            accessor: 'mobileNumber',
            Filter: true,
            sortable: true,
            width: '25%'
        },
        {
            Header: 'Email',
            accessor: 'email',
            Filter: true,
            sortable: true,
            width: '25%'
        },
        {
            Header: 'University Name',
            accessor: 'universityName',
            Filter: true,
            sortable: true,
            width: '25%'
        },

    ], []);

    const handleCheckboxChange = (checkedIds: any, unCheckIds) => {
        selectedIds.push(checkedIds);
        let updatedValues = [...selectedValues];
        updatedValues.push(checkedIds);
        let updatedArray = updatedValues.flat();
        updatedArray = updatedArray.filter(item => !unCheckIds.includes(item));
        selectedIds = [updatedArray];
        setSelectedValues(updatedArray);
    }

    // const handleAllCheckBoxChange = (checkedIds) => {
    //     
    //     selectedIds = [checkedIds];
    //     const uniqueIds = selectedIds.filter(id => !selectedValues.includes(id));
    //     let updatedValues = [...selectedValues, ...uniqueIds];
    //     // updatedValues = [...updatedValues, ...uniqueIds]
    //     setSelectedValues(updatedValues.flat());
    // }

    const handleAllCheckBoxChange = (checkedIds, checked) => {
        
        if (checked == true) {
            setAllSelected(true);
            // let selectedIds = Array.isArray(checkedIds) ? checkedIds : [checkedIds];
            // const uniqueIds = selectedIds.filter(id => !selectedValues.includes(id));
            // setSelectedValues(prevValues => [...prevValues, ...uniqueIds]);
            getAllExistingUser(1, "", "Desc", "", totalRowsCount,true);
        }
        else {
            setSelectedValues([]);
        }
    }


    const getAllExistingUser = (pageNumber, searchText, sortcolumn?: any, sortorder?: any, rowPerPageVal?: any,allDataSelected:any = false) => {
        let searchModel = {
            offsetStart: pageNumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "Desc",
            searchText: searchText ? searchText : "",
            sortColumn: sortcolumn ? sortcolumn : "",
            restaurentId: restaurentId.join(','),
            userIds: filterUserIds?.length > 0 ? filterUserIds.join(',') : null
        }
        CommonService.post("User", "GetAllExistingUser", searchModel).then((res => {
            if (res.status == 200) {
                if (!allDataSelected) {
                    setUser(res.data.responseData);
                    setTotalRowsCount(res.data.responseData[0]?.totalRowsCount);
                    setIsPageNumber(pageNumber);
                    if (res.data.responseData?.length == 0) {
                        isUserAvailable(false);
                    } else {
                        isUserAvailable(true);
                    }
                }
                if (allDataSelected) { 
                    const selectedIdValues = res.data.responseData.map((row) => row.id);
                    let selectedIds = Array.isArray(selectedIdValues) ? selectedIdValues : [selectedIdValues];
                    const uniqueIds = selectedIds.filter(id => !selectedValues.includes(id));
                    setSelectedValues(prevValues => [...prevValues, ...uniqueIds]);
                    setAllSelected(false);
                    return;
                }
            }
        })
        )
    }

    const handleSubmit = () => {
        callBack(selectedValues);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getAllExistingUser(1, searchKeyword);
    }

    const pagination = (pageNumber: any) => {
        getAllExistingUser(pageNumber, search);
    }

    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        getAllExistingUser(1, "", "Desc", "", val);
    }


    useEffect(() => {
        if (isOpen == true) {
            getAllExistingUser(1, "");
            setSelectedValues(selectedUserIds?.length > 0 ? selectedUserIds : [])
        }
    }, [isOpen])

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>
                    <div className="modal-title  h5" >
                        {"Select Users"}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="modal-body">
                        <CustomDataTable
                            rowPerPageValue={rowPerPageValue}
                            rowPerPageHandler={rowPerPageHandler}
                            data={user}
                            selectedValues={selectedValues}
                            customPageSize={10}
                            columns={columns}
                            isPagination={true}
                            isGlobalFilter={true}
                            selectedPage={pagination}
                            perPageData={TablePagePerView.Count}
                            searchHandler={searchHandler}
                            isAction={false}
                            editHandler={undefined}
                            deleteHandler={undefined}
                            canShowButton={false}
                            path={''}
                            // isSelect={true}
                            createHandler={undefined}
                            onAllCheckboxChange={handleAllCheckBoxChange}
                            onCheckboxChange={handleCheckboxChange} isShowingPageLength={true} />
                        <Row>
                            <div className="d-flex justify-content-end">
                                <CustomButton
                                    type="submit"
                                    btnClassName="btn-secondary ms-2"
                                    iconClasssName="mdi mdi-close"
                                    name="Cancel"
                                    onClick={toggle}
                                    disabled={false}
                                />
                                <CustomButton
                                    type="submit"
                                    btnClassName="btn-success loginbtn ms-2"
                                    iconClasssName="dripicons-checkmark"
                                    name="Submit"
                                    onClick={handleSubmit}
                                    disabled={false}
                                />
                            </div>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )

}

export default AddUserPopup;