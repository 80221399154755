//Author: KaniMurugan
import React, { InputHTMLAttributes, useState } from "react";
import { Card, Col, FormFeedback, Label } from "reactstrap";
import Select from "react-select";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} value specifies the value */
    value: any,
    /** @param {any} OnChange specifies the onChangeEvent */
    onChange: any,
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} options specifies the value of MultiSelect */
    options: any,
    /** @param {any} disabled is defines the if it's editable or not*/
    disabled?: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur?: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error?: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid?: any;
    /** @param {any} touched it takes boolean values as input and it sets to true if a field is clicked*/
    touched?: any,
    /** @param {any} valueField is defines the field value*/
    valueField?: any,
    /** @param {any} id is defines the id*/
    id?: any,
    /** @param {any} isMandatory is defines the if it's mandatory or not*/
    isMandatory?: boolean

}

export const CustomMultiSelect = React.forwardRef(({ label, id, name, placeholder, value, options, onChange, onBlur, error, touched, disabled, isMandatory, ...rest }: inputProps, ref) => {
    return (
        <>
            <Label className="mt-3 mb-2">{label}</Label>
            {isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
            <Select ref={ref} {...rest as any}
                value={value}
                isMulti={true}
                onChange={onChange}
                onBlur={onBlur}
                options={options}
                className="basic-multi-select mb-1"
                isDisabled={disabled}
                error={error}
                touched={touched}
            />
            {error ? (
                <span className="text-danger fa-xs">{error}</span> 
            ) : null
            }
        </>
    );
});

