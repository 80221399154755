import React from 'react'
import { Modal } from 'reactstrap'
import "./Loader.css"

type Props = {
    open: boolean
}

export function Loader({ open }: Props) {
    return (
        <Modal isOpen={open} centered size='sm' className='loading-modal'>
            <div className="clock-loader"></div>
        </Modal>
    )
}