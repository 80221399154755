import { DecodeParam, EncodeParam } from "../../helpers/helper";
import withRouter from "Components/Common/withRouter";
import { CustomAutoComplete } from "Components/Shared/CustomAutoComplete";
import { CustomDropdown } from "Components/Shared/CustomDropdown";
import { CustomEmailBox } from "Components/Shared/CustomEmailBox";
import { CustomImageUpload } from "Components/Shared/CustomImageUpload";
import { CustomPasswordBox } from "Components/Shared/CustomPasswordBox";
import { CustomPhoneNumberBox } from "Components/Shared/CustomPhoneNumberBox";
import { CustomTextBox } from "Components/Shared/CustomTextBox";
import { CustomToaster } from "Components/Shared/CustomToaster";
import { HelperConstant, Toaster_ENUM } from "Components/constants/PandaConstants";
import { IUserRestaurantCreationModel } from "Models/IUserRestaurantCreationModel";
import CommonService from "Services/CommonService";
import { useFormik } from "formik";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { forkJoin } from "rxjs";
import * as Yup from "yup";

type Props = {
    restaurantId: any,
    isCreate: any,
    canCancel: any,
    isModelOpen: any,
    isEditId: any,
    decryptedPassword: any
    isCreateMode: any
}

const UserRestaurantForm = ({ restaurantId, isCreate, canCancel, isModelOpen, isEditId, decryptedPassword, isCreateMode }: Props) => {
    const [countryMaster, setCountryMaster] = useState<any>([]);
    const [stateMaster, setStateMaster] = useState<any>([]);
    const [cityMaster, setCityMaster] = useState<any>([]);
    const [zipCodeMaster, setZipCodeMaster] = useState<any>([]);
    const [userRoleMaster, setUserRoleMaster] = useState<any>([]);
    const [toastCreate, setToastCreate] = useState(false);
    const [toastUpdate, setToastUpdate] = useState(false);
    const [profileImage, setProfileImage] = useState<any>();
    const [updateImage, setUpdateImage] = useState<any>();
    const [existingEmail, setExistingEmail] = useState("");
    const [existingMobile, setExistingMobile] = useState("");

    const [isToggle, setToggle] = useState<boolean>(true);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showActiveConfirmation, setShowActiveConfirmation] = useState(false);

    const [uploadedDocument, setUploadedDocument] = useState(null);
    const [isErr, setIsErr] = useState("");
    const [isGetUserRestaurantDetail, setIsUserRestaurantDetail] = useState<IUserRestaurantCreationModel>();
    const [toast, SetToast] = useState(false);
    const { id } = useParams();
    const isEdit = isModelOpen ? isEditId > 0 : !!id;

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
            password: '',
            confirmpassword: '',
            countryId: '1',
            stateId: '',
            cityId: '',
            zipId: '',
            userRoleId: ''
            // checkbox: termsChecked,
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please Enter Your First Name"),
            lastName: Yup.string().required("Please Enter Your Last Name"),
            email: Yup.string().required("Please Enter Your Email").matches(HelperConstant.emailPattern.pattern, 'Please Enter a Valid Email'),
            mobileNumber: Yup.string().required("Please Enter Your Mobile Number").matches(HelperConstant.phonenumberPattern.pattern, "Please Enter valid Mobile Number"),
            password: Yup.string().required("Please Enter Your Password"),
            confirmpassword: Yup.string().required("Please Enter Your Confirm Password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
            checkbox: Yup.string().notRequired(),
            // countryId: Yup.string().required("Please Select Your Country"),
            countryId: Yup.string(),
            stateId: Yup.string().required("Please Select Your State"),
            cityId: Yup.string().required("Please Select Your City"),
            zipId: Yup.string().required("Please Select Your ZipCode"),
            userRoleId: Yup.string().required("Please Select User Role"),
        }),
        onSubmit: (values: any) => {
            if (isEdit ? false : !uploadedDocument) {
                setIsErr("Please upload your Profile");
                return;
            }
            if (!isCreateMode && isEdit) {
                createUserRestaurant(values);
            } else {
                validateEmail(values);
            }
        }
    });

    const createUserRestaurant = (data: IUserRestaurantCreationModel) => {
        const formData = new FormData();
        formData.append("id", (!isCreateMode && isEdit) ? isGetUserRestaurantDetail?.id as any : 0)
        formData.append("loginId", (!isCreateMode && isEdit) ? isGetUserRestaurantDetail?.loginId as any : 0);
        formData.append("restaurentId", restaurantId);
        formData.append("userDetails.id", (!isCreateMode && isEdit) ? isGetUserRestaurantDetail?.userDetails.id as any : 0)
        formData.append("userDetails.firstName", validation.values.firstName);
        formData.append("userDetails.lastName", validation.values.lastName);
        formData.append("userDetails.email", validation.values.email);
        formData.append("userDetails.mobileNumber", validation.values.mobileNumber);
        formData.append("userDetails.UploadedFile", isEdit ? updateImage : uploadedDocument as any);
        formData.append("userDetails.UploadFilePath", null as any);
        formData.append("userDetails.profileImage", null as any);
        formData.append("userDetails.userLogin.id", (!isCreateMode && isEdit) ? isGetUserRestaurantDetail?.userDetails.userLogin.id as any : 0);
        formData.append("userDetails.userLogin.userName", validation.values.email);
        formData.append("userDetails.userLogin.password", EncodeParam(validation.values.password));
        formData.append("userDetails.userLogin.userRoleId", validation.values.userRoleId);
        // formData.append("userDetails.userLogin.isVerifiedUser", isToggle as any);
        formData.append("userDetails.userAddress.id", (!isCreateMode && isEdit) ? isGetUserRestaurantDetail?.userDetails.userAddress?.id as any : 0);
        formData.append("userDetails.userAddress.loginId", (!isCreateMode && isEdit) ? isGetUserRestaurantDetail?.userDetails.userAddress?.loginId as any : 0)
        formData.append("userDetails.userAddress.name", validation.values.firstName + validation.values.lastName);
        formData.append("userDetails.userAddress.countryId", validation.values.countryId);
        formData.append("userDetails.userAddress.stateId", validation.values.stateId);
        formData.append("userDetails.userAddress.cityId", validation.values.cityId);
        formData.append("userDetails.userAddress.zipId", validation.values.zipId);
        formData.append("userDetails.userAddress.isPrimaryAddress", true as any)

        CommonService.postWithFormData("UserRestaurent", (!isCreateMode && isEdit) ? "UpdateUserRestaurent" : "CreateUserRestaurent", formData).then(res => {
            if (res.status === 200) {
                SetToast(true);
                isCreate();
            }
        })
    }

    const base64toFile = (base64String, fileName, mimeType) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeType });

        // Create a File object from the Blob
        const file = new File([blob], fileName, { type: mimeType });
        return file;
    };


    const handleFileSelected = (fileName, fileSize, selectedFile) => {
        setIsErr("");
        setUploadedDocument(selectedFile);
        setUpdateImage(selectedFile);
    };
    const isCancel = () => {
        canCancel();
    }

    useEffect(() => {
        if (!isCreateMode && isEdit) {
            getUserRestaurantByLoginId();
        }

    }, [isCreateMode, isEdit]);

    const getStateByCountryId = (countryid: any) => {
        CommonService.getWithQueryParam("Masters", "GetStateByCountryId", "countryId", countryid).then(res => {
            if (res.status === 200) {
                setStateMaster(res.data.responseData.stateList);
            }
        });
    }

    const getCityByStateId = (stateid: any) => {
        CommonService.getWithQueryParam("Masters", "GetCityByStateId", "stateId", stateid).then(res => {
            if (res.status === 200) {
                setCityMaster(res.data.responseData.cityList);
            }
        });
    }

    const getZipCodeByCityId = (cityId: any) => {
        CommonService.getWithQueryParam("Masters", "GetZipCodeByCityId", "cityId", cityId).then(res => {
            if (res.status === 200) {
                setZipCodeMaster(res.data.responseData.zipCodeList);
            }
        });
    }

    const validateEmail = (values: any) => {
        CommonService.getWithSingleParam("User", "RegisteredUserName", values.email).then(res => {
            if (res.status === 200) {
                if (res.data == "You are already Registered Email.") {
                    return setExistingEmail(res.data);
                }
                else {
                    setExistingEmail("");
                    validatePhoneNumber(values);
                }
            }
        })
    }

    const validatePhoneNumber = (values: any) => {
        CommonService.getWithSingleParam("User", "RegisteredMobileNo", values.mobileNumber).then(res => {
            if (res.status === 200) {
                if (res.data == "You are already Registered MobileNo.") {
                    return setExistingMobile(res.data);
                }
                else {
                    setExistingMobile("");
                    createUserRestaurant(values);
                }
            }
        })
    }

    useEffect(() => {
        getStateByCountryId(1);
        let country = CommonService.get("Masters", "GetCountryMaster");
        // let state = CommonService.get("Masters", "GetStateMaster");
        // let city = CommonService.get("Masters", "GetCityMaster");
        // let zipcode = CommonService.get("Masters", "GetZipCodeMaster");
        let userRole = CommonService.get("Masters", "GetUserRoleMaster");

        forkJoin({ country, userRole }).subscribe((res: { country: any, userRole: any }) => {
            setCountryMaster(res.country.data.responseData.countryList);
            // setStateMaster(res.state.data.responseData.stateList);
            // setCityMaster(res.city.data.responseData.cityList);
            // setZipCodeMaster(res.zipcode.data.responseData.zipCodeList);
            const filteredLeadStatus = res.userRole.data.responseData.userRoleList?.filter(role => role.name !== "SuperAdmin" && role.name !== "User");
            setUserRoleMaster(filteredLeadStatus);
        });
    }, [])

    const getUserRestaurantByLoginId = () => {
        CommonService.getWithQueryParam("UserRestaurent", "GetUserRestaurentByLoginIdAsync", "loginId", isEditId).then(res => {
            if (res.status === 200) {
                let responseValue = res.data.responseData;
                setIsUserRestaurantDetail(res.data.responseData);
                CommonService.getWithSingleParam("Auth", "DecryptPassword", decryptedPassword).then(response => {
                    if (response.status === 200) {
                        responseValue.userDetails.userLogin.password = response.data as any;
                        CommonService.GetBase64ProfileImage("User", "GetBase64UserFile", "id", responseValue.userDetails.id).then(response => {
                            if (response.status === 200) {
                                let base64ImageString = `data:image/png;base64,${response.data}`;
                                setProfileImage(base64ImageString);
                                const base64ImageStringforFile = response.data;
                                const convertedfile = base64toFile(base64ImageStringforFile, responseValue.uploadFileName, "image/png");
                                setUpdateImage(convertedfile);
                            }
                        });

                        const defaultCountryId = res.data.responseData?.userDetails.userAddress.countryId;
                        const defaultStateId = res.data.responseData?.userDetails.userAddress.stateId;
                        const defaultCityId = res.data.responseData?.userDetails.userAddress.cityId;
                        const defaultZipId = res.data.responseData?.userDetails.userAddress.zipId;
                        validation.setValues({
                            ...validation.values,
                            countryId: defaultCountryId,
                            stateId: defaultStateId,
                            cityId: defaultCityId,
                            zipId: defaultZipId
                        });

                        // Fetch and set state, city, and zip code based on default country
                        getStateByCountryId(defaultCountryId);
                        getCityByStateId(defaultStateId);
                        getZipCodeByCityId(defaultCityId);

                        if (res.data.responseData.userDetails?.userLogin?.isVerifiedUser == false) {
                            setToggle(!isToggle);
                        }
                        else {
                            setToggle(isToggle)
                        }

                        validation.setValues({
                            ...responseValue,
                            firstName: responseValue.userDetails.firstName,
                            lastName: responseValue.userDetails.lastName,
                            email: responseValue.userDetails.email,
                            mobileNumber: responseValue.userDetails.mobileNumber,
                            countryId: responseValue.userDetails.userAddress.countryId.toString(),
                            stateId: responseValue.userDetails.userAddress.stateId.toString(),
                            cityId: responseValue.userDetails.userAddress.cityId.toString(),
                            zipId: responseValue.userDetails.userAddress.zipId.toString(),
                            userRoleId: responseValue.userDetails.userLogin.userRoleId.toString(),
                            password: response.data.toString(),
                            confirmpassword: response.data.toString(),
                            // uploadedFile: profileImage,
                        });
                    }
                });
            }
        });
    }

    const onDeleteRestaurantUser = () => {
        setShowDeleteConfirmation(false);
        CommonService.putWithQueryParam("UserRestaurent", "GetRestaurantAdminInactive", "loginId", isGetUserRestaurantDetail?.loginId)
            .then(res => {
                if (res.data.responseData === true) {
                    SetToast(true);
                    isCreate();
                }
                else {
                    console.error("Delete failed with an unexpected response:", res);
                }
            })
            .catch(e => console.log(e));
    }

    const onActiveRestaurantUser = () => {
        setShowActiveConfirmation(false);
        CommonService.putWithQueryParam("UserRestaurent", "GetRestaurantAdminActive", "loginId", isGetUserRestaurantDetail?.loginId)
            .then(res => {
                if (res.data.responseData === true) {
                    SetToast(true);
                    isCreate();
                }
                else {
                    console.error("Delete failed with an unexpected response:", res);
                }
            })
            .catch(e => console.log(e));
    }

    const cancelDelete = () => {
        setShowDeleteConfirmation(false);
        setToggle(!isToggle);
    }

    const cancelActive = () => {
        setShowActiveConfirmation(false);
        setToggle(!isToggle);
    }

    const onToggleChange = () => {
        setToggle(!isToggle);
        if (!isToggle) {
            setShowActiveConfirmation(true);
        }
        else {
            setShowDeleteConfirmation(true);
        }

    }

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <p className="header-text-grid fw-bold fa-lg">{(!isCreateMode && isEdit) ? "Update User" : "Create User"}</p>
                <div onClick={() => isCancel()} className="btn-close position-absolute end-0 top-0 m-3" ></div>
                <Form onSubmit={validation.handleSubmit}>
                    <Row>

                        <Col lg={6}>
                            <CustomTextBox id="firstName"
                                placeholder="Enter First Name" name="firstName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName}
                                invalid={validation.touched.firstName && validation.errors.firstName ? true : false}
                                label="First Name" error={validation.errors.firstName} isMandatory={true} />

                            <CustomTextBox id="lastName"
                                placeholder="Enter Last Name" name="lastName"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName}
                                invalid={validation.touched.lastName && validation.errors.lastName ? true : false}
                                label="Last Name" error={validation.errors.lastName} isMandatory={true} />
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={3}>
                                    {(!isCreateMode && isEdit) &&
                                        <Col xxl={3} lg={6} >
                                            <div className="form-check form-switch mt-3"   >
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="toggle"
                                                    checked={isToggle}
                                                    onChange={() => onToggleChange()}
                                                />
                                                <label className="form-check-label">{isToggle ? "Active" : "InActive"}</label>
                                            </div>
                                        </Col>}
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-1">
                                        <CustomImageUpload
                                            customClass={isErr ? "custom-err" : ""}
                                            placeholder={"Drop your image here"}
                                            label={"User Image"}
                                            id={"profileimage"}
                                            value={undefined}
                                            isMandatory={true}
                                            isProfileUpload={true}
                                            error={isErr}
                                            onFileSelected={handleFileSelected} isEditForm={isEdit} isEditPageImage={profileImage} />
                                    </div>
                                </Col>
                                <Col lg={3}></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="row-adjustment">
                        <Col lg={6}>
                            <CustomEmailBox label="Email" name="email" id="email" placeholder="Enter Email" value={validation.values.email}
                                onChange={(e) => { validation.handleChange(e); setExistingEmail("") }} onBlur={validation.handleBlur} invalid={validation.errors.email && validation.touched.email ? true : false}
                                error={validation.errors.email} isMandatory={true} emailvalidationError={existingEmail} />
                        </Col>
                        <Col lg={6}>
                            <CustomPhoneNumberBox label="Mobile Number" id="mobileNumber" placeholder="Enter Mobile Number" name="mobileNumber"
                                value={validation.values.mobileNumber} error={validation.errors.mobileNumber}
                                onChange={(e) => { validation.handleChange(e); setExistingMobile("") }} onBlur={validation.handleBlur} touched={validation.touched.mobileNumber}
                                invalid={validation.errors.mobileNumber && validation.touched.mobileNumber ? true : false} isMandatory={true} phoneValidationError={existingMobile} />
                        </Col>
                        {/* <Col lg={6}><CustomDropdown label="Country" id="countryId" value={validation.values.countryId} data={countryMaster}
                            error={validation.errors.countryId} valueField="id" displayField='displayName' onChange={(e) => { validation.handleChange(e); getStateByCountryId(e.target.value) }}
                            onBlur={validation.handleBlur} invalid={validation.touched.countryId && validation.errors.countryId ? true : false}
                            touched={validation.touched.countryId} isMandatory={true} /></Col> */}

                        <Col lg={6}>
                            {(stateMaster?.length > 0) &&
                                <CustomAutoComplete
                                    label="State" id="stateId" data={stateMaster}
                                    onChange={(e, v) => { validation.setFieldValue("stateId", v.id); getCityByStateId(v?.id); }}
                                    placeholder="State"
                                    name="stateId"
                                    isMandatory={true}
                                    error={validation.errors.stateId} valueField="id" displayField='displayName'
                                    defaultValue={validation.values.stateId} touched={validation.touched.stateId} />
                            }
                        </Col>

                        <Col lg={6}>
                            {((!isCreateMode && isEdit) ? (stateMaster?.length > 0 && cityMaster?.length > 0) : (stateMaster?.length > 0 || cityMaster?.length > 0)) &&
                                <CustomAutoComplete
                                    label="City" id="cityId" data={cityMaster}
                                    onChange={(e, v) => { validation.setFieldValue("cityId", v.id); getZipCodeByCityId(v?.id); }}
                                    placeholder="State"
                                    name="cityId"
                                    isMandatory={true}
                                    error={validation.errors.cityId} valueField="id" displayField='displayName'
                                    defaultValue={validation.values.cityId} touched={validation.touched.cityId} />
                            }
                        </Col>
                        <Col lg={6}>
                            {((!isCreateMode && isEdit) ? (cityMaster?.length > 0 && zipCodeMaster?.length > 0) : (stateMaster?.length > 0 || zipCodeMaster?.length > 0)) &&
                                <CustomAutoComplete
                                    label="ZipCode" id="zipId" data={zipCodeMaster}
                                    onChange={(e, v) => { validation.setFieldValue("zipId", v.id); }}
                                    placeholder="ZipCode"
                                    name="zipId"
                                    isMandatory={true}
                                    error={validation.errors.zipId} valueField="id" displayField='displayName'
                                    defaultValue={validation.values.zipId} touched={validation.touched.zipId} />
                            }
                        </Col>
                        <Col lg={6}>
                            <CustomDropdown label="User Role" id="userRoleId" value={validation.values.userRoleId} data={userRoleMaster}
                                error={validation.errors.userRoleId} valueField="id" displayField='displayName' onChange={validation.handleChange}
                                onBlur={validation.handleBlur} invalid={validation.touched.userRoleId && validation.errors.userRoleId ? true : false}
                                touched={validation.touched.userRoleId} isMandatory={true} />
                        </Col>
                        <Col lg={6}>
                            <CustomPasswordBox
                                label="Password"
                                placeholder="Enter Password"
                                id="password"
                                value={validation.values.password}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                error={validation.errors.password}
                                touched={validation.touched.password}
                                invalid={validation.errors.password && validation.touched.password ? true : false}
                                isMandatory={true}
                            />
                        </Col>
                        <Col lg={6}>
                            <CustomPasswordBox
                                name="confirmpassword"
                                label="Confirm Password"
                                placeholder="Enter Confirm Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmpassword}
                                touched={validation.touched.confirmpassword}
                                invalid={validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false}
                                error={validation.errors.confirmpassword}
                                isMandatory={true} />
                        </Col>
                    </Row>
                    {/* <div className="mt-4 text-center">
                        <CustomCheckBox
                            checked={termsChecked}
                            id="checkbox"
                            value={termsChecked}
                            error={""}
                            onChange={() => setTermsChecked(!termsChecked)}
                            defaultChecked={false}
                            touched={""}
                            label={<div> <p className="mb-0"> I agree to the{" "} <Link to="#" className="fw-medium text-primary">Terms and Conditions </Link></p> </div>} /></div> */}
                    <Row>
                        <div className="mt-4 d-grid">
                            <Col className="text-end">
                                <button className="user-cancel-button m-3" type="button" onClick={() => isCancel()}>
                                    Cancel
                                </button>
                                <button
                                    className="loginbtn w-md"
                                    type="submit"
                                >{(!isCreateMode && isEdit) ? "Update User" : "Create User"}</button>
                            </Col>
                        </div>
                    </Row>
                </Form>
            </div>
            <div className="container">
                <Modal isOpen={showDeleteConfirmation} toggle={cancelDelete}>
                    <ModalHeader toggle={cancelDelete}>Confirm Delete</ModalHeader>
                    <ModalBody>
                        Are you sure you want to Delete the User?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={cancelDelete}>No</Button>
                        <Button color="danger" onClick={onDeleteRestaurantUser}>Yes</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
            <div className="container">
                <Modal isOpen={showActiveConfirmation} toggle={cancelActive}>
                    <ModalHeader toggle={cancelActive}>Confirm Activation</ModalHeader>
                    <ModalBody>
                        Are you sure you want to Activate the User?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={cancelActive}>No</Button>
                        <Button color="danger" className="btn bg-success" onClick={onActiveRestaurantUser}>Yes</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
            <CustomToaster message={(!isCreateMode && isEdit) ? Toaster_ENUM.edit.text : Toaster_ENUM.create.text} color={"text-success"}
                toast={(!isCreateMode && isEdit) ? toastUpdate : toastCreate}
                setToast={(!isCreateMode && isEdit) ? setToastUpdate : setToastCreate} path={""} />
        </React.Fragment>
    )
}

export default withRouter(UserRestaurantForm);