import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";

import { withTranslation } from "react-i18next";
import withRouter from "../../Common/withRouter";
import { createSelector } from 'reselect';

import { useSelector } from "react-redux";
import { ITokenModel } from "Models/ITokenModel";
import { jwtDecode } from 'jwt-decode';

import "./ProfileMenu.css";
import ProfileForm from "Components/ProfileForm/ProfileForm";

const ProfileMenu = (props: any) => {
  const [menu, setMenu] = useState(false);
  const [userModel, setUserModel] = useState(false);
  const [isLoginId, setIsLoginId] = useState<any>();
  const [username, setusername] = useState("");
  const profile = (state: any) => state.Profile;
  const selectProfileProperties = createSelector(
    profile,
    (success) => success,
  );

  const { success } = useSelector(selectProfileProperties);

  const modelcreate = () => {
    setUserModel(false);
  }

  const isCancel = () => {
    setUserModel(false);
  };

  const handleProfileClick = () => {
    setUserModel(true);
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      let token: string = localStorage.getItem("authUser") as string;
      let tokendata: ITokenModel = jwtDecode(token);
      let email = tokendata?.Email;
      let name = email?.split('@')[0];
      let loginid = tokendata?.LoginId;
      setIsLoginId(loginid);
      setusername(name);

      // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      //   const obj = JSON.parse(localStorage.getItem("authUser") || "");
      //   setusername(obj.displayName);
      // } else if (
      //   process.env.REACT_APP_DEFAULTAUTH === "fake" ||
      //   process.env.REACT_APP_DEFAULTAUTH === "jwt"
      // ) {
      //   const obj = JSON.parse(localStorage.getItem("authUser") || "");
      //   setusername(obj.username);
      // }
    }
  }, [success]);

  const onLogoutClicked = () => {
    let remember = localStorage.getItem("rememberme");
    if (remember) {
      localStorage.removeItem("authUser");
    }
    else {
      window.localStorage.clear();
    }
    window.location.href = "/login";
  }

  return (
    <React.Fragment>
      <div className='custom-nav-lg right'>
        <button
          id="PopoverFocus"
          type="button"
          className="header-button d-none d-xl-inline-block ms-2 me-1 admin-text"
        >
          {username}
        </button>
        <UncontrolledPopover
          placement="bottom"
          target="PopoverFocus"
          trigger="legacy"
        >
          <PopoverBody>
            <div className="dropdown-item mb-2 d-flex cursor-pointer" onClick={() => { setUserModel(true) }}>
              <i className="bx bx-user font-size-20 align-middle me-1 text-danger" />
              <p className=' font-size-13' >Profile</p>
            </div>
            <div className="dropdown-item d-flex">
              <i className="bx bx-power-off font-size-20 align-middle me-1 text-danger" />
              <p className='cursor-pointer font-size-13' onClick={() => {
                onLogoutClicked()
              }}>logout</p>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      </div>

      <div className='custom-nav-sm' style={{ height: "65px" }}>
        <Button
          id="PopoverFocuss"
          type="button"
          className='profile-icon-header-admin'
        >
          {username.slice(0, 1)}
        </Button>
        <UncontrolledPopover
          placement="bottom"
          target="PopoverFocuss"
          trigger="legacy"
        >
          <PopoverBody>
            <div className="dropdown-item mb-2 d-flex cursor-pointer" onClick={() => { setUserModel(true) }}>
              <i className="bx bx-user font-size-20 align-middle me-1 text-danger" />
              <p className=' font-size-13' >Profile</p>
            </div>
            <div className="dropdown-item d-flex">
              <i className="bx bx-power-off font-size-20 align-middle me-1 text-danger" />
              <p className='cursor-pointer font-size-13' onClick={() => {
                onLogoutClicked()
              }}>logout</p>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      </div>
      <Modal isOpen={userModel} size="lg" centered>
        <div role='document'>
          <div className='modal-content'>
            <div className='modal-body m-3'>
              <ProfileForm paramid={isLoginId} canCancel={isCancel} isCreate={modelcreate} />
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));