import { CustomButton } from "Components/Shared/CustomButton";
import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "reactstrap";
import Breadcrumb from "Components/Common/Breadcrumb";
import { EncodeParam, getTokenDetail } from "../../helpers/helper";
import { Role } from "Components/constants/PandaConstants";
import CustomViewCard from "Components/Shared/CustomViewCard";
import { Loader } from "Components/Loader/Loader";

const MenuDashBoard = () => {
    const [menu, setMenu] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(true);

    let navigate = useNavigate();
    document.title = "Menu | Panda GoGoGo ";


    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "20%"
        }, {
            Header: 'Name',
            accessor: 'displayName',
            width: "60%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Cost',
            accessor: 'amount',
            width: "45%",
            displayInList: true,
            Filter: true,
        }
    ], []);

    const onAddMenuClick = () => {
        navigate('/menu/create');
    }

    const getMenuBySearch = (pageNumber: any, searchText: string, sortorder?: string,) => {
        setLoader(true);
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        let restaurentId = localStorage.getItem("restaurentId");
        let searchModel = {
            offsetStart: pageNumber,
            rowsPerPage: 10,
            sortOrder: sortorder ? sortorder : "Desc",
            searchText: searchText ? searchText : "",
        }
        CommonService.postWithSingleParam("Menu", "GetMenuBySearch", "restaurentId", roleIdValue == Role.Admin ? restaurentId : "", searchModel).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                setMenu(data)
                setLoader(false);
            }
        })
    }

    const editHandler = (val: any) => {
        let encodedId = EncodeParam(val.id)
        navigate(`/menu/edit/${encodedId}`)
    }

    const pagination = (pageNumber: any) => {
        getMenuBySearch(pageNumber, search);
    }
 
    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getMenuBySearch(1, searchKeyword);
    }

    useEffect(() => {
        getMenuBySearch(1, "")
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Menu" breadcrumbItem="Dashboard" breadcrumbTitle={""}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard"} />
                <div className="mt-0 d-grid">
                    <Col className="text-end" style={{ marginTop: "-20px", marginBottom: "10px" }}>
                        <CustomButton type="button" btnClassName="btn btn-success w-md" iconClasssName="fa fa-plus" name="Create" onClick={() => onAddMenuClick()} />
                    </Col>
                </div>
                <Loader open={loader} />
                <CustomViewCard
                    data={menu}
                    canEdit={true}
                    iconClassName={""}
                    perPageData={10}
                    selectedPage={pagination}
                    paginationDiv={undefined}
                    onIconClick={editHandler}
                    onSearch={searchHandler}>
                </CustomViewCard>
            </div>
        </React.Fragment>
    );
}

export default MenuDashBoard;