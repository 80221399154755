import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from 'reactstrap';
import { CustomNumberOnlyBox } from "./CustomNumberOnlyBox";

interface inputProps {
    /**@param {any} type defines which type of input control to display */
    type?: any;
    /** @param {any} name reference of the elements*/
    name?: any;
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} id defines the id of the DOM element */
    id: any,
    /** @param {any} className return the value of an element's class*/
    className?: any,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder?: any;
    /** @param {any} value is used to set or get the value for the input field*/
    value: any;
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: (selectedValues: Array<any>) => void;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur?: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} touched it takes boolean values as input and it sets to true if a field is clicked*/
    touched?: any,
    data: Array<any>;
    selectedValues: Array<any>;
    minValues?: number;
    maxValues?: any;
    /** @param {any} isHorizontal defines the direction of the data arrangement */
    isHorizontal?: any;
    /** @param {any} valueField is defines the field value*/
    valueField: any,
    /** @param {any} displayField is defines the field display*/
    displayField: string,
    disabled: boolean,
    /*** @param {any} restrictedLength defines the length after which the check box should be disabled */
    restrictedLength?: any,
    isCount?: any,
    isWeeklyCount?: any
    handleChange?: any
    setIsCount?: any
    inputValue?: any
}

export const CustomMultiCheckbox = React.forwardRef(({ data, disabled, selectedValues, onChange, className, label, id, onBlur, invalid, error, touched, minValues = 0, maxValues = data.length, isHorizontal, displayField, valueField, restrictedLength, isCount, isWeeklyCount, handleChange, setIsCount, inputValue }: inputProps, ref) => {
    const [checkedCount, setCheckedCount] = useState<any>([]);
    const [localIsCount, setLocalIsCount] = useState<any>([]);

    useEffect(() => {
        setCheckedCount(selectedValues);
        setLocalIsCount(new Array(data.length).fill(0));
        if (isCount) {
            setLocalIsCount(isCount);
        }
    }, [data, isCount]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
        const isChecked = event.target.checked;
        let updatedValues;
        if (isChecked) {
            updatedValues = [...selectedValues, value];
            setCheckedCount(updatedValues);
            setLocalIsCount(prevState => [...prevState, '']);
            if (handleChange) {
                handleChange([...localIsCount, '']);
            }
        } else {
            updatedValues = selectedValues.filter((val) => val !== value);
            setCheckedCount(updatedValues);
            const index = selectedValues.indexOf(value);
            if (index !== -1) {
                let counts = [...isCount];
                let updatednewCounts = counts.filter((_, i) => i !== index);
                handleChange(updatednewCounts);
                setLocalIsCount(prevState => {
                    const newState = [...prevState];
                    let newStateValues = newState.filter((_, i) => i !== index); // Remove count entry for unchecked item
                    return newStateValues;
                });
            }
        }
        onChange(updatedValues);
    };

    // const handleQuantityChange = (index: any, quantity: any) => {
    //     if (quantity !== "" && quantity !== null && quantity >= 0) {
    //         // let newCount = [...isCount];
    //         // const selectedItem = data[index];
    //         // const selectedIndex = selectedValues.indexOf(selectedItem[valueField]);
    //         // newCount[selectedIndex] = parseInt(quantity);
    //         // setIsCount(newCount);
    //         // if (handleChange) {
    //         //     handleChange(newCount);
    //         // }
    //         const selectedItem = data[index];
    //         const selectedIndex = selectedValues.indexOf(selectedItem[valueField]);
    //         const newCounts = [...localIsCount];
    //         newCounts[selectedIndex] = parseInt(quantity);
    //         setLocalIsCount(newCounts);
    //         if (handleChange) {
    //             handleChange(newCounts);
    //         }
    //     }
    // };

    const handleQuantityChange = (index: number, quantity: number | string) => {
        const newQuantity = typeof quantity === "string" && quantity !== "" ? parseInt(quantity, 10) : 0;
        const selectedItem = data[index];
        const selectedIndex = selectedValues.indexOf(selectedItem[valueField]);
        if (newQuantity === 0 || localIsCount[selectedIndex] === 0) {
            const newCounts = [...localIsCount];
            newCounts[selectedIndex] = '';
            setLocalIsCount(newCounts);
            if (handleChange) {
                handleChange(newCounts);
            }
        } else if (!isNaN(newQuantity as number) && newQuantity as number >= 0) {
            const newCounts = [...localIsCount];
            newCounts[selectedIndex] = newQuantity as number;
            setLocalIsCount(newCounts);
            if (handleChange) {
                handleChange(newCounts);
            }
        }
    };

    const isValid = selectedValues.length >= minValues && selectedValues.length <= maxValues;

    useEffect(() => {
        setCheckedCount(selectedValues);
    }, [])

    return (
        <>
            {isHorizontal ? (
                <><Label check>{label}</Label><FormGroup className={className} row>
                    {data.map((item, index) => (
                        <div key={index} className="col-md-4 mt-3 registration-custom-checkbox">
                            <FormGroup check>
                                <Input
                                    type="checkbox"
                                    checked={selectedValues.includes(item[valueField])}
                                    onChange={(event) => handleCheckboxChange(event, item[valueField])}
                                    onBlur={onBlur}
                                    disabled={restrictedLength > 0 ? checkedCount.length >= restrictedLength && !selectedValues.includes(item[valueField]) : disabled}
                                    invalid={!isValid && touched}
                                    error={error}
                                    id={`${id}-${index}`} />
                                {item[displayField]}
                            </FormGroup>
                        </div>
                    ))}
                </FormGroup></>) : (<><Label check>{label}</Label><FormGroup className={className}>
                    {data.map((item, index) => (
                
                        <FormGroup check key={index} className="registration-custom-checkbox" style={{ marginTop: '15px', display: "flex" }} >
                            <Input
                                type="checkbox"
                                style={{ marginRight: "10px" }}
                                checked={selectedValues.includes(item[valueField])}
                                onChange={(event) => handleCheckboxChange(event, item[valueField])}
                                onBlur={onBlur}
                                disabled={restrictedLength > 0 ? checkedCount.length >= restrictedLength && !selectedValues.includes(item[valueField]) : disabled}
                                invalid={!isValid && touched}
                                error={error}
                                id={`${id}-${index}`} />
                            <span>{item[displayField]}</span>
                            {
                                isWeeklyCount && <>
                                    {selectedValues.includes(item[valueField]) &&
                                        <>
                                            <div className="count d-flex">
                                                {/* <i className={`fa fa-minus ${isCount[index] <= 1 && 'disabled-i'}`} onClick={() => handleQuantityChange(index, isCount[index] - 1)}></i> */}
                                                {/* <input type="number" style={{ width: '90px', height: '25px', fontSize: '12px', marginLeft: "20px" }} value={inputValue[selectedValues.indexOf(item[valueField])]}

                                                    onChange={(event) => handleQuantityChange(index, event.target.value)}
                                                    name={`isCount[${index}]`}
                                                    id={`${id}-${index}`}
                                                // error={formik.touched.pickupAddress && formik.touched.pickupAddress[index] && formik.errors.pickupAddress && formik.errors.pickupAddress[index] ? (formik.errors.pickupAddress[index] as any).name : undefined}
                                                // label="Location Name"
                                                /> */}
                                                <CustomNumberOnlyBox
                                                    style={{ width: '90px', height: '25px', fontSize: '12px', marginLeft: "20px" }} value={inputValue[selectedValues.indexOf(item[valueField])]}
                                                    onChange={(event) => handleQuantityChange(index, event.target.value)}
                                                    name={`isCount[${index}]`}
                                                    id={`${id}-${index}`}
                                                    onBlur={undefined}
                                                    placeholder={"Count"}
                                                    error={undefined} label={undefined} />
                                                <span style={{ color: 'red', marginLeft: '5px', marginTop: '-5px' }}> *</span>
                                                {/* <i className={`fa fa-plus`} onClick={() => handleQuantityChange(index, isCount[index] + 1)}></i> */}
                                            </div>
                                        </>
                                    }

                                </>
                            }
                        </FormGroup>
                    ))}
                </FormGroup></>)}
            {error && !isValid ? (
                <span className="text-danger fa-xs">{`${error} (Min: ${minValues}, Max: ${maxValues})`}</span>
            ) : null
            }
        </>
    );
}
);
