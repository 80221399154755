//Author : Kavitha Sri

import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { InputHTMLAttributes, useState } from "react";
import { Label } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} name reference of the elements*/
    name?: any;
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} id defines the id of the DOM element */
    id?: any,
    /** @param {any} className return the value of an element's class*/
    className?: any,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder?: any;
    /** @param {any} value is used to set or get the value for the input field*/
    value: any;
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid?: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} isMandatory is defines the if it's mandatory or not*/
    isMandatory?: boolean;
    touched?: any
}

export const CustomPasswordBox = React.forwardRef(({ name, placeholder, value, onChange, onBlur, invalid, error, label, isMandatory, id, touched, ...rest }: inputProps, ref) => {
    const [isPasswordSecure, setIsPasswordSecure] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleClickShowPassword = () => setIsPasswordSecure((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <>
            <Label className="mt-3 mb-1">{label}</Label>
            {isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
            <TextField
                ref={ref}
                {...rest as any}
                variant="outlined"
                fullWidth
                type={!isPasswordSecure ? 'password' : 'text'}
                id={id}
                name={name}
                touched={touched}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                invalid={invalid}
                error={error}
                className={`form-control text-field ${(error && touched) ? "error-border" : null} mb-1`}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {(error && touched) ?
                                <i style={{ color: "#f57777", fontSize: "large" }} className="mdi mdi-alert-circle-outline"></i>
                                :
                                null
                            }
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {isPasswordSecure ?
                                    <i className="fas fa-eye" style={{ fontSize: "15px", color: "black" }}></i>
                                    :
                                    <i className="fas fa-eye-slash" style={{ fontSize: "15px", color: "black" }}></i>
                                }
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            {/* <FormFeedback>{error}</FormFeedback> */}

            {(error && touched) ? (
                <span className="text-danger fa-xs">{error}</span>
            ) : null
            }
        </>
    )
})