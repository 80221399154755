import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomTextBox } from "Components/Shared/CustomTextBox";
import CommonService from "Services/CommonService";
import { CustomDropdown } from "Components/Shared/CustomDropdown";
import { forkJoin } from "rxjs";
import { IUniversityModel } from "Models/IUniversityModel";

type Props = {
    canCancel: any,
    isModelOpen: any,
    isEditId: any,
    isEdit: boolean
}

const UniversityMaster = ({ isEditId, isModelOpen, canCancel, isEdit }: Props) => {
    const [toast, SetToast] = useState(false);
    const [countryMaster, setCountryMaster] = useState<any>([]);
    const [stateMaster, setStateMaster] = useState<any>([]);
    const [cityMaster, setCityMaster] = useState<any>([]);
    const [zipCodeMaster, setZipCodeMaster] = useState<any>([]);
    const [isToggle, setToggle] = useState<boolean>(true);
    const [existingName, setExistingName] = useState("");

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            address: '',
            countryId: '1',
            stateId: '',
            cityId: '',
            zipId: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter University  Name").max(100, "Univerity name should not exceed 100 characters"),
            address: Yup.string().required("Please Enter Your Address").max(100, "Street Name should not exceed 100 characters"),
            // countryId: Yup.string().required("Please Select Your Country"),
            countryId: Yup.string(),
            stateId: Yup.string().required("Please Select Your State"),
            cityId: Yup.string().required("Please Select Your City"),
            zipId: Yup.string().required("Please Select Your ZipCode"),
        }),
        onSubmit: (values: any) => {
            if (isEdit) {
                submitUniversityCreation(values);
            }
            else {
                validateUniversityName(values);
            }
        }
    });


    const submitUniversityCreation = (values: IUniversityModel) => {
        values.id = isEditId;
        values.displayName = values.name;
        values.enumName = values.name.replaceAll(' ', '_');
        values.active = isToggle;
        CommonService.post("University", isEdit ? "UpdateUniversity" : "CreateUniversity", values).then(res => {
            if (res.status === 200) {
                canCancel();
            }
        });
    }
    const isCancel = () => {
        canCancel();
    }

    const getStateByCountryId = (countryid: any) => {
        CommonService.getWithQueryParam("Masters", "GetStateByCountryId", "countryId", countryid).then(res => {
            if (res.status === 200) {
                setStateMaster(res.data.responseData.stateList);
            }
        });
    }

    const getCityByStateId = (stateid: any) => {
        CommonService.getWithQueryParam("Masters", "GetCityByStateId", "stateId", stateid).then(res => {
            if (res.status === 200) {
                setCityMaster(res.data.responseData.cityList);
            }
        });
    }

    const getZipCodeByCityId = (cityId: any) => {
        CommonService.getWithQueryParam("Masters", "GetZipCodeByCityId", "cityId", cityId).then(res => {
            if (res.status === 200) {
                setZipCodeMaster(res.data.responseData.zipCodeList);
            }
        });
    }

    const validateUniversityName = (values: any) => {
        CommonService.getWithQueryParam("University", "GetValidateUniversityName", "name", values.name).then(res => {
            if (res.status === 200) {
                if (res.data == "Already Exist UniversityName") {
                    return setExistingName(res.data);
                }
                else {
                    setExistingName("");
                    submitUniversityCreation(values);
                    canCancel();
                }
            }
        })
    }

    const getUniversityById = () => {
        let restaurentId = localStorage.getItem("restaurentId");
        CommonService.getWithQueryParam("University", "GetUniversityById", "id", isEditId).then((res) => {
            if (res.status == 200) {
                validation.setFieldValue("name", res.data.responseData.name);
                validation.setFieldValue("address", res.data.responseData.address);
                validation.setFieldValue("countryId", res.data.responseData.countryId);
                validation.setFieldValue("stateId", res.data.responseData.stateId);
                validation.setFieldValue("cityId", res.data.responseData.cityId);
                validation.setFieldValue("zipId", res.data.responseData.zipId);

                getStateByCountryId(1);
                getCityByStateId(res.data.responseData?.stateId);
                getZipCodeByCityId(res.data.responseData?.cityId);
                if (res.data.responseData.active == false) {
                    setToggle(!isToggle);
                }
                else {
                    setToggle(isToggle)
                }
            }
        })
    }

    useEffect(() => {
        let country = CommonService.get("Masters", "GetCountryMaster");
        getStateByCountryId(1);

        forkJoin({ country }).subscribe((res: { country: any }) => {
            setCountryMaster(res.country.data.responseData.countryList);
        })
    }, []);

    useEffect(() => {
        if (isEdit) {
            getUniversityById();
        }
    }, [isEdit]);

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <p className="header-text-grid fw-bold fa-lg">{isEdit ? `${validation.values.name}` : 'Create University'}</p>
                <div onClick={() => isCancel()} className="btn-close position-absolute end-0 top-0 m-3" ></div>
                <Form onSubmit={validation.handleSubmit}>
                    <Row>
                        <Col lg={12}>
                            <CustomTextBox id="name"
                                placeholder="Enter University Name" name="name"
                                onChange={(e) => { validation.handleChange(e); setExistingName("") }}
                                onBlur={validation.handleBlur}
                                value={validation.values.name}
                                invalid={validation.touched.name && validation.errors.name ? true : false}
                                nameValidationError={existingName}
                                label="University Name" error={validation.errors.name} isMandatory={true} />
                        </Col>
                        <Col lg={6}>
                            <CustomTextBox
                                name={`address`}
                                placeholder="Enter Street Name"

                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                id={`address`}
                                value={validation.values.address}
                                error={validation.touched.address && validation.errors.address ? validation.errors.address : undefined}
                                invalid={validation.touched.address && validation.errors.address ? true : false}
                                label="Street Name"
                                isMandatory={true}
                            />
                        </Col>
                        {/* <Col lg={6}><CustomDropdown label="Country" id="countryId" value={validation.values.countryId} data={countryMaster}
                            error={validation.errors.countryId} valueField="id" displayField='displayName' onChange={(e) => { validation.handleChange(e); getStateByCountryId(e.target.value) }}
                            onBlur={validation.handleBlur} invalid={validation.touched.countryId && validation.errors.countryId ? true : false}
                            touched={validation.touched.countryId} isMandatory={true} /></Col> */}

                        <Col lg={6}><CustomDropdown label="State" id="stateId" value={validation.values.stateId} data={stateMaster}
                            error={validation.errors.stateId} valueField="id" displayField='displayName' onChange={(e) => { validation.handleChange(e); getCityByStateId(e.target.value) }}
                            onBlur={validation.handleBlur} invalid={validation.touched.stateId && validation.errors.stateId ? true : false}
                            touched={validation.touched.stateId} isMandatory={true} /></Col>

                        <Col lg={6}><CustomDropdown label="City" id="cityId" value={validation.values.cityId} data={cityMaster}
                            error={validation.errors.cityId} valueField="id" displayField='displayName' onChange={(e) => { validation.handleChange(e); getZipCodeByCityId(e.target.value) }}
                            onBlur={validation.handleBlur} invalid={validation.touched.cityId && validation.errors.cityId ? true : false}
                            touched={validation.touched.cityId} isMandatory={true} /></Col>

                        <Col lg={6}><CustomDropdown label="Zip Code" id="zipId" value={validation.values.zipId} data={zipCodeMaster}
                            error={validation.errors.zipId} valueField="id" displayField='displayName' onChange={validation.handleChange}
                            onBlur={validation.handleBlur} invalid={validation.touched.zipId && validation.errors.zipId ? true : false}
                            touched={validation.touched.zipId} isMandatory={true} /></Col>
                        {isEdit &&
                            <Col xxl={3} lg={6} >
                                <div className="form-check form-switch mt-3"   >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="toggle"
                                        checked={isToggle}
                                        onChange={() => {
                                            setToggle(!isToggle);
                                        }}
                                    />
                                    <label className="form-check-label">{isToggle ? "Active" : "InActive"}</label>
                                </div>
                            </Col>}
                    </Row>
                    <div className="mt-4 d-grid">
                        <button className="loginbtn" type="submit">{isEdit ? "Update" : "Submit"}</button>
                    </div>
                </Form>
            </div>
            {/* <CustomToaster message={(!isCreateMode && isEdit) ? Toaster_ENUM.edit.text : Toaster_ENUM.create.text} color={"text-success"}
                toast={(!isCreateMode && isEdit) ? toastUpdate : toastCreate}
                setToast={(!isCreateMode && isEdit) ? setToastUpdate : setToastCreate} path={""} /> */}
        </React.Fragment>
    )
};

export default UniversityMaster;
