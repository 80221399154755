import React, { InputHTMLAttributes, useState } from 'react';
import { Card, CardBody, Col, Input, Row } from 'reactstrap';
import { CustomPagination } from './CustomPagination';
import moment from 'moment';

interface View {
    id: string;
    name?: string;
    menuName?: string;
    amount?: number;
    menuDate?: string;
    customerName?: string;
    mobileNumber?: string;
    email?: string;
    active?: boolean;
}

interface ViewCardProps extends InputHTMLAttributes<HTMLInputElement> {
    data: View[];
    iconClassName: string;
    perPageData: number,
    PaginationData?: any,
    paginationDiv: any,
    selectedPage?: any,
    canEdit?: boolean;
    canView?: boolean;
    isUser?: boolean;
    showName?: boolean;
    onIconClick?: (id: string) => void;
    onSearch: (value: string) => void;
    canToggle?: boolean;
    toggleChange?: (id: string) => void;
    checked?: boolean
}

const ViewCard = React.forwardRef(({ data, onIconClick, selectedPage, perPageData, PaginationData, paginationDiv, canEdit = true, canView = false, isUser, showName = true, onSearch, canToggle, toggleChange, checked, ...rest }: ViewCardProps, ref) => {
    const [searchValue, setSearchValue] = useState("");

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        onSearch(value);
    };

    const editHandler = (id: any) => {
        if (onIconClick) {
            onIconClick(id);
        }
    }

    const toggle = (id: any) => {
        if (toggleChange) {
            toggleChange(id);
        }
    }

    const formatCell = (value) => {
        if (value === null) {
            return "-";
        } else {
            return moment(value).format("DD-MM-YYYY");
        }
    }
    return (
        <React.Fragment>
            <Row>
                <Col xxl={3} lg={5} className="mb-3" >
                    <Input
                        type="text"
                        placeholder="Search"
                        value={searchValue}
                        onChange={handleSearch}
                    />
                </Col>
            </Row>
            <Row>
                {(data || []).map((value: View, key: number) => (
                    <Col xl={4} lg={6} md={6} sm={6} xs={12} key={key} ref={ref} {...rest as any}>
                        <Card style={{ borderRadius: "13px", cursor: "pointer", boxShadow: '0 4px 5px lightgray', borderLeft: `4px solid #E75605` }}>
                            <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Row >
                                    <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                                        {showName ? <h6 className='font-size-3' style={{ color: '#8F9193' }}>Name</h6> : null}
                                        <h5 className="font-size-14 pl-0 text-start fw-bold pointer-cursor">
                                            {value.name}
                                        </h5>
                                        {value.menuName && (<h5 className="font-size-14 pl-0 text-start fw-bold pointer-cursor">
                                            {value.menuName}
                                        </h5>)}
                                        {value.amount && (<h5 className="font-size-12 pl-0 text-start fw-bolder pointer-cursor" style={{ color: '#474848' }}>
                                            ${value.amount}
                                        </h5>)}
                                        {value.menuDate && (<h5 className="font-size-12 pl-0 text-start fw-bolder pointer-cursor" style={{ color: '#474848' }}>
                                            {formatCell(value.menuDate)}
                                        </h5>)}
                                    </Col>
                                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className='mt-2'>
                                        {canView ?
                                            <i className="mdi mdi-eye-check cursor-pointer" style={{ fontSize: "19px", color: '#474848' }} onClick={() => editHandler(value)}></i>
                                            : null
                                        }
                                        {canEdit ?
                                            <i className="fa fa-edit cursor-pointer" style={{ fontSize: "16px", color: '#474848' }} onClick={() => editHandler(value)}></i>
                                            : null
                                        }
                                        {canToggle ?
                                            (value.active === true) &&
                                            <i className="mdi mdi-eye-check cursor-pointer" style={{ fontSize: "19px", color: '#474848' }} onClick={() => editHandler(value)}></i>
                                            :
                                            null
                                        }
                                        {canToggle ? (value.active === false &&
                                            <>
                                                <span className='inactive-badge'>InActive</span>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={"checkBox_" + value?.id}
                                                        checked={value.active}
                                                        onChange={() => toggle(value)} />
                                                </div></>)
                                            :
                                            null}
                                    </Col>
                                </Row>
                                {isUser ? <Row>
                                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                        {value.customerName && (<h5 className="font-size-14 pl-0 text-start fw-bolder pointer-cursor" style={{ color: '#474848' }}>
                                            {value.customerName}
                                        </h5>)}
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                        {value.mobileNumber && (<h5 className="font-size-12 pl-0 text-start fw-bolder pointer-cursor" style={{ color: '#8F9193' }}>
                                            {value.mobileNumber}
                                        </h5>)}
                                    </Col>
                                    <Row>
                                        {value.email && (<h5 className="font-size-12 pl-0 text-start fw-bolder pointer-cursor" style={{ color: '#474848' }}>
                                            {value.email}
                                        </h5>)}
                                    </Row>
                                </Row> : null}

                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            < Row >
                <CustomPagination perPageData={perPageData} data={data}
                    isShowingPageLength={true} paginationDiv={undefined} CurrentPageHandler={selectedPage}
                    paginationClass="pagination pagination-rounded justify-content-center mt-2 mb-5" />
            </Row>
        </React.Fragment>
    )
});

export default ViewCard;
