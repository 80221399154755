import React, { InputHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**@param {string} type defines the title of the path */
  title?: string,
  /**@param {string} type defines the title of the breadcrumb title */
  breadcrumbTitle?: string,
  /**@param {string} type defines the item name of the page */
  breadcrumbItem?: string,
  /**@param {string} type defines the path of the bread crump title*/
  breadcrumbTitlePath?: any,
  /**@param {string} type defines the path of the bread crump item */
  breadcrumbItemPath?: any
}


const Breadcrumb = React.forwardRef(({ title, breadcrumbTitle, breadcrumbItem, breadcrumbTitlePath, breadcrumbItemPath }: inputProps, ref) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{title}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to={breadcrumbTitlePath}>{breadcrumbTitle}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to={breadcrumbItemPath}>{breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
});

export default Breadcrumb;
