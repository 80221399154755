import React, { useEffect, useRef, useCallback, useState } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { EncodeParam, getTokenDetail } from "../../helpers/helper";
import { ITokenModel } from "Models/ITokenModel";
import { jwtDecode } from "jwt-decode";
import { Role } from "Components/constants/PandaConstants";

const SidebarContent = (props: any) => {

  const [isRoleId, setIsRoleId] = useState<any>();
  const [isRestaurantId, setIsRestaurantId] = useState<any>();

  const ref = useRef<any>();
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    let token: string = localStorage.getItem("authUser") as string;
    let tokendata: ITokenModel = jwtDecode(token);
    setIsRoleId(tokendata.RoleId);

    let id = localStorage.getItem("restaurentId");
    let encodedId = EncodeParam(id);
    setIsRestaurantId(encodedId);
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {(isRoleId != Role.User || isRoleId == Role.SuperAdmin || isRoleId != Role.Admin) ?
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboards")}</span>
                </Link>
              </li> : ""}

            {/* <li className="menu-title">Pages</li> */}
            {(isRoleId == Role.SuperAdmin) ?
              <li>
                <Link to="/university">
                  <i className="bx bxs-institution"></i>
                  <span>{props.t("University")}</span>
                </Link>
              </li> : ""
            }
            {(isRoleId == Role.SuperAdmin) ?
              <li>
                <Link to="/promotional-content">
                  <i className="bx bxs-pen"></i>
                  <span>{props.t("Promotional Content")}</span>
                </Link>
              </li> : ""
            }
            {/* {(isRoleId == Role.SuperAdmin || isRoleId == Role.Admin) ?
            </li> : ""} */}
            {(isRoleId == Role.SuperAdmin || isRoleId == Role.Admin) ?
              <li>
                <Link to={`${isRoleId == Role.SuperAdmin ? "/restaurant" : `/restaurant/view/${isRestaurantId}`}`}>
                  <i className="fas fa-utensils"></i>
                  <span>{props.t("Restaurant")}</span>
                </Link>
              </li> : ""}
            {(isRoleId == Role.Admin) ?
              <li>
                <Link to="/menu">
                  <i className="bx bx-food-menu"></i>
                  <span>{props.t("Menu")}</span>
                </Link>
              </li> : ""}
            {(isRoleId == Role.Admin) ?
              <li>
                <Link to="/weekly-menu">
                  <i className="bx bx-food-tag"></i>
                  <span>{props.t("Weekly Menu")}</span>
                </Link>
              </li> : ""}

            {(isRoleId == Role.Admin) ? <li>
              <Link to="/kitchenorder">
                <i className="mdi mdi-pot-steam-outline"></i>
                <span>{props.t("Kitchen Order")}</span>
              </Link>
            </li> : ""}
            {/* {(isRoleId == Role.Admin) ?
              <li>
                <Link to="/packed-order">
                  <i className="mdi mdi-package-variant"></i>
                  <span>{props.t("Packed Order")}</span>
                </Link>
              </li> : ""} */}
            {(isRoleId != Role.User) ? <li>
              <Link to="/orderhistory">
                <i className="mdi mdi-order-bool-ascending-variant"></i>
                <span>{props.t("Order History")}</span>
              </Link>
            </li> : ""}
            {(isRoleId == Role.SuperAdmin || isRoleId == Role.Admin) ?
              <li>
                <Link to="/dashboard-ticket">
                  <i className="bx bx-chat"></i>
                  <span>{props.t("Tickets")}</span>
                </Link>
              </li> : ""}
            {/* {(isRoleId == Role.SuperAdmin || isRoleId == Role.Admin) ? <li>
              <Link to="/orderhistory">
                <i className="mdi mdi-order-bool-ascending-variant"></i>
                <span>{props.t("Order History")}</span>
              </Link>
            </li> : ""} */}

            {(isRoleId == Role.SuperAdmin) ?
              <li>
                <Link to="/user">
                  <i className="fas fa-user"></i>
                  <span>{props.t("Students")}</span>
                </Link>
              </li> : ""}

            {/* <li className="menu-title">Customer</li> */}
            {(isRoleId == Role.User) ?
              <li>
                <Link to="/customerDashboard">
                  <i className="bx bxs-happy-alt"></i>
                  <span>{props.t("Customer")}</span>
                </Link>
              </li> : ""}

            {/* {(isRoleId == Role.SuperAdmin) ?
              <li>
                <Link to="/user">
                  <i className="bx bx-user-pin"></i>
                  <span>{props.t("Users")}</span>
                </Link>
              </li> : ""} */}
            {(isRoleId == Role.SuperAdmin) ?
              <li>
                <Link to="/coupon-code">
                  <i className="mdi mdi-sack-percent"></i>
                  <span>{props.t("Coupon")}</span>
                </Link>
              </li> : ""}

              {(isRoleId == Role.SuperAdmin) ?
              <li>
                <Link to="/coupon-history">
                  <i className="bx bxs-offer"></i>
                  <span>{props.t("Coupon History")}</span>
                </Link>
              </li> : ""}
              {(isRoleId == Role.SuperAdmin) ?
              <li>
                <Link to="/analysis">
                  <i className="fas fa-receipt"></i>
                  <span>{props.t("Analysis")}</span>
                </Link>
              </li> : ""}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
