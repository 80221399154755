import { Loader } from "Components/Loader/Loader";
import { CustomToaster } from "Components/Shared/CustomToaster";
import CommonService from "Services/CommonService";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const UserVerification = () => {
    const [toast, SetToast] = useState(false);
    const [notverifiedToast, setNotVerifiedToast] = useState(false);
    const { id } = useParams();
    const [loader, setLoader] = useState(true);

    const getUserDetails = () => {
        CommonService.getWithQueryParam("User", "GetVerifiedUserByGuId", "guId", id).then(res => {
            if (res.status === 200) {
                if (res.data.responseData == true) {
                    setLoader(false);
                    SetToast(true);
                } else {
                    setLoader(false);
                    setNotVerifiedToast(true);
                }
            }
        })
    }

    useEffect(() => {
        getUserDetails();
    }, [])

    return (
        <div className="page-content default-height">
            <Loader open={loader} />
            <CustomToaster message={"You are verified Successfully !!!"} color={"text-success"} toast={toast} setToast={SetToast} path="/login" />
            <CustomToaster message={"You are already an activated user !!!"} color={"text-warning"} toast={notverifiedToast} setToast={setNotVerifiedToast} path="/" />
        </div>
    )
}

export default UserVerification 