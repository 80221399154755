import ProfileForm from 'Components/ProfileForm/ProfileForm';
import { ITokenModel } from 'Models/ITokenModel';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import {
    Button,
    Col,
    Modal,
    Navbar,
    NavbarBrand,
    NavItem,
    PopoverBody,
    Row,
    UncontrolledPopover,
} from 'reactstrap';
import { createSelector } from 'reselect';

function Headers(args, props: any) {
    const [username, setusername] = useState("");
    const [userModel, setUserModel] = useState(false);
    const [isLoginId, setIsLoginId] = useState<any>();

    const selectProperties = createSelector(
        (state: any) => state.Login,
        (error) => error
    );
    const { pageName } = useSelector(selectProperties);

    let navigate = useNavigate();

    const onLoginClick = () => {
        navigate('/login');
    }

    const modelcreate = () => {
        setUserModel(false);
    }

    const isCancel = () => {
        setUserModel(false);
    };

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            let token: string = localStorage.getItem("authUser") as string;
            let tokendata: ITokenModel = jwtDecode(token);
            let email = tokendata?.Email;
            let loginid = tokendata?.LoginId;
            setIsLoginId(loginid);
            let name = email?.split('@')[0];
            setusername(name);
        }
    }, []);

    const onLogoutClicked = () => {
        let remember = localStorage.getItem("rememberme");
        if (remember) {
            localStorage.removeItem("authUser");
        }
        else {
            window.localStorage.clear();
        }
        window.location.href = "/";
    }

    return (
        <><div className='px-lg-5'>
            <Navbar {...args} className='custom-nav-lg ' style={{ height: "75px" }}>
                <NavbarBrand href="/">
                    <img src={require("../../assets/images/logo.png")} className='logo' alt="" />
                </NavbarBrand>
                <NavItem className='custom-nav-lg center'>
                    <NavLink to="/">Home</NavLink>
                    {username ? <>
                        <NavLink to="/my-orders/">My Orders</NavLink>
                        <NavLink to="/tickets/">Tickets</NavLink>
                    </> : null}
                    <NavLink to="/about-us/">About us</NavLink>
                    <NavLink to="/contact-us/">Contact Us</NavLink>
                </NavItem>
                <NavItem className='custom-nav-lg right'>
                    {/* <img src={require("../../assets/images/search.png")} style={{ width: "25px" }} alt="" /> */}
                    {username ?
                        <React.Fragment>
                            <button
                                id="PopoverFocus"
                                type="button"
                                className='header-button d-none d-xl-inline-block ms-2 me-1 admin-text'
                            >
                                {username}
                            </button>
                            <UncontrolledPopover
                                placement="bottom"
                                target="PopoverFocus"
                                trigger="legacy"
                            >
                                <PopoverBody>
                                    <div className="dropdown-item mb-2 d-flex cursor-pointer" onClick={() => { setUserModel(true) }}>
                                        <i className="bx bx-user font-size-20 align-middle me-1 text-danger" />
                                        <p className=' font-size-13' >Profile</p>
                                    </div>
                                    <div className="dropdown-item d-flex">
                                        <i className="bx bx-power-off font-size-20 align-middle me-1 text-danger" />
                                        <p className='cursor-pointer font-size-13' onClick={() => {
                                            onLogoutClicked()
                                        }}>logout</p>
                                    </div>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </React.Fragment>
                        :
                        <button className='btn custom-button' onClick={() => onLoginClick()}>Login</button>}

                </NavItem>
            </Navbar>
            <div className='custom-nav-sm' style={{ height: "65px" }}>
                <Row>
                    <Col xs={"4"}>
                        <nav role="navigation">
                            <div id="menuToggle">
                                <input type="checkbox" />
                                <span></span>
                                <span></span>
                                <span></span>
                                <ul id="menu">
                                    <a href="/"><li><i className='bx bxs-home'></i> Home</li></a>
                                    {username ? <>
                                        <a href="/my-orders/"><li> <i className='bx bxs-food-menu'></i> My Orders</li></a>
                                        <a href="/tickets/"><li><i className='bx bxs-coupon'></i> Tickets</li></a>
                                    </> : null}
                                    <a href="/about-us/"><li><i className='bx bxs-user-detail'></i> About us</li></a>
                                    <a href="/contact-us/"><li><i className='bx bxs-phone-call'></i> Contact us</li></a>
                                </ul>
                            </div>
                        </nav>
                    </Col>
                    <Col xs={4} className='text-center mt-3'>
                        <span className='page-name nowrap'>{pageName}</span>
                    </Col>
                    <Col xs={"4"} className='text-end'>
                        {username ?
                            <React.Fragment>
                                <Button
                                    id="PopoverFocuss"
                                    type="button"
                                    className='profile-icon-header'
                                >
                                    {username.slice(0, 1)}
                                </Button>
                                <UncontrolledPopover
                                    placement="bottom"
                                    target="PopoverFocuss"
                                    trigger="legacy"
                                >
                                    <PopoverBody>
                                        <div className="dropdown-item mb-2 d-flex cursor-pointer" onClick={() => { setUserModel(true) }}>
                                            <i className="bx bx-user font-size-20 align-middle me-1 text-danger" />
                                            <p className=' font-size-13' >Profile</p>
                                        </div>
                                        <div className="dropdown-item d-flex cursor-pointer">
                                            <i className="bx bx-power-off font-size-20 align-middle me-1 text-danger" />
                                            <p className='cursor-pointer font-size-13' onClick={() => {
                                                onLogoutClicked()
                                            }}>logout</p>
                                        </div>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </React.Fragment>
                            :
                            <button className='btn custom-button mt-3' onClick={() => onLoginClick()}>Login</button>}
                    </Col>
                </Row>
            </div>
        </div>
            <Modal isOpen={userModel} size="lg" centered>
                <div role='document'>
                    <div className='modal-content'>
                        <div className='modal-body m-3'>
                            <ProfileForm paramid={isLoginId} canCancel={isCancel} isCreate={modelcreate} />
                        </div>
                    </div>
                </div>
            </Modal></>
    );
}

export default Headers;