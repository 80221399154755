import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "reactstrap";
import CustomDataTable from "Components/Shared/CustomDataTable";
import Breadcrumb from "Components/Common/Breadcrumb";
import { getTokenDetail } from "../../helpers/helper";
import OrderEdit from "./OrderEdit";
import { Role } from "Components/constants/PandaConstants";
import { IOrderSearchModel } from "Models/IOrderSearchModel";
import { ITokenModel } from "Models/ITokenModel";

const OrderDashBoard = () => {
    const [menu, setMenu] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [userModel, setUserModel] = useState(false);
    const [isDecodedRestaurantId, setIsDecodedRestaurantId] = useState<any>();
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [roleId, setRoleId] = useState(0);
    const [tokenData, setTokenData] = useState<ITokenModel>();
    const [rowPerPageValue, setRowPerPageValue] = useState(10);

    const toggleModal = (isText) => {
        setModalIsOpen(!modalIsOpen);
        removeBodyCss();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "1%"
        }, {
            Header: ' User Name',
            accessor: 'userName',
            width: "25%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: ' Menu Name',
            accessor: 'menuName',
            width: "25%",
            displayInList: true,
            Filter: true,
        }, {
            Header: ' Restaurant Name',
            accessor: 'restaurentName',
            width: "25%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: ' Status',
            accessor: 'statusName',
            width: "20%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Cost',
            accessor: 'amount',
            width: "20%",
            displayInList: true,
            Filter: true,
        }
    ], []);

    const AdminColumns: IColumnModel[] = useMemo(() => [
        {
            Header: ' User Name',
            accessor: 'userName',
            width: "25%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: ' Menu Name',
            accessor: 'menuName',
            width: "25%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: ' Status',
            accessor: 'statusName',
            width: "20%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Cost',
            accessor: 'amount',
            width: "20%",
            displayInList: true,
            Filter: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "0%"
        },
    ], []);

    const getOrderBySearch = (pageNumber: any, searchText: string, sortorder?: any, rowPerPageVal?: any) => {
        let token: any = getTokenDetail();
        let roleIdValue = parseInt(token.RoleId, 10);
        let loginId = localStorage.getItem("loginId");
        let restaurantId = localStorage.getItem("restaurentId");
        let searchModel: IOrderSearchModel = {
            offsetStart: pageNumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "Desc",
            searchText: searchText ? searchText : "",
            userId: roleIdValue == Role.User ? loginId : null,
            restaurentId: roleIdValue == Role.Admin ? restaurantId : null,
        }
        CommonService.post("UserOrder", "GetUserOrderByUser", searchModel).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                setMenu(data)
            }
        })
    }

    const editHandler = (val: any) => {
        setUserModel(true);
        setIsDecodedRestaurantId(val.id);
    }

    const pagination = (pageNumber: any) => {
        let getPageNumber = ((pageNumber) * 10);
        getOrderBySearch(pageNumber, search);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getOrderBySearch(1, searchKeyword);
    }

    const isCancel = () => {
        setUserModel(false);
        getOrderBySearch(1, "")
    }

    const validateUserRole = () => {
        let token: any = getTokenDetail();
        setTokenData(token);
        let roleIdValue = parseInt(token.RoleId, 10);
        setRoleId(roleIdValue);
        if (roleIdValue == Role.Admin) {
            setIsRestaurant(true);
        }
        getOrderBySearch(1, "")
    }
    useEffect(() => {
        validateUserRole();
    }, []);

    
    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        getOrderBySearch(1, "", null, val);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Orders" breadcrumbItem="Dashboard" breadcrumbTitle={""}
                    breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/order"} />
                <CustomDataTable
                     rowPerPageValue={rowPerPageValue}
                     rowPerPageHandler={rowPerPageHandler}
                    columns={roleId > 1 ? AdminColumns : columns}
                    data={menu}
                    customPageSize={rowPerPageValue}
                    isAction={false}
                    editHandler={editHandler} canShowButton={false} deleteHandler={undefined}
                    createHandler={undefined} path={""} canView={false} perPageData={rowPerPageValue} isPagination={true}
                    isGlobalFilter={true} canEdit={false}
                    selectedPage={pagination}
                    searchHandler={searchHandler} isShowingPageLength={true} />
                <div className="container">
                    <Modal isOpen={userModel} size="lg" centered>
                        <div role='document'>
                            <div className='modal-content'>
                                <div className='modal-body m-3'>
                                    <OrderEdit
                                        isModelOpen={true}
                                        orderId={isDecodedRestaurantId}
                                        canCancel={isCancel}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
}

export default OrderDashBoard;