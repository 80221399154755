import { Radio } from "@mui/material";
import CommonService from "Services/CommonService";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { ITokenModel } from "Models/ITokenModel";
import { jwtDecode } from "jwt-decode";
import { DecodeParam, EncodeParam } from "../../../helpers/helper";
import "../Restaurants/PickUpAddress.css";
import { HelperConstant, days, splitDateTime } from "Components/constants/PandaConstants";
import locationlogo from '../../../assets/images/location.png';
import { useDispatch } from "react-redux";
import { pageNameHandler } from "slices/thunk";
import { Loader } from "Components/Loader/Loader";
import moment from "moment";

const PickUpAddressPopUp = () => {
    const [pickupAddressData, setPickupAddressData] = useState<Array<{ name: string, id: any, countryName: string, stateName: string, cityName: string, zipCodeName: any, uploadFilePath: any, deliveryCutOff: any }>>([]);
    const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null);
    const { restaurentId, menuId, dayId } = useParams();
    const [loader, setLoader] = useState(false);
    const [isDeliveryDate, setIsDeliveryDate] = useState("");

    let navigate = useNavigate();

    let token: string = localStorage.getItem("authUser") as string;
    let tokendata: ITokenModel = jwtDecode(token);

    useEffect(() => {
        GetPickUpAddress();
        let DecodedDayId = DecodeParam(dayId);
        let day = moment().format('dddd');
        let todayId = days.find(item => item.day == day)?.id;
        let difference;
        if (todayId !== undefined && parseInt(DecodedDayId) < todayId) {
            difference = (7 - todayId) + parseInt(DecodedDayId);
        }
        else if (todayId !== undefined && parseInt(DecodedDayId) > todayId) {
            difference = parseInt(DecodedDayId) - todayId;
        }

        if (parseInt(DecodedDayId) === todayId) {
            setIsDeliveryDate(moment(new Date().toISOString()).format('YYYY-MM-DD'));
        }
        else {
            setIsDeliveryDate(moment().add(difference, 'days').format('YYYY-MM-DD'))
        }
    }, []);

    const dispatch: any = useDispatch();
    document.title = "Locations | Panda GoGoGo ";

    useEffect(() => {
        dispatch(pageNameHandler({ pageName: "Locations" }));
    }, []);

    const GetPickUpAddress = () => {
        setLoader(true);
        CommonService.postWithQueryParam("PickupAddress", "GetPickUpAddressByUniversityId", "universityId", tokendata.UniversityId)
            .then(res => {
                if (res.status === 200) {
                    setLoader(false);
                    const responseValue = res.data.responseData;
                    setPickupAddressData(responseValue);
                    responseValue.map((address, index) => {
                        address.deliveryCutOff = splitDateTime(address.deliveryCutOffStartTime, address.deliveryCutOffEndTime)
                    })
                }
            })
            .catch(e => console.log(e));
    }

    const handleCardClick = (id: string) => {
        setSelectedAddressId(prevId => (prevId === id ? null : id));
    }

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: (data: any) => {
            if (selectedAddressId) {
                menuPageView();
            }
        }
    });

    const menuPageView = () => {
        let encodedMenuId = EncodeParam(selectedAddressId)
        navigate(`/ordercreation/${restaurentId}/${encodedMenuId}/${menuId}/${dayId}`);
    }

    const onCancelClick = () => {
        navigate(`/`);
    }

    const renderPickupAddressCards = () => {
        return pickupAddressData.map(value => (
            <Col xl={6} lg={6} md={6} sm={6} key={value.id} className="">
                <Card style={{ width: '100%', borderRadius: "10px" }}>
                    <CardBody>
                        <Row style={{ display: "flex", flexDirection: "row" }}>
                            <Col lg={2} md={12}>
                                {value.uploadFilePath ?
                                    <img src={`${HelperConstant.image_cdn_Url}/${value.uploadFilePath}`} className="ch-img" />
                                    :
                                    <img src={locationlogo} className="ch-default-img" />
                                }
                            </Col>
                            <Col lg={8} md={12}>
                                <h3 style={{ color: '#393A3B', fontSize: '17px' }}>{value.name}</h3>
                            </Col>
                            <Col lg={2} md={12}>
                                <Radio
                                    id={`${value.id}`}
                                    name="addressRadio"
                                    checked={selectedAddressId === value.id}
                                    onChange={() => handleCardClick(value.id)}
                                    style={{ color: selectedAddressId === value.id ? '#E75605' : '#8F9193', position: "absolute", right: 0, top: "-10px" }}
                                />
                            </Col>
                            <Col lg={7} md={12}>
                                <h3 style={{ color: '#393a3bcc', fontSize: '15px' }}>{value.cityName}, {value.stateName}</h3>
                                <h3 style={{ color: '#393a3bcc', fontSize: '15px' }}>{value.countryName} - {value.zipCodeName}</h3>
                            </Col>
                            <Col lg={5} md={12}>
                                <h3 style={{ color: '#393A3B', fontSize: '13px', whiteSpace: "nowrap" }}>Pickup Date & Time  : </h3>
                                <h3 className="primary-color" style={{ fontSize: '12px', whiteSpace: "nowrap" }}>{isDeliveryDate}</h3>
                                <h3 className="primary-color" style={{ fontSize: '12px', whiteSpace: "nowrap" }}>{value.deliveryCutOff}</h3>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        ));
    }

    return (
        <React.Fragment>
            <Container fluid style={{ backgroundColor: '#FFFFFF', overflowX: "hidden", paddingTop: '25px' }} className="default-height">
                <Loader open={loader} />
                <Row>
                    <Col lg={2} md={1}></Col>
                    <Col md={10} lg={8}>
                        <Card style={{ backgroundColor: '#F1F1F1', boxShadow: "unset" }} className="border-container">
                            <CardBody>
                                <p style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}  >Choose Location</p>
                                <Row>
                                    <form onSubmit={validation.handleSubmit}>
                                        <div className="row">
                                            {renderPickupAddressCards()}
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-12 col-sm-6  col-xs-6 text-center">
                                                <Button
                                                    style={{ width: '171px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                                    className='btn btn-secondary ms-2'
                                                    type="button" onClick={onCancelClick}
                                                >Back
                                                </Button>
                                                <Button
                                                    style={{ backgroundColor: '#E75605', width: '171px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                                    type={"submit"} disabled={!selectedAddressId} className="ms-2"
                                                >Continue
                                                </Button>
                                            </div>
                                        </div> */}
                                        <Row>
                                            <Col xl={3} lg={3} md={3}></Col>
                                            <Col lg={3} md={3} sm={6} xs={6}>
                                                <Button
                                                    className='btn btn-secondary ms-2 cancel-button'
                                                    type="button" onClick={onCancelClick}
                                                >Back
                                                </Button>
                                            </Col>
                                            <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                                                <button
                                                    type={"submit"} disabled={!selectedAddressId} className="ms-2 submit-button"
                                                >Continue
                                                </button>
                                            </Col>
                                            <Col xl={3} lg={3} md={3}></Col>
                                        </Row>
                                    </form>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={2} md={1}></Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default PickUpAddressPopUp;
