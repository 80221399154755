import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomTextBox } from "Components/Shared/CustomTextBox";
import CommonService from "Services/CommonService";
import { CustomTextArea } from "Components/Shared/CustomTextArea";
import { IPromotionalContentModel } from "Models/IPromotionalContentModel";
import { appendFormData } from "../../helpers/helper";
import { CustomDropdown } from "Components/Shared/CustomDropdown";

type Props = {
    canCancel: any,
    isModelOpen: any,
    isEditId: any,
    isEdit: boolean
}

const PromotionalContentMaster = ({ isEditId, isModelOpen, canCancel, isEdit }: Props) => {
    const [isToggle, setToggle] = useState<boolean>(true);


    const PromotionalContentData = [{
        id: 1,
        name: "Banner"
    }]

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            displayArea: '',
            description: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Promotional Content ").max(600, "Promotional Content must be at most 600 characters"),
            description: Yup.string().required("Please Enter Your Address").max(600, "Description must be at most 600 characters"),
            displayArea: Yup.string().required("Please Select the Type of Promotional Content")
        }),
        onSubmit: (values: any) => {
            submitPromotionalContentCreation(values)
        }
    });


    const submitPromotionalContentCreation = (values: IPromotionalContentModel) => {
        values.id = isEditId;
        values.displayName = values.name;
        values.enumName = values.name.replaceAll(' ', '_');
        values.active = isToggle;
        let formData = new FormData();
        formData = appendFormData(formData, values);
        CommonService.postWithFormData("PromotionalContents", isEdit ? "UpdatePromotionalContents" : "CreatePromotionalContents", formData).then(res => {
            if (res.status === 200) {
                canCancel();
            }
        });
    }
    const isCancel = () => {
        canCancel();
    }

    const getPromotionalContentById = () => {
        CommonService.getWithQueryParam("PromotionalContents", "GetPromotionalContentsById", "id", isEditId).then((res) => {
            if (res.status == 200) {
                validation.setFieldValue("name", res.data.responseData.name);
                validation.setFieldValue("description", res.data.responseData.description);
                validation.setFieldValue("displayArea", res.data.responseData.displayArea);

                if (res.data.responseData.active == false) {
                    setToggle(!isToggle);
                }
                else {
                    setToggle(isToggle)
                }
            }
        })
    }

    useEffect(() => {
        if (isEdit) {
            getPromotionalContentById();
        }
    }, [isEdit]);

    return (
        <React.Fragment>
            <div className="page-content p-0">
                <p className="header-text-grid fw-bold fa-lg">Create Promotional Content</p>
                <div onClick={() => isCancel()} className="btn-close position-absolute end-0 top-0 m-3" ></div>
                <Form onSubmit={validation.handleSubmit}>
                    <Row>
                        <Col lg={12}>
                            <CustomTextBox id="name"
                                placeholder="Enter Promotional Content" name="name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name}
                                invalid={validation.touched.name && validation.errors.name ? true : false}
                                label="Promotional Content" error={validation.errors.name} isMandatory={true} />
                        </Col>
                        {/* <Col lg={12}>
                            <CustomTextBox id="Type"
                                placeholder="Enter Type of Promotional Content" name="displayArea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.displayArea}
                                invalid={validation.touched.displayArea && validation.errors.displayArea ? true : false}
                                label="Type of Promotional Content" error={validation.errors.displayArea} isMandatory={true} />
                        </Col> */}
                        <Col lg={12}>
                            <CustomDropdown
                                label="Type of Promotional Content"
                                id={"displayArea"}
                                data={PromotionalContentData}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.displayArea}
                                error={validation.errors.displayArea} isMandatory={true}
                                invalid={validation.touched.displayArea && validation.errors.displayArea ? true : false}
                                touched={validation.touched.displayArea}
                                valueField={"name"}
                                displayField={"name"} />
                        </Col>
                        <Col lg={12}>
                            <CustomTextArea
                                name={`description`}
                                placeholder="Enter Description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                id={`description`}
                                value={validation.values.description}
                                error={validation.touched.description && validation.errors.description ? validation.errors.description : undefined}
                                invalid={validation.touched.description && validation.errors.description ? true : false}
                                label="Description"
                                isMandatory={true}
                            />
                        </Col>
                        <Col xxl={3} lg={3} >
                            <div className="form-check form-switch mt-3"   >
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="toggle"
                                    checked={isToggle}
                                    onChange={() => {
                                        setToggle(!isToggle);
                                    }}
                                />
                                <label className="form-check-label">{isToggle ? "Active" : "InActive"}</label>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-4 d-grid">
                        <button className="loginbtn" type="submit"> Submit</button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
};

export default PromotionalContentMaster;
