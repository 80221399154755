import React, { useEffect, useState } from "react";
import "./App.css";
import { authProtectedRoutes, publicRoutes } from "./Routes/allRoutes";
import { Route, Routes } from "react-router-dom";
import VerticalLayout from "./Layouts/VerticalLayout";
import HorizotanlLayout from "./Layouts/HorizontalLayout/index";
import "./assets/scss/theme.scss";
import NonAuthLayout from "./Layouts/NonLayout";

import AOS from "aos";
import "aos/dist/aos.css";

//constants
import {
  LAYOUT_TYPES,
} from "./Components/constants/layout";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import AuthProtected from "Routes/AuthProtected";
import Headers from "Components/Headers/Headers";
import Footers from "Components/Footers/Footers";
import { ITokenModel } from "Models/ITokenModel";
import { jwtDecode } from "jwt-decode";
import { Role } from "Components/constants/PandaConstants";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

// Activating fake backend
fakeBackend();

const getLayout = (layoutType: any) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case LAYOUT_TYPES.VERTICAL:
      Layout = VerticalLayout;
      break;
    case LAYOUT_TYPES.HORIZONTAL:
      Layout = HorizotanlLayout;
      break;
    default:
      break;
  }
  return Layout;
};

function App() {

  const [isRoleId, setIsRoleId] = useState<any>();

  // useEffect(() => {
  let token: string = localStorage.getItem("authUser") ?? "" as string;
  let tokendata: ITokenModel = {
    name: "",
    Password: "",
    Email: "",
    LoginId: "",
    StatusId: 0,
    token: "",
    RoleId: "",
    UniversityId: ""
  }
  if (token != "") {
    tokendata = jwtDecode(token);

  }
  // setIsRoleId(tokendata.RoleId);
  // }, [])


  const selectLeadData = createSelector(
    (state: any) => state.Layout,
    (layoutTypes) => layoutTypes
  );
  const { layoutTypes } = useSelector(selectLeadData);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 800,
      easing: "ease-out-cubic",
    });
  }, []);

  const Layout = getLayout(layoutTypes);
  return (
    <React.Fragment>
      {(parseInt(tokendata.RoleId) == Role.User || token == "") && <Headers />}
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route path={route.path} key={idx}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>} />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <React.Fragment>
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              </React.Fragment>
            }
          />
        ))}
      </Routes>
      {(parseInt(tokendata.RoleId) == Role.User || token == "") && <Footers />}
    </React.Fragment>
  );
}

export default App;
