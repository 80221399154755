import { Loader } from "Components/Loader/Loader";
import { CustomPasswordBox } from "Components/Shared/CustomPasswordBox";
import { CustomToaster } from "Components/Shared/CustomToaster";
import { IResetPasswordModel } from "Models/IResetPasswordModel";
import CommonService from "Services/CommonService";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";
import { EncodeParam } from "../../helpers/helper";


const ResetPassword = () => {
    const [notverifiedToast, setNotVerifiedToast] = useState(false);
    const { id } = useParams();
    const [isReset, setIsReset] = useState(false);
    const [loader, setLoader] = useState(false);

    document.title = "Reset Password| Panda GoGoGo ";

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password"),
            confirmPassword: Yup.string().required("Please Enter Your ConfirmPassword").oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: (values: IResetPasswordModel) => {
            setLoader(true);
            CommonService.postWithDoubleQueryParam("User", "ResetPasswordByGuId", "guId", id, "password", EncodeParam(values.confirmPassword)).then(res => {
                if (res.status === 200) {
                    setLoader(false);
                    window.location.href = "/login";
                }
            }).catch((err) => {
                console.log(err)
            });
        }
    });

    useEffect(() => {
        CommonService.postWithQueryParam("User", "ValidateUserByGuId", "guId", id).then(res => {
            if (res.status === 200) {
                if (res.data.responseData == true) {
                    setIsReset(true);
                } else {
                    setTimeout(() => {
                        setNotVerifiedToast(true);
                    }, 2000);
                }
            }
        })
    }, [])

    return (
        <React.Fragment>
            <div className=" pt-sm-5 reset-app default-height">
                <Loader open={loader} />
                {isReset &&
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5} className="d-flex justify-content-center">
                                <Card className="overflow-hidden mt-2" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                    <div className="background-grid">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <p className="header-text-grid">Reset Your Password Here.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0 mx-1 mt-2">
                                        <div className="p-1">
                                            <Form className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >

                                                <Col lg={12}>
                                                    <CustomPasswordBox
                                                        label="Password"
                                                        placeholder="Enter Password"
                                                        id="password"
                                                        value={validation.values.password}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        error={validation.errors.password}
                                                        touched={validation.touched.password}
                                                        invalid={validation.errors.password && validation.touched.password ? true : false}
                                                        isMandatory={true}
                                                    />
                                                </Col>
                                                <Col lg={12}>
                                                    <CustomPasswordBox
                                                        label="ConfirmPassword"
                                                        placeholder="Enter ConfirmPassword"
                                                        id="confirmPassword"
                                                        value={validation.values.confirmPassword}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        error={validation.errors.confirmPassword}
                                                        touched={validation.touched.confirmPassword}
                                                        invalid={validation.errors.confirmPassword && validation.touched.confirmPassword ? true : false}
                                                        isMandatory={true}
                                                    />
                                                </Col>

                                                <div className="mt-3 d-grid">
                                                    <button className="loginbtn" type="submit">Submit</button>
                                                </div>

                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>}
                <CustomToaster message={"Link Expired!!"} color={"text-warning"} toast={notverifiedToast} setToast={setNotVerifiedToast} path="/" />
            </div >
        </React.Fragment >

    );
}

export default ResetPassword 