import React, { useState } from "react";
import QRCode from "qrcode.react";
import { Col, Container, Row } from "reactstrap";
import QRCodeScanner from "./Qrcode";

const AppQRCode = () => {
  const [inputText, setInputText] = useState("");

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="mt-80">
            <h3>QR Code Generator</h3>
            <Row>
              <Col sm={6}>
                <div>
                  <label htmlFor="inputText">Enter Text :</label>
                  <input
                    type="text"
                    id="inputText"
                    value={inputText}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <h5>Generated QR Code:</h5>
                  {inputText && <QRCode value={inputText} />}
                </div>
              </Col>
              <Col sm={6}>
                <div>
                  <QRCodeScanner />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AppQRCode;
