//Author : Kavitha Sri

import React, { InputHTMLAttributes } from "react";
import { FormFeedback, Input, Label } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} name reference of the elements*/
    name?: any;
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} id defines the id of the DOM element */
    id: any,
    /** @param {any} className return the value of an element's class*/
    className?: any,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder: any;
    /** @param {any} value is used to set or get the value for the input field*/
    value: any;
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} disabled is defines the field is displayed or not */
    disabled?: any;
    /** @param {any} readOnly is defines the it's non editable*/
    readOnly?: boolean;
    /** @param {any} isMandatory is defines the if it's mandatory or not*/
    isMandatory?: boolean,
    touched?: any,
    nameValidationError?: any;
}

export const CustomTextBox = React.forwardRef(({ name, placeholder, value, onChange, onBlur, invalid, error, label, id, disabled, readOnly, touched, isMandatory, nameValidationError, ...rest }: inputProps, ref) => {

    return (
        <>
            <Label className="mt-3 mb-2">{label}</Label>
            {isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
            <Input ref={ref} {...rest as any}
                disabled={disabled}
                readOnly={readOnly}
                type="text"
                className="form-control mb-1"
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                touched={touched}
                invalid={invalid}
                error={error} />
            <FormFeedback>{error}</FormFeedback>
            {(!error && nameValidationError) &&
                <>
                    <span className="text-danger fa-xs">{nameValidationError}</span>&nbsp;
                </>
            }
        </>
    )
})