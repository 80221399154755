import axios from "axios";

let defaultAPIKey = process.env.REACT_APP_PUBLIC_API ?? '';
// let token = getTokenDetail()?.token;
// let userAPIKey = token ? "Bearer " + token : defaultAPIKey;

export default axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_API,
    headers: {
        'Accept': 'application/json',
        "Content-type": "application/json",
        "Cache-Control": "no-store, no-cache, must-revalidate",
        "Authorization": defaultAPIKey,
    }
}); 