import React, { useEffect, useState } from 'react';
import "./Banner.css";
import { Col, Row } from 'reactstrap';
import icon1 from "../../assets/images/banner/icon1.png";
import icon2 from "../../assets/images/banner/icon2.png";
import icon3 from "../../assets/images/banner/icon3.png";
import { useNavigate } from 'react-router-dom';
import { ITokenModel } from 'Models/ITokenModel';
import { jwtDecode } from 'jwt-decode';
import CommonService from 'Services/CommonService';

function Banner() {

    const navigate = useNavigate();

    const [username, setusername] = useState("");
    const [bannerData, setBannerData] = useState("")

    const onLoginClick = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            let token: string = localStorage.getItem("authUser") as string;
            let tokendata: ITokenModel = jwtDecode(token);
            let email = tokendata?.Email;
            let name = email?.split('@')[0];
            setusername(name);
        }
    }, []);

    const iconsData = [
        {
            id: 1,
            name: `50+ Restaurants`,
            img: icon1
        },
        {
            id: 2,
            name: `Easy steps to order`,
            img: icon2
        },
        {
            id: 3,
            name: `Accurate Pick up time`,
            img: icon3
        }
    ]

    const getPromotionalContents = () => {
        CommonService.getWithQueryParam("PromotionalContents", "GetPromotionalContentsByDisplayArea", "displayName", "Banner").then(res => {
            if (res.status == 200) {
                setBannerData(res.data.responseData[0]?.displayName);
            }
        })
    }

    useEffect(() => {
        getPromotionalContents();
    })

    return (
        <div className="banner">
            <fieldset className="banner-text">
                <h1 data-aos="fade-down">{bannerData} </h1>
                <p></p>
                {/* <a href={`${username ? '#menu-item' : 'javascript:void(0)'}`} onClick={() => username ? null : onLoginClick()}>Order Now</a> */}

                {/* <span className="pm-card-author subtle primary-color"> <b>No Delivery Charge + No Tipping</b></span> */}
            </fieldset>
            {/* <fieldset className="banner-strip">
                <Row>
                    {iconsData.map((val, i) => (
                        <Col key={i} xl={4} lg={4} md={4} sm={4} xs={4} className='banner-col'>
                            <img src={val.img} alt="" />
                            <p>{val.name}</p>
                        </Col>
                    ))}
                </Row>
            </fieldset> */}
        </div>
    )
}

export default Banner