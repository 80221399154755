import CommonService from 'Services/CommonService';
import React, { useEffect, useState } from 'react'
import Breadcrumb from "Components/Common/Breadcrumb";
import './Dashboard.css';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import LocationChart from './LocationChart';
import MenuChart from './MenuChart';
import Universitychart from './UniversityChart';
import MonthlyOrderChart from './MonthlyOrderChart';
import DailyOrderChart from './DailyOrderChart';
import { Role } from 'Components/constants/PandaConstants';
import { getTokenDetail } from '../../helpers/helper';
import { Loader } from 'Components/Loader/Loader';

const Dashboard = () => {

  document.title = "Dashboards | Panda GoGoGo ";

  const [dashboardStats, setDashboardStats] = useState<any>([]);
  const [isLocationChart, setIsLocationChart] = useState(false);
  const [isMenuChart, setIsMenuChart] = useState(true);
  const [isUniversityChart, setIsUniversityChart] = useState(false);
  const [isUserOrderChart, setIsUserOrderChart] = useState(false);
  const [locationCount, setLocationCount] = useState(0);
  const [menuCount, setMenuCount] = useState(0);
  const [universityCount, setUniversityCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [locationChartData, setLocationChartData] = useState<Array<any>>([]);
  const [menuChartData, setMenuChartData] = useState<Array<any>>([]);
  const [universityChartData, setUniversityChartData] = useState<Array<any>>([]);
  const [montlyOrderChartData, setMonthlyOrderChartData] = useState<any>([]);
  const [dailyOrderChartData, setDailyOrderChartData] = useState<any>([]);
  const [loader, setLoader] = useState(true);

  let navigate = useNavigate();

  const statsCards = [
    { id: 3, title: "Menu", iconClass: "bx bx-food-menu", text: menuCount },
    { id: 1, title: "User Order", iconClass: "mdi mdi-order-bool-ascending-variant", text: orderCount },
    { id: 4, title: "University", iconClass: "bx bx-building-house", text: universityCount },
    { id: 2, title: "Locations", iconClass: "bx bx-map", text: locationCount },
  ];


  const handleOrderHistoryClick = () => {
    navigate('/orderhistory');
  };

  const filterHandler = (value: any) => {
    if (value == 1) {
      setIsUserOrderChart(true);
      setIsLocationChart(false);
      setIsMenuChart(false);
      setIsUniversityChart(false);
    } else if (value == 2) {
      setIsLocationChart(true);
      setIsMenuChart(false);
      setIsUniversityChart(false);
      setIsUserOrderChart(false);
    } else if (value == 3) {
      setIsMenuChart(true);
      setIsLocationChart(false);
      setIsUniversityChart(false);
      setIsUserOrderChart(false);
    } else if (value == 4) {
      setIsUniversityChart(true);
      setIsMenuChart(false);
      setIsLocationChart(false);
      setIsUserOrderChart(false);
    }
  }

  const getDashboardStats = () => {
    setLoader(true);
    let token: any = getTokenDetail();
    let roleIdValue = parseInt(token.RoleId, 10);
    let restaurantId = localStorage.getItem("restaurentId");
    CommonService.postWithQueryParam("UserOrder", "GetMasterDashboard", "restaurentId", roleIdValue == Role.Admin ? restaurantId : "",).then(res => {
      if (res.status == 200) {
        setDashboardStats(res.data.responseData);
        const locationData = res.data.responseData.filter(item => item.subType == "Location");
        setLocationCount(locationData[0].dataCount);
        const menuData = res.data.responseData.filter(item => item.entityType == "Stats" && item.subType == "Menu");
        setMenuCount(menuData[0].dataCount);
        const universityData = res.data.responseData.filter(item => item.subType == "University");
        setUniversityCount(universityData[0].dataCount);
        const orderData = res.data.responseData.filter(item => item.subType == "Summary");
        setOrderCount(orderData[0].dataCount);
        const menuChartData = res.data.responseData.filter(item => item.entityType == "Menu");
        setMenuChartData(menuChartData);
        const locationChartData = res.data.responseData.filter(item => item.entityType == "Location");
        setLocationChartData(locationChartData);
        const universityChartData = res.data.responseData.filter(item => item.entityType == "University");
        setUniversityChartData(universityChartData);
        const monthlyChartData = res.data.responseData.filter(item => item.entityType == "Summary" && item.subType == "Monthly");
        setMonthlyOrderChartData(monthlyChartData);
        const dailyChartData = res.data.responseData.filter(item => item.entityType == "Summary" && item.subType == "Daily");
        setDailyOrderChartData(dailyChartData);
        setLoader(false);
      }
    })
  }

  useEffect(() => {
    getDashboardStats()
  }, []);

  return (
    <div className="container-fluid bg-gray" data-aos="fade-down">
      <div className="page-content">
        <Breadcrumb title="Dashoard" breadcrumbItem="Dashboard" breadcrumbTitle={""}
          breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard"} />
        <Row >
          {statsCards.map((val, i) => (
            <Col lg={3} xl={3} md={3}>
              <Card className="mini-stats-wid" style={{ cursor: "pointer", }} onClick={() => filterHandler(val.id)}>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium"> {val.title} </p>
                      <h4 className="mb-0">{val.text}</h4>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle icon-class-color">
                        <i className={"bx " + val.iconClass + " font-size-24"} ></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
          {isLocationChart && <Card>
            <CardBody>
              <CardTitle tag="h4">Locations</CardTitle>
              <LocationChart data={locationChartData} />
            </CardBody>
          </Card>}
          {isMenuChart && menuChartData.length > 0 &&
            <Card >
              <CardBody>
                <CardTitle tag="h4">Menu</CardTitle>
                <MenuChart data={menuChartData} />
              </CardBody>
            </Card>
          }
          {isUniversityChart &&
            <Card>
              <CardBody>
                <CardTitle tag="h4">University</CardTitle>
                <Universitychart data={universityChartData} />
              </CardBody>
            </Card>
          }
          {isUserOrderChart &&
            <><Card data-aos="fade-up">
              <CardBody>
                <CardTitle tag="h4" >Monthly Order</CardTitle>
                <Row>
                  <Col lg={12} className='align-content-center'>
                    <MonthlyOrderChart data={montlyOrderChartData} />
                  </Col>
                </Row>
              </CardBody>
            </Card><Card data-aos="fade-up">
                <CardBody>
                  <CardTitle tag="h4" >Daily Order</CardTitle>
                  <Row>
                    <Col>
                      <DailyOrderChart data={dailyOrderChartData} />
                    </Col>
                  </Row>
                </CardBody>
              </Card></>

          }
        </Row>
      </div>
      <Loader open={loader} />

    </div >
  )

}
export default Dashboard