
import { useStripe, useElements, PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import CommonService from 'Services/CommonService';
import "./StripePayment.css"
import { Dispatch, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Loader } from 'Components/Loader/Loader';

type Props = {
    restaurantId: any,
    callBack: any,
    amount: any,
    loginId: any,
    setPaymentStart: Dispatch<{ paymentProcess: boolean, orderCreation: boolean }>,
    isPaymentStart: { paymentProcess: boolean, orderCreation: boolean },
    setIsPaymentOpen: any,
    setStripePromise: any,
    menuName: any
}

const StripePayment = ({ callBack, amount, isPaymentStart, menuName }: Props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isPaymentErr, setIsPaymentErr] = useState("");
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     if (!stripe || !elements) {
    //         return;
    //     }

    //     const paymentMethod = await stripe.createPaymentMethod({
    //         type: "card",
    //         card: elements.getElement('card') as StripeCardElement
    //     });

    //     if (paymentMethod.paymentMethod?.id) {
    //         setPaymentStart({ paymentProcess: true, orderCreation: false });
    //     }
    //     let decodedId = DecodeParam(restaurantId);
    //     let data = {
    //         RestaurantId: decodedId,
    //         PaymentMethodId: paymentMethod.paymentMethod?.id,
    //         LoginId: loginId,
    //         Amount: amount
    //     }

    //     CommonService.post("Payment", "CreatePaymentDetails", data).then((res) => {
    //         // setIsLoader(true);
    //         if (res.status == 200) {
    //             if (res.data) {
    //                 let key = res.data;
    //                 onStripeClick(key);
    //                 // callBack(key);
    //             }
    //         }
    //     });

    // };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        setLoader(true);
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
        });
        if(error){
            setLoader(false)
            setIsModelOpen(true);
            setIsPaymentErr(error.message as string);
        }
        if (paymentIntent) {
            onStripeClick(paymentIntent.id);
        }
    };

    const onStripeClick = (paymentKey: any) => {
        CommonService.postWithQueryParam("Payment", "GetPaymentResponse", "paymentId", paymentKey).then(res => {
            if (res.status === 200) {
                if (res.data == "succeeded") {
                    // setIsLoader(false);
                    setLoader(false)
                    callBack(paymentKey);
                }
                else {
                    // setIsLoader(false);
                    setLoader(false)
                    setIsModelOpen(true);
                    setIsPaymentErr("OOPS, Your Payment Failed, Try Again!");
                }
            }
        })
    }

    const onClose = () => {
        setIsModelOpen(false);
    }

    const onRetryClicked = () => {
        setIsModelOpen(false);
        window.location.reload();
    }

    const intialLoad = () => {
        const load = stripe?.paymentRequest({
            currency: "usd",
            country: "US",
            requestPayerEmail: true,
            requestPayerName: true,
            total: {
                amount: amount * 100,
                label: menuName
            }
        });

        load?.canMakePayment().then(res => {
            if (res) {
                setPaymentRequest(load);
            }
        });
    }

    useEffect(() => {
        // intialLoad();
    }, []);

    return (
        <><form onSubmit={handleSubmit}>
            <div className="row p-2">
                <div className="col-12" style={{ display: "grid", alignItems: "center" }}>
                    {paymentRequest &&
                        <div className='mb-3'>
                            <span className='line-or-line'></span>
                            <PaymentRequestButtonElement options={{ paymentRequest }} />
                        </div>
                    }
                    <PaymentElement options={{ paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna'] }} className='custom-StripeElement' />
                </div>
                <Loader open={loader} />
                <div className="col-12 text-center mt-2">
                    <Button className='btn btn-success payment-btn' disabled={!stripe || isPaymentStart.paymentProcess}>Pay Now</Button>
                </div>
            </div>
        </form>
            <div className="container">
                <Modal isOpen={isModelOpen} toggle={onClose}>
                    <ModalBody>
                        {isPaymentErr && <><span style={{
                            color: "red", fontSize: "15px", fontWeight: "500", textAlign: "left", margin: "6px"
                        }}><i className="fas fa-arrows-rotate"></i>{isPaymentErr}</span></>}
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ width: '80px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} type="button" onClick={() => onClose()}>No</Button>
                        <Button style={{ backgroundColor: '#E75605', width: '80px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }} type={"submit"} onClick={() => onRetryClicked()}>Retry</Button>
                    </ModalFooter>
                </Modal>
            </div></>
    )
};

export default StripePayment;