import Breadcrumb from "Components/Common/Breadcrumb";
import { Loader } from "Components/Loader/Loader";
import CustomDataTable from "Components/Shared/CustomDataTable";
import { CustomDatePicker } from "Components/Shared/CustomDatePicker";
import { CustomDropdown } from "Components/Shared/CustomDropdown";
import { formatDate } from "Components/constants/PandaConstants";
import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { forkJoin } from "rxjs";

const RegistereduserDashboard = () => {
    const [registeredUser, setRegisteredUser] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [selectedColumn, setSelectedColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [loader, setLoader] = useState(false);
    const [selectedMenuIds, setSelectedMenuIds] = useState<any>(1);
    const [rowPerPageValue, setRowPerPageValue] = useState(10);
    const [universityMaster, setUniversityMaster] = useState<any>([]);
    const [filterDetails, setFilterDetails] = useState({
        fromDate: '',
        toDate: "",
        universityId: "",
        isVerified: ''
    });
    const [dateValues, setDateValues] = useState<{ fromDate: any, toDate: any }>({ fromDate: '', toDate: "" });
    document.title = "Students | Panda GoGoGo ";


    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'id',
            Filter: true,
            displayInList: false,
            width: "0%"
        }, {
            Header: 'Name',
            accessor: 'name',
            width: "15%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Email',
            accessor: 'email',
            width: "18%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Mobile Number',
            accessor: 'mobileNumber',
            width: "18%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'University',
            accessor: 'universityName',
            width: "18%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Order Count',
            accessor: 'orderCount',
            width: "13%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Date',
            accessor: 'created',
            width: "25%",
            displayInList: true,
            Filter: true,
            datatype: 'date',
            sortable: true,
        },
        {
            Header: 'Status',
            accessor: 'isVerified',
            datatype: "isverifiedstatus",
            Filter: true,
            sortable: true,
            width: '10%',
        },
    ], []);

    const sampledata = [{
        id: 1,
        name: "Today"
    },
    {
        id: 2,
        name: "This week"
    }, {
        id: 3,
        name: "This month"
    },
    ]

    const isVerifiedData = [{
        id: 0,
        name: 'Not verified Users'
    }, {
        id: 1,
        name: 'Verified Users '
    }
    ]

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromDate: "",
            toDate: "",
            universityId: "",
            isVerified: ''
        },
        onSubmit: (values: any) => {
            getAllRegistereduserBySearch(1, "");
        }
    });

    const filterHandler = (type: "fromDate" | "toDate" | "universityId" | "isVerified", val: any, fromDate?: any, toDate?: any) => {
        if (type == "fromDate" || type == "toDate") {
            filterDetails.fromDate = fromDate;
            filterDetails.toDate = toDate;
            setFilterDetails(filterDetails);
        }
        else {
            filterDetails[`${type}`] = val;
            setFilterDetails(filterDetails);
        }

        getAllRegistereduserBySearch(1, "", null, null, null, filterDetails.universityId, filterDetails.fromDate, filterDetails.toDate, filterDetails.isVerified)
    }


    const handleFilter = (value) => {
        setSelectedMenuIds(value);
        let fromDates = "";
        let toDates = "";
        dateValues.fromDate = "";
        dateValues.toDate = "";
        const date = new Date();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        if (value == 1) {
            validation.setFieldValue("fromDate", formatDate(date));
            validation.setFieldValue("toDate", formatDate(tomorrow));
            fromDates = formatDate(date);
            toDates = formatDate(tomorrow);
        } else if (value == 2) {
            const dayOfWeek = date.getDay();
            const diffFromMonday = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
            const monday = new Date(date.setDate(diffFromMonday));
            const sunday = new Date(date.setDate(diffFromMonday + 6));
            validation.setFieldValue("fromDate", formatDate(monday));
            validation.setFieldValue("toDate", formatDate(sunday));
            fromDates = formatDate(monday);
            toDates = formatDate(sunday);
        } else if (value == 3) {
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            validation.setFieldValue("fromDate", formatDate(firstDayOfMonth));
            validation.setFieldValue("toDate", formatDate(lastDayOfMonth));
            fromDates = formatDate(firstDayOfMonth);
            toDates = formatDate(lastDayOfMonth);
        }
        else if (value == 4) {
            const tomorrowNextDay = new Date(new Date().setDate(new Date().getDate() + 2));
            validation.setFieldValue("fromDate", formatDate(tomorrow));
            validation.setFieldValue("toDate", formatDate(tomorrowNextDay));
            fromDates = formatDate(tomorrow);
            toDates = formatDate(tomorrowNextDay);
        }
        filterHandler("fromDate", fromDates, fromDates, toDates);
    }

    const clear = () => {
        filterDetails.universityId = "";
        dateValues.fromDate = '';
        dateValues.toDate = "";
        filterDetails.isVerified = ""
        setSelectedMenuIds(1);
        setFilterDetails(filterDetails);
        const date = new Date();
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        filterDetails.fromDate = "";
        filterDetails.toDate = "";
        getAllRegistereduserBySearch(1, "", null, null, null, null, null, null)
    }

    const getAllRegistereduserBySearch = (pageNumber: any, searchText: string, sortcolumn?: any, sortorder?: any, rowPerPageVal?: any, universityId?: any, startDate?: any, endDate?: any, isVerified?: any) => {
        setLoader(true);
        let searchModel = {
            offsetStart: pageNumber,
            rowsPerPage: rowPerPageVal > 0 ? rowPerPageVal : rowPerPageValue,
            sortOrder: sortorder ? sortorder : "Desc",
            searchText: searchText ? searchText : "",
            sortOrderColumn: sortcolumn ? sortcolumn : "modified",
            universityId: universityId > 0 ? universityId : null,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : formatDate(new Date()),
            isVerified: isVerified == undefined || isVerified == "" ? null : (isVerified == "1" ? true : false)
        }
        CommonService.post("User", "GetAllRegisteredUserBySearch", searchModel).then(res => {
            setLoader(false);
            if (res.status == 200) {
                let data = res.data.responseData;
                setRegisteredUser(data);
                setLoader(false);
            }
        })
    }

    const pagination = (pageNumber: any) => {
        getAllRegistereduserBySearch(pageNumber, search, selectedColumn, sortOrder);
    }

    const onColumnSortChange = (columnName, sortorder) => {
        setSelectedColumn(columnName);
        setSortOrder(sortorder);
        getAllRegistereduserBySearch(1, search, columnName, sortOrder);
    };

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getAllRegistereduserBySearch(1, searchKeyword);
    }


    const rowPerPageHandler = (val) => {
        setRowPerPageValue(val);
        getAllRegistereduserBySearch(1, "", null, null, val);
    }


    useEffect(() => {
        let university = CommonService.get("University", "GetAllUniversityMaster");

        forkJoin({ university }).subscribe((res: { university: any }) => {
            setUniversityMaster(res.university.data.responseData);
        })
    }, [])

    useEffect(() => {
        getAllRegistereduserBySearch(1, "", null, null, null, null, null, null)
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title="Students List" breadcrumbItem="Dashboard" breadcrumbTitle={""} breadcrumbTitlePath={"/dashboard"} breadcrumbItemPath={"/dashboard"} />
                <Loader open={loader} />
                <Card>
                    <CardBody>
                        <Row className="mb-0">
                            <Col lg={4}>
                                <CustomDatePicker label={"Registered From"} id={undefined} value={moment(dateValues.fromDate).format("YYYY-MM-DD")} onChange={(e) => { setDateValues({ ...dateValues, fromDate: e.target.value }); filterHandler("fromDate", e.target.value, e.target.value, dateValues.toDate); setSelectedMenuIds(0) }} error={undefined} />
                            </Col>
                            <Col lg={4}>
                                <CustomDatePicker label={"Registered To"} id={undefined} value={moment(dateValues.toDate).format("YYYY-MM-DD")} onChange={(e) => { setDateValues({ ...dateValues, toDate: e.target.value }); filterHandler("toDate", e.target.value, dateValues.fromDate, e.target.value); setSelectedMenuIds(0) }} error={undefined} />
                            </Col>
                            <Col lg={4}>
                                <CustomDropdown
                                    label="University" id="universityId"
                                    data={universityMaster}
                                    onChange={(e) => { filterHandler("universityId", e.target.value); }}
                                    placeholder="Universtiy"
                                    name="universityId"
                                    error={validation.errors.universityId}
                                    touched={validation.touched.universityId}
                                    displayField={"displayName"}
                                    valueField={"id"}
                                    value={filterDetails.universityId}
                                    onBlur={undefined}
                                    invalid={undefined} />
                            </Col>
                        </Row>
                        <Row >
                            <Col lg={4} className="mb-3">
                                <CustomDropdown
                                    label={"User Status"}
                                    id={"verifiedId"}
                                    data={isVerifiedData}
                                    value={filterDetails.isVerified}
                                    onChange={(e) => filterHandler("isVerified", e.target.value)}
                                    // onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    error={validation.errors.isVerified}
                                    invalid={validation.touched.isVerified && validation.errors.isVerified ? true : false}
                                    touched={validation.touched.isVerified}
                                    valueField={"id"}
                                    displayField={"name"} />
                            </Col>
                            {/* <Col lg={6} className="mt-4 mb-3 custom-border">
                                <div className="border-label">
                                    <label className="border-label-text">Registered</label>
                                    <CustomSingleCheckbox
                                        label={""}
                                        className={"col-4 mb-2 registration-custom-checkbox"}
                                        id={"ids"}
                                        value={undefined}
                                        onChange={handleFilter}
                                        invalid={undefined}
                                        error={undefined}
                                        data={sampledata}
                                        isHorizontal={true}
                                        selectedValues={selectedMenuIds}
                                        valueField={"id"}
                                        displayField={"name"} />
                                </div>
                            </Col> */}
                            <Col lg={8} className="mt-5">
                                <div className='d-flex justify-content-end' >
                                    <Button
                                        style={{ width: '75px', height: '37px', borderRadius: '5px', border: '1px', padding: '1px 11px 1px 11px' }}
                                        type={"button"} onClick={() => clear()} className="btn btn-secondary ms-2">Reset
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <CustomDataTable
                            rowPerPageValue={rowPerPageValue}
                            rowPerPageHandler={rowPerPageHandler}
                            columns={columns}
                            data={registeredUser}
                            customPageSize={rowPerPageValue}
                            canShowButton={false} deleteHandler={undefined}
                            editHandler={undefined}
                            createHandler={undefined} path={""} canView={false} isPagination={true}
                            isGlobalFilter={true} canEdit={false} searchHandler={searchHandler}
                            selectedPage={pagination} perPageData={rowPerPageValue} onColumnSortChange={onColumnSortChange}
                            // showDeleteIcon='No'
                            isAction={false} isShowingPageLength={true} />
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default RegistereduserDashboard;