import CommonService from "Services/CommonService";
import { EncodeParam } from "../../helpers/helper";
import { ITokenModel } from "Models/ITokenModel";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Modal, Row } from "reactstrap";
import ProfileForm from "./ProfileForm";
import { CustomButton } from "Components/Shared/CustomButton";
import { Loader } from "Components/Loader/Loader";

const ProfileView = () => {
    const [isUserProfileData, setIsUserProfileData] = useState<any>();
    const [decryptid, setdecryptid] = useState<any>();
    const [isLoginId, setIsLoginId] = useState<any>();
    const [userModel, setUserModel] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false);

    let navigate = useNavigate();

    const { id } = useParams();
    const isEdit = !!id;

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            let token: string = localStorage.getItem("authUser") as string;
            let tokendata: ITokenModel = jwtDecode(token);
            let loginid = tokendata?.LoginId;
            getUsersByLoginId(loginid);
            setIsLoginId(loginid);
            let encodedId = EncodeParam(loginid);
            setdecryptid(encodedId);
        }

    }, []);

    const getUsersByLoginId = (id: any) => {
        setLoader(true);
        CommonService.getWithQueryParam("User", "GetUserByLoginId", "loginId", id).then(res => {
            if (res.status === 200) {
                setLoader(false);
                res.data.responseData.userAddress = null;
                let responseValue = res.data.responseData;
                setIsUserProfileData(res.data.responseData);
            }
        })
    }

    // const getUserAddressView = (id: any) => {
    //     CommonService.postWithQueryParam("User", "GetUserLoginbyUserAddressDetailsView", "loginId", id).then(res => {
    //         if (res.status == 200) {
    //             setUseraddressView(res.data.responseData);
    //         }
    //     })
    // }

    const onEditUser = () => {
        let encodedId = EncodeParam(isLoginId);
        setUserModel(true);
    }

    const modelcreate = () => {
        setUserModel(false);
        getUsersByLoginId(isLoginId);
    }

    const isCancel = () => {
        setUserModel(false);
    };

    return (
        <><div className="page-content">
            <Loader open={loader} />
            <Card>
                <CardBody>
                    <Row>
                        <Col lg={12} className="text-end">
                            <i className="fa fa-edit primary-color cursor-pointer fa-lg" onClick={() => onEditUser()}></i>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={3}>
                            <p className="text-muted"> Name : <span className="fw-bold primary-color">{isUserProfileData?.firstName} {isUserProfileData?.lastName}</span></p>
                        </Col>
                        <Col lg={3}>
                            <p className="text-muted"> Email :  <span className="fw-bold text-dark">{isUserProfileData?.email}</span> </p>
                        </Col>
                        <Col lg={3}>
                            <p className="text-muted"> Mobile :  <span className="fw-bold text-dark">{isUserProfileData?.mobileNumber}</span> </p>
                        </Col>
                        <Col lg={1}></Col>
                    </Row>
                </CardBody>
            </Card>
            <div className="d-flex justify-content-end ">
                <CustomButton
                    type="submit"
                    btnClassName="btn-secondary ms-2"
                    iconClasssName="bx bx-arrow-back"
                    name="Back"
                    onClick={() => navigate('/')}
                    disabled={disabled}
                />
            </div>
        </div>

            <div className="container">
                <Modal isOpen={userModel} size="lg" centered>
                    <div role='document'>
                        <div className='modal-content'>
                            <div className='modal-body m-3'>
                                <ProfileForm paramid={decryptid} canCancel={isCancel} isCreate={modelcreate} />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default ProfileView;