import CommonService from 'Services/CommonService';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Col, Card, CardBody, Container, Row } from 'reactstrap';
import "./OrderSummary.css";

import { QRCodeCanvas } from 'qrcode.react';
import { DecodeParam } from '../../helpers/helper';
import { useDispatch } from 'react-redux';
import { pageNameHandler } from 'slices/thunk';
import { Loader } from 'Components/Loader/Loader';
import moment from 'moment';
import { splitDate, splitDateTime } from 'Components/constants/PandaConstants';

function OrderSummary() {
    const [detail, setDeatil] = useState<Array<any>>([]);
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();

    document.title = "Order Summary | Panda GoGoGo ";

    useEffect(() => {
        const GetUserOrderByOrderIdSearch = () => {
            setLoader(true);
            const location = window.location.href.split('?');
            const encryptedIds = location[1].split('/').filter(id => id);
            const decryptedIds = encryptedIds.map(id => DecodeParam(id));
            const ids = decryptedIds.join(',');
            CommonService.postWithQueryParam("UserOrder", "GetUserOrderByOrderIdSearch", "orderId", ids).then(res => {
                if (res.status == 200) {
                    setLoader(false);
                    let data = res.data.responseData;
                    data.map((order, index) => {
                        order.deliveryTime = splitDateTime(order.deliveryCutOffStartTime,order.deliveryCutOffEndTime);
                    })
                    setDeatil(data);
                }
            });
        }
        GetUserOrderByOrderIdSearch();
    }, []);

    const dispatch: any = useDispatch();

    useEffect(() => {
        dispatch(pageNameHandler({ pageName: "Order Summary" }));
    }, []);

    const renderOrderHistoryCards = () => {

        const isStepActive = (step) => {
            if (step === 1) {
                return detail[0].statusId < 4 || detail[0].statusId === 8;
            } else if (step === 2) {
                return detail[0].statusId === 6 || detail[0].statusId === 7 || detail[0].statusId === 8;
            } else if (step === 3) {
                return detail[0].statusId === 8;
            }
            return false;
        };

        return (
            <Col xl={10} lg={10} md={12} sm={12} xs={12} className="my-orders default-height">
                {detail.map((val, i) => (
                    <Card key={i} style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                        <CardBody className="d-flex justify-content-between">
                            <div className="container-fluid justify-content-center">
                                <div className="card" style={{ boxShadow: "unset" }}>
                                    <div className="bg-white">
                                        <div className="row justify-content-between">
                                            <div className="col mb-2">
                                                <p onClick={() => navigate("/my-orders")} className="primary-color my-order-btn"> My Orders  <i className="fa fa-arrow-right myorder_arrow"></i></p>
                                            </div>
                                            <Row>
                                                <Col lg={6} md={12} sm={12} xs={12}>
                                                    <p> Order ID <span className="fw-bold primary-color os-text"> #{val.formattedOrderId}</span></p>
                                                </Col>
                                                <Col lg={6} md={12} sm={12} xs={12}>
                                                    <p className="text-muted"> Placed On : <span className="fw-bold text-dark os-text">{moment(val.orderDate).format('ddd, DD MMM YYYY')}</span> </p>
                                                </Col>
                                                <Col lg={6} md={12} sm={12} xs={12}>
                                                    <p>Order Status : <span className="font-weight-bold primary-color os-text">{val.statusName}</span></p>
                                                </Col>
                                                <Col lg={6} md={12} sm={12} xs={12}>    
                                                    <p className="text-muted nowrap">Pickup Date & Time : <span className="fw-bold text-dark os-text">{val.deliveryDate == null ? ' -' : moment(val.deliveryDate).format('YYYY-MM-DD')}</span>
                                                        <p className="fw-bold text-dark os-text">{val.deliveryTime == null ? ' -' : val.deliveryTime}</p></p>
                                                </Col>
                                                <Col lg={6} md={12} sm={12} xs={12}>
                                                    <p className="text-muted"> Pickup Location : <span className="fw-bold text-dark os-text">{val.pickupAddressName}</span></p>
                                                </Col>
                                            </Row>

                                            <div className="media mb-0" style={{ position: 'relative' }}>
                                                <Row>
                                                    <Col lg={8} md={8} sm={12} xs={12}>
                                                        <h5 style={{ whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '10px' }}>{val?.menuName} - <span className="bold primary-color text-center nowrap">${val?.amount}</span></h5>
                                                        <p className="os-card-description">{val?.description}</p>
                                                    </Col>
                                                    <Col lg={4} md={4} sm={12} xs={12}>
                                                        <div className='qr-code'>
                                                            <QRCodeCanvas className="qr-code-adjustment" value={JSON.stringify({ id: val.id, menuName: val.menuName, statusName: val.statusName }, null, 2)} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                ))}
            </Col>
        );
    }

    return (
        <React.Fragment>
            <Container fluid style={{ backgroundColor: 'white', overflowX: "hidden" }}>
                <Loader open={loader} />
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card style={{ backgroundColor: 'white', boxShadow: "unset" }}>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-xl-1 col-lg-1 col-md-0 col-sm-0 col-xs-0'></div>
                                    {detail && renderOrderHistoryCards()}
                                    <div className='col-xl-1 col-lg-1 col-md-0 col-sm-0 col-xs-0'></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default OrderSummary
