import React, { useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, Form } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import forgotprofile from "../../assets/images/login/forgotprofile1.png"
import { CustomEmailBox } from "Components/Shared/CustomEmailBox";
import { HelperConstant } from "Components/constants/PandaConstants";
import CommonService from "Services/CommonService";
import { useDispatch } from "react-redux";
import { saveUserNameHandler } from "slices/thunk";
import { Loader } from "Components/Loader/Loader";

const ForgetPasswordPage = (props) => {

    const [toast, SetToast] = useState(false);
    const [authErr, setAuthErr] = useState("");
    const [encryptedPassword, setEncryptedPassword] = useState("");
    const [message, setMessage] = useState<any>("");
    const [loader, setLoader] = useState(false);

    const dispatch: any = useDispatch();
    let navigate = useNavigate();

    document.title = "Forgot Password | Panda GoGoGo ";


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email").matches(HelperConstant.emailPattern.pattern, 'Please Enter a Valid Email'),
        }),
        onSubmit: () => {
            resetpassword();
        }
    });

    const resetpassword = () => {
        setLoader(true);

        CommonService.getWithSingleParam("Auth", "ForgetPassword", validation.values.email).then(res => {
            if (res.data == "New User") {
                setLoader(false);
                return setAuthErr(res.data);
            }
            if (res.status === 200) {
                setLoader(false);
                setAuthErr("")
                setMessage("Shortly, You Will Receive the Reset Password link in Your Mail !!");
                setTimeout(() => {
                    window.location.href = "/login";
                }, 4000);

            }
        })
    }

    const decryptedPassword = (passsword: any) => {
        CommonService.getWithSingleParam("Auth", "DecryptPassword", passsword).then(res => {
            if (res.status === 200) {
                setEncryptedPassword(res.data);
            }
        });
    }

    const navigateHandler = (path: string) => {
        navigate(path);
        dispatch(saveUserNameHandler({ userName: validation.values.email, password: encryptedPassword }));
    }

    return (
        <React.Fragment>
            <div className="account-pages pt-sm-5 forgot-app">
                <Loader open={loader} />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={2} lg={3} xl={3}></Col>
                        <Col md={8} lg={6} xl={6}>
                            <Card className="forgot-card-style" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                <div className="background-grid">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h4 className="header-text-grid">Welcome Back !</h4>
                                                <p className="header-text-grid">Reset your password to Continue with Panda GoGoGo.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={forgotprofile} alt="" className="profile-forgot" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0 mx-2 mt-2">
                                    <div className="p-2">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            {authErr &&
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <Alert
                                                            color="warning"
                                                            className="fade show"
                                                            role="alert"
                                                        >
                                                            <i className="mdi mdi-alert-outline me-2 "></i>
                                                            You are <b>{authErr}</b> if you want to join with us <Link to={"/register"}> <b>Click Here</b></Link>
                                                        </Alert>
                                                    </div>
                                                </div>
                                            }
                                            {message &&
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <Alert
                                                            color="success"
                                                            className="fade show"
                                                            role="alert"
                                                        >
                                                            <b>{message}</b>
                                                        </Alert>
                                                    </div>
                                                </div>
                                            }
                                            <div className="mb-3">
                                                <Col lg={12}>
                                                    <CustomEmailBox label="Email" name="email" id="email" placeholder="Enter Email" value={validation.values.email}
                                                        onChange={validation.handleChange} onBlur={validation.handleBlur} invalid={validation.errors.email && validation.touched.email ? true : false}
                                                        error={validation.errors.email} isMandatory={true} />
                                                </Col>
                                            </div>
                                            {/* <Row className="mb-3">
                                                <Col className="text-end">
                                                    <button
                                                        className="btn btn-primary w-md "
                                                        type="submit"
                                                    >
                                                        Reset
                                                    </button>
                                                </Col>
                                            </Row> */}
                                            <div className="mt-4 d-grid">
                                                {/* <button className="loginbtn" type="submit">Sign In</button> */}
                                                <Col className="text-end">
                                                    <button
                                                        className="loginbtn w-md"
                                                        type="submit"
                                                    >Reset</button>
                                                </Col>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className="mt-3 text-center">
                                        <p>
                                            Go back to{" "}
                                            <Link to="/login" className="fw-medium text-primary">
                                                Login
                                            </Link>{" "}
                                        </p>
                                        <p> Pandagogogo © {new Date().getFullYear()}. All Rights Reserved..</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={2} lg={3} xl={3}></Col>
                    </Row>
                </Container>
            </div>
            {/* <CustomToaster message={Toaster_ENUM.resetPassword.text} color={"text-success"} toast={toast} setToast={SetToast} path="/login" /> */}
        </React.Fragment>
    );
};

export default withRouter(ForgetPasswordPage);