import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    user: "",
    userName: "",
    error: "",// for error msg
    loading: false,
    isUserLogout: false,
    password: "",
    errorMsg: false,// for error
    isOrderCountChange: false,
    pageName: "",
    isOrderDeliverChange: false,
    startDate: "",
    endDate: ""
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginSuccess(state, action) {

            state.user = action.payload
            state.loading = false;
            state.errorMsg = false;
        },
        apiError(state, action) {
            state.error = action.payload;
            state.loading = true;
            state.isUserLogout = false;
            state.errorMsg = true;
        },
        resetLoginFlag(state) {
            // state.error = null;
            state.error = "";
            state.loading = false;
            state.errorMsg = false;
        },
        logoutUserSuccess(state, action) {
            state.isUserLogout = true
        },
        saveUserName(state, action) {
            state.userName = action.payload.userName;
            state.password = action.payload.password;
        },
        saveDeliveryDate(state, action) {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate
        },
        saveOrderCount(state, action) {
            state.isOrderCountChange = action.payload.isOrderCountChange
        },
        saveOrderDeliver(state, action) {
            state.isOrderDeliverChange = action.payload.isOrderDeliverChange
        },
        pageNameController(state, action) {
            state.pageName = action.payload.pageName
        }
    }
});
export const { loginSuccess, apiError, resetLoginFlag, logoutUserSuccess, saveUserName, saveDeliveryDate, saveOrderCount, saveOrderDeliver, pageNameController } = loginSlice.actions;
export default loginSlice.reducer;