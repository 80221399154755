import { DecodeParam } from "../../helpers/helper";
import CustomDataTable from "Components/Shared/CustomDataTable";
import { IColumnModel } from "Models/IColumnModel";
import CommonService from "Services/CommonService";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { forkJoin } from "rxjs";
import "./RestaurantStyle.css";
import { IUserRestaurantSearchModel } from "Models/IRestaurantSearchModel";
import UserRestaurantForm from "pages/UserRestaurant/UserRestaurantForm";
import { Loader } from "Components/Loader/Loader";

const RestaurantView = () => {
    const [userModel, setUserModel] = useState(false);
    const [restaurantById, setRestaurantById] = useState<any>({});
    const [isGridLoginId, setIsGridLoginId] = useState<any>();
    const [isEncryptedPasswod, setIsEncryptedPassword] = useState<any>();
    const [isDecodedRestaurantId, setIsDecodedRestaurantId] = useState<any>();
    const [userRestaurantSearch, setUserRestaurantSearch] = useState<any>([]);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(true);
    const [isdelete, setIsDelete] = useState();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

    const [isAdding, setIsAdding] = useState(false);
    document.title = "Restaurant View | Panda GoGoGo ";


    const columns: IColumnModel[] = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'customerName',
            width: "30%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Id',
            accessor: 'loginId',
            Filter: true,
            sortable: true,
            displayInList: false,
            width: "0%"
        },
        {
            Header: 'password',
            accessor: 'encryptedPassword',
            Filter: true,
            sortable: true,
            displayInList: false,
            width: "0%"
        },
        {
            Header: 'Mobile',
            accessor: 'mobileNumber',
            width: "30%",
            displayInList: true,
            Filter: true,
            sortable: true,
        },
        {
            Header: 'Role',
            accessor: 'userRoleName',
            width: "30%",
            displayInList: true,
            Filter: true,
            sortable: true,
        }, {
            Header: 'Active',
            accessor: 'isVerifiedUser',
            width: "0%",
            displayInList: false,
            Filter: true,
            sortable: true,
        },

    ], []);


    let navigate = useNavigate();
    const { id } = useParams();

    const onAddClick = () => {
        let decodedRestaurantId = DecodeParam(id);
        setIsDecodedRestaurantId(decodedRestaurantId);
        setIsAdding(true);
        setUserModel(true);
    }
    const onEditClick = () => {
        navigate(`/restaurant/edit/${id}`)
    }

    const isCancel = () => {
        setIsAdding(false);  // Reset the flag when the modal is canceled
        setUserModel(false);
        window.location.reload();
    }


    const getUserRestaurantBySearch = (pagenumber: any, searchText: string, sortorder?: string,) => {
        setLoader(true);
        let deocodedIdForSearch = DecodeParam(id);
        let searchModel: IUserRestaurantSearchModel = {
            offsetStart: pagenumber,
            rowsPerPage: 5,
            sortOrder: "Desc",
            searchText: searchText ? searchText : "",
        }
        CommonService.postWithSingleParam("UserRestaurent", "GetUserRestaurentByLoginandRestaurentSearch", "restaurentId", deocodedIdForSearch, searchModel).then(res => {
            if (res.status == 200) {
                let data: Array<IUserRestaurantSearchModel> = res.data.responseData;
                setUserRestaurantSearch(data);
                setLoader(false);
            }
        })
    }

    const pagination = (pageNumber: any) => {
        getUserRestaurantBySearch(pageNumber, search);
    }

    const searchHandler = (searchKeyword: any) => {
        setSearch(searchKeyword);
        getUserRestaurantBySearch(1, searchKeyword);
    }

    useEffect(() => {
        getUserRestaurantBySearch(1, "")
    }, []);

    useEffect(() => {
        let decodedId = DecodeParam(id);
        let decodedRestaurantId = DecodeParam(id);
        setIsDecodedRestaurantId(decodedRestaurantId);
        let getRestaurantById = CommonService.postWithQueryParam("PickupAddress", "GetPickupAddressView", "restaurentId", decodedId);

        forkJoin({ getRestaurantById }).subscribe((res: { getRestaurantById: any }) => {
            setRestaurantById(res.getRestaurantById.data.responseData);
        });
    }, []);

    const modelcreate = () => {
        setIsAdding(false);  // Reset the flag when the form is created
        setUserModel(false);
        // window.location.reload();
        getUserRestaurantBySearch(1, "");
    }

    const editHandler = (val: any) => {
        setUserModel(true);
        setIsGridLoginId(val.loginId);
        setIsEncryptedPassword(val.encryptedPassword);
    }


    const deleteHandler = (val) => {
        setIsDelete(val.loginId);
        setShowDeleteConfirmation(true);
    }

    const confirmDelete = () => {
        setShowDeleteConfirmation(false);
        CommonService.putWithQueryParam("UserRestaurent", "GetRestaurantAdminInactive", "loginId", isdelete)
            .then(res => {
                if (res.data.responseData === true) {
                    setShowDeleteSuccess(true);
                    // getUserRestaurantBySearch(1, "");
                    window.location.reload();
                    // setTimeout(() => {
                    //     setShowDeleteSuccess(false);
                    //     getUniversityBySearch(1, "")
                    // }, 2000);
                }
                else {
                    console.error("Delete failed with an unexpected response:", res);
                }
            })
            .catch(e => console.log(e));
    }


    const cancelDelete = () => {
        setShowDeleteConfirmation(false);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col lg={12}>
                        <Col lg={12} className="text-end">
                            <p className="res_name text-uppercase">{restaurantById[0]?.restaurentName}</p>
                            <div className="edit-res">
                                <i className="fas fa-edit fa-lg" style={{ color: "#084a69" }} onClick={() => onEditClick()}></i>
                            </div>
                        </Col>
                        <p className="p-2 header-text-grid" style={{ textAlign: "center" }}>{restaurantById[0]?.address}, {restaurantById[0]?.cityName}, {restaurantById[0]?.stateName}, {restaurantById[0]?.countryName}</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={6}>
                                        <p className="header-text-grid fw-bold">User Details</p>
                                    </Col>
                                    <Col lg={6} className="text-end" style={{ marginTop: "6px", marginBottom: "10px" }}>
                                        <i className="fas fa-plus fa-lg" style={{ color: "#084a69" }} onClick={() => onAddClick()}></i>
                                    </Col>
                                </Row>
                                <Row>
                                    <Loader open={loader} />

                                    <CustomDataTable columns={columns} data={userRestaurantSearch} customPageSize={5}
                                        isAction={false} editHandler={editHandler} canShowButton={false} deleteHandler={deleteHandler}
                                        createHandler={undefined} path={""} canEdit={false} perPageData={5} isPagination={true}
                                        isGlobalFilter={true}
                                        selectedPage={pagination}
                                        isVerifiedaction={true}
                                        searchHandler={searchHandler} isShowingPageLength={true} />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="container">
                <Modal isOpen={userModel} size="lg" centered>
                    <div role='document'>
                        <div className='modal-content'>
                            <div className='modal-body m-3'>
                                <UserRestaurantForm
                                    isModelOpen={true}
                                    restaurantId={isDecodedRestaurantId}
                                    canCancel={isCancel}
                                    isCreate={modelcreate}
                                    isEditId={isGridLoginId}
                                    decryptedPassword={isEncryptedPasswod}
                                    isCreateMode={isAdding}  // Pass the flag to the form
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="container">
                <Modal isOpen={showDeleteConfirmation} toggle={cancelDelete}>
                    <ModalHeader toggle={cancelDelete}>Confirm Delete</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={cancelDelete}>No</Button>
                        <Button color="danger" onClick={confirmDelete}>Yes</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
            <div className="container">
                <Modal isOpen={showDeleteSuccess} toggle={() => setShowDeleteSuccess(false)}>
                    <ModalHeader toggle={() => setShowDeleteSuccess(false)}>Success</ModalHeader>
                    <ModalBody>
                        Deleted successfully.
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment >
    )
}

export default RestaurantView;