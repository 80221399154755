import React from 'react'
import "./Footers.css";
import { Col, Container, Row } from 'reactstrap';

import logo from "../../assets/images/logo.png";
import twitter from "../../assets/images/twitter.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";

function Footers() {

    const socialIcons = [twitter, facebook, instagram];

    return (
        <React.Fragment>
            <div className="container-fluid bg-gray">
                <div className="container p-lg-5 p-2">
                    <Container className='mx-sm-5'>
                        <Row className='footer-row'>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12} className='text-justify'>
                                <h6>About PandaGoGoGo</h6>
                                <div className="text">
                                    We work with local restaurants to provide cost- effective lunch delivery to you.You need to sign in, select your meal, pay for it, and be ready to pick up your lunch
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12} className='text-center  pt-md-0 pt-4'>
                                <img src={logo} className='mb-2'></img>
                            </Col>
                            {/* <Col xl={2} lg={2} md={4} sm={6} xs={6} className=' text-justify pt-md-0 pt-4'>
                                <h6>Reach us at</h6>
                                <p><i className='fa fa-map-marker' ></i> Texas , Texas</p>
                                <p><i className='bx bxs-phone-call' ></i> + 123 123 123123</p>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="container-fluid bg-white">
                <div className="container p-3">
                    <Row className='footer-row'>
                        <Col lg={4} md={4} sm={12} xs={12} className='text-center'><p style={{ fontSize: "12px" }} className='cursor-pointer' onClick={() => {
                            window.location.href = "/terms"
                        }}>Terms & Conditions</p></Col>
                        <Col lg={4} md={4} sm={12} xs={12} className='text-center'>
                            <h6>Pandagogogo © 2024. All Rights Reserved. </h6>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12} className='text-center'><p style={{ fontSize: "12px" }} className='cursor-pointer'
                            onClick={() => {
                                window.location.href = "/privacy-policy"
                            }}>Privacy Policy</p></Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footers