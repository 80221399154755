import { EncodeParam, getTokenDetail } from '../../helpers/helper';
import { CustomDropdown } from 'Components/Shared/CustomDropdown';
import { CustomEmailBox } from 'Components/Shared/CustomEmailBox';
import { CustomPasswordBox } from 'Components/Shared/CustomPasswordBox';
import { CustomPhoneNumberBox } from 'Components/Shared/CustomPhoneNumberBox';
import { CustomTextBox } from 'Components/Shared/CustomTextBox';
import { CustomToaster } from 'Components/Shared/CustomToaster';
import { HelperConstant, Role, Toaster_ENUM } from 'Components/constants/PandaConstants';
import { IUserCreationModel } from 'Models/IUserCreationModel';
import CommonService from 'Services/CommonService'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Modal, ModalHeader, Row } from 'reactstrap';
import { forkJoin } from 'rxjs';
import * as Yup from "yup";

type props = {
    paramid?: any;
    canCancel?: any;
    isCreate?: any
}
const ProfileForm = ({ paramid, canCancel, isCreate }: props) => {
    const [isUserProfileData, setIsUserProfileData] = useState<any>();
    const [existingEmail, setExistingEmail] = useState("");
    const [existingMobile, setExistingMobile] = useState("");
    const [toast, SetToast] = useState(false);
    const [universityMaster, setUniversityMaster] = useState<any>([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [roleId, setRoleId] = useState(0);
    const [universityId, setUniversityId] = useState(0);
    const [modal_center, setmodal_center] = useState(false);
    const [loader, setLoader] = useState(true);

    const { id } = useParams();
    const isEdit = !!id;

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
            password: '',
            confirmpassword: '',
            universityId: '',
            // checkbox: termsChecked,
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please Enter Your First Name"),
            lastName: Yup.string().required("Please Enter Your Last Name"),
            email: Yup.string().required("Please Enter Your Email").matches(HelperConstant.emailPattern.pattern, 'Please Enter a Valid Email'),
            mobileNumber: Yup.string().required("Please Enter Your Mobile Number").matches(HelperConstant.phonenumberPattern.pattern, "Please Enter valid Mobile Number"),
            password: Yup.string().required("Please Enter Your Password"),
            confirmpassword: Yup.string().required("Please Enter Your Confirm Password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
            // checkbox: Yup.string().notRequired(),
        }),
        onSubmit: (values: any) => {
            if (isUserProfileData.email == validation.values.email && isUserProfileData.mobileNumber == validation.values.mobileNumber) {
                updateUser(values);
            }
            else if (isUserProfileData.email == validation.values.email && isUserProfileData.mobileNumber !== validation.values.mobileNumber) {
                validatePhoneNumber(values);
            }
            else if (isUserProfileData.email !== validation.values.email && isUserProfileData.mobileNumber !== validation.values.mobileNumber) {
                validateEmail(values);
            }
            else {
                validateEmail(values);
            }
        }
    });

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }


    const validateEmail = (values: any) => {
        CommonService.getWithSingleParam("User", "RegisteredUserName", values.email).then(res => {
            if (res.status === 200) {
                if (res.data == "You are already Registered Email.") {
                    return setExistingEmail(res.data);
                }
                else {
                    setExistingEmail("");
                    if (isUserProfileData.mobileNumber !== validation.values.mobileNumber) {
                        validatePhoneNumber(values);
                    }
                    else {
                        updateUser(values);
                    }
                }
            }
        })
    }

    const validatePhoneNumber = (values: any) => {
        CommonService.getWithSingleParam("User", "RegisteredMobileNo", values.mobileNumber).then(res => {
            if (res.status === 200) {
                if (res.data == "You are already Registered MobileNo.") {
                    return setExistingMobile(res.data);
                }
                else {
                    setExistingMobile("");
                    updateUser(values);
                }
            }
        })
    }

    const isCancel = () => {
        canCancel();
    }

    const updateUser = (data: IUserCreationModel) => {

        const formData = new FormData();
        formData.append("id", isUserProfileData.id as any);
        formData.append("firstName", validation.values.firstName);
        formData.append("lastName", validation.values.lastName);
        formData.append("email", validation.values.email);
        formData.append("mobileNumber", validation.values.mobileNumber);
        formData.append("uploadedFile", null as any);
        formData.append("uploadfilepath", null as any);
        formData.append("profileImage", null as any);
        if (roleId == Role.User) {
            formData.append("universityId", validation.values.universityId > 0 ? validation.values.universityId : universityId as any);
        }
        formData.append("userLogin.id", isUserProfileData.userLogin.id as any);
        formData.append("userLogin.userName", validation.values.email);
        formData.append("userLogin.password", EncodeParam(validation.values.password));
        formData.append("userLogin.userRoleId", roleId as any);
        formData.append("userAddress", null as any);
        CommonService.postWithFormData("User", "Updateuser", formData).then(res => {
            if (res.status === 200) {
                isCancel();
                SetToast(true);
                isCreate();
                setTimeout(() => {
                    setLoader(false);
                    window.localStorage.clear();
                    window.location.href = "/";
                }, 2000);
            }
        })
    }

    const getUsersByLoginId = () => {
        CommonService.getWithQueryParam("User", "GetUserByLoginId", "loginId", paramid).then(res => {
            if (res.status === 200) {
                let responseValue = res.data.responseData;
                setIsUserProfileData(res.data.responseData);
                CommonService.getWithSingleParam("Auth", "DecryptPassword", responseValue.userLogin.password).then(response => {
                    if (response.status === 200) {
                        responseValue.userLogin.password = response.data as any;
                        validation.setValues({
                            ...responseValue,
                            firstName: responseValue.firstName,
                            lastName: responseValue.lastName,
                            email: responseValue.email,
                            mobileNumber: responseValue.mobileNumber,
                            universityId: responseValue.universityId,
                            password: response.data.toString(),
                            confirmpassword: response.data.toString(),
                        });
                        setUniversityId(responseValue.universityId);
                    }
                });
            }
        })
    }

    useEffect(() => {
        if (paramid) {
            getUsersByLoginId();
        }
        let tokenData: any = getTokenDetail();
        let roleId = parseInt(tokenData?.RoleId, 10);
        setRoleId(roleId);
        if (roleId == Role.Admin || roleId == Role.SuperAdmin) {
            setIsAdmin(true);
        }
        let university = CommonService.get("University", "GetAllUniversityMaster");

        forkJoin({ university }).subscribe((res: { university: any }) => {
            setUniversityMaster(res.university.data.responseData);
        });
    }, [])

    return (
        <><div className='page-content p-1'>
            <p className="header-text-grid fw-bold fa-lg">Update Profile</p>
            <div onClick={() => isCancel()} className="btn-close position-absolute end-0 top-0 m-3" ></div>
            <Form onSubmit={validation.handleSubmit}>
                <Row>
                    <Col lg={6}>
                        <CustomTextBox id="firstName"
                            placeholder="Enter First Name" name="firstName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName}
                            invalid={validation.touched.firstName && validation.errors.firstName ? true : false}
                            label="First Name" error={validation.errors.firstName} isMandatory={true} />
                    </Col>
                    <Col lg={6}>
                        <CustomTextBox id="lastName"
                            placeholder="Enter Last Name" name="lastName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName}
                            invalid={validation.touched.lastName && validation.errors.lastName ? true : false}
                            label="Last Name" error={validation.errors.lastName} isMandatory={true} />
                    </Col>
                    <Col lg={6}>
                        <CustomEmailBox label="Email" name="email" id="email" placeholder="Enter Email" value={validation.values.email}
                            onChange={(e) => { validation.handleChange(e); setExistingEmail(""); }} onBlur={validation.handleBlur} invalid={validation.errors.email && validation.touched.email ? true : false}
                            error={validation.errors.email} isMandatory={true} emailvalidationError={existingEmail} />

                    </Col>
                    <Col lg={6}>
                        <CustomPhoneNumberBox label="Mobile Number" id="mobileNumber" placeholder="Enter Mobile Number" name="mobileNumber"
                            value={validation.values.mobileNumber} error={validation.errors.mobileNumber}
                            onChange={(e) => { validation.handleChange(e); setExistingMobile(""); }} onBlur={validation.handleBlur} touched={validation.touched.mobileNumber}
                            invalid={validation.errors.mobileNumber && validation.touched.mobileNumber ? true : false} isMandatory={true} phoneValidationError={existingMobile} />
                    </Col>
                    <Col lg={6}>
                        <CustomPasswordBox
                            label="Password"
                            placeholder="Enter Password"
                            id="password"
                            value={validation.values.password}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            error={validation.errors.password}
                            touched={validation.touched.password}
                            invalid={validation.errors.password && validation.touched.password ? true : false}
                            isMandatory={true}
                        />
                    </Col>
                    <Col lg={6}>
                        <CustomPasswordBox
                            name="confirmpassword"
                            label="Confirm Password"
                            placeholder="Enter Confirm Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmpassword}
                            touched={validation.touched.confirmpassword}
                            invalid={validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false}
                            error={validation.errors.confirmpassword}
                            isMandatory={true} />
                    </Col>
                    {!isAdmin ? <Col lg={6}>
                        <CustomDropdown label="University" id="universityId" value={validation.values.universityId} data={universityMaster}
                            error={validation.errors.universityId} valueField="id" displayField='displayName' onChange={validation.handleChange}
                            onBlur={validation.handleBlur} invalid={validation.touched.universityId && validation.errors.universityId ? true : false}
                            touched={validation.touched.universityId} isMandatory={true} disabled={true} />
                    </Col> : null}

                </Row>
                <div className="mt-4 d-grid">
                    <Col className="text-end">
                        <button className="btn btn-secondary  w-md ms-2" type="button" onClick={() => isCancel()}>
                            Cancel
                        </button>
                        <button
                            className="loginbtn w-md ms-2"
                            type="button" onClick={tog_center}
                        >{"Update"}</button>
                    </Col>
                </div>
            </Form>
        </div>
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader toggle={() => {
                    tog_center();
                }}>
                    <div className="modal-title mt-0 h5"></div>
                </ModalHeader>
                <div className="modal-body">
                    <Form onSubmit={validation.handleSubmit}>
                        <p >When you update your profile, you will be logged out.</p>
                        <p className='fw-bold text-center' style={{ fontSize: '18px' }}>Do you want to continue?</p>
                        <div className="mt-4 d-grid">
                            <Col className="text-end">
                                <button className="btn btn-secondary w-md ms-2" type="button" onClick={() => isCancel()}>
                                    Cancel
                                </button>
                                <button
                                    className="loginbtn w-md ms-2"
                                    type="submit"
                                >{"Continue"}</button>
                            </Col>
                        </div>
                    </Form>
                </div>
            </Modal>
            <CustomToaster message={Toaster_ENUM.editUser.text} color={"text-success"} toast={toast} setToast={SetToast} path={""} /></>

    )
}

export default ProfileForm;