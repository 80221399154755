import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Label } from "reactstrap";

interface CustomImageUploadProps extends InputHTMLAttributes<HTMLInputElement> {
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} name reference of the elements*/
    name?: any;
    /** @param {any} id defines the id of the DOM element */
    id: any,
    /** @param {any} className return the value of an element's class*/
    className?: any,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder: any;
    /** @param {any} value is used to set or get the value for the input field*/
    value: any;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur?: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid?: any;
    /** @param {any} touched it takes boolean values as input and it sets to true if a field is clicked*/
    touched?: any,
    /** @param {any} onFileSelected it emit the file size, name and file and you should use one variable to hold the values or */
    onFileSelected: any,
    /**@param {any} disabled it doesn't allow to click more than once */
    disabled?: any,
    /** @param {any} customClass it shows the validation error styles */
    customClass?: any,
    isProfileUpload?: boolean,
    /** @param {any} isMandatory is defines the if it's mandatory or not*/
    isMandatory?: boolean;
    isEditForm?: any;
    isEditPageImage?: any;
    isPicupaddressImage?: any;
    index?: any;
}

interface fileObject {
    path: string,
    name: string,
    size: number,
    type: string
}
export const CustomImageUpload = React.forwardRef(({ label, touched, placeholder, name, id, value, onBlur, invalid, error, disabled, customClass, onFileSelected, isProfileUpload, isMandatory, isEditForm, isEditPageImage, isPicupaddressImage, index }: CustomImageUploadProps, ref) => {
    const [files, setfiles] = useState([]);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    let selectedFile: Array<fileObject> = [];
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
    });

    const thumbs = acceptedFiles.map(file => (
        <div className={`${isProfileUpload ? "thumb-profile" : "thumb"}`} key={file.name}>
            <div className="thumbInner">
                <img
                    src={file.preview}
                    className={`${isProfileUpload ? "circular-profile-image" : "img circular-image"}`}
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const deleteFile = () => {
        setselectedFiles([]);
    }

    // useEffect(() => {
    //     if (acceptedFiles || isEditForm) {
    //         if (acceptedFiles.length > 0) {
    //             setfiles(acceptedFiles.map(file => Object.assign(file, {
    //                 preview: URL.createObjectURL(file)
    //             })));
    //             selectedFile.push(acceptedFiles[0]);
    //             setselectedFiles(selectedFile);
    //             onFileSelected(acceptedFiles[0].name, acceptedFiles[0].size, acceptedFiles[0]);
    //         } else {
    //             onFileSelected("", "");
    //         }
    //     }
    // }, [acceptedFiles])

    useEffect(() => {
        if (acceptedFiles || isEditForm) {
            if (acceptedFiles.length > 0) {
                setfiles(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                selectedFile.push(acceptedFiles[0]);
                setselectedFiles(selectedFile);
                onFileSelected(acceptedFiles[0].name, acceptedFiles[0].size, acceptedFiles[0], index);
            } else {
                onFileSelected("", "", "", index);
            }
        }
    }, [acceptedFiles]);

    return (
        <><section className="container img-adjustment">
            <div className={customClass}>
                <Label className="mt-3 mb-1">{label}</Label>{isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
                {selectedFiles.length > 0 ?
                    <div className="selected-image-container">
                        <div className="image-wrapper">
                            {thumbs}
                            <div className="delete-icon">
                                <i className="mdi mdi-close-circle-outline" onClick={deleteFile} />
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <>{isEditForm ? <><div className={`${isProfileUpload ? "custom-profile-dropzone" : "custom-image-dropzone"}`}>
                            <div className="dropzone">
                                <div className="custom-dz-message"
                                    {...getRootProps()}
                                >
                                    <>{isEditPageImage && isPicupaddressImage == undefined ? <img
                                        src={isEditPageImage}
                                        className={`${isProfileUpload ? "circular-profile-image" : "img circular-image"}`} /> :

                                        (isPicupaddressImage && isEditPageImage == undefined ? <img
                                            src={isPicupaddressImage}
                                            className={`${isProfileUpload ? "circular-profile-image" : "img circular-image"}`} /> : "")}</>
                                    <input {...getInputProps()}
                                        id={id}
                                        type="file"
                                        name={name}
                                        value={value}
                                        onBlur={onBlur}
                                        touched={touched}
                                        invalid={invalid}
                                        error={error}
                                        disabled={disabled} />
                                    {/* <div>
                                        <i className="fas fa-upload" />
                                    </div> */}
                                </div>
                            </div>
                        </div></> : <div className={`${isProfileUpload ? "custom-profile-dropzone" : "custom-image-dropzone"}`}>
                            <div className="dropzone">
                                <div className="custom-dz-message"
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()}
                                        id={id}
                                        type="file"
                                        name={name}
                                        value={value}
                                        onBlur={onBlur}
                                        touched={touched}
                                        invalid={invalid}
                                        error={error}
                                        disabled={disabled} />
                                    {!isEditForm && !isEditPageImage &&
                                        <div style={{ cursor: "pointer" }}>
                                            <i className="fas fa-upload" />
                                        </div>}
                                </div>
                            </div>
                        </div>}</>
                    </>}
            </div>
            {error || touched ? (
                <span className="text-danger fa-xs">{error}</span>
            ) : null}
        </section>
        </>
    )
})