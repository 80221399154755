//Author : Kavitha Sri

import React, { InputHTMLAttributes } from "react";
import Switch from "react-switch";
import { Label } from "reactstrap";

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
    /**@param {any} checkedIcon is refers positive side of toggle*/
    checkedIcon?: any,
    /**@param {any} uncheckedIcon is refers negative side of toggle*/
    uncheckedIcon?: any,
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: any,
    /** @param {any} onColor is defines the color of the switch*/
    onColor?: any,
    /** @param {any} checkedState refers if the button is toggled or not*/
    checkedState: any,
    /** @param {any} positiveText is positive text of toggle switch*/
    positiveText: any,
    /** @param {any} negativeText is negative text of toggle switch*/
    negativeText: any,
    /** @param {any} label specifies the title of the text track */
    label: any
    /** @param {any} isHorizontal specifies the label position is horizontal or vertical */
    isHorizontal?: boolean
}

export const CustomSwitch = React.forwardRef(({ onChange, onColor, checkedState, negativeText, positiveText, label, isHorizontal, ...rest }: inputProps, ref) => {

    const OnSymbol = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: 12, color: "#fff", paddingRight: 2 }}>
                {positiveText}
            </div>
        );
    };

    const Offsymbol = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: 12, color: "#fff", paddingRight: 2 }}>
                {negativeText}
            </div>
        );
    };

    return (
        <>
            {isHorizontal ? (<div>
                <Label>{label}</Label>
                <Switch ref={ref} {...rest as any}
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2 ms-3"
                    onColor={onColor}
                    onChange={onChange}
                    checked={checkedState}
                />
            </div>) : (
                <><Label className="mt-3 mb-1">{label}</Label><div>
                    <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor={onColor}
                        onChange={onChange}
                        checked={checkedState} />
                </div></>
            )}
        </>
    )
})