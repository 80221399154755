import React, { useEffect } from 'react'
import "./AboutUs.css"
import banner from "../../assets/images/aboutus/banner.jpg"
import { useDispatch } from 'react-redux';
import { pageNameHandler } from 'slices/thunk';

function AboutUs() {

    const dispatch: any = useDispatch();
    document.title = "About Us | Panda GoGoGo ";

    useEffect(() => {
        dispatch(pageNameHandler({ pageName: "About Us" }));
    }, []);

    return (
        <section className="about-section bg-white">
            <div className="container">
                <div className="row">
                    <div data-aos="fade-left" className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                        <div className="inner-column">
                            <div className="sec-title">
                                <span className="title">About PandaGOGOGO</span>
                                <h2>We work with local restaurants to provide cost- effective lunch delivery to you.</h2>
                            </div>
                            <div className="text">You need to sign in, select your meal, pay for it, and be ready to pick up your lunch</div>
                            {/* <div className="btn-box">
                                <a href="/contact-us" className="theme-btn btn-style-one">Contact Us</a>
                            </div> */}
                        </div>
                    </div>
                    <div data-aos="fade-right" className="image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column wow fadeInLeft">
                            <div className="author-desc">
                                <h2>Panda GOGOGO</h2>
                                <span>FOODS</span>
                            </div>
                            <figure className="image-1"><a href="#" className="lightbox-image" data-fancybox="images"><img title="Rahul Kumar Yadav" src={banner} alt="" /></a></figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs