import React, { InputHTMLAttributes } from "react";
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

interface inputProps {
    /**@param {any} type defines which type of input control to display */
    type?: any;
    /** @param {any} name reference of the elements*/
    name?: any;
    /** @param {any} label specifies the title of the text track */
    label: any,
    /** @param {any} id defines the id of the DOM element */
    id: any,
    /** @param {any} className return the value of an element's class*/
    className?: any,
    /** @param {any} placeholder what kind of information is expected in the field*/
    placeholder?: any;
    /** @param {any} value is used to set or get the value for the input field*/
    value: any;
    /** @param {any} onChange is detects when the value of an input element changes.*/
    onChange: (selectedValues: Array<any>) => void;
    /** @param {any} onBlur is fires the moment that the element loses focus*/
    onBlur?: any;
    /** @param {any} invalid occurs when you're trying to perform on an invalid value.*/
    invalid: any;
    /** @param {any} error interferes with the proper operation of the program*/
    error: any;
    /** @param {any} touched it takes boolean values as input and it sets to true if a field is clicked*/
    touched?: any,
    data: Array<any>;
    selectedValues: any;
    minValues?: number;
    maxValues?: any;
    /** @param {any} isHorizontal defines the direction of the data arrangement */
    isHorizontal?: any;
    /** @param {any} valueField is defines the field value*/
    valueField: any,
    /** @param {any} displayField is defines the field display*/
    displayField: string,
}

export const CustomSingleCheckbox = React.forwardRef(({ data, selectedValues, onChange, className, label, id, onBlur, invalid, error, touched, minValues = 0, maxValues = data.length, isHorizontal, displayField, valueField }: inputProps, ref) => {
    
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
        const isChecked = event.target.checked;
        const updatedValue = isChecked ? value : null;
        onChange(updatedValue);
    };

    const isChecked = selectedValues !== null && selectedValues !== undefined;

    return (
        <>
            {isHorizontal ? (
                <><Label check>{label}</Label><FormGroup  row>
                    {data.map((item, index) => (
                        <div key={index} className={className}>
                            <FormGroup check className="mr-1">
                                <Input
                                    type="checkbox"
                                    checked={selectedValues === item[valueField]}
                                    onChange={(event) => handleCheckboxChange(event, item[valueField])}
                                    onBlur={onBlur}
                                    invalid={invalid && touched}
                                    error={error}
                                    id={`${id}-${index}`} />
                                {item[displayField]}
                            </FormGroup>
                        </div>
                    ))}
                </FormGroup></>) : (<><Label check>{label}</Label><FormGroup className={className}>
                    {data.map((item, index) => (
                        <FormGroup check key={index} className="registration-custom-checkbox" >
                            <Input
                                type="checkbox"
                                checked={selectedValues === item[valueField]}
                                onChange={(event) => handleCheckboxChange(event, item[valueField])}
                                onBlur={onBlur}
                                invalid={invalid && touched}
                                error={error}
                                id={`${id}-${index}`} />
                            {item[displayField]}
                        </FormGroup>
                    ))}
                </FormGroup></>)}
        </>
    );
}
);
