import { getTokenDetail, routeHandlerThroughUser } from '../../helpers/helper';
import Banner from 'Components/Banner/Banner'
import PopularMenu from 'Components/PopularMenu/PopularMenu'
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageNameHandler } from 'slices/thunk';

export default function Home() {
    useEffect(() => {
        routeHandlerThroughUser();
    }, []);

    const dispatch: any = useDispatch();

    useEffect(() => {
        dispatch(pageNameHandler({ pageName: "Home" }));
    }, []);

    return (
        <div>
            {getTokenDetail()?.UserId ?
                null
                :
                <Banner />
            }
            {/* <HowToOrder /> */}
            {/* <PopularRestaurant /> */}
            <PopularMenu />
        </div>
    )
}
