import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row } from 'reactstrap';
import "../OrderCreation/OrderCreation.css";
import { useNavigate } from 'react-router-dom';
import { getTokenDetail } from 'helpers/helper';
import { IOrderSearchModel } from 'Models/IOrderSearchModel';
import CommonService from 'Services/CommonService';
import { QRCodeCanvas } from 'qrcode.react';
import { ITicketModal } from 'Models/ITicketsModel';
import { ITokenModel } from 'Models/ITokenModel';
import { jwtDecode } from 'jwt-decode';
import myorderimg from '../../../assets/images/no-orders.png'
import { Loader } from 'Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { pageNameHandler } from 'slices/thunk';
import moment from 'moment';
import { splitDate, splitDateTime } from 'Components/constants/PandaConstants';
import { RFC_2822 } from 'moment';

const MyOrders = () => {

    const [menu, setMenu] = useState<any>([]);
    const [isModalOpen, setisModalOpen] = useState(false);
    const [comments, setComments] = useState("");
    const [subjects, setSubjects] = useState("");
    const [loginDetail, setLoginDetail] = useState<ITokenModel>();
    const [orderId, setOrderId] = useState<any>();
    const [loader, setLoader] = useState(true);

    let navigate = useNavigate();
    document.title = "My Orders | Panda GoGoGo ";

    const getOrderBySearch = (pageNumber: any, searchText: string, sortorder?: string,) => {
        setLoader(true);
        let token: any = getTokenDetail();
        let searchModel: IOrderSearchModel = {
            offsetStart: 1,
            rowsPerPage: 1000,
            sortOrder: sortorder ? sortorder : "",
            searchText: searchText ? searchText : "",
            userId: parseInt(token.UserId),
            restaurentId: null
        }
        CommonService.post("UserOrder", "GetUserOrderByUser", searchModel).then(res => {
            if (res.status == 200) {
                let data = res.data.responseData;
                data.map((order, index) => {
                    order.deliveryTime = splitDateTime(order.deliveryCutOffStartTime, order.deliveryCutOffEndTime);
                })
                const uniques = data.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === value.id
                    ))
                )
                setLoader(false);
                setMenu(uniques);
            }
        });
    }

    const toggle = (orderId) => {
        setisModalOpen(!isModalOpen);
        setComments("");
        setOrderId(orderId);
        // if (orderId) {
        //     setSubjects(orderId);
        // }
        // else {
        //     setSubjects("");
        // }

    }

    const createHandler = () => {
        let data: ITicketModal = {
            id: 0,
            active: true,
            userId: loginDetail?.UserId,
            comments: `${orderId} - ${comments}`,
            ticketCommunication: null,
            userRoleId: loginDetail?.RoleId,
            universityId: loginDetail?.UniversityId,
            subject: `${subjects} - ${orderId}`
        }
        submitHandler(data);
    }

    const submitHandler = (data: ITicketModal) => {
        CommonService.post("UserTicket", "CreateUserTicket", data).then((res => {
            if (res.status == 200) {
                setisModalOpen(false);
                setComments("");
                setSubjects("");
                window.location.href = "/tickets";
            }
        })).catch(e => console.log(e));
    }


    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            let token: string = localStorage.getItem("authUser") as string;
            let tokendata: ITokenModel = jwtDecode(token);
            setLoginDetail(tokendata);
        }
    }, []);

    useEffect(() => {
        getOrderBySearch(1, "");
    }, []);

    const dispatch: any = useDispatch();

    useEffect(() => {
        dispatch(pageNameHandler({ pageName: "My Orders" }));
    }, []);

    const renderOrderHistoryCards = () => {
        const isStepActive = (step, value: any) => {
            if (step === 1) {
                return value.statusId == 1 || value.statusId === 6 || value.statusId === 7 || value.statusId === 8;
            } else if (step === 2) {
                // if (isStepActive(1, value)) {
                //     return true;
                // }
                return value.statusId === 6 || value.statusId === 7 || value.statusId === 8;
            } else if (step === 3) {
                // if (!isStepActive(2, value)) {
                //     return false;
                // }
                return value.statusId === 8;
            }
            return false;
        };

        const stepValue = (value) => {
            if (value.statusId < 8) {
                return 0;
            } else if (value.statusId == 8) {
                return 100;
            }
        }
        let isActive = false;
        const stepActive = (step, value) => {
            if (step == 1 && value.statusId >= 1) {
                return true
            } else if (step == 2 && value.statusId == 8) {
                isActive = true;
                return true
            }
        }


        const progressColor = isActive ? '#E75605' : '#adb8c1';

        return menu.sort((a, b) => b.id - a.id).map(value => (
            <Col xl={6} lg={6} md={12} sm={6} xs={12} key={value.id} className="my-orders">
                <Card style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                    <CardBody className="d-flex justify-content-between">
                        <div className="container-fluid justify-content-center">
                            <div className="row justify-content-between">
                                <div className='col-12 text-end delete-icon'>
                                    <i className="bx bxs-coupon cursor-pointer" onClick={() => toggle(value.formattedOrderId)}></i>
                                </div>
                                <Row>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <p className='mb-1'> Order ID : <span className="font-weight-bold primary-color mb-0 mo-text">{value.formattedOrderId}</span></p>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <p className="mb-1" >Order Status : <span className="font-weight-bold primary-color mo-text">{value.externalStatusName}</span></p>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <p className="text-muted mb-1"> Placed On <span className="font-weight-bold text-dark mb-0 mo-text">{moment(value.created).format('ddd, DD MMM YYYY')}</span> </p>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12}>
                                        <p className='mb-1' style={{ whiteSpace: "nowrap" }}> Pickup Date & Time : <span className="font-weight-bold primary-color mo-text">{value?.deliveryDate == null ? ' -' : moment(value?.deliveryDate).format('YYYY-MM-DD')}</span></p>
                                        <p className="font-weight-bold primary-color mo-text">{value?.deliveryTime == null ? ' -' : value?.deliveryTime}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <p>Pickup Location : <span className="font-weight-bold primary-color mo-text" >{value.pickupAddressName}, </span><p className="font-weight-bold primary-color mo-text" >{value.cityName}, {value.stateName}, {value.countryName} - {value.zipCodeName}</p></p>
                                    </Col>
                                </Row>
                            </div>
                            <div className="media mb-0" style={{ position: 'relative' }}>
                                <Row>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <h5 style={{ whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '10px' }}>{value.menuName} - <span className="mt-3 mb-4 bold">${value.amount}</span></h5>
                                        <p className="myorder-card-description">{value.description}</p>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12}>
                                        <div className='qr-code'>
                                            <QRCodeCanvas value={JSON.stringify({ id: value.id, menuName: value.menuName, statusName: value.statusName }, null, 2)} />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                            {/* <div className="col">
                                <ul id="progressbar">
                                    <li className={`step0 ${isStepActive(1, value) ? 'active' : ''}`} id="step1">Order Placed</li>
                                    <li className={`step0 ${isStepActive(2, value) ? 'active' : ''} text-center`} id="step2">Available for Pickup</li>
                                    <li className={`step0 ${isStepActive(3, value) ? 'active' : ''} text-center`} id="step3">Order Delivered</li>
                                </ul>
                            </div> */}
                            <div className="position-relative m-5 col">
                                <Progress
                                    value={stepValue(value)}
                                    color={`${progressColor}`}
                                    style={{ height: '1.5px' }}
                                ></Progress>
                                <button className={`position-absolute top-0 start-0 translate-middle btn btn-sm ${stepActive(1, value) ? "btn-primary" : "btninactive"} rounded-pill`} style={{ width: "2rem", height: "2rem" }}>
                                    1
                                </button>
                                <span className="position-absolute top-100 start-0 translate-middle mt-4" style={{ left: "0", right: "0", textAlign: "center" }}>
                                    Order Placed
                                </span>
                                <button className={`position-absolute top-0 start-100 translate-middle btn btn-sm ${stepActive(2, value) ? "btn-primary" : "btninactive"} rounded-pill`} style={{ width: "2rem", height: "2rem" }}>
                                    2
                                </button>
                                <span className="position-absolute top-100 start-100 translate-middle mt-4">
                                    Delivered
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        ));
    }

    return (
        <React.Fragment>
            <Container fluid style={{ backgroundColor: '#fff', overflowX: "hidden" }} className='default-height'>
                {/* <Row className='mo-heading'>
                    <Col className='text-center'>
                        <h5 style={{ display: 'inline-block', marginRight: '10px', marginBottom: "-20px" }}>My Orders</h5>
                    </Col>
                </Row> */}
                {(menu.length === 0 && !loader) &&
                    <Row className="justify-content-center">
                        <img src={myorderimg} style={{ width: "80%" }} /> </Row>}
                <Loader open={loader} />

                <Row className="justify-content-center">
                    <Col lg={12} md={12}>
                        <Card style={{ backgroundColor: '#fff', boxShadow: "unset" }}>
                            <CardBody>
                                <Row>
                                    {renderOrderHistoryCards()}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={isModalOpen}>
                <ModalHeader toggle={() => toggle("")}>Raise a Ticket {orderId ? `(${orderId})` : ""}</ModalHeader>
                <ModalBody >
                    <Input type="text" value={subjects} onChange={(e) => setSubjects(e.target.value)} placeholder='Subject' style={{ width: "100%", marginBottom: "15px" }} />
                    <Input type="textarea" value={comments} onChange={(e) => setComments(e.target.value)} rows={5} placeholder='Write here...' style={{ width: "100%" }} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={comments == "" || subjects == ""} onClick={() => createHandler()}>
                        Submit
                    </Button>{' '}
                    <Button color="secondary" onClick={() => toggle("")}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default MyOrders;
